import * as requestFromServer from "./Crud";
import { InfluencerSlice, callTypes } from "./Slice";

const { actions } = InfluencerSlice;

export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (type === 'influencer_details') {
        dispatch(actions.influencerDetails(res.success));
        return;
      } else if (type === 'influencer_campaigns') {
        dispatch(actions.influencerCampaigns(res.success));
        return;
      } else if (type === 'summary') {
        dispatch(actions.influencerSummary(res.success));
        return;
      } else if (type === 'socials') {
        dispatch(actions.influencerSocials(res.success));
        return;
      } else if (type === 'demographics') {
        dispatch(actions.influencerDemo(res.success));
        return;
      } else if (type === 'accounts_summary') {
        dispatch(actions.influencerAccount(res.success));
        return;
      } else if (type === 'contact') {
        dispatch(actions.influencerContact(res.success));
        return;
      } else if (type === 'file') {
        dispatch(actions.influencerFile(res.success));
        return;
      }
      return res;
   })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const reset_info = () => dispatch => {
  dispatch(actions.reset());
};


export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }
};

export const editDetails = (body, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams)
    .then(response => {
      let res = response.data;
      console.log(res);
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};