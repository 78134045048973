
export default function StatsContact(contactd: any) {
  // console.log(contact);
  const contact = contactd?.contact;
  return (
    contact &&
    <div className="row g-5 mt-4 p-5">
      <div className="d-flex">
        <span className="d-block fs-5 text-gray-600  me-5">Fullname:</span>
        <span className="d-block fs-4 fw-bolder">{contact.Address?.FullName || ''}</span>
      </div>
      <div className="d-flex">
        <span className="d-block fs-5 text-gray-600  me-5">Phone:</span>
        <span className="d-block fs-4 fw-bolder">{contact.Address?.PhoneNumber || ''}</span>
      </div>
      <div className="d-flex">
        <span className="d-block fs-5 text-gray-600 me-5">Email:</span>
        <span className="d-block fs-4 fw-bolder">{contact.Address?.Email || ''}</span>
      </div>
      <div className="d-flex">
        <span className="d-block fs-5 text-gray-600 me-5">Location:</span>
        <span className="d-block fs-4 fw-bolder">{`${contact.Address?.City || ''}, ${contact.Address?.Country || ''}`}</span>
      </div>
    </div>
  )
}
