import * as requestFromServer from "./Crud";
import {DashboardSlice, callTypes} from "./Slice";

const {actions} = DashboardSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      return res;
   })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignDetails = (type = 'd', queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDetails(type, queryParams)
    .then(response => {
      let res = response.data;
      if (type == 'd') {
        dispatch(actions.campaignDetails(res.success));
      } else {
        return res.success;
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignStats = (type= 'd', queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStats(type, queryParams)
    .then(response => {
      let res = response.data;
      if (type == 'd') {
        dispatch(actions.campaignStats(res.success));
      } else {
        return res.success;
      }
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignEngagementRates = (id, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchEngagementRates(id, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignEngagementRates(res));
    })
    .catch(error => {
      if (error.response.status == 403) {
        dispatch(actions.campaignEngagementRatesError());
      }
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignScore = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchScore(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const reset_campaign_info = () => dispatch => {
  dispatch(actions.reset());
};


export const fetchDefault = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDafault()
    .then(response => {
      let res = response.data;
      dispatch(actions.default(res.success[0]));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const editDetails = (body, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams)
    .then(response => {
      let res = response.data;
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }
};
