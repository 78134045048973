import { metricValueFormatter } from "../../../../../../../helper";


interface Metric  {
    value: number;
    symbol: string;
}
interface DataProps {
    data: {
        Reach: Metric;
        Impressions: Metric;
        Likes: Metric;
        Comments: Metric;
        Saves: Metric;
        Engagements: Metric;
        ER: Metric;
        "Link Clicks": Metric;
        CTR: Metric;
    }

}

interface Props {
    data: any
}

export default function PerformanceMetrics({ data }: Props) {

    const performanceData = {
        Reach: {
            value:data?.reach,
            symbol:"unit"
        },
        Impressions: {
            value:data?.impressions,
            symbol:"unit"
        },
        Likes: {
            value:data?.likes,
            symbol:"unit"
        },
        Comments: {
            value:data?.comments,
            symbol:"unit"
        },
        Saves: {
            value:data?.saves,
            symbol:"unit"
        },
        Engagements: {
            value:data?.engagement,
            symbol:"unit"
        },
        ER: {
            value:data?.engangementRate,
            symbol:""
        },
        "Link Clicks":{
            value:data?.clicks,
            symbol:"unit"
        },
        CTR: {
            value:data.ctr,
            symbol:""
        },
    }

    return (
        <div style={{ width: "300px", maxWidth: "300px", }}>
            <h4
                className="text__24 NeueMontreal-bold text-dark text-align-center p-0"
                style={{ marginBottom: "24px"}}
            >Performance</h4>

            <div 
                style={{
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "space-between", 
                    flex: 1, 
                    gap: "20px"
                }}
            >
                {Object.keys(performanceData).map((dataKey, index) => {
                    const { symbol, value } = performanceData[dataKey as keyof typeof performanceData];

                    const formattedValue = symbol === "unit" ? metricValueFormatter(value || 0, 2) : `${value?.toFixed(2) || 0}%`;
                    return (
                        <div className="d-flex flex-column align-items-stretch"
                            // style={{ backgroundColor: "red", flexGrow: 1}}
                        >
                            <div 
                                className="d-flex justify-content-between align-items-stretch"
                                style={{ gap: "24px ", width: "100%", 
                                // marginBottom: "24px"
                            }}
                                key={index}
                            >
                                <p className="NeueMontreal text-dark text-align-center p-0 m-0"
                                    style={{fontSize: "20px", fontWeight: 400 }}
                                >
                                    {dataKey}
                                </p>

                                <p className="NeueMontreal text-align-center p-0 m-0"
                                    style={{ color: "#8C8C8C", fontSize: "20px", fontWeight: 400 }}
                                >
                                    {formattedValue || 0}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}