import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  listLoading: false,
  actionsLoading: false,
  campaignDetails: {},
  campaignScore: null,
  campaignStats: {},
  campaignEngagementRates: {},
  campaignEngagementRatesError: false,
  defaultCampaign: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    default: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.defaultCampaign = action.payload;
    },
    reset: (state, action) => {
      state.error = null;
      state.campaignDetails = {};
      state.campaignScore = null;
      state.campaignDetails = {};
      state.campaignEngagementRates = {};
      state.campaignEngagementRatesError = false;
      state = initialdatasState;
  
    },
    users: (state, action) => {
      state.listLoading = false;
      state.entities = action.payload;
      state.error = null;
    },
    // finddatas
    datasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dashboardData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.campaign_data = action.payload;
    },
    campaignDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignDetails = action.payload;
    },
    campaignStats: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignStats = action.payload;
    },
    campaignEngagementRates: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignEngagementRates = action.payload;
      state.campaignEngagementRatesError = false;
    },
    campaignScore: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignScore = action.payload;
    },
    campaignEngagementRatesError: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaignEngagementRatesError = true;
    },
  }
});
