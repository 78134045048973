import { CSSProperties } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as functions from "../../../../global/functions";

import {IoClose} from "react-icons/io5"

interface CustomSelectProps {
    file:File;
    className?: any;
    containerStyle?: CSSProperties;
    textStyle?: CSSProperties;
    onDelete?:() => void;
}

export const ImageCard = ({ containerStyle, className,onDelete, file, textStyle }: CustomSelectProps) => {
    
    return (
        
            <div 
                style={{ padding: '1rem 1.2rem', ...textStyle }}
                className="d-flex justify-between align-items-center "
            >
                <div className="d-flex w-full ">
                    <img src={toAbsoluteUrl('/media/cpg/csv-Icon.png')} alt="csv drop" style={{
                        width:"25px",
                        height:"35px",
                        marginRight:"5px"
                    }}/>
                    <div className="pl-2 d-flex flex__col">
                        <h3 className="text__black text__12 font__600" style={{overflow: "hidden", textOverflow:"ellipsis", 
                        whiteSpace: "nowrap" , marginBottom:"2px", maxWidth: "150px"}}>
                            {file?.name || ""}</h3>
                        <p 
                        className="text_gray text__12" 
                        style={{overflow: "hidden", textOverflow:"ellipsis", 
                        whiteSpace: "nowrap", margin:0, maxWidth: "150px"}}>
                            {functions.formatFileSize(file?.size || 0)}
                        </p>
                    </div>
                </div>


               <button onClick={onDelete} 
               className="d-flex font__600 align-items-center button text_white bg_delete_red p-3" 
               style={{
                border:"none",
                outline:"none",
                borderRadius:"8px",
               }}>
                Delete
                <IoClose className="text__16" style={{marginLeft:"2px"}}/>
               </button>
            </div>

    )
}

