import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import ValueChange from '../shared/ValueChange';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'


export default function SummaryHeadStats(props: any) {
    let { chosenBrand, timeSeries, dataToCompare } = props;





    let seriesStartDate = chosenBrand;
    let seriesEndDate = dataToCompare;



    let totalPostsTrendStart = (seriesStartDate?.instagramPosts || 0) + (seriesStartDate?.twitterTweets || 0) + (seriesStartDate?.facebookPosts || 0) + (seriesStartDate?.youTubePosts || 0) + (seriesStartDate?.tikTokPosts || 0);
    let totalPostsTrendEnd = (seriesEndDate?.instagramPosts || 0) + (seriesEndDate?.twitterTweets || 0) + (seriesEndDate?.facebookPosts || 0) + (seriesEndDate?.youTubePosts || 0) + (seriesEndDate?.tikTokPosts || 0);


    let totalPostsTrendChange: any = totalPostsTrendEnd>0 ? (((totalPostsTrendStart - totalPostsTrendEnd) / totalPostsTrendEnd) * 100).toFixed(2) : 0;





    let totalFollowersTrendStart = (seriesStartDate?.tikTokFollowers || 0) + (seriesStartDate?.instagramFollowedBy || 0) + (seriesStartDate?.facebookPageLikes || 0) + (seriesStartDate?.youTubeSubscribers || 0);
    let totalFollowersTrendEnd = (seriesEndDate?.tikTokFollowers || 0) + (seriesEndDate?.instagramFollowedBy || 0) + (seriesEndDate?.facebookPageLikes || 0) + (seriesEndDate?.youTubeSubscribers || 0);





    let totalFollowersTrendChange: any = totalFollowersTrendEnd>0 ? (((totalFollowersTrendStart - totalFollowersTrendEnd) / totalFollowersTrendEnd) * 100).toFixed(2) : 0;





    let totalVideoViewsTrendStart = (seriesStartDate?.twitterVideoViews || 0) + (seriesStartDate?.youTubePostViews || 0) + (seriesStartDate?.tikTokViews || 0) + (seriesStartDate?.reelviews || 0);
    let totalVideoViewsTrendEnd = (seriesEndDate?.twitterVideoViews || 0) + (seriesEndDate?.youTubePostViews || 0) + (seriesEndDate?.tikTokViews || 0) + (seriesEndDate?.reelviews || 0);


    let totalVideoViewsTrendChange: any = totalVideoViewsTrendEnd>0 ? (((totalVideoViewsTrendStart - totalVideoViewsTrendEnd) / totalVideoViewsTrendEnd) * 100).toFixed(2): 0;








    let totalEngagementTrendStart = (seriesStartDate?.twitterTweetEngagementTotal || 0) + (seriesStartDate?.youTubePostsEngagementTotal || 0) + (seriesStartDate?.tikTokPostsEngagementTotal || 0) + (seriesStartDate?.facebookPostEngagementTotal || 0) + (seriesStartDate?.instagramPostsEngagementTotal || 0);
    let totalEngagementTrendEnd = (seriesEndDate?.twitterTweetEngagementTotal || 0) + (seriesEndDate?.youTubePostsEngagementTotal || 0) + (seriesEndDate?.tikTokPostsEngagementTotal || 0) + (seriesEndDate?.facebookPostEngagementTotal || 0) + (seriesEndDate?.instagramPostsEngagementTotal || 0);




    let totalEngagementTrendChange: any = totalEngagementTrendEnd>0 ? (((totalEngagementTrendStart - totalEngagementTrendEnd) / totalEngagementTrendEnd) * 100).toFixed(2) : 0;



    let totalPosts = (chosenBrand?.instagramPosts || 0) + (chosenBrand?.twitterTweets || 0) + (chosenBrand?.facebookPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.tikTokPosts || 0);
    let totalFollowers = (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0);
    let totalVideoViews = (chosenBrand?.twitterVideoViews || 0) + (chosenBrand?.youTubePostViews || 0) + (chosenBrand?.tikTokViews || 0) + (chosenBrand?.reelviews || 0);
    let totalEngagement = (chosenBrand?.twitterTweetEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.instagramPostsEngagementTotal || 0);

    let youtubeTubeER = (((chosenBrand?.youTubePostsEngagementTotal || 0) / (chosenBrand?.youTubeSubscribers || 0)))

    let tiktokER = (((chosenBrand?.tikTokPostsEngagementTotal || 0) / (chosenBrand?.tikTokFollowers || 0)))
    let instagramER = (((chosenBrand?.instagramPostsEngagementTotal || 0) / (chosenBrand?.instagramFollowedBy || 0)))
    let facebookER = (((chosenBrand?.facebookPostEngagementTotal || 0) / (chosenBrand?.facebookPageLikes || 0)))

    let aveEngagementRate: any = totalFollowers>0 ? (totalEngagement / totalFollowers) * 100 : 0;
    aveEngagementRate = totalPosts>0 ? (aveEngagementRate|| 0)/ totalPosts : 0;
    





    // let youtubeTubePreviousER = (((dataToCompare?.youTubePostsEngagementTotal ||0)/ (dataToCompare?.youTubeSubscribers ||0)) )

    // let tiktokPreviousER = (((dataToCompare?.tikTokPostsEngagementTotal ||0)/ (dataToCompare?.tikTokFollowers ||0)) )
    // let instagramPreviousER = (((dataToCompare?.instagramPostsEngagementTotal ||0 )/ (dataToCompare?.instagramFollowedBy ||0 )) )
    // let facebookPreviousER = (((dataToCompare?.facebookPostEngagementTotal ||0)/ (dataToCompare?.facebookPageLikes ||0)) )


    let previousTotalFollowers = (dataToCompare?.tikTokFollowers || 0) + (dataToCompare?.instagramFollowedBy || 0) + (dataToCompare?.facebookPageLikes || 0) + (dataToCompare?.youTubeSubscribers || 0);
    let previousTotalPosts = (dataToCompare?.instagramPosts || 0) + (dataToCompare?.twitterTweets || 0) + (dataToCompare?.facebookPosts || 0) + (dataToCompare?.youTubePosts || 0) + (dataToCompare?.tikTokPosts || 0);
    let previousTotalEngagement = (dataToCompare?.twitterTweetEngagementTotal || 0) + (dataToCompare?.youTubePostsEngagementTotal || 0) + (dataToCompare?.tikTokPostsEngagementTotal || 0) + (dataToCompare?.facebookPostEngagementTotal || 0) + (dataToCompare?.instagramPostsEngagementTotal || 0);

    let aveEngagemenPrevioustRate: any = previousTotalFollowers>0? (previousTotalEngagement / previousTotalFollowers)*100 : 0;
    aveEngagemenPrevioustRate = previousTotalPosts>0? (aveEngagemenPrevioustRate|| 0)/ previousTotalPosts : 0;

    let totalAveEngagementRateTrendChange: any = (aveEngagemenPrevioustRate>0 && totalPosts>0) ? ((((aveEngagementRate - aveEngagemenPrevioustRate) / aveEngagemenPrevioustRate) * 100)/totalPosts).toFixed(2) : 0;

  

    const RoundUpNumber = ({ number, hasDecmals }: any) => {


        let valToUse = number.toFixed(0);

        if (number >= 1000000) {

            valToUse = `${Number(number / 1000000).toFixed(2)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${Number(number / 1000).toFixed(2)}`;
            return (
                <div className="value_txt">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${Number(number).toFixed(2)}`

            return (
                <div className="value_txt">{valToUse}</div>
            )
        }


        return (
            <div className="value_txt">{valToUse}</div>
        )


    }
    return (
        <div className="summary_head">
            <div className="each_summary_heading">
                <div className="summary_label">Total Followers</div>
                <div className="summary_value_txt" >

                    <RoundUpNumber number={totalFollowers} />

                    {/* {totalFollowers} */}
                    <ValueChange value={`${Math.abs(totalFollowersTrendChange)}%`} change={totalFollowersTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="followers_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#followers_count" place="right">
                    {totalFollowers.toLocaleString()}
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Number of Posts</div>
                <div className="summary_value_txt">
                    <RoundUpNumber number={totalPosts} />

                    <ValueChange value={`${Math.abs(totalPostsTrendChange)}%`} change={totalPostsTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="post_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#post_count" place="right">
                    {totalPosts.toLocaleString()}
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Video Views</div>
                <div className="summary_value_txt">
                    <RoundUpNumber number={totalVideoViews} />

                    <ValueChange value={`${Math.abs(totalVideoViewsTrendChange)}%`} change={totalVideoViewsTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="view_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#view_count" place="right">
                    {totalVideoViews.toLocaleString()}
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Total Engagement</div>
                <div className="summary_value_txt">
                    <RoundUpNumber number={totalEngagement} />
                    {/* {totalEngagement} */}
                    <ValueChange value={`${Math.abs(totalEngagementTrendChange)}%`} change={totalEngagementTrendChange > 0 ? "rise" : "fall"} />
                    <div className="info_ico" id="eng_count">
                        <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                            alt=""
                        />
                    </div>
                </div>
                <Tooltip className="tool_tip_wrap" anchorSelect="#eng_count" place="right">
                    {totalEngagement.toLocaleString()}
                </Tooltip>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Average Engagement Rate Per Post</div>
                <div className="summary_value_txt">
                    {isNaN(aveEngagementRate) && "-"}

                    {!isNaN(aveEngagementRate) &&
                        <>
                            <RoundUpNumber number={aveEngagementRate} hasDecmals={true} />% 

                            <ValueChange value={`${Math.abs(totalAveEngagementRateTrendChange)}%`} change={totalAveEngagementRateTrendChange > 0 ? "rise" : "fall"} />
                            <div className="info_ico" id="ave_eng_count">
                                <img src={toAbsoluteUrl('/media/cpg/info_ico.png')}
                                    alt=""
                                />
                            </div>
                        </>
                    }

                    {/* <RoundUpNumber number={aveEngagementRate} hasDecmals={true} />%
                    <ValueChange value={`${Math.abs(totalAveEngagementRateTrendChange)}%`} change={totalAveEngagementRateTrendChange > 0 ? "rise" : "fall"} /> */}

                </div>

            </div>
        </div>
    )
}