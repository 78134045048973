import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import * as actions from "../../_redux/Actions";
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import ValueChange from '../shared/ValueChange';



export default function ActionableInsights(props: any) {
    const [isEditable, setIsEditable] = useState(false);
    const [optimizationTxt, setOptimizationTxt] = useState<any>();
    const [optimizationTxtToSend, setOptimizationTxtToSend] = useState<any>();
    const [isTruncated, setIsTruncated] = useState(true);

    let { chosenBrand, chosenBrandId, timeSeries, brandsOptimizationInfoData, dataToCompare, isExternal, startDateToCompare, endDateToCompare } = props;
    let initalOptimizationHtml: any = brandsOptimizationInfoData?.optimization;
    const [editorState, setEditorState] = useState(
        () => {
            if (brandsOptimizationInfoData?.optimization) {
                return EditorState.createWithContent(
                    // @ts-ignore
                    ContentState.createFromBlockArray(convertFromHTML(brandsOptimizationInfoData?.optimization))
                )
            }
            return EditorState.createEmpty()

        },
    );
    const [convertedContent, setConvertedContent] = useState<any>("");





    const dispatch = useDispatch<any>();

    const {
        updateBrandsOptimization } = useSelector(
            (state: any) => ({

                updateBrandsOptimization: state.brandAtGlance.updateBrandsOptimization,
            })
        );



    let seriesStartDate = chosenBrand;
    let seriesEndDate = dataToCompare;

    let totalEngagementTrendStart = (seriesStartDate?.twitterTweetEngagementTotal || 0) + (seriesStartDate?.youTubePostsEngagementTotal || 0) + (seriesStartDate?.tikTokPostsEngagementTotal || 0) + (seriesStartDate?.facebookPostEngagementTotal || 0) + (seriesStartDate?.instagramPostsEngagementTotal || 0);
    let totalEngagementTrendEnd = (seriesEndDate?.twitterTweetEngagementTotal || 0) + (seriesEndDate?.youTubePostsEngagementTotal || 0) + (seriesEndDate?.tikTokPostsEngagementTotal || 0) + (seriesEndDate?.facebookPostEngagementTotal || 0) + (seriesEndDate?.instagramPostsEngagementTotal || 0);


    let totalEngagementDiff: any = totalEngagementTrendStart - totalEngagementTrendEnd;


    let totalEngagementTrendChange: any = totalEngagementTrendEnd > 0 ? (((totalEngagementTrendStart - totalEngagementTrendEnd) / totalEngagementTrendEnd) * 100).toFixed(2) : 0;






    let totalVideoViewsTrendStart = (seriesStartDate?.twitterVideoViews || 0) + (seriesStartDate?.youTubePostViews || 0) + (seriesStartDate?.tikTokViews || 0) + (seriesStartDate?.reelviews || 0);
    let totalVideoViewsTrendEnd = (seriesEndDate?.twitterVideoViews || 0) + (seriesEndDate?.youTubePostViews || 0) + (seriesEndDate?.tikTokViews || 0) + (seriesEndDate?.reelviews || 0);


    let totalVideoViewsDiff: any = totalVideoViewsTrendStart - totalVideoViewsTrendEnd;



    let totalVideoViewsTrendChange: any = totalVideoViewsTrendEnd > 0 ? (((totalVideoViewsTrendStart - totalVideoViewsTrendEnd) / totalVideoViewsTrendEnd) * 100).toFixed(2) : 0;


    let totalFollowersTrendStart = (seriesStartDate?.tikTokFollowers || 0) + (seriesStartDate?.instagramFollowedBy || 0) + (seriesStartDate?.facebookPageLikes || 0) + (seriesStartDate?.youTubeSubscribers || 0);
    let totalFollowersTrendEnd = (seriesEndDate?.tikTokFollowers || 0) + (seriesEndDate?.instagramFollowedBy || 0) + (seriesEndDate?.facebookPageLikes || 0) + (seriesEndDate?.youTubeSubscribers || 0);





    let totalFollowersDiff: any = totalFollowersTrendStart - totalFollowersTrendEnd;


    let totalFollowersTrendChange: any = totalFollowersTrendEnd > 0 ? (((totalFollowersTrendStart - totalFollowersTrendEnd) / totalFollowersTrendEnd) * 100).toFixed(2) : 0;



    useEffect(() => {
        if (optimizationTxtToSend) {
            let optimizationPayload = {
                optimization: optimizationTxtToSend,
                companyId: chosenBrandId
            }
            dispatch(actions.createCampaignOptimization(chosenBrandId, optimizationPayload))
            setIsEditable(false)
        }

    }, [optimizationTxtToSend])

    useEffect(() => {

        let html: any = convertToHTML(editorState.getCurrentContent());

        setConvertedContent(html);
        if(html.length > 190){
            setIsTruncated(true);
        }
       

    }, [editorState]);



    const handleDoubleTap = () => {
        setIsTruncated(!isTruncated);
    };

    const RoundUpNumber = ({ number, hasDecmals }: any) => {


        let valToUse = number.toFixed(0);

        if (number >= 1000000) {

            valToUse = `${Number(number / 1000000).toFixed(0)}`;
            return (
                <div className="trend_value">{valToUse}<span className="value_unit">m</span></div>
            )
        }
        if (number >= 1000) {
            valToUse = `${Number(number / 1000).toFixed(0)}`;
            return (
                <div className="trend_value">{valToUse}<span className="value_unit">k</span></div>
            )
        }

        if (hasDecmals) {
            valToUse = `${Number(number).toFixed(2)}`

            return (
                <div className="trend_value">{valToUse}</div>
            )
        }


        return (
            <div className="trend_value">{valToUse}</div>
        )


    }

    const createMarkup = (html: any) => {
        if (html) {
            return {
                __html: DOMPurify.sanitize(html)
            }
        }
        return {
            __html: "<>Click the pen icon to add optimization</>"
        }
    }


    const returnMonthText = (month: number) => {
        let months: any = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        return months[month];
    }


    return (
        <div className="actionables_wrap" id="slide5">
            <div className="actionables_header">
                <div className="medium_heading">Actionable Insights - against previous selected date period</div>
                {/* <div className="medium_heading">Actionable Insights - against {(startDateToCompare.getDate()) >= 10 ? startDateToCompare.getDate() : `0${startDateToCompare.getDate()} ${returnMonthText(startDateToCompare.getMonth())}, ${startDateToCompare.getFullYear()}`} to {(endDateToCompare.getDate()) >= 10 ? endDateToCompare.getDate() : `0${endDateToCompare.getDate()}`} { returnMonthText(endDateToCompare.getMonth() )}, {endDateToCompare.getFullYear()}  </div> */}
                {!isExternal &&
                    <div className="edit_cta" onClick={() => {
                        setOptimizationTxt(optimizationTxtToSend || brandsOptimizationInfoData?.optimization || "")
                        setIsEditable(!isEditable)

                    }}>
                        <img src={toAbsoluteUrl('/media/icons/socials/edit.svg')} alt="" />
                    </div>
                }
            </div>
            <div className="all_insights">
                <div className="each_insight_wrap">
                    <div className="insight_head">Followers Insight</div>
                    <div className="insight_subhead">{totalFollowersDiff < 0 ? "Much followers not gained" : "More followers gained"}</div>
                    <div className="insight_trend">
                        <RoundUpNumber number={Math.abs(totalFollowersDiff)} />

                        <div className="trend_wrap">
                            <ValueChange value={`${Math.abs(totalFollowersTrendChange)}%`} change={totalFollowersTrendChange >= 0 ? "rise" : "fall"} />
                            <div className="trend_duration">within selected date</div>
                        </div>
                    </div>
                </div>
                <div className="each_insight_wrap">
                    <div className="insight_head">Video Views</div>
                    <div className="insight_subhead">{totalVideoViewsDiff < 0 ? "Reduction in views" : "More views"}</div>
                    <div className="insight_trend">
                        <RoundUpNumber number={Math.abs(totalVideoViewsDiff)} />
                        <div className="trend_wrap">
                            <ValueChange value={`${Math.abs(totalVideoViewsTrendChange)}%`} change={totalVideoViewsTrendChange >= 0 ? "rise" : "fall"} />
                            <div className="trend_duration">within selected date</div>
                        </div>
                    </div>
                </div>
                <div className="each_insight_wrap">
                    <div className="insight_head">Engagement</div>
                    <div className="insight_subhead">{totalEngagementDiff < 0 ? "Lesser Engagements" : "More Engagements"}</div>
                    <div className="insight_trend">
                        <RoundUpNumber number={Math.abs(totalEngagementDiff)} />
                        <div className="trend_wrap">
                            <ValueChange value={`${Math.abs(totalEngagementTrendChange)}%`} change={totalEngagementTrendChange >= 0 ? "rise" : "fall"} />
                            <div className="trend_duration">within selected date</div>
                        </div>
                    </div>
                </div>

                <div className="each_insight_wrap">
                    <div className="insight_head">Optimization Plan</div>
                    {isEditable &&
                        <>
                            <Editor
                                // editorStyle={{ height: '150px' }}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="insight-plan"
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'emoji'],
                                }}
                            />
                            {/* <textarea name="" value={optimizationTxt} rows={4} onChange={(e) => setOptimizationTxt(e.target.value)} className="insight-plan">

                            </textarea> */}
                            <div className="send_insight">
                                <button type="button" className=""
                                    onClick={() => {
                                        setOptimizationTxtToSend(convertedContent)
                                        // setOptimizationTxtToSend(optimizationTxt.trim())
                                    }}
                                >
                                    Save

                                </button>
                            </div>
                        </>
                    }
                    {!isEditable &&
                        <>

                            <div className={`actions_list wrapped text-container ${!isTruncated ? 'truncated' : 'text-truncate'} `} 
                                dangerouslySetInnerHTML={(convertedContent == "<p></p>" || convertedContent == "") ? 
                                createMarkup(brandsOptimizationInfoData?.optimization) : createMarkup(convertedContent)}>
                                {/* {optimizationTxtToSend || brandsOptimizationInfoData?.optimization || "Click the pen icon to add optimization"} */}
                            </div>
                            {convertedContent.length > 190 && <button onClick={handleDoubleTap}
                                className="read-more-button Helvetica-Neue-light">
                                {isTruncated ? "Read More" : "Read Less"}
                            </button>}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}