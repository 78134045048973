export const classnames = (...classes: any) => classes.filter(Boolean).join(' ');


export const metricValueFormatter = (metricValue: number | undefined, toFixed: number = 2): string => {

    if(typeof metricValue === 'undefined'){
        return "0"
    }

    else{
        let ranges = [
            { divider: 1e6 , suffix: 'M' },
            { divider: 1e3 , suffix: 'k' }
        ];
    
        for (let i=0; i < ranges.length; i++) {
            if (metricValue >= ranges[i].divider) {
                return (metricValue / ranges[i].divider).toFixed(toFixed) + ranges[i].suffix;
            }
        }

        
    
        return metricValue?metricValue.toString():"";
    }
}

export function abvNum (n:any) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  }


export function getMonthName(monthNumber: string): string {
    const date = new Date();
    date.setMonth(+monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'short' });
}

export function getMonthAndDay(date: string): string {
    const determinant = "T";

    if (date.includes(determinant)) {
        let dateTime = date.split(determinant);
        let formatDate = dateTime[0].split("-");
        let [ year, month, day ] = formatDate;

        return `${getMonthName(month)} ${day}`;
    }

    return date;
}

export function roundToDecimal(value: number, decimalPlace: number = 2) {
    return +value.toFixed(decimalPlace);
}