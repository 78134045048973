/* eslint-disable jsx-a11y/anchor-is-valid */
// 618220 - have both current and target
import React, { useState, useRef, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toAbsoluteUrl } from '../../../helpers'
import { RadialChart } from '../custom/RadialChart2'
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as functions from "../../../../app/global/functions";
import Form from 'react-bootstrap/Form';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Range, getTrackBackground } from 'react-range';

const limitStr = (str: string, length: number) => {
  const spl = str.split('');
  let newStr = str;
  if (spl.length > length) {
    spl.length = length;
    newStr = spl.join('') + '...'
  }
  return newStr;
}

type Props = {
  className?: string
  svgIcon?: string
  iconColor?: string
  title?: string
  description?: string
  clicks?:number
  campaignData: any;
  index?: number;
  activity?:any;
  dataL?: number;
  campaignStats: any
}

const StatisticsWidgetX: React.FC<Props> = ({
  className,
  campaignStats,
  clicks,
  dataL, index,
  activity,
  campaignData,
}) => {

  const Impressions_ref = useRef();
  const Clicks_ref = useRef();
  const ER_ref = useRef();
  const CPE_ref = useRef();
  const Budget_ref = useRef();
  const rtl: boolean = false;

  const refs = [Impressions_ref, Clicks_ref, ER_ref, CPE_ref, Budget_ref]
  
  const INITIAL_CHART = [
    {
      stroke: 0,
      value: 0,
      rate: 0,
      meta: '0',
      label: "Impressions",
    },
    {
      stroke: 0,
      meta: '0',
      value: 0,
      rate: 0,
      label: "Clicks",
    },
    {
      stroke: 0,
      meta: '0',
      value: 0,
      rate: 0,
      label: "ER",
    },
    {
      stroke: 0,
      meta: '0',
      value: 0,
      rate: 0,
      label: "CPE",
    },
    {
      stroke: 0,
      meta: '0',
      value: 0,
      label: "Budget",
      rate: 0
    }
  ]

  const [charts, set_chart] = useState<any>([]);
  const [payed, setPayed] = useState<number>(0);
  const [values, setValues] = useState([50]);
  const [chartData, setChartData] = useState<any>(INITIAL_CHART);

  const calcAvg = (a: any, b: any) => {
    if (a && b) {
      // console.log(a, b)
      const numb = (Number(a) / Number(b)) * 100
      return numb.toFixed(2);
    }
    return 0.00;
  }

  const updateChart = (paidss: any) => {
    let clone = chartData;
    if (campaignStats.current_data && campaignStats.target_data) {
      const { Impressions, Clicks, EngagementRate } = campaignStats.current_data;
      const { target_impression, target_clicks, target_er, target_cpe } = campaignStats.target_data;
      clone.map((clone: any) => {
        

        if (clone?.label === 'Impressions') {
          var impppp = activity?.data?.new_impressions || campaignStats?.target_data?.campaign_actual?.impressions || Impressions;
          clone.stroke = calcAvg(impppp, target_impression);
          clone.value = impppp.toString();
          clone.rate = `${functions.abvNum(impppp)} / ${functions.abvNum(target_impression)}`;
          clone.meta = `${functions.abvNum(Number(impppp))}`;
          clone.fromApi = true;
        }
        if (clone.label === 'Clicks') {
          clone.stroke = calcAvg(campaignStats?.target_data?.campaign_actual?.clicks || clicks, target_clicks);
          clone.value = campaignStats?.target_data?.campaign_actual?.clicks.toString() || clicks?.toString();
          clone.rate = `${functions.abvNum(campaignStats?.target_data?.campaign_actual?.clicks || clicks)} / ${functions.abvNum(target_clicks)}`;
          clone.meta = `${functions.abvNum(Number(campaignStats?.target_data?.campaign_actual?.clicks || clicks))}`;
          clone.fromApi = true;
        } 

        // if (clone.label === 'ER') {
        //   clone.stroke = calcAvg((campaignStats?.target_data?.campaign_actual?.er || EngagementRate) * 100, target_er);
        //   clone.value = campaignStats?.target_data?.campaign_actual?.er.toString() || EngagementRate?.toString();
        //   clone.rate = `${functions.abvNum(Number((campaignStats?.target_data?.campaign_actual?.er || EngagementRate) * 100).toFixed(2))} / ${functions.abvNum(Number(target_er).toFixed(2))}`;
        //   clone.meta = `${Number(campaignStats?.target_data?.campaign_actual?.er || EngagementRate) * 100} %`;
        //   clone.fromApi = true;
        // }
     
        if (clone.label === 'ER') {

          var vals =  activity?.data?.new_er || campaignStats?.target_data?.campaign_actual?.er || EngagementRate;
          var val100s = (vals * 100) >= 100 ? vals : vals * 100;
          val100s = Number(val100s).toFixed(2);

          clone.stroke = calcAvg(val100s, target_er);
          clone.value = val100s.toString();
          clone.rate = `${functions.abvNum(Number(val100s).toFixed(2))} / ${functions.abvNum(Number(target_er).toFixed(2))}`;
          clone.meta = `${Number(val100s)} %`;
          clone.fromApi = true;
        }
     
        if (clone.label === 'CPE') {
          // clone.stroke = calcAvg(campaignStats?.target_data?.campaign_actual?.cpe ||  Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement), target_cpe);
          // clone.value = campaignStats?.target_data?.campaign_actual?.cpe.toString() || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2);
          // clone.rate = `${(campaignStats?.target_data?.campaign_actual?.cpe || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2))} / ${functions.abvNum(Number(target_cpe).toFixed(2))}`;
          // clone.meta = `$ ${campaignStats?.target_data?.campaign_actual?.cpe || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2)}`;
          // clone.fromApi = `${campaignStats?.target_data?.campaign_summary?.cpe ? true : false}`;
      
      
      
          clone.stroke = calcAvg(target_cpe, campaignStats?.target_data?.campaign_actual?.cpe ||  Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement));
          // clone.value = campaignStats?.target_data?.campaign_actual?.cpe.toString() || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2);
          // clone.rate = `${(campaignStats?.target_data?.campaign_actual?.cpe || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2))}`;
          clone.rate = `${campaignStats?.target_data?.campaign_actual?.cpe || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2)} / ${functions.abvNum(Number(target_cpe).toFixed(2))}`;
          clone.meta = `$ ${campaignStats?.target_data?.campaign_actual?.cpe || Number((paidss?paidss : 0) / campaignStats?.current_data?.Engagement).toFixed(2)}`;
          clone.fromApi = `${campaignStats?.target_data?.campaign_summary?.cpe ? true : false}`;
          // console.log("WDcdcwewef",clone);
        }


        if (clone.label === 'Budget') {
          clone.stroke = calcAvg(campaignStats?.target_data?.campaign_summary?.paid || paidss, campaignStats?.target_data?.campaign_summary?.TotalBudget || campaignData.TotalBudget);
          clone.value = campaignStats?.target_data?.campaign_summary?.TotalBudget?.toString() || campaignData?.TotalBudget?.toString();
          clone.rate = `$${functions.abvNum(campaignStats?.target_data?.campaign_summary?.paid || paidss)} / $${functions.abvNum(campaignStats?.target_data?.campaign_summary?.TotalBudget || campaignData?.TotalBudget)}`;
          clone.meta = `$${functions.abvNum(campaignStats?.target_data?.campaign_summary?.paid || paidss)}`;
          clone.fromApi = `${campaignStats?.target_data?.campaign_summary?.paid ? true : false}`;
        }

      });
      clone.done = true;
      // console.log("NEW clone", clone);
      setChartData(clone);
    } else {
      clone.done = true;
    }
  }


  useEffect(() => {
    var new_arr: any = [];
    setPayed(0);
    if (campaignStats && campaignData) {
      if (!campaignStats.target_data) {
        return;
      }
      let paid = 0;
      if (campaignData.Payout) {
        campaignData.Payout.forEach((e: any, i: number) => {
          if (e.Paid) {
            paid += e.FixedFee;
          }
        });
        setPayed(paid);
      }
      if (campaignStats.current_data && campaignStats.target_data) {
        // console.log("PAID!!!!", paid);
        updateChart(paid);
      } else {
        setChartData(INITIAL_CHART);
      }

    } else {
      setChartData(INITIAL_CHART);
    }

    // console.log(Impressions_ref.current);
  }, [campaignStats, activity, campaignData]);

  const [timeframe, setTimeframe] = useState('');
  const diffTime = Math.abs(new Date(campaignData?.StartDate).valueOf() - new Date(campaignData?.EndDate).valueOf());
  const diffTimeNow = Math.abs(new Date(campaignData?.StartDate).valueOf() - new Date().valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // get current day - end day
  const diffDaysNoew = Math.ceil(diffTimeNow / (1000 * 60 * 60 * 24));

  const new_d = ((diffDaysNoew / 7) / (diffDays / 7) )  * 100

  return (
    <>
      {!campaignData || !campaignData.CampaignId
        && <Skeleton height={70} count={3} />
      }
      {campaignData && campaignData.CampaignId &&
        <div className={`card ${className} p-6`}>
          <div className="row">
            <div className="col-xl-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img src={campaignData.ThumbLogoUrl || toAbsoluteUrl('/media/cpg/na.png')} alt="@" className="shadow-sm"
                      style={{ width: '36px', height: '36px', marginRight: '12px', borderRadius: '18px', display: 'block' }} />
                    <h3 className="fs-2 fw-bolder">{campaignData.CampaignName} <span className='text-muted fs-6'>{`${(dataL && dataL > 0) ? ` Showing ${index} of ${dataL}` : ''}`}</span></h3>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-lg-nowrap align-items-center">
                  <div className="d-flex align-items-center me-6">
                    <div className="d-flex align-items-center">
                      <span className="fw-bold">Week 1 ({campaignData?.StartDate})</span>
                      {/* <input className="input-slider mx-2" type="range" value={timeframe}
                        onChange={(e) => setTimeframe(e.target.value)} min={1} max={10} step={0.5} /> */}
                      <div style = {{
                        width: 250, marginLeft: 10, marginRight: 10
                      }}>
                        <Range
                              step={0.1}
                              min={0}
                              max={100}
                              values={[Math.round(new_d) <= 100 ? Math.round(new_d) : 100]}
                              disabled = {true}
                              onChange={(values) => console.log({ values })}
                              renderTrack={({ props, children, disabled }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: '36px',
                                    display: 'flex',
                                    width: '100%', 
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: '8px',
                                      width: '100%',
                                      borderRadius: '4px',
                                      background: getTrackBackground({
                                        values,
                                        colors: [disabled ? '#999' : 'transparent'],
                                        // colors: ["linear-gradient(270deg, #06D6A0 7.03%, #FFDD55 49.96%, #FF0000 100%)"],
                                        min: 0,
                                        max: 100,
                                        rtl
                                      }),
                                      backgroundImage: "linear-gradient(270deg, #06D6A0 7.03%, #FFDD55 49.96%, #FF0000 100%)",
                                      alignSelf: 'center'
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}

                              // renderTrack={({ props, children }) => (
                              //   <div
                              //     {...props}
                              //     style={{
                              //       ...props.style,
                              //       height: '6px',
                              //       width: '100%',
                              //       borderRadius: '8px',
                              //       backgroundImage: "linear-gradient(270deg, #06D6A0 7.03%, #FFDD55 49.96%, #FF0000 100%)",
                               
                              //     }}
                              //   >
                              //     {children}
                              //   </div>
                              // )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: '24px',
                                    width: '8.48px',
                                    borderRadius: '8px',
                                    backgroundColor: '#FFF',
                                    borderWidth: 1,
                                    borderColor: '#000',
                                    border: '1px solid rgba(0, 0, 0, 0.4)',
                                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'

                                  }}
                                />
                              )}
                            />

                         {/* <Form.Range /> */}
                         {/* <RangeSlider
                          value={20}
                          // onChange={changeEvent => setValue(changeEvent.target.value)}
                        /> */}

                      {/* <ProgressBar animated variant="primary" now={Math.round(new_d) <= 100 ? Math.round(new_d) : 100} label={`${(diffDaysNoew / 7).toFixed(1)} weeks`} /> */}
                      </div>
                      <span className="fw-bold">Week {(diffDays / 7).toFixed(0)} ({campaignData?.EndDate})</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-xl-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
              {/* ref = {refs[index]} */}
                {chartData &&
                  chartData.map((chart: any, index: number) => (
                    <RadialChart key={index} {...chart} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
{/* <p className="text-gray-600 fs-sm my-4 fw-light lh-lg">{campaignData.Description || 'Description is not available'}</p> */ }

export { StatisticsWidgetX }
