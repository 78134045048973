import React, { ReactElement, useEffect, useState } from "react";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";


interface DataGraphProps {
    pointsOptions: string[];
    children?: React.ReactElement<any>;
    optionHandler: (option:string) => void;
    tabHandler:   (option:string) => void
}

interface TabsProps {
    currentTabHandler: (tab: string) => void;
  }

type TabsType = "General" | "Paid" | "Influencers";

const Tabs = ({currentTabHandler}:TabsProps) => {
    const tabs = ["General", "Paid", "Influencers"]
    const [activeTab, setActiveTab] = useState<number>(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        currentTabHandler(tabs[index]);
    };

    return (
        <div 
            className="d-flex justify-content-between align-items-center py-2 px-4"
            style={{ backgroundColor: "#F4F4FA", borderRadius: "12px", width: "260px"}}
        >
            {tabs.map((tab, index) => {
                return (
                    <div key={index}>
                        {index === activeTab ? (
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    padding: "6px 10px",
                                    borderRadius: "12px",
                                    cursor: "pointer"
                                }}
                            >
                                <p style={{ color: "#000", fontWeight: "bold", margin: 0, fontSize: "14px" }}>{tab}</p>
                            </div>
                        ) : (
                            <div
                                onClick={() => handleTabClick(index)}
                                style={{ cursor: "pointer"}}
                            >
                                <p style={{ color: "#8C8C8C", fontWeight: 500, fontSize: "14px", margin: 0 }}>{tab}</p>
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export const DataGraph = ({ children, pointsOptions, optionHandler, tabHandler}: DataGraphProps) => {
    const [option, setOption] = useState<string>("impressions");

    const [activeTab, setActiveTab] = useState<string>("general");

    const handleCurrentTab = (tab:string) => {
        setActiveTab(tab)
    }

    useEffect(() =>{
        tabHandler(activeTab)
    }, [activeTab])
    
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
            
                <CustomSelect 
                    textStyle={{width:"100px"}}
                    options={pointsOptions.map((pOpt) => {
                        if(pOpt === "Impressions"){
                            return {
                                label: "Impressions/Views",
                                value: pOpt.toLowerCase()
                            }
                        }
                        return {
                            label: pOpt,
                            value: pOpt.toLowerCase()
                        }
                    })}
                    value={option}
                    onChange={(e) => {
                        setOption(e.target.value)
                        optionHandler(e.target.value)
                    }}
                    containerStyle={{ gap: "8px", padding: "12px", borderRadius: "16px" }}
                />
                <Tabs currentTabHandler={handleCurrentTab}/>
            </div>

            {children}
        </div>
    )
}