import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";
import { getPerformanceBreakdownRes } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { useEffect } from "react";
import { PerformanceBreakdownType } from "../../../../../../setup/types/report";
import Skeleton from "react-loading-skeleton";
import * as functions from "../../../../../global/functions";
import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import moment from "moment"


const tableData = [
    {}
]

function addDefaultSrc(ev: any) {
    ev.target.src = toAbsoluteUrl('/media/cpg/na.png');
  }

const tableHeadings = [
    "Creator", "Followers","Clicks", "CTR", "Reach", "Impressions/Views", "Engagement", "Engagement Rate"
]

const Header = () => {
    const filter1 = [
        { label: "Reach", value: "Reach" },
    ];

    return (
        <div className="d-flex justify-content-between align-content-center ">
            <h3 className="fs-3 NeueMontreal-bold text-dark ">Influencer Efficiencies</h3>

            <div className="d-flex justify-content-between align-content-center"
                style={{ gap: 10 }}
            >
                <CustomSelect className="custom-select" options={filter1} />
            </div>
        </div>
    )
}
const TableValueHeaderText = ({ text }: { text: string }) => (
    <p className="Helvetica-Neue-medium" style={{
        fontSize: "16px",
        fontWeight: 600,
        // fontFamily:'Helvetica-Neue',
        color: "#000",
        margin: 0,
        paddingTop:"12px",
        paddingBottom:"4px",
        whiteSpace:"nowrap",
    }}>{text}</p>
)

const TableValueText = ({ text }: { text: string }) => (
    <p className="Helvetica-Neue-normal" style={{
        fontSize: "14px",
        fontWeight: 600,
        // fontFamily:'Helvetica-Neue',
        color: "#8c8c8c",
        margin: 0,
        paddingTop:"12px",
        paddingBottom:"4px",
        whiteSpace:"nowrap",
    }}>{text}</p>
)

export const InfluencerBreakdown = ({ campaignId}: { campaignId: string }) => {

    const dispatch = useAppDispatch();

    const { loading, error, data } = useAppSelector((state) => state.report.PerformanceBreakdownRes);

    const range = useAppSelector((state) => state.report.range).toString();

    useEffect(() => {
        dispatch(getPerformanceBreakdownRes({
            campaign_id: campaignId,
            date_range: range.toString()
        }));
        
    }, [campaignId, dispatch, range]);

    return (
        <div id="break_down_details">
        <div className="margint-24">
            <h3 className="text__24 NeueMontreal-bold text-dark ">
                Influencer Performance Breakdown – This Week
            </h3>
            <p className="font__300 text__14">
                W/C {moment(data?.date || '2023-01-30T00:00:00.000Z').format('MMM Do, YYYY')}
            </p>
        </div>
        <div className="mt-10 p-8 bg-white rounded overflow__x" style={{width:"100%"}}>
           

           {loading? <Skeleton height={40} count={5}/>: (error || data === null || data?.data?.length === 0) ? (
                    <ErrorBoundary error={error}/>
                ) : (
               <table className="table  table-borderless overflow__x mt-6" style={{width:"100%"}}>
               <thead style={{ backgroundColor: "#F4F4FA", borderRadius: 100 }}>
                   <tr>
                       {tableHeadings.map((heading, index) => {
                           if(heading === "Name"){
                               return(
                                   <th key={index} scope="col" 
                                   className="NeueMontreal-bold whitespace__nowrap font__700 text__black text__16">{heading}</th>
                               )
                           }
                           return (
                           <th key={index} scope="col" 
                           className="NeueMontreal-bold whitespace__nowrap font__600 text__black text__16">{heading}</th>
                           )
                       })}
                   </tr>
               </thead>

               <tbody>
                   {data?.data?.map((item:PerformanceBreakdownType , index:number) => (
                       <tr key={index} style={{
                           borderBottom: "1px solid #F4F4FA"
                       }}>
                           <th scope="row" className="whitespace__nowrap">
                               <a href={item?.influencerLink || ''} 
                               target="_blank" rel="noreferrer" 
                               className="d-flex align-items-center">
                                   
                                   <img
                                       onError={(ev) => addDefaultSrc(ev)}
                                       src={item.logoURL || toAbsoluteUrl("/media/cpg/reportImg1.png")}
                                       alt="hero-card"
                                       className="image-fluid d-block " 
                                       style={{ 
                                           width: '30px', 
                                           maxHeight: '30px', 
                                           resize: "both", 
                                           objectFit: "cover", 
                                           borderRadius: "8px",
                                           margin: "0 10px",
                                           marginRight:"12px"
                                       }}
                                   /> 
                                   <TableValueHeaderText text={`${item?.name}`} />
                               </a>
                           </th>
                           <td><TableValueText text={`${functions.abvNum(item?.followers) || 0}`} /></td>
                           <td><TableValueText text={`${functions.abvNum(item?.clicks) || 0}`} /></td>
                           <td><TableValueText text={`${item?.ctr?.toFixed(2) || 0}%`} /></td>
                           <td><TableValueText text={`${functions.abvNum(item?.reach) || 0}`} /></td>
                           <td><TableValueText text={`${functions.abvNum(item?.impressions) || 0}`} /></td>
                           <td><TableValueText text={`${functions.abvNum(item?.engagement) || 0}`} /></td>
                           <td><TableValueText text={`${item?.engangementRate?.toFixed(2) || 0}%`} /></td>
                       </tr>
                   ))}
               </tbody>
           </table>
           )
           }
       </div>
        </div>
    )
}