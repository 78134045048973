import React, {  useRef, useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import ApexCharts from 'apexcharts'
import distinctColors from 'distinct-colors'
import ReactWordcloud from 'react-wordcloud';
import Randomwords from 'random-words';


export default function TrendingWords(props:any){
    const chartRef = useRef<HTMLDivElement | null>(null);
    const generateWords: any[] = Randomwords(30);
    const nums = new Set();
    while (nums.size !== 30) {
        nums.add(Math.floor(Math.random() * 100) + 1);
    }

    let randomNumbers = Array.from(nums)


    const allpopularWords: any[] = generateWords.map((eachWord: any, index: any) => ({ text: eachWord, value: randomNumbers[index] }))

    const wordsOptions: any = {
        rotations: 1,
        rotationAngles: [360],
    };

    useEffect(() => {
        let palette: any[] = distinctColors({ count: 3 }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)
        let options = {

            series: [20, 40, 70],
            chart: {
                type: 'donut',
                width: 300
            },
            dataLabels: {
                enabled: false,
            },
            labels: ["Negative", "Neutral", "Positive"],
            // hollow: {
            //     margin: 15,
            //     size: '40%'
            // },
            stroke: {
                width: 0
            },
            legend: {
                position: 'bottom',
                markers: {
                    width: 8,
                    height: 8,
                },
                labels: {
                    colors: ["#666666"]
                }
            },
            fill: {
                colors: [...chartColors]
            },

        }
        let numbersChart = new ApexCharts(chartRef.current, options);

        if (numbersChart) {
            numbersChart.render()
        }
    }, [chartRef])



    return (
        <div className="word_trends">
            <div className="word_trend_chart" ref={chartRef}></div>
            <div className="top_words">
                <ReactWordcloud options={wordsOptions} words={allpopularWords} size={[300, 200]} />
            </div>
        </div>
    )
}