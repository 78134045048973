import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore} from 'redux-persist'
import {rootReducer, rootSaga} from './RootReducer'
import logger from 'redux-logger';

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    // thunk: true,
  }),
  sagaMiddleware
]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}

const store = configureStore({
  reducer: rootReducer,
  // middleware,
  middleware: (getDefautlMiddleware) => {
    return [...getDefautlMiddleware({
      serializableCheck: false,
      immmutableCheck: false
    }), ...middleware]
  },
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})
    
// export type AppDispatch = typeof store.dispatch  


export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store
 
 
      