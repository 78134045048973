import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'



export function findDatas(type, queryqueryParams) {
  if (type === 'brand_at_glance') {
    return axios.get(`${URL}api/campaign/social-listing?${queryqueryParams}`);
  }
  
}

export function fetchABrandSummary(landscapeId, queryParams, is_external) {
  if(is_external){
    return axios.get(`${URL}api/campaign/external/metrics/summary?landscapeId=${landscapeId}&${queryParams}`);
  }
  return axios.get(`${URL}api/campaign/metrics/summary?landscapeId=${landscapeId}&${queryParams}`);
}

export function fetchAllSocialPosts(landscapeId, queryParams, is_external) {
  if(is_external){
    return axios.get(`${URL}api/campaign/external/company-posts?landscapeId=${landscapeId}&${queryParams}`);
  }
  return axios.get(`${URL}api/campaign/company-posts?landscapeId=${landscapeId}&${queryParams}`);
}

export function fetchAllSocialTimeSeries(landscapeId, queryParams, is_external) {
  if(is_external){
    return axios.get(`${URL}api/campaign/external/metrics/time-series?landscapeId=${landscapeId}&${queryParams}`);
  }
  return axios.get(`${URL}api/campaign/metrics/time-series?landscapeId=${landscapeId}&${queryParams}`);
}

export function fetchAllCampaignCompanies( landscapeId, is_external) {
  if(is_external){
    return axios.get(`${URL}api/campaign/external/company?landscapeId=${landscapeId}`);
  }
  return axios.get(`${URL}api/campaign/companies`);
  // return axios.get(`${URL}api/campaign/companies?landscapeId=${landscapeId}`);
}

export function fetchOptimizationPlan(commpanyId, is_external) {
  if(is_external){
    return axios.get(`${URL}api/campaign/external/optimisation-plan?companyId=${commpanyId}`);
  }
  return axios.get(`${URL}api/campaign/optimisation-plan?companyId=${commpanyId}`);
}

export function createOptimizationPlan(commpanyId, data, is_external) {
  return axios.post(`${URL}api/campaign/optimisation-plan?companyId=${commpanyId}`, data);
  // return axios.get(`${URL}api/campaign/optimisation-plan?${commpanyId}`);
}


