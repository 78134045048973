
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";

import {
    CampaignDetailResponse,
    CmpAnalyticsResponse,
    PerformanceAnalysisResponse,
    ReportState,
    StaticImageResponse,
    AddCommentPostResponse,
    ExportReportPdfResponse,
    DeleteCommentPostResponse,
    SummaryState,
    InfluencerNarrativeResponse,
    KeyInsightsResponse,
    PerformanceBreakdownData,
    AttachmentGetResponse,
    EarnedMediaValueData,
    EarnedMediaData,
    SocialPlatformsData,
    TopCreatorsData,
    AttachmentPostResponse,
    CommentType,
    NewChannelPerformanceData,
    NewEfficiencyData,
    PATopPerformingResponse,
    ActionableInsightsResponse,
    GraphResponse,
    InfluencerDetailsModal,
    InfluencerSummaryModal,
    InfluencerAccountModal,
    InfluencerSocialsModal,
    TopPerformingNarrativeResponse
} from "../types/report";


const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
    summary: {
        loading: false,
        error: null,
        data: null
    },
    commentsRes: {
        loading: false,
        error: null,
        data: null
    },

    campaignDetail: {
        loading: true,
        error: null,
        data: null
    },
    campaignAnalytics: {
        loading: false,
        error: null,
        data: null
    },
    staticImages: {
        loading: false,
        error: null,
        data: null
    },
    performanceAly: {
        loading: false,
        error: null,
        data: null
    },
    exportReportPdfRes: {
        loading: false,
        error: null,
        data: null
    },
    addReportCommentRes: {
        loading: false,
        error: null,
        data: null
    },
    deleteReportCommentRes: {
        loading: false,
        error: null,
        data: null
    },
    InfluencerNarrativeRes: {
        loading: false,
        error: null,
        data: null
    },
    PerformanceBreakdownRes: {
        loading: false,
        error: null,
        data: null
    },
    keyInsightsRes: {
        loading: false,
        error: null,
        data: null
    },
    EarnedMediaValueRes: {
        loading: false,
        error: null,
        data: null
    },
    EarnedMediaDataRes: {
        loading: false,
        error: null,
        data: null
    },
    SocialPlatformsRes: {
        loading: false,
        error: null,
        data: null
    },
    TopCreatorsRes: {
        loading: false,
        error: null,
        data: null
    },
    AttachmentGetRes: {
        loading: false,
        error: null,
        data: null
    },
    AttachmentPostRes: {
        loading: false,
        error: null,
        data: null
    },
    NewChannelPerformanceRes: {
        loading: false,
        error: null,
        data: null
    },
    NewEfficiencyRes: {
        loading: false,
        error: null,
        data: null
    },
    PATopPerformingRes: {
        loading: false,
        error: null,
        data: null
    },
    ActionableInsightsRes: {
        loading: false,
        error: null,
        data: null
    },
    GraphRes: {
        loading: false,
        error: null,
        data: null
    },
    InfluencerSocialsModalRes: {
        loading: false,
        error: null,
        data: null
    },
    InfluencerAccountModalRes: {
        loading: false,
        error: null,
        data: null
    },
    InfluencerSummaryModalRes: {
        loading: false,
        error: null,
        data: null
    },
    InfluencerDetailsModalRes: {
        loading: false,
        error: null,
        data: null
    },
    TopPerformingNarrativeRes:{
        loading: false,
        error: null,
        data: null
    },
    range: "7",
    displayReports: ["Campaign Performance Summary", "Performance Analysis", 
                    "Top Performing Content", "Influencer Performance Breakdown", 
                    "Channel Performance", "Campaign Efficiency", "Influencer Efficiencies", 
                    "Earned Media Value", 
                    "Actionable insights", "Links and Attachments"]
} as ReportState;

type Params = {
    campaign_id: string;
    date_range?: string;
    is_external?: boolean
}

type graphParams ={
    campaign_id: string;
    date_range?: string;
    is_external?: boolean
    type?: string
}

type FormsParams = {
    campaign_id: string;
    file: FormData;


}

type BasicParams = {
    campaign_id: string;
    is_external?: boolean
    date_range?: string;
}

export const getSummary = createAsyncThunk('report/summary',
    async ({ campaign_id, date_range, is_external }: Params) => {
        const result = await axios.get(`${API_URL}api/reports/campaign-summary${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data['data'];
    });

export const getCampaignComments = createAsyncThunk<
    any,
    Params
>('report/Comments',
    async ({ campaign_id, is_external, date_range }: Params) => {
        let result;

        if (is_external) {
            result = await axios.get(`${API_URL}api/reports/all-comments/client?campaign_id=${campaign_id}`);
        } else {
            result = await axios.get(`${API_URL}api/reports/all-comments/?campaign_id=${campaign_id}`);
        }
        return result.data;
    });

export const getCampaignDetails = createAsyncThunk<
    CampaignDetailResponse,
    Params
>('report/campaignDetails',
    async ({ campaign_id, is_external, date_range }: Params) => {

        const result = await axios.get(`${API_URL}api/new-reports/stats/details${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getCampaignAnalytics = createAsyncThunk<
    CmpAnalyticsResponse,
    Params
>('report/campaignAnalytics',
    async ({ campaign_id, is_external }: Params) => {
        const result = await axios.get(`${API_URL}api/campaign/analytics${is_external ? '/client' : ''}?campaign_id=${campaign_id}`);
        return result.data;
    });

export const uploadStaticImages = createAsyncThunk<
    StaticImageResponse,
    FormData
>('report/staticImages',
    async (file) => {
        // console.log(file?.get("file"))
        const result = await axios.post(`${API_URL}api/file`, file);
        // console.log("IMAGES ", result.data);
        return result.data;
    });

export const exportReportToPdf = createAsyncThunk<
    ExportReportPdfResponse,
    any
>('report/exportReportToPdf',
    async ({ data, is_external }: any) => {
        let result;
        let options = {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            }
        }

        result = await axios.post(`${API_URL}api/new-reports/print-pdf`, data, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            }
        });

        const blob = new Blob([result.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `your-file-name.pdf`
        link.click()

        return result.data;
    });

export const addReportComment = createAsyncThunk<
    AddCommentPostResponse,
    any
>('report/addReportComment',
    async ({ data, is_external }: any) => {
        let result;

        if (is_external) {
            result = await axios.post(`${API_URL}api/reports/add-comment/client`, data);
        } else {
            result = await axios.post(`${API_URL}api/reports/add-comment`, data);
        }

        return result.data;
    });

export const deleteReportComment = createAsyncThunk<
    DeleteCommentPostResponse,
    any
>('report/deleteReportComment',
    async ({ data, is_external }: any) => {

        let result;

        // if (is_external) {
        //     result = await axios.post(`${API_URL}api/reports/add-comment/client`, data);
        // } else {
        result = await axios.delete(`${API_URL}api/reports/delete-comment?comment_id=${data}`);
        // }
        console.log("response delete", result);
        return result.data;
    });


export const getPerformanceAnalysis = createAsyncThunk<
    PerformanceAnalysisResponse,
    Params
>('report/performance',
    async ({ campaign_id, is_external, date_range }: Params) => {
        const result = await axios.get(`${API_URL}api/new-reports/stats/performance-analysis${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getPATopPerformingRes = createAsyncThunk<
    PATopPerformingResponse,
    Params
>('report/PATopPerforming',
    async ({ campaign_id, is_external, date_range }: Params) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/performance-analysis/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/stats/performance-analysis?campaign_id=${campaign_id}&range=${date_range}`);
        }
        return result.data;
    });

    export const getTopPerformingNarrativeRes = createAsyncThunk<
    TopPerformingNarrativeResponse,
    Params
    >('report/TopPerformingNarrative',
        async ({ campaign_id, is_external, date_range }: Params) => {
            let result;
            if (is_external) {
                result = await axios.get(`${API_URL}api/new-reports/tiers-metric/client?campaign_id=${campaign_id}&range=${date_range}`);
            } else {
                result = await axios.get(`${API_URL}api/new-reports/tiers-metric?campaign_id=${campaign_id}&range=${date_range}`);
            }
            return result.data;
        });

export const getInfluencerNarrativeRes = createAsyncThunk<
    InfluencerNarrativeResponse,
    Params
>('report/InfluencerNarrative',
    async ({ campaign_id, is_external, date_range }: Params) => {
        const result = await axios.get(`${API_URL}api/new-reports/stats/content-narrative${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getEarnedMediaValueRes = createAsyncThunk<
    EarnedMediaValueData,
    Params
>('report/EarnedMediaValue',
    async ({ campaign_id, is_external, date_range }) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/earned-media-value/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/earned-media-value?campaign_id=${campaign_id}&range=${date_range}`);
        }
        // const result = await axios.get(`${API_URL}api/reports/earned-media-value?campaign_id=${campaign_id}`);
        return result.data;
    });


export const getEarnedMediaRes = createAsyncThunk<
    EarnedMediaData,
    Params
>('report/EarnedMedia',
    async ({ campaign_id, is_external, date_range }) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/earned-media/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/earned-media?campaign_id=${campaign_id}&range=${date_range}`);
        }
        // const result = await axios.get(`${API_URL}api/new-reports/earned-media?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getTopCreatorsRes = createAsyncThunk<
    TopCreatorsData,
    Params
>('report/TopCreators',
    async ({ campaign_id, is_external, date_range }) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/earned-media/top-creators/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/earned-media/top-creators?campaign_id=${campaign_id}&range=${date_range}`);
        }

        return result.data;
    });

export const getSocialPlatformsRes = createAsyncThunk<
    SocialPlatformsData,
    Params
>('report/SocialPlatforms',
    async ({ campaign_id, is_external, date_range }: Params) => {
        // const result = await axios.get(`${API_URL}api/new-reports/stats/channel-performance?campaign_id=${campaign_id}&range=${date_range}`);
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/social-platforms/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/stats/channel-performance?campaign_id=${campaign_id}&range=${date_range}`);
        }
        return result.data;
    });

export const getKeyInsightsRes = createAsyncThunk<
    KeyInsightsResponse,
    Params
>('report/KeyInsights',
    async ({ campaign_id, is_external, date_range }: Params) => {
        const result = await axios.get(`${API_URL}api/new-reports/stats/key-insights${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getNewEfficiencyRes = createAsyncThunk<
    NewEfficiencyData,
    Params
>('report/NewEfficiency',
    async ({ campaign_id, is_external, date_range }: Params) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/campaign-efficiency/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/campaign-efficiency?campaign_id=${campaign_id}&range=${date_range}`);
        }
        return result.data;
    });

export const getNewChannelPerformanceRes = createAsyncThunk<
    NewChannelPerformanceData,
    Params
>('report/NewChannelPerformance',
    async ({ campaign_id, is_external, date_range }: Params) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/social-platforms/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/social-platforms?campaign_id=${campaign_id}&range=${date_range}`);
        }
        return result.data;
    });

export const getPerformanceBreakdownRes = createAsyncThunk<
    PerformanceBreakdownData,
    Params
>('report/PerformanceBreakdown',
    async ({ campaign_id, date_range }: Params) => {
        const result = await axios.get(`${API_URL}api/reports/performance-breakdown?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getAttachmentGetRes = createAsyncThunk<
    AttachmentGetResponse,
    Params
>('report/AttachmentGet',
    async ({ campaign_id, is_external }: Params) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/attachments/client?campaign_id=${campaign_id}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/attachments?campaign_id=${campaign_id}`);
        }
        // const result = await axios.get(`${API_URL}api/new-reports/attachments?campaign_id=${campaign_id}`);
        return result.data;
    });

export const getAttachmentPostRes = createAsyncThunk<
    AttachmentPostResponse,
    FormsParams
>('report/AttachmentPost',
    async ({ campaign_id, file }: FormsParams) => {
        const result = await axios.post(`${API_URL}api/file?campaign_id=${campaign_id}`, file);
        return result.data;
    });

export const getActionableInsightsRes = createAsyncThunk<
    ActionableInsightsResponse,
    Params
>('report/ActionableInsights',
    async ({ campaign_id, is_external, date_range }: Params) => {
        const result = await axios.get(`${API_URL}api/new-reports/actionable-insights?campaign_id=${campaign_id}&range=${date_range}`);
        return result.data;
    });

export const getGraphRes = createAsyncThunk<
    GraphResponse,
    graphParams
>('report/GraphResponse',
    async ({ campaign_id, is_external, type }: graphParams) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/graph/client?campaign_id=${campaign_id}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/stats/graph?campaign_id=${campaign_id}`);
        }

        return result.data;
    });

export const getInfluencerDetailsModalRes = createAsyncThunk<
    InfluencerDetailsModal,
    string
>('report/InfluencerDetailsModal',
    async (influncer_id: string) => {
        let result;
        result = await axios.get(`${API_URL}api/influencer?influncer_id=${influncer_id}`);
        return result.data;
    });

export const getInfluencerSocialsModalRes = createAsyncThunk<
    InfluencerSocialsModal,
    string
>('report/InfluencerSocialsModal',
    async (influncer_id: string) => {
        let result;
        result = await axios.get(`${API_URL}api/influencer/socials?influncer_id=${influncer_id}`);
        return result.data;
    });

export const getInfluencerAccountModalRes = createAsyncThunk<
    InfluencerAccountModal,
    string
>('report/InfluencerAccountModal',
    async (influncer_id: string) => {
        let result;
        result = await axios.get(`${API_URL}api/influencer/accounts_summary?influncer_id=${influncer_id}`);
        return result.data;
    });

export const getInfluencerSummaryModalRes = createAsyncThunk<
    InfluencerSummaryModal,
    string
>('report/InfluencerSummaryModal',
    async (influncer_id: string) => {
        let result;
        result = await axios.get(`${API_URL}api/influencer/summary?influncer_id=${influncer_id}`);
        return result.data;
    });

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        updateRange: (state, action) => {
            state.range = action.payload
        },
        updateDisplayReports: (state, action) =>{
            state.displayReports = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSummary.pending, (state) => {
            state.summary.loading = true;
            state.summary.error = null;
        });

        builder.addCase(getSummary.fulfilled, (state, action) => {
            state.summary.data = null;
            state.summary.data = action.payload;
            state.summary.loading = false;
        })

        builder.addCase(getSummary.rejected, (state) => {
            state.summary.data = null;
            state.summary.error = "Error retrieving summary data";
            state.summary.loading = false;
        })

        builder.addCase(getCampaignComments.pending, (state) => {
            state.commentsRes.loading = true;
            state.commentsRes.error = null;
        });

        builder.addCase(getCampaignComments.fulfilled, (state, action) => {

            state.commentsRes.data = action.payload.data;
            state.commentsRes.loading = false;
        })

        builder.addCase(getCampaignComments.rejected
            , (state) => {
                state.commentsRes.data = null;
                state.commentsRes.error = "Error retrieving comments";
                state.commentsRes.loading = false;
            })

        builder.addCase(getCampaignDetails.pending, (state) => {
            state.campaignDetail.loading = true;
            state.campaignDetail.error = null;
        });

        builder.addCase(getCampaignDetails.fulfilled, (state, action) => {
            state.campaignDetail.data = action.payload.data;
            state.campaignDetail.error = null;
            state.campaignDetail.loading = false;
        })

        builder.addCase(getCampaignDetails.rejected, (state) => {
            state.campaignDetail.data = null;
            state.campaignDetail.error = "Campaign Not Found";
            state.campaignDetail.loading = false;
        })

        builder.addCase(getCampaignAnalytics.pending, (state) => {
            state.campaignAnalytics.loading = true;
            state.campaignAnalytics.error = null;
        });

        builder.addCase(getCampaignAnalytics.fulfilled, (state, action) => {
            state.campaignAnalytics.data = action.payload.success;
            state.campaignAnalytics.loading = false;
        })

        builder.addCase(getCampaignAnalytics.rejected, (state) => {
            state.campaignAnalytics.data = null;
            state.campaignAnalytics.error = "Error retrieving analytics data";
            state.campaignAnalytics.loading = false;
        })

        builder.addCase(getGraphRes.pending, (state) => {
            state.GraphRes.loading = true;
            state.GraphRes.error = null;
        });

        builder.addCase(getGraphRes.fulfilled, (state, action) => {
            // console.log(action.payload.data)
            state.GraphRes.data = action.payload.data;
            state.GraphRes.loading = false;
        })

        builder.addCase(getGraphRes.rejected, (state) => {
            state.GraphRes.data = null;
            state.GraphRes.error = "Error retrieving analytics data";
            state.GraphRes.loading = false;
        })


        builder.addCase(addReportComment.pending, (state) => {
            state.addReportCommentRes.loading = true;
            state.addReportCommentRes.error = null;
        });

        builder.addCase(addReportComment.fulfilled, (state, action) => {
            state.addReportCommentRes.data = action.payload.data;
            state.addReportCommentRes.loading = false;
        })

        builder.addCase(addReportComment.rejected, (state) => {
            // console.log("case is", state)
            state.addReportCommentRes.data = null;
            state.addReportCommentRes.error = "Error adding comments";
            state.addReportCommentRes.loading = false;
        })

        builder.addCase(exportReportToPdf.pending, (state) => {
            state.exportReportPdfRes.loading = true;
            state.exportReportPdfRes.error = null;
        });

        builder.addCase(exportReportToPdf.fulfilled, (state, action) => {
            state.exportReportPdfRes.data = action.payload.data;
            state.exportReportPdfRes.loading = false;
        })

        builder.addCase(exportReportToPdf.rejected, (state) => {
            // console.log("case is", state)
            state.exportReportPdfRes.data = null;
            state.exportReportPdfRes.error = "Error downloading PDF";
            state.exportReportPdfRes.loading = false;
        })

        builder.addCase(deleteReportComment.pending, (state) => {
            state.deleteReportCommentRes.loading = true;
            state.deleteReportCommentRes.error = null;
        });

        builder.addCase(deleteReportComment.fulfilled, (state, action) => {
            state.deleteReportCommentRes.data = action.payload.data;
            state.deleteReportCommentRes.loading = false;
        })

        builder.addCase(deleteReportComment.rejected, (state) => {
            // console.log("case is", state)
            state.deleteReportCommentRes.data = null;
            state.deleteReportCommentRes.error = "Error deleting comments";
            state.deleteReportCommentRes.loading = false;
        })

        builder.addCase(uploadStaticImages.pending, (state) => {
            state.staticImages.loading = true;
            state.staticImages.error = null;
        });

        builder.addCase(uploadStaticImages.fulfilled, (state, action) => {
            state.staticImages.data = action.payload.data;
            state.staticImages.loading = false;
        })

        builder.addCase(uploadStaticImages.rejected, (state) => {
            state.staticImages.data = null;
            state.staticImages.error = "Error retrieving uploaded data";
            state.staticImages.loading = false;
        })

        builder.addCase(getPerformanceAnalysis.pending, (state) => {
            state.performanceAly.loading = true;
            state.performanceAly.error = null;
        });

        builder.addCase(getPerformanceAnalysis.fulfilled, (state, action) => {
            state.performanceAly.data = action.payload.data;
            state.performanceAly.loading = false;
        })

        builder.addCase(getPerformanceAnalysis.rejected, (state) => {
            state.performanceAly.data = null;
            state.performanceAly.error = "Error retrieving PA data";
            state.performanceAly.loading = false;
        })

        builder.addCase(getPATopPerformingRes.pending, (state) => {
            state.PATopPerformingRes.loading = true;
            state.PATopPerformingRes.error = null;
        });

        builder.addCase(getPATopPerformingRes.fulfilled, (state, action) => {
            state.PATopPerformingRes.data = action.payload.data;
            state.PATopPerformingRes.loading = false;
        })

        builder.addCase(getPATopPerformingRes.rejected, (state) => {
            state.PATopPerformingRes.data = null;
            state.PATopPerformingRes.error = "Error retrieving PA data";
            state.PATopPerformingRes.loading = false;
        })


        builder.addCase(getInfluencerNarrativeRes.pending, (state) => {
            state.InfluencerNarrativeRes.loading = true;
            state.InfluencerNarrativeRes.error = null;
        });

        builder.addCase(getInfluencerNarrativeRes.fulfilled, (state, action) => {
            state.InfluencerNarrativeRes.data = action.payload.data;
            state.InfluencerNarrativeRes.loading = false;
        })

        builder.addCase(getInfluencerNarrativeRes.rejected
            , (state) => {
                state.InfluencerNarrativeRes.data = null;
                state.InfluencerNarrativeRes.error = "Error retrieving influencer Narratives data";
                state.InfluencerNarrativeRes.loading = false;
            })

        builder.addCase(getEarnedMediaValueRes.pending, (state) => {
            state.EarnedMediaValueRes.loading = true;
            state.EarnedMediaValueRes.error = null;
        });

        builder.addCase(getEarnedMediaValueRes.fulfilled, (state, action) => {
            state.EarnedMediaValueRes.data = action.payload.data;
            state.EarnedMediaValueRes.loading = false;
        })

        builder.addCase(getEarnedMediaValueRes.rejected, (state) => {
            state.EarnedMediaValueRes.data = null;
            state.EarnedMediaValueRes.error = "Error retrieving earned media value data";
            state.EarnedMediaValueRes.loading = false;
        })

        builder.addCase(getEarnedMediaRes.pending, (state) => {
            state.EarnedMediaDataRes.loading = true;
            state.EarnedMediaDataRes.error = null;
        });

        builder.addCase(getEarnedMediaRes.fulfilled, (state, action) => {
            state.EarnedMediaDataRes.data = action.payload.data;
            state.EarnedMediaDataRes.loading = false;
        })

        builder.addCase(getEarnedMediaRes.rejected
            , (state) => {
                state.EarnedMediaDataRes.data = null;
                state.EarnedMediaDataRes.error = "Error retrieving earned media data";
                state.EarnedMediaDataRes.loading = false;
            })

        builder.addCase(getSocialPlatformsRes.pending, (state) => {
            state.SocialPlatformsRes.loading = true;
            state.SocialPlatformsRes.error = null;
        });

        builder.addCase(getSocialPlatformsRes.fulfilled, (state, action) => {
            state.SocialPlatformsRes.data = action.payload.data;
            state.SocialPlatformsRes.loading = false;
        })

        builder.addCase(getSocialPlatformsRes.rejected
            , (state) => {
                state.SocialPlatformsRes.data = null;
                state.SocialPlatformsRes.error = "Error retrieving social platform data";
                state.SocialPlatformsRes.loading = false;
            })

        builder.addCase(getTopCreatorsRes.pending, (state) => {
            state.TopCreatorsRes.loading = true;
            state.TopCreatorsRes.error = null;
        });

        builder.addCase(getTopCreatorsRes.fulfilled, (state, action) => {
            state.TopCreatorsRes.data = action.payload.data;
            state.TopCreatorsRes.loading = false;
        })

        builder.addCase(getTopCreatorsRes.rejected
            , (state) => {
                state.TopCreatorsRes.data = null;
                state.TopCreatorsRes.error = "Error retrieving top creators data";
                state.TopCreatorsRes.loading = false;
            })

        builder.addCase(getPerformanceBreakdownRes.pending, (state) => {
            state.PerformanceBreakdownRes.loading = true;
            state.PerformanceBreakdownRes.error = null;
        });

        builder.addCase(getPerformanceBreakdownRes.fulfilled, (state, action) => {
            state.PerformanceBreakdownRes.data = action.payload;
            state.PerformanceBreakdownRes.loading = false;
        })

        builder.addCase(getPerformanceBreakdownRes.rejected
            , (state) => {
                state.PerformanceBreakdownRes.data = null;
                state.PerformanceBreakdownRes.error = "Error retrieving performance breakdown data";
                state.PerformanceBreakdownRes.loading = false;
            })

        builder.addCase(getKeyInsightsRes.pending, (state) => {
            state.keyInsightsRes.loading = true;
            state.keyInsightsRes.error = null;
            state.keyInsightsRes.data = null;
        });

        builder.addCase(getKeyInsightsRes.fulfilled, (state, action) => {
            state.keyInsightsRes.data = action.payload.data;
            state.keyInsightsRes.loading = false;
        })

        builder.addCase(getKeyInsightsRes.rejected
            , (state) => {
                state.keyInsightsRes.data = null;
                state.keyInsightsRes.error = "Error retrieving key insights data";
                state.keyInsightsRes.loading = false;
            })

        builder.addCase(getAttachmentGetRes.pending, (state) => {
            state.AttachmentGetRes.loading = true;
            state.AttachmentGetRes.error = null;
        });

        builder.addCase(getAttachmentGetRes.fulfilled, (state, action) => {
            state.AttachmentGetRes.data = action.payload.data;
            state.AttachmentGetRes.loading = false;
        })

        builder.addCase(getAttachmentGetRes.rejected
            , (state) => {
                state.AttachmentGetRes.data = null;
                state.AttachmentGetRes.error = "Error retrieving Attachments data";
                state.AttachmentGetRes.loading = false;
            })

        builder.addCase(getAttachmentPostRes.pending, (state) => {
            state.AttachmentPostRes.loading = true;
            state.AttachmentPostRes.error = null;
        });

        builder.addCase(getAttachmentPostRes.fulfilled, (state, action) => {
            state.AttachmentPostRes.data = action.payload.success;
            state.AttachmentPostRes.loading = false;
        })

        builder.addCase(getAttachmentPostRes.rejected
            , (state) => {
                state.AttachmentPostRes.data = null;
                state.AttachmentPostRes.error = "Error updating Attachments data";
                state.AttachmentPostRes.loading = false;
            })

        builder.addCase(getNewChannelPerformanceRes.pending, (state) => {
            state.NewChannelPerformanceRes.loading = true;
            state.NewChannelPerformanceRes.error = null;
        });

        builder.addCase(getNewChannelPerformanceRes.fulfilled, (state, action) => {
            state.NewChannelPerformanceRes.data = action.payload.data;
            state.NewChannelPerformanceRes.loading = false;
        })

        builder.addCase(getNewChannelPerformanceRes.rejected, (state) => {
            state.NewChannelPerformanceRes.data = null;
            state.NewChannelPerformanceRes.error = "Error retrieving channel performance data";
            state.NewChannelPerformanceRes.loading = false;
        })

        builder.addCase(getNewEfficiencyRes.pending, (state) => {
            state.NewEfficiencyRes.loading = true;
            state.NewEfficiencyRes.error = null;
        });

        builder.addCase(getNewEfficiencyRes.fulfilled, (state, action) => {
            state.NewEfficiencyRes.data = action.payload.data;
            state.NewEfficiencyRes.loading = false;
        })

        builder.addCase(getNewEfficiencyRes.rejected, (state) => {
            state.NewEfficiencyRes.data = null;
            state.NewEfficiencyRes.error = "Error retrieving efficiency data";
            state.NewEfficiencyRes.loading = false;
        })

        builder.addCase(getActionableInsightsRes.pending, (state) => {
            state.ActionableInsightsRes.loading = true;
            state.ActionableInsightsRes.error = null;
            // state.ActionableInsightsRes.data = null;
        });

        builder.addCase(getActionableInsightsRes.fulfilled, (state, action) => {
            state.ActionableInsightsRes.data = action.payload.data;
            state.ActionableInsightsRes.loading = false;
        })

        builder.addCase(getActionableInsightsRes.rejected, (state) => {
            state.ActionableInsightsRes.data = null;
            state.ActionableInsightsRes.error = "Error retrieving efficiency data";
            state.ActionableInsightsRes.loading = false;
        })

        builder.addCase(getInfluencerDetailsModalRes.rejected, (state) => {
            state.InfluencerDetailsModalRes.data = null;
            state.InfluencerDetailsModalRes.error = "Error retrieving modal data";
            state.InfluencerDetailsModalRes.loading = false;
        })
        builder.addCase(getInfluencerSummaryModalRes.rejected, (state) => {
            state.InfluencerSummaryModalRes.data = null;
            state.InfluencerSummaryModalRes.error = "Error retrieving modal data";
            state.InfluencerSummaryModalRes.loading = false;
        })
        builder.addCase(getInfluencerAccountModalRes.rejected, (state) => {
            state.InfluencerAccountModalRes.data = null;
            state.InfluencerAccountModalRes.error = "Error retrieving modal data";
            state.InfluencerAccountModalRes.loading = false;
        })
        builder.addCase(getInfluencerSocialsModalRes.rejected, (state) => {
            state.InfluencerSocialsModalRes.data = null;
            state.InfluencerSocialsModalRes.error = "Error retrieving modal data";
            state.InfluencerSocialsModalRes.loading = false;
        })

        //getTopPerformingNarrativeRes
        builder.addCase(getTopPerformingNarrativeRes.pending, (state) => {
            state.TopPerformingNarrativeRes.loading = true;
            state.TopPerformingNarrativeRes.error = null;
            // state.ActionableInsightsRes.data = null;
        });

        builder.addCase(getTopPerformingNarrativeRes.fulfilled, (state, action) => {
            state.TopPerformingNarrativeRes.data = action.payload.data;
            state.TopPerformingNarrativeRes.loading = false;
        })

        builder.addCase(getTopPerformingNarrativeRes.rejected, (state) => {
            state.TopPerformingNarrativeRes.data = null;
            state.TopPerformingNarrativeRes.error = "Error retrieving efficiency data";
            state.TopPerformingNarrativeRes.loading = false;
        })
        
    }
})

export default reportSlice.reducer;
export const { updateRange, updateDisplayReports } = reportSlice.actions
