import {topPerformingData} from '../../../../../../../setup/types/topPerformingTypes'
// import {Creators} from '../../../../../../../setup/types/topPerformingTypes'
import {abvNum} from '../../../../../../global/functions'
// abvNum
import { ContentCardProps } from './contentCardProps';


interface filterObj{
    first:string,
    second:string
}
// "/media/cpg/reportImg1.png"
export const topCreators = (data:topPerformingData|null, filterValues:filterObj) =>{
    let creatorsContent:ContentCardProps[] = [];

    let sortKey:string = filterValues.first
    let filterkey:string =  filterValues.second

    // let check = data ? data.contents.map((content) => {
    //     return content.PostType
    // }) : null;
    // console.log(check)
    // Filter by SocialNetwork
    let filteredData = data ? data.contents.filter((content) => {
        if(filterkey === "All") return true
        if(filterkey === "TikTok Video" && content?.SocialNetwork?.toLowerCase() === "tiktok" && content?.PostType?.toLowerCase() === "video") return content?.PostType?.toLowerCase() === "video"
        if(filterkey === "IG Story" && content?.PostType?.toLowerCase() === "story") return content?.PostType?.toLowerCase() === "story"
        if(filterkey === "Static" && content?.PostType?.toLowerCase() === "image") return content?.PostType?.toLowerCase() === "image"
        if(filterkey === "YouTube Long-Form" && content?.SocialNetwork?.toLowerCase() === "youtube" && content?.PostType?.toLowerCase() === "video") return content?.PostType?.toLowerCase() === "video"

        return content?.PostType?.toLowerCase() === filterkey?.toLowerCase()
    }) : null;


    let creators = filteredData ? filteredData.sort((a, b) =>  (b[sortKey] as number) - (a[sortKey] as number)) : null;


    creators?.slice(0,4).map((item, index)=>{


        if(item?.SocialNetworkId){
            
            creatorsContent.push({
                    id: index+1,
                    postId: item.Id,
                    social: {
                        publisherId:item?.PublisherId?.toString() || "",
                        imageBgSrc: item?.SavedPicture || item?.Thumbnail || item?.LogoURL || "/media/cpg/reportImg4.png",
                        imageSrc:  item?.LogoURL || item?.SavedPicture || item?.Thumbnail || "/media/cpg/reportImg4.png",
                        icon: null,
                        handle: item?.UserName || "N/A",
                        platform: item?.SocialNetwork,
                        link:item?.PostLink
                    },
                    label: `Total ${sortKey === "EngangementRate" ? "Engagement Rate" : 
                    sortKey === "LinkClicks" ? "Link Clicks" : sortKey} 
                    (${sortKey === "CTR" || sortKey === "EngangementRate" ? 
                    `${parseFloat(item[sortKey] as string).toFixed(2) || "0"}%` : `${abvNum(item[sortKey]) || "0"}` || "0"})`,
                    data: ""
            })

            

        }
        else{
            // if((sortKey === "CTR" && filterkey === "Carousel") || 
            // (sortKey === "LinkClicks" && filterkey === "Carousel") ){
            //     return
            // }
            // if((sortKey === "CTR" && filterkey === "Reel") || 
            // (sortKey === "LinkClicks" && filterkey === "Reel") ){
            //     return
            // }
            // if((sortKey === "Likes" && filterkey === "IG Story") ){
            //     return
            // }
            creatorsContent.push({
                id: index+1,
                postId: item.Id,
                social: {
                    publisherId:item?.PublisherId?.toString() || "",
                    imageBgSrc: item?.SavedPicture || item?.PostLink || "/media/cpg/reportImg4.png",
                    imageSrc:  item?.LogoURL || item?.SavedPicture || "/media/cpg/reportImg4.png",
                    icon: null,
                    handle: item?.PublisherName || "N/A",
                    platform: item?.SocialNetwork,
                    link:item?.PostLink
                },
                label: `Total ${sortKey === "EngangementRate" ? "Engagement Rate" : 
                sortKey === "LinkClicks" ? "Link Clicks" : sortKey} 
                (${sortKey === "CTR" || sortKey === "EngangementRate" ? 
                `${isNaN(parseFloat(item[sortKey] as string)) ? "0" : parseFloat(item[sortKey] as string).toFixed(2)}%` : `${abvNum(item[sortKey]) ?? "0"}` || "0"})`,
                data: "stats"
            })
        }
        

        

        return index
    })

    return creatorsContent;
}


// start of former code


// let topComments: Creators = {
//     publisherId: 0,
//     name: "creator",
//     impressions: 0,
//     engangementRate: -100,
//     reach: 0,
//     posts: 0,
//     comments: 0,
//     likes: 0,
//     engagement: 0,
//     logoURL: "logourl"
// }


// let highestSentiment:Creators = topComments
// let highestEngagement: Creators = topComments
// let highestEngagementRate: Creators = topComments

// data?.creators.map((item:any, index:number) => {
//     // if(!topComments || item.comments > topComments.comments){
//     //     topComments = item
        
//     // }

//     if(index===1){
//         topComments = item
//     }

//     if(index===2){
//         highestSentiment = item
//     }

//     if(index===3){
//         highestEngagement = item
//     }

//     if(index===4){
//         highestEngagementRate = item
//     }

//     // if(!highestSentiment || item.likes > highestSentiment.likes){
//     //     highestSentiment = item
//     // }
//     // if(!highestEngagement || item.engagement > highestEngagement.engagement){
//     //     highestEngagement = item
//     // }
//     // if(!highestEngagementRate || item.engangementRate > highestEngagementRate.engangementRate){
        
//     //     highestEngagementRate = item
//     // }
    
// })



// if(highestSentiment!){
//     creatorsContent.push({
//     id: highestSentiment?.publisherId,
//     social: {
//         imageBgSrc: highestSentiment?.logoURL || "/media/cpg/reportImg1.png",
//         imageSrc: "",
//         icon: null,
//         handle: highestSentiment?.name || "N/A",
//         platform: "instagram"
//     },
//     label: `Total Reach (${abvNum(highestSentiment?.reach) || "0"})`
//     })
// }

// if(topComments!){
//     creatorsContent.push({
//         id: topComments?.publisherId || "N/A",
//         social: {
//             imageBgSrc: topComments?.logoURL || "/media/cpg/reportImg1.png",
//             imageSrc: "",
//             icon: null,
//             handle: topComments?.name || "N/A",
//             platform: "instagram"
//         },
//         label: `Total Reach (${abvNum(topComments?.reach) || "0"})`
//     })
// }

// if(highestEngagement!){
//     creatorsContent.push({
//         id: highestEngagement?.publisherId || "N/A",
//         social: {
//             imageBgSrc: highestEngagement?.logoURL || "/media/cpg/reportImg1.png",
//             imageSrc: "",
//             icon: null,
//             handle: highestEngagement?.name || "N/A",
//             platform: "instagram"
//         },
//         label: `Total Reach(${abvNum(highestEngagement?.reach) || "0"})`
//     })
// }


// if(highestEngagementRate!){
    
//     creatorsContent.push({
//         id: highestEngagementRate?.publisherId || "1",
//         social: {
//             imageBgSrc: highestEngagementRate?.logoURL || "/media/cpg/reportImg1.png",
//             imageSrc: "",
//             icon: null,
//             handle: highestEngagementRate?.name || "N/A",
//             platform: "instagram"
//         },
//         label: `Total Reach ${abvNum(highestEngagementRate?.reach) || "0"}`
//     })
// }
