import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector} from "../../../../../../../setup/redux/hooks";
import { getAttachmentPostRes, getAttachmentGetRes } from "../../../../../../../setup/features/report.slice";

import axios from 'axios';
import { toast } from 'react-toastify';

import { useState, useRef, useEffect } from "react";
import { getPaddingAndBorder } from "react-range/lib/utils";

interface MyDropdownProps {
  handleClick?: () => void;
  optionList?: string[];
  isExternal?: boolean;
  isNotAmyris?:boolean;
  campaignId: string;
}

const API_URL = process.env.REACT_APP_API_URL;

export const AttachmentDropdown: React.FC<MyDropdownProps> = ({ optionList,isNotAmyris, campaignId, isExternal }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [linkOpen, setLinkOpen] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [linkInput, setLinkInput] = useState('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (imageFile) {

            const formData = new FormData();
            formData.append('campaign_id', campaignId);
            formData.append('file', imageFile);
            formData.append('description' , "");
            formData.append('type', "attachment")
            

            const uploadImage = dispatch(getAttachmentPostRes({
                campaign_id: campaignId,
                file: formData
            }));

            toast.promise(
                uploadImage,
                {
                  pending: 'Uploading Attachment',
                  success: 'Attachment Uploaded',
                  error: 'Network error or file size is too large'
                }
            )

            uploadImage.then(res => dispatch(getAttachmentGetRes({
                campaign_id: campaignId, 
                is_external:isExternal
            })))
            setImageFile(null)

        }
    }, [imageFile])

    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const selectedFile = e.target.files?.[0];
        setImageFile(selectedFile ? selectedFile : null);
    }

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
        setLinkOpen(false);
      };

    const handleLinkInput = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setLinkInput(event.target.value)
    }
    
    const handleSubmitLink = async() => {
        handleDropdownToggle()
        const dataSubmit = {
            "campaign_id": campaignId,
            "link": linkInput
        }
        
        const response = await toast.promise(axios.post(`${API_URL}api/new-reports/add-links`,dataSubmit),{
            pending: 'Updating Link Attachment',
            success: `Link added successfully!`,
            error: `Error adding Link: try again later`
        }).then(res => dispatch(getAttachmentGetRes({
            campaign_id: campaignId, 
            is_external:isExternal
        })))

        
    }
    
  return (
    <>
    {dropdownOpen && <div className="comments_underlay" style={{width:"100%"}}></div>}

    <input type="file" name="upload-attachment" 
                id="upload-attachment" className="" onChange={handleFile}  style={{
                    width:"1px",
                    height:"1px",
                    visibility:"hidden",
                }}/>
                
    <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
        <Dropdown.Toggle  id="dropdown-attachments" className="attachments-options" >
            <img src={toAbsoluteUrl("/media/cpg/upload-attachment.png")} 
            alt="edit sections" style={{marginRight:"8px"}}/>
            Upload
        </Dropdown.Toggle>

        { dropdownOpen &&
             (
                <Dropdown.Menu className="dropdownMenu-attachment">
                    
                    <div className="attachmentsMenu">
                        <div className="attachmentsMenu__item" onClick={() => setLinkOpen(true)}>
                            <img src={toAbsoluteUrl("/media/cpg/link-icon.png")} alt="link" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                            <p style={{fontSize:"14px", margin:0}}>Link</p>
                        </div>
                        <label htmlFor="upload-attachment" className="attachmentsMenu__item">
                            <img src={toAbsoluteUrl("/media/cpg/file-icon.png")} alt="link" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                            <p style={{fontSize:"14px", margin:0}}>File</p>
                        </label>
                    </div>
                </Dropdown.Menu>
            )
        }

        {/* This opens if it is a link */}
        {
            linkOpen && dropdownOpen && (
                <Dropdown.Menu className="dropdownMenu-attachment">
                    <div className="attachmentsMenu-link">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0 NeueMontreal-bold text__20 text__black">Upload Link</p>
                        <img onClick={handleDropdownToggle} src={toAbsoluteUrl("/media/cpg/circle-xmark-attachments.png")} alt="link" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                    </div>

                    <div className="mt-6 bg_white_fade d-flex justify-content-between align-items-center">
                        <input type="url" className="dropdown_link_text text_gray bg_white_fade border__none" 
                        placeholder="Paste the link you want to upload" 
                        style={{border:"none", outline:"none"}} onChange={
                            (event) => handleLinkInput(event)
                        }/>
                        <img src={toAbsoluteUrl("/media/cpg/url-paste.png")} alt="link" style={{width:"16px", height:"16px", marginRight:"3px"}}/>
                    </div>

                    <div className="mt-6">
                        <button onClick={handleSubmitLink} className="Button border-none font__600 text__download link__attachment_button" >Upload</button>
                    </div>
                    </div>
                </Dropdown.Menu>
            )
        }

        
    </Dropdown>
    </>
  );
};

