
import {
    // fbIgOrganic, 
    // tiktokOrganic, 
    // youtubeOrganic, 
    actualDeliveryOrganic, 
    estimatedDeliveryOrganic, 
    fbIgOrganic2 as fbIgOrganic,
    tiktokOrganic2 as tiktokOrganic,
    youtubeOrganic2 as youtubeOrganic
} from './campaignFieldsSMItems/index'

import {
    // fbIgOrganic, 
    // tiktokOrganic, 
    // youtubeOrganic, 
    actualDeliveryPaid, 
    estimatedDeliveryPaid, 
    fbIgPaid,
    tiktokPaid,
    youtubePaid
} from './paidCampaignFieldsSMItems/index'

export const tableForms: any = {
    // actualDeliveryOrganic,
    // estimatedDeliveryOrganic,
    fbIgOrganic,
    tiktokOrganic,
    youtubeOrganic
}

export const paidTableForms: any = {
    fbIgPaid,
    tiktokPaid,
    youtubePaid
}