import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { metricValueFormatter } from "../../../../../../../helper";


const placeholder = [
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
];
function addDefaultSrc(ev: any) {
    ev.target.src = toAbsoluteUrl('/media/cpg/reportImg4.png');
  }

export function TPCProfileHeader({
    avatar,
    profileName="",
    followers=0,
    impressionsPerViews,
    averageEngagementRate,
    influencerLink
}: {
    avatar: string;
    profileName: string;
    followers: number;
    impressionsPerViews?: string;
    averageEngagementRate?: string;
    influencerLink?:string
}) {
    return (
        <div 
            className="d-flex justify-content-between align-items-stretch"
            style={{ maxWidth: "700px", gap: "32px", 
            // backgroundColor: "green" 
        }}
        >
            <a href={influencerLink} target="_blank" rel="noreferrer">
            <img 
                onError={(ev)=> { 
                    addDefaultSrc(ev)
                }}
                src={avatar}
                alt="hero-card"
                className="image-fluid d-block" 
                style={{ 
                    width: 180, 
                    height: 180,
                    borderRadius: 90
                }}
            /> 
            </a>

            <div>
                <h3
                    className="text__24 NeueMontreal-normal text-dark text-align-center p-0"
                    style={{ fontWeight: 500, marginBottom: "16px", width: "fit-content" }}
                >
                    {profileName || ""}
                </h3>
                
                <p 
                    className="text__16 NeueMontreal text-align-center p-0"
                    style={{ 
                        color: "#8C8C8C",
                        fontWeight: 500, 
                        marginBottom: "10px", 
                        width: "fit-content"
                    }}
                >{`${metricValueFormatter(followers || 0, 2) || 0} Followers`}</p>

                <div className="d-flex justify-content-between align-items-center" 
                    style={{ gap: 10 }}
                >
                    <div>
                        <p 
                            className="text__20 NeueMontreal text-dark text-align-center p-0 m-0"
                            style={{ fontWeight: 300, marginBottom: "16px", width: "fit-content" }}
                        >
                            Est. Impressions/Views
                        </p>
                        <p 
                            className="NeueMontreal-bold text-align-center p-0 m-0"
                            style={{ color: "#3771C8", fontSize: "40px" }}    
                        >
                            {impressionsPerViews || 0}
                        </p>
                    </div>

                    <div  
                        style={{
                            height: "100px",
                            width: "3px",
                            backgroundColor: "#F4F4F4"
                        }}
                    />

                    <div>
                        <p 
                            className="text__20 NeueMontreal text-dark text-align-center p-0 m-0"
                            style={{ fontWeight: 300, marginBottom: "16px", width: "fit-content" }}
                        >
                            Avg. Engagement Rate
                        </p>
                        <p 
                           className="NeueMontreal-bold text-align-center p-0 m-0"
                           style={{ color: "#000", fontSize: "40px" }}      
                        >
                            {averageEngagementRate || 0}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}