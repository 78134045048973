import { useState, useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Chart from "react-apexcharts";
import { PieChart } from '../../../../_metronic/partials/widgets';

export default function StatsAudience(props: any) {
  console.log('props: ', props);
  const [audiences, set_data] = useState<any>([]);
  const [options, set_options] = useState<any>([]);
  const [emoStd, setDemoStd] = useState<any>([]);

  useEffect(() => {
    // console.log(props); return;
    if (props.demographics && props.demographics.Audience) {
      // props.campaigns.Demographics.forEach((e: any) => {
      //   var b: any = {};

      //   console.log(b);
      //   // t.push(b);
      //   set_data((prevState: any) => [...prevState, b])
      // })
      // const demoSt = props.demographics.Audience;
      const demoStd = props.demographics.Audience?.FlatAudienceUI;

      // var response = {
      //   11791146: {m_serverQuery: "", m_key: 11791146} 
      // }

      var demoSt = demoStd[Object.keys(demoStd)[0]];
      // console.log(demoSt); return;
      if (demoSt) {
        setDemoStd(true);
        var ddd = [
          {
            label: 'Age',
            data: [
              // { label: '< 18', value: demoSt['13-17'] || 0 },
              { label: '18 - 24', value: Number(demoSt['18-24']).toFixed(2) || 0 },
              { label: '25 - 34', value: Number(demoSt['25-34']).toFixed(2) || 0 },
              { label: '35 - 34', value: Number(demoSt['35-44']).toFixed(2) || 0 },
              { label: '45 - 64', value: Number(demoSt['45-64']).toFixed(2) || 0 },
              // { label: '55 - 64', value: demoSt['55-64'] || 0 },
              { label: '> 65', value: Number(demoSt['65-']).toFixed(2) || 0 },
            ]
          },
          {
            label: 'Ethnicity',
            data: [
              { label: demoSt.AudienceEthnicity_Top1 || 'Ethnicity 1', value: Number(demoSt.AudienceEthnicityShare_Top1).toFixed(2) || 0 },
              { label: demoSt.AudienceEthnicity_Top2 || 'Ethnicity 2', value: Number(demoSt.AudienceEthnicityShare_Top2).toFixed(2) || 0 },
              { label: demoSt.AudienceEthnicity_Top3 || 'Ethnicity 3', value: Number(demoSt.AudienceEthnicityShare_Top3).toFixed(2) || 0 },
              { label: demoSt.AudienceEthnicity_Top4 || 'Ethnicity 4', value: Number(demoSt.AudienceEthnicityShare_Top4).toFixed(2) || 0 },
              // { label: demoSt.AudienceEthnicity_Top5 || 'Ethnicity 5', value: Number(demoSt.AudienceEthnicityShare_Top5).toFixed(2) || 0 },
            ]
          },
          // {
          //   label: 'Top Countries',
          //   data: [
          //     { label: 'Portugal', value: '50', icon: 'flag' },
          //     { label: 'Ireland', value: '30', icon: 'flag' },
          //     { label: 'Poland', value: '20', icon: 'flag' },
          //   ]
          // }
        ]

        var op = [demoSt?.Female || 0, demoSt?.Male || 0]
        set_options(op);

        set_data(ddd);
      } else {
        setDemoStd(false);
      }
    }
  }, [props])

  // const audiences = [
  //   {
  //     label: 'Age',
  //     data: [
  //       { label: '< 18', value: '5.2' },
  //       { label: '18 - 24', value: '35' },
  //       { label: '25 - 34', value: '48' },
  //       { label: '35 - 34', value: '3.5' },
  //       { label: '45 - 54', value: '11' },
  //     ]
  //   },
  //   {
  //     label: 'Age',
  //     data: [
  //       { label: '< 18', value: '5.2' },
  //       { label: '18 - 24', value: '35' },
  //       { label: '25 - 34', value: '48' },
  //       { label: '35 - 34', value: '3.5' },
  //       { label: '45 - 54', value: '11' },
  //     ]
  //   },
  //   {
  //     label: 'Ethnicity',
  //     data: [
  //       { label: 'Caucasian', value: '45' },
  //       { label: 'Asian', value: '24.5' },
  //       { label: 'Hispanic', value: '17.5' },
  //       { label: 'Black', value: '13.5' },
  //     ]
  //   },
  //   {
  //     label: 'Age',
  //     data: [
  //       { label: '< 18', value: '5.2' },
  //       { label: '18 - 24', value: '35' },
  //       { label: '25 - 34', value: '48' },
  //       { label: '35 - 34', value: '3.5' },
  //       { label: '45 - 54', value: '11' },
  //     ]
  //   },
  //   {
  //     label: 'Top Countries',
  //     data: [
  //       { label: 'Portugal', value: '50', icon: 'flag' },
  //       { label: 'Ireland', value: '30', icon: 'flag' },
  //       { label: 'Poland', value: '20', icon: 'flag' },
  //     ]
  //   },
  // ]

  return (
    <div className="row gx-2 gy-4 mt-4" >
      {/* <div className="col-xl-3">
        {options && <PieChart stroke={options} />}
      </div> */}
      <div className="col-xl-9">
        <div className="row">
          {audiences && audiences.map((audience: any, index: number) => (
            <div className="col-xl-3 mb-4 mb-lg-10" key={index}>
              <h5 className="fw-normal mb-3">{audience.label}</h5>
              {audience.data.map((el: any, index: number) => (
                <div className="d-flex align-items-center justify-flex-start mb-2" key={index}>
                  <div className="d-flex align-items-center" style={{ width: '98px' }}>
                    {el?.icon && <img src={toAbsoluteUrl(`/media/cpg/${el.icon}.png`)}
                      style={{ marginRight: '4px', borderRadius: '16px', width: '20px', objectFit: 'cover' }}
                    />}
                    <span className="d-block" style={{ fontSize: '14px', color: '#000' }}>{el.label}</span>
                  </div>
                  <div style={{ backgroundColor: '#99D0F2', width: `${Number(el.value) * 1.25}px`, height: '16px', marginRight: '16px' }} />
                  <div>
                    <span className="d-block" style={{ fontSize: '14px', color: '#000' }}>{el.value}%</span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="">
          <h1 className="text-left mt-8">Audience data not found</h1>
        </div>
      </div>
    </div >
  )
}
