import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'campaigns') {
    return axios.get(`${URL}api/campaign/all${queryParams}`);
  } 
}

export function fetchDetails(t, id) {
  return axios.get(`${URL}api/campaign?campaign_id=${id}`);
}

export function fetchStats(t, id) {
  return axios.get(`${URL}api/campaign/stats?campaign_id=${id}`);
}

export function fetchEngagementRates(id) {
  return axios.get(`${URL}api/campaign/engagement_rates?campaign_id=${id}`);
}

export function fetchScore(id) {
  return axios.get(`${URL}api/campaign/score?campaign_id=${id}`);
}

export function editDetails(body, id) {
  return axios.put(`${URL}api/campaign?campaign_id=${id}`, body);
}

export function fetchDafault() {
  return axios.get(`${URL}api/campaign/default`);
}