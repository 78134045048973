import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import * as functions from "../../../../../global/functions";
import { TableBody } from "../../../../../../_metronic/layout/components/table/TableBody"
import { TableHead } from "../../../../../../_metronic/layout/components/table/TableHead"
import { TableWrapper } from "../../../../../../_metronic/layout/components/table/TableWrapper"
import { CommentBox } from "../../../../../../_metronic/layout/components/CommentBox";
import { EarnedMedia } from "./EarnedMedia";
import { EarnedMediaValue } from "./EarnedMediaValue";
import { TopCreators } from "./TopCreators";

import { getSocialPlatformsRes, getTopCreatorsRes } from "../../../../../../setup/features/report.slice";
import { SocialPlatformsType } from "../../../../../../setup/types/report";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { CommentListPopUp } from "../components/CommentBox";
import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"


const TableValueText = ({ text, priority }: { text: string | number, priority?: boolean }) => (
    <p className={`${priority ? "Helvetica-Neue-medium" : "Helvetica-Neue-light"}`} style={{
        fontSize: "14px",
        fontWeight: priority ? 600 : 300,
        color: "#303030",
        textAlign:"left",
        margin: 0
    }}>{text}</p>
) 

export const ChannelPerformance = ({campaignId, isExternal, allCommentsData, commentsFilter, commentSectionToDisplay, setCommentSectionToDisplay}: { campaignId: any, isExternal?:boolean, allCommentsData?:any, commentsFilter:string, setCommentSectionToDisplay:any,commentSectionToDisplay:any}) => {
    const tableHeadings = [
        "Social Platform", "Creators", "Posts", "Est. Impressions", "Impressions / Views",
        "Reach", "Likes", "Comments","Saves", "Shares", "Engagement", "ER", "Clicks", "CTR", 
        "Retention Rate"
    ];

    const tableData = [ 
        {
            socialPlatform: {
                imageSource: "/media/cpg/insta.png",
                name: "Instagram"
            },
            creators: "7",
            posts: "10",
            impressions: "-",
            "Est. Impressions":  "203",
            "likes": "200",
            comments: "2300",
            "vide views": "1000",
            shares: "300",
            clicks: "24000",
            totalEngagement: "101000" 
        },
        {
            socialPlatform: {
                imageSource: "/media/cpg/tiktok.png",
                name: "Tiktok"
            },
            creators: "7",
            posts: "10",
            impressions: "-",
            "Est. Impressions":  "203",
            "likes": "200",
            comments: "2300",
            "vide views": "1000",
            shares: "300",
            clicks: "24000",
            totalEngagement: "101000" 
        },
    ]

    const dispatch = useAppDispatch();

    const { loading, error, data } = useAppSelector((state) => state.report.SocialPlatformsRes);

    const range  = useAppSelector((state) => state.report.range).toString() ;

    useEffect(() => {
        dispatch(getSocialPlatformsRes({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range: range.toString()
        }));
        dispatch(getTopCreatorsRes({
            campaign_id: campaignId,
            is_external:isExternal,
            date_range: range.toString()
        }))
        // dispatch(getNewChannelPerformanceRes({
        //     campaign_id: campaignId, 
        //     date_range: range.toString()
        // }));
        
    }, [campaignId, dispatch, range]);

    let {commentsLoading, commentsData} = allCommentsData;
    let filteredComments = !commentsLoading && commentsData? commentsData.filter((eachComment:any)=>eachComment?.tab_name===commentsFilter):[]
    
    return (
        <div className="my-16">

            {loading ? <Skeleton height={80} count={1}/> : (
                <div id="channel_breakdown" className="mt-4 p-8 bg-white section_wrap_content">
                {/* <h3 className="fs-3 NeueMontreal-bold">Social Platforms</h3> */}
                <div className="d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1 text-align-center">
                        <h1 className=" font__700 text__28 NeueMontreal-bold text-center m-0">
                        Channel performance This Week
                        </h1>
                    </div>
                    <CommentBox commentSectionToDisplay={commentsFilter} setCommentSectionToDisplay={setCommentSectionToDisplay} comments={[...filteredComments]} />
                    {(commentSectionToDisplay===commentsFilter)  &&  <CommentListPopUp isExternal={isExternal} campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={filteredComments} />}
                    {/* {(filteredComments.length>=1 && commentSectionToDisplay===commentsFilter)  &&  <CommentListPopUp setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={filteredComments} />} */}
                </div>

                {
                    (error || data === null ) ? (
                        <ErrorBoundary error={error}/>
                    ):(
                        <>
                            <div className="social-platforms mt-4">
                            <table className="table__overflow table table-borderless mt-4 " id="table__overflow">
                                <thead style={{ backgroundColor: "#F4F4FA", color:"#8c8c8c"}} className="border__round_16">
                                    <tr>
                                        {tableHeadings.map((heading, index) => (
                                            <th className="Helvetica-Neue-normal"  scope="col" key={index}>{heading}</th>
                                        ))}

                                        
                                    </tr>
                                </thead>

                                <tbody>
                                    {data && data?.map((item:SocialPlatformsType, index:number) => (
                                        <tr key={index} className="border__b-table channel__tr">
                                            <th className="channel__th" scope="row" style={{ marginLeft: "0", paddingLeft: "0px !important" }}>
                                                <div className="d-flex align-items-center media_name">
                                                    {/* <p style={{ fontSize: "16px", color: "#8C8C8C", fontWeight: 600, margin: "0 6px 0 0" }}>#{index + 1}</p> */}
                                                    <img
                                                        src={item.name?.toLowerCase() === "instagram" ? toAbsoluteUrl("/media/cpg/insta.png") : toAbsoluteUrl("/media/cpg/tiktok.png")}
                                                        alt="hero-card"
                                                        className="image-fluid d-block" 
                                                        style={{ 
                                                            width: '30px', 
                                                            maxHeight: '30px', 
                                                            resize: "both", 
                                                            objectFit: "cover", 
                                                            borderRadius: "4px",
                                                            marginRight:"8px"
                                                        }}
                                                    /> 
                                                    <TableValueText text={`${item?.name?.toLowerCase() === "instagram"? "Instagram" : "Tiktok"}`} />
                                                </div>
                                                </th>
                                            <td className="th_wrap" ><TableValueText text={`${item?.influencers?.length || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${item?.posts || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.estimatedImpressions || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.impressions || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.reach || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.likes || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.comments || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.saves || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.shares || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.engagement || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${item?.engagementRate?.toFixed(2) || item?.er?.toFixed(2) || 0}%`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${functions.abvNum(item?.clicks || 0) || 0}`} /></td>
                                            <td className="th_wrap"><TableValueText text={`${item?.ctr?.toFixed(2) || 0}%`}  /></td>
                                            <td className="th_wrap"><TableValueText text={`${item?.retentionRate?.toFixed(2) || 0}%`}  /></td>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </>
                    )
                }
                
            </div>
            )}
            
            
            {/* <EarnedMediaValue campaignId={campaignId} isExternal={isExternal}/>


            <div className="row p-0 mt-4">
                <div className="col">
                   <TopCreators />                             
                </div>  

                <div className="col">
                    <EarnedMedia campaignId={campaignId} isExternal={isExternal}/>
                </div>
            </div> */}
        </div>
    )
}