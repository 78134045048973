import axios from "axios";

const URL = process.env.REACT_APP_API_URL || 'api'

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'campaigns') {
    return axios.get(`${URL}api/campaign/all${queryParams}`);
  } else if (type === 'filter') {
    return axios.get(`${URL}api/campaign/filter${queryParams}`);
  } else if (type === 'refresh') {
    return axios.get(`${URL}api/campaign/refresh`);
  } else if (type === 'corrections') {
    return axios.get(`${URL}api/campaign/cross_corrections`);
  } 
}

export function fetchDetails(id) {
  return axios.get(`${URL}api/campaign?campaign_id=${id}`);
}

export function fetchStats(id) {
  return axios.get(`${URL}api/campaign/stats?campaign_id=${id}`);
}

export function fetchStatsAll(id, formType, statType) {
  return axios.get(`${URL}api/stats/all?campaign_id=${id}&type=${formType}&data_type=${statType}`);
}
 
export function fetchStatsPaginated(id, formType, statType, queryParams) {
  return axios.get(`${URL}api/stats/all?campaign_id=${id}&type=${formType}&data_type=${statType}${queryParams}`);
}


export function fetchDafault() {
  return axios.get(`${URL}api/campaign/default`);
}

export function fetchStuff(type, query) {
  if (type === 'links') {
    return axios.get(`${URL}api/campaign/links/all${query ? query : ''}`);
  } else if (type === 'clicks') {
    return axios.get(`${URL}api/campaign/click_summary?campaign_id=${query}`);
  } else if (type === 'click_analytics') {
    return axios.get(`${URL}api/campaign/click_analytics${query}`);
  } else if (type === 'analytics') {
    return axios.get(`${URL}api/campaign/analytics${query}`);
  } else if (type === 'corrections') {
    return axios.get(`${URL}api/campaign/cross_corrections${query}`);
  } else if (type === 'activity') {
    return axios.get(`${URL}api/campaign/activity${query}`);
  } 
}

export function fetchPosts(id) {
  return axios.get(`${URL}api/campaign/posts?campaign_id=${id}`);
}

export function fetchCampaignInfluencers(id) {
  return axios.get(`${URL}api/campaign/influencers?campaign_id=${id}`);
}

export function fetchEngagementRates(id) {
  return axios.get(`${URL}api/campaign/engagement_rates?campaign_id=${id}`);
}

export function fetchScore(id) {
  return axios.get(`${URL}api/campaign/score?campaign_id=${id}`);
}

export function editDetails(body, id, type) {
  if (type === 'details') {
    return axios.put(`${URL}api/campaign?campaign_id=${id}`, body);
  } else if (type === 'links') {
    return axios.post(`${URL}api/campaign/links`, body);
  } else if (type === 'corrections') {
    return axios.put(`${URL}api/campaign/cross_corrections`, body);
  } else {
    return axios.put(`${URL}api/campaign/default?campaign_id=${id}`, body);
  }
}

export function addCampaignStats(data) {
  return axios.post(`${URL}api/stats/`, data);
}

export function deleteDatas(query, id) {
  return axios.delete(`${URL}api/campaign/link${query}`);
}

export function deleteSingleStat(body) {
  return axios.delete(`${URL}api/stats?stat_id=${body.row_id}&data_type=${body.data_type}`);
}

export function editSingleStat(payload) {
  return axios.put(`${URL}api/stats?campaign_id=${payload.campaign_id}&type=${payload.type}&data_type=${payload.data_type}&stat_id=${payload.stat_id}`, payload);
}