import { useSelector, useDispatch } from 'react-redux';
import * as actions from "./_redux/Actions";
import { useEffect } from 'react';

// import { getCode } from 'iso-3166-1-alpha-2';
import { getAlpha2Code } from 'i18n-iso-countries';
import ReactCountryFlag from "react-country-flag";

import { registerLocale } from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';

registerLocale(localeEn);



type ProgressBarProps = {
    bgColor: string,
    fillColor: string;
    containerWidth?: string;
    fillWidth: string;
}

const CustomProgressBar = (
    {bgColor, containerWidth, fillColor, fillWidth,}: ProgressBarProps) => {

    return (
        <div
            style={{
                backgroundColor: bgColor,
                width: containerWidth || "100%",
                height: "20px",
                // borderRadius: 4
            }}
        >
            <div 
                style={{
                    width: fillWidth,
                    background: fillColor,
                    height: "inherit",
                    borderRadius: "inherit"
                }}
            />
        </div>
    )
}

export default function TPCAudience({data}:{data:any}) {
    const dispatch = useDispatch<any>()

    const { demographics } = useSelector(
        (state: any) => ({
          demographics: state.influencer?.demographics
        })
      );
    
      useEffect(() => {
        
        dispatch(actions.fetchAllData('demographics', `?influencer_id=${data?.networkPublisherId ?? data?.post[Object.keys(data.post)[0]]?.NetworkPublisherId ?? ""}`));
      }, []);

    const demoStd = demographics && demographics.Audience?.FlatAudience ;

    let demographicsKey =demographics && demoStd[Object.keys(demoStd)[0]]

    let AgeDemographics = (demographics && demographicsKey?.CombinedAge) || null 
    let EthnicityDemographics = (demographics && demographicsKey?.Ethnicity) || null
    let CountriesDemographics = (demographics && demographicsKey?.Countries) || null
    let CitiesDemographics = (demographics && demographicsKey?.Cities) || null
    
    return (
        <div>
            <h3 className="text__20 NeueMontreal-normal text-dark text-align-center p-0"
                style={{ marginBottom: "8px"}}
            >
                Audience 
            </h3>

            {
                !demographics ? (
                    <div></div>
                ) : (
                <div className="d-flex justify-content-between align-items-stretch flex-wrap" style={{gap:"10px"}}>
                <div>
                    <p 
                        className="text__20 NeueMontreal-normal text-dark text-align-center p-0"
                        style={{ margin: "0 0 18px 0"}}
                    >
                        Age
                    </p>

                    {AgeDemographics && AgeDemographics.map((group:any, index:number) => (
                        <div 
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                            style={{ gap: "20px", marginBottom: "8px", minWidth:"200px" }}
                        >
                            <p 
                                className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0"
                                style={{ width: "50px" }}
                            >
                                {group?.Title || ""}
                            </p>

                            {/* progress */}
                            <div style={{flex: 1, display: "flex", width:"100px", justifyContent:"space-between", alignItems: "center", gap: "8px"}}>
                                <CustomProgressBar 
                                    fillColor="#99D0F2"
                                    fillWidth={`${group?.Value?.toFixed(2) ?? 0}%`}
                                    bgColor="#f4f4f4"
                                    containerWidth="100%"
                                />

                                
                            </div>
                            <p className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0" 
                                style={{ width: "80px" }}>
                                    {`${group.Value?.toFixed(2) ?? 0}   %`} 
                            </p>
                        </div>
                    ))}
                </div>


                <div>
                    <p 
                        className="text__20 NeueMontreal-normal text-dark text-align-center p-0"
                        style={{ margin: "0 0 18px 0"}}
                    >
                        Top Ethnicities
                    </p>

                    {EthnicityDemographics && EthnicityDemographics.map((ethnicity:any, index:number) => (
                    <div 
                        className="d-flex justify-content-between align-items-center"
                        key={index}
                        style={{ gap: "20px", marginBottom: "8px", minWidth:"200px" }}
                    >
                        <p 
                            className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0"
                            style={{ width: "80px", paddingRight:"10px" }}
                        >
                            {ethnicity.Title}
                        </p>

                        {/* progress */}
                        
                        <div style={{flex: 1, display: "flex", width:"100px", justifyContent:"space-between", alignItems: "center", gap: "8px"}}>
                            <CustomProgressBar 
                                fillColor="#99D0F2"
                                fillWidth={`${ethnicity?.Value?.toFixed(2) ?? 0}%`}
                                bgColor="#f4f4f4"
                                containerWidth="100%"
                            />

                            
                        </div>

                        <p className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0"
                            style={{ width: "80px" }}>
                                {`${ethnicity?.Value?.toFixed(2) ?? 0}%`}
                            </p>
                    </div>
                    ))}
                </div>
                        
                <div className="d-flex flex-column align-items-stbg-danger">
                    <p className="text__20 NeueMontreal-normal text-dark text-align-center p-0"
                        style={{ margin: "0 0 18px 0"}}
                    >
                        Top Countries
                    </p>
                    
                    <div style={{ flex: 1}}>
                        {CountriesDemographics && CountriesDemographics.map((item:any, index:number) => (
                            <div 
                                className="d-flex justify-content-between align-items-stretch gap-50"
                                key={index}
                                style={{ gap: "50px", marginBottom: "18px", minWidth:"200px"}}
                            >   
                                <div className="d-flex align-items-center gap-4" style={{ minWidth: "140px"}} >
                                    {/* <img 
                                        // src={item.country.logo}
                                        src={"https://flagsapi.com/NG/shiny/64.png"}
                                        style={{
                                            width: "24px",
                                            height: "24px",
                                            borderRadius: "8px"
                                        }}
                                        alt="country flag"
                                    /> */}
                                    
                                    <ReactCountryFlag
                                        countryCode={getAlpha2Code(item.Title, 'en')}
                                        svg
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: "8px"
                                        }}
                                        title={item.Title}
                                    />
                                    <p className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0"
                                    style={{ paddingRight:"10px", width: "80px" }}>{item.Title}</p>
                                </div>

                                {/* progress */}
                                <div style={{flex: 1, display: "flex", width:"100px", justifyContent:"space-between", alignItems: "center", gap: "8px"}} >
                                    <CustomProgressBar 
                                        fillColor="#99D0F2"
                                        fillWidth={`${item?.Value?.toFixed(2) ?? 0}%`}
                                        bgColor="#f4f4f4"
                                        containerWidth="100%"
                                    />
                                </div>

                                <p className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0" 
                                    style={{ width: "80px" }}>
                                        {`${item?.Value?.toFixed(2) ?? 0}%`}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <p className="text__20 NeueMontreal-normal text-dark text-align-center p-0"
                        style={{ margin: "0 0 18px 0"}}

                    >
                        Top Cities Global
                    </p>

                    {CitiesDemographics && CitiesDemographics.map((topCity:any, index:number) => (
                        <div 
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                            style={{ gap: "20px", marginBottom: "8px", minWidth:"200px" }}
                        >
                            <p 
                                className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0"
                                style={{ width: "150px" }}
                            >
                                {topCity?.Title || ""}
                            </p>

                            {/* progress */}
                            
                            <div style={{flex: 1, display: "flex", width:"100px", justifyContent:"space-between",alignItems: "center", gap: "8px"}}>
                                <CustomProgressBar 
                                    fillColor="#99D0F2"
                                    fillWidth={`${topCity?.Value?.toFixed(2) ?? 0}%`}
                                    bgColor="#f4f4f4"
                                    containerWidth="100%"
                                />

                                
                            </div>

                            <p className="text__16 NeueMontreal-normal text-dark text-align-center p-0 m-0" 
                            style={{ width: "80px" }}>
                                {`${topCity?.Value?.toFixed(2) ?? 0}%`}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
                )
            }
        </div>
    )
}