import { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import Skeleton from 'react-loading-skeleton';
import * as functions from "../../../../../global/functions";

import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";


interface BannerImageProps {
    id:number;
    src: string;
    social?: string;
    username?: string;
    index: number;
    hoveredImageIndex?: number;
    handleImageHover?: (index: any) => void;
    handleImageUpload?: (event: any) => void;
    handleImageOut?: () => void;
    uploadRef?: React.ForwardedRef<HTMLDivElement>;
}

const platformsIcons = {
    instagram: toAbsoluteUrl("/media/cpg/insta.png"),
    tiktok: toAbsoluteUrl("/media/cpg/tiktok.png"),
    facebook: toAbsoluteUrl("/media/cpg/facebook.png"),
    youtube: toAbsoluteUrl("/media/cpg/youtube.png"),
}

export const BannerImage = ({ 
    handleImageHover, 
    handleImageOut, 
    handleImageUpload, 
    hoveredImageIndex, 
    index, 
    social, 
    id,
    src, 
    username,
    uploadRef
}: BannerImageProps) => {
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleLabelClick = () => {
        
        fileInputRef?.current?.click();
    };

    return (
        <div 
            style={{
                padding: social ? "16px 16px 0px 16px" : "16px 16px 16px 16px",
                backgroundColor: "#fff",
                borderRadius: "24px",
                position: "relative"
            }}
            onMouseOver={() => handleImageHover && handleImageHover(id)}
            // onMouseOut={handleImageOut}
        >   
             
            
            {hoveredImageIndex === id && 
                <div ref={uploadRef}><label 
                    
                    onMouseOver={() => handleImageHover && handleImageHover(id)}
                    htmlFor="formId-dynamic"
                    style={{ 
                        border: "none", 
                        background: "#F4F4FA", 
                        display: "flex", 
                        alignItems: "center", 
                        gap: "10px", 
                        position: "absolute", 
                        right: "40px",
                        padding: "10px",
                        borderRadius: "8px",
                        // pointerEvents:"none",
                        top: "40px",
                        zIndex: 2,
                        cursor: "pointer"
                    }}
                >
                    <input name="" type="file" id="formId-dynamic" 
                    accept=".png,.jpg,.jpeg" style={{visibility:'hidden', width:"1px", height:"1px"}} 
                    onChange={handleImageUpload} ref={fileInputRef} />
                    <p  style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        margin: 0,
                        color: "#000",
                        zIndex: 5,
                        cursor: "pointer",

                    }}>Upload</p>
                    <SVG 
                        
                        src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')} 
                        className='svg-icon-2' 
                        style={{zIndex: 5}}
                    />
                </label>
                </div>
            } 

            <img 
                src={toAbsoluteUrl(src)}
                alt="hero-card"
                className="image-fluid d-block" 
                style={{ 
                    width: '100%', 
                    opacity: 0.5,
                    height: "480px",
                    resize: "both", 
                    objectFit: "cover", 
                    borderRadius: "16px"
                }}
                onLoad={() => setImageLoaded(true)}
                // onError={() => setImageLoaded(true)}
                onError={(ev) => functions.addDefaultSrc(ev, true)}
            /> 

             {/* {!imageLoaded && <Skeleton height={"400px"} style={{ zIndex: 0 }} /> } */}

            {social && 
                <div className="d-flex flex-row align-items-center align-self-center"
                    style={{
                        maxHeight: "62px",
                        height: "62px"
                    }}
                >
                    <img  
                        onError={(ev) => functions.addDefaultSrc(ev)}
                        src={platformsIcons[social.toLowerCase() as keyof typeof platformsIcons]}
                        style={{ height: "24px", width: "24px", marginRight: "8px" }} 
                        alt="icon" 
                    />
                    <p 
                        style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "16.7px",
                            margin: 0
                        }}
                    >@{username}</p>
                </div>
            }
        </div>
    )
};