import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { Formik, Field } from 'formik';
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector} from "../../../../../../../setup/redux/hooks";
import { updateDisplayReports } from "../../../../../../../setup/features/report.slice";

import {defaultOptions} from './defaultOptions.js'
import { useState, useRef, useEffect } from "react";

interface MyDropdownProps {
  optionList?: string[];
  isExternal?: boolean;
  isNotAmyris?:boolean;
}

export const OptionsDropdown: React.FC<MyDropdownProps> = ({ optionList,isNotAmyris,  isExternal }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [options, setOptions] = useState<string[]>(defaultOptions)

    const dispatch = useAppDispatch()

    const sectionOptions = useAppSelector((state) => state.report.displayReports);

    const handleDropdownToggle = () => {
        setDropdownOpen((prevState) => !prevState);
        // setDropdownOpen(!dropdownOpen);
      };
    
  return (

    <Formik
      initialValues={{ selectedOptions: sectionOptions }}
      onSubmit={(values) => {
        // console.log('Selected options:', values.selectedOptions);
        dispatch(updateDisplayReports(values.selectedOptions) )
        handleDropdownToggle()
        
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
            <Dropdown.Toggle  id="dropdown-basic" className="options-button" >
                Edit Sections
                <img src={toAbsoluteUrl("/media/cpg/cog-icon.png")} 
                alt="edit sections" style={{marginLeft:"8px"}}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className="optionsMenu">
                {defaultOptions.map((option:string) => (
                    <div key={option} className="" 
                    style={{padding:"5px"}}>
                    <label className="d-flex justify-content-between align-items-center">
                        {option}
                        <Field
                        type="checkbox"
                        name="selectedOptions"
                        value={option}
                        className="custom-options-checkbox"
                        style={{accentColor:"teal", color:"white", width:"20px", height:"20px", 
                        borderRadius:"16px", cursor:"pointer"}}
                        />
                        
                    </label>
                    
                    </div>
                    
                    )
                )}

                <div className="d-flex align-items-center my-2 py-2" style={{gap:"20px"}}>
                  {/* {!isExternal &&  */}
                  <button onClick={() => handleDropdownToggle()} className="Button border-none section-button-delete font__600">Cancel</button>
                  <div className="section-button-submit d-flex align-items-center justify-content-center">
                    <button type="submit"  className="Button  border-none font__600 edit_section_submit" >Save</button>
                  </div>

                </div>
                    
                </div>
            </Dropdown.Menu>

            
          </Dropdown>
        </form>
      )}
    </Formik>
  );
};

// export default OptionsDropdown;
