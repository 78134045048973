import {topPerformingData} from '../../../../../../../setup/types/topPerformingTypes'


interface filterObj{
    first:string,
    second:string
}
interface Tiers {
    [key: string]: any
}

interface Category{
    [key: string]: Tiers | number | any;
    InfluencerTier : Tiers;
    InfluencerVerticals:Tiers;
    ContentNarrative:Tiers;
    total:number
} 

  
  

const socialObject={
    instagram : ["Reel", "IG Story", "Carousel", "Static"],
    tiktok : ["TikTok Video", "TikTok Photo Swipe"],
    youtube : ["YouTube Long-Form", "YouTube Short"]
}


const handleSocialFilter = (filterKey:string) =>{
    let result = ""
    for(const key in socialObject){
        if((socialObject as any)[key].includes(filterKey)){
            result = key;
            break;
        }
    }
    return result
}



export const topCategories = (data:topPerformingData|null, filterValues:filterObj) =>{

    let sortKey:string = filterValues.first
    let filterkey:string =  filterValues.second

    
    // let categoriesL:Category = {
    //     "InfluencerTier":{
    //         "Micro":{
    //             instagram:0,
    //             tiktok: 0,
                    
    //         },
    //         "Mid Tier":{
    //             instagram:0,
    //             tiktok:0,
                    
    //         }
    //     },
    //     "InfluencerVerticals":{
    //         "Micro":{
    //             instagram:0,
    //             tiktok: 0,
                    
    //         },
    //         "Mid Tier":{
    //             instagram:0,
    //             tiktok:0,
                    
    //         }
    //     },
    //     "ContentNarrative":{
    //         "Micro":{
    //             instagram:0,
    //             tiktok: 0,
                    
    //         },
    //         "Mid Tier":{
    //             instagram:0,
    //             tiktok:0,
                    
    //         }
    //     },
    //     total:0
            // InfluencerTierTotal:0
            // InfluencerVerticalsTotal:0
            // ContentNarrativeTotal:0
    // }

    //This is an example of the result we want for each of the categories in category
    let categories:Category = {
        "InfluencerTier":{},
        "InfluencerVerticals":{},
        "ContentNarrative":{},
        total:0
    }

    let categoriesArray = ["InfluencerTier","InfluencerVerticals","ContentNarrative"]

    let socialMediaFilter = handleSocialFilter(filterkey)
 
    // console.log("socialMediaFilter -> ",socialMediaFilter)
    
    if(data) {
        data.contents?.map((content) => {
            let value:number = content[sortKey] as number || 0

            if(socialMediaFilter !== "" && socialMediaFilter !== content?.SocialNetwork.toLowerCase()){
                return false
            }

            categoriesArray.map((itemCategory)=>{
                if (content[itemCategory] !== ""){
                    let category:string = String(content[itemCategory]) || ""
                    let socialMedia:string = content?.SocialNetwork || ""
                    
                    let prevValue = 0
                    let prevTotal = categories[itemCategory+"Total"] || 0
        
                    if(category in categories[itemCategory]){ 
                        if(socialMedia in categories[itemCategory][category]){
                            prevValue = categories[itemCategory][category][socialMedia]
                        }
                        categories[itemCategory][category][socialMedia] = value + prevValue
                    }
                    else{
                        categories[itemCategory][category]= {}
                        categories[itemCategory][category][socialMedia] = value
                    }
                    
                    categories[itemCategory+"Total"] = prevTotal + value
                    
                    
                }
            })
            
            
            categories.total = categories.total + value

            return true
        })
    }

    

    return categories;
}

