import { useState, useEffect } from "react";

import { useFormik, Form, FormikProvider, Field } from "formik";

import {FiArrowDownLeft, FiArrowUpRight} from 'react-icons/fi'


// import {abvNum} from '../../../../../../global/functions'

interface Props {
    handleChanges: (type:any, data:any, index:any) => any;
    bgWhite?:boolean;
    editState?:boolean|null;
    type?:string;
    data?:any;
    index?:number;
    cancelState: boolean|null;
    error?:string|null;
    resetForm?:boolean;
}

export const ActionableKeyInsights = ({handleChanges, resetForm, error, cancelState, data, type,index, bgWhite, editState}: Props) => {

    // const insightsValue = "Key Insights";

    const initialValues:any = {
        "value": data?.value || "6.65",
        "rate":data?.rate || "60",
        "metric": data?.metric || "ER",
        "date": data?.date || "last week",
        "arrow": data?.arrow || false,
    }

    const onSubmit = (values:any) =>{
        handleChanges(type, values, index)
        
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
      });

    if(!editState){
        formik.initialValues.value = data?.value || 6.65
        formik.initialValues.rate = data?.rate || 60
        formik.initialValues.metric = data?.metric ||"ER"
        formik.initialValues.date = data?.date || "last week"
        formik.initialValues.arrow = data?.arrow || false
    }

    useEffect(()=>{
        // console.log(resetForm)
        if(resetForm){
            formik.resetForm()
        }
    }, [resetForm])


    useEffect(()=>{
        if(editState === false && !cancelState){
            
            formik.submitForm()
            
        }
    }, [editState])

    const handleArrowChange = () => {
        formik.setFieldValue('arrow', !formik.values.arrow);
    }
  

    return(
        <>
        {!editState ? 
        <div className="bg-white p-6 border__round_24 ">
            
            <p className="text__16 text__black Helvetica-Neue-light mb-0">{data?.metric || "ER"}</p>
            <div className='d-flex align-items-center'>
            <p className="text__40 text__black NeueMontreal-bold mb-0" style={{maxWidth:"150px"}}>
                {data?.value || 6.65}
            </p>
            <div className=" d-flex flex__col " style={{marginLeft:"8px"}}>
                <p className={`text__16  mb__1 font__600 Helvetica-Neue-Medium ${data?.arrow ? " text_green" : " text_red"}`}> {data?.rate || 60}% 
                {data?.arrow || false ? <FiArrowUpRight/> : <FiArrowDownLeft/>}
                </p>
                <p className='text__14 font__300 Helvetica-Neue-light mb__1'>{data?.date || "last week"}</p>
            </div>
            </div>
        </div> : 
            (
                <FormikProvider value={formik}>
                    <Form>

                    <div className="bg-white p-6 Helvetica-Neue-normal border__round_24 ">
                        
                        {/* <input type="text"
                        className="text__20 p-2 px-3 border__round_16 border-none Helvetica-Neue bg_white_fade text_gray width__content" 
                        value={insightsValue} onChange={handleInsightsChange}/> */}
                        <Field className="text__16 text__black my-2 Helvetica-Neue-light width__content border-none p-2 bg_white_fade " name="metric"/>
                        <div className='d-flex align-items-center'>
                        <Field type="text" name="value"
                        className="text__40 text__black NeueMontreal-bold mb-0 width__content border-none bg_white_fade" style={{width:"150px"}} />
                        <div className=" d-flex flex__col " style={{marginLeft:"8px"}}>
                            <div className="d-flex align-items-center">
                                <Field type="text" className={`text__16 Helvetica-Neue-Medium  mb__2 font__600 width__content border-none bg_white_fade ${formik?.values?.arrow ? "text_green" : "text_red"}`} 
                                style={{width:"120px"}} name="rate"/>
                                {
                                    formik?.values?.arrow ? <FiArrowUpRight onClick={handleArrowChange} className='text_green font__600 text__16'/> :  <FiArrowDownLeft onClick={handleArrowChange} className='text_red font__600 text__16'/>
                                }
                            </div>
                            <Field className='text__14 font__300 mb__1 Helvetica-Neue-light width__content border-none bg_white_fade' 
                            style={{width:"120px"}} name="date"/>
                        </div>
                        </div>
                    </div>

                    </Form>
                </FormikProvider>
            )
        }
        </>
    )
}