import { ActionableInsightsType } from "../../../../../../../setup/types/report";

export const defaultActionableData:any = {
    "campaign_id":  "656700",
    "range":"7",
    "data":[
        {
            "name": "Key Insights",
            "details": [
                {
                    "value": "6.65",
                    "rate": "60",
                    "metric": "ER",
                    "date": "last week",
                    "arrow":false
                },
                {
                    "value": "6.65",
                    "rate": "60",
                    "metric": "ER",
                    "date": "last week",
                    "arrow":false
                },
                {
                    "value": "6.65",
                    "rate": "60",
                    "metric": "ER",
                    "date": "last week",
                    "arrow":false
                },
                {
                    "value": "6.65",
                    "rate": "60",
                    "metric": "ER",
                    "date": "last week",
                    "arrow":false
                }
            ]
        },
        {
            "name": "Hypothesis",
            "details": ""
        },
        {
            "name": "Optimization Plans",
            "details": [
                {
                    "topic": "Posts",
                    "text": "Blah blah blah"
                },
                {
                    "topic": "Posts",
                    "text": "Blah blah blah"
                },
                {
                    "topic": "Posts",
                    "text": "Blah blah blah"
                },
                {
                    "topic": "Posts",
                    "text": "Blah blah blah"
                }
            ]
        }
    ]
}

// export const defaultActionableData:ActionableInsightsType = {
//     "campaign_id":  "656700",
//     "range":"7",
//     "data":[
//         {
//             "name": "Key Insights",
//             "details": [
//                 {
//                     "value": "6.65",
//                     "rate": "60",
//                     "metric": "ER",
//                     "date": "last week",
//                     "arrow":false
//                 },
//                 {
//                     "value": "6.65",
//                     "rate": "60",
//                     "metric": "ER",
//                     "date": "last week",
//                     "arrow":false
//                 },
//                 {
//                     "value": "6.65",
//                     "rate": "60",
//                     "metric": "ER",
//                     "date": "last week",
//                     "arrow":false
//                 },
//                 {
//                     "value": "6.65",
//                     "rate": "60",
//                     "metric": "ER",
//                     "date": "last week",
//                     "arrow":false
//                 }
//             ]
//         },
//         {
//             "name": "Hypothesis",
//             "details": [
//                 {
//                     "topic": "Influencer",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                    "topic": "Influencer",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                     "topic": "Influencer",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                    "topic": "Influencer",
//                     "text": "Blah blah blah"
//                 }
//             ]
//         },
//         {
//             "name": "Optimization Plans",
//             "details": [
//                 {
//                     "topic": "Posts",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                     "topic": "Posts",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                     "topic": "Posts",
//                     "text": "Blah blah blah"
//                 },
//                 {
//                     "topic": "Posts",
//                     "text": "Blah blah blah"
//                 }
//             ]
//         }
//     ]
// }