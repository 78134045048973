import { toAbsoluteUrl } from "../../helpers";


interface EditButtonProps {
    handleClick?: (value?:any) => void;
    bgWhite?:boolean;
    text?:string
}

export const EditButton = ({handleClick, bgWhite, text}: EditButtonProps) => {

    return (
        <button 
            className={`btn ${bgWhite ? "bg-white" : "bg_white_fade"} text_gray px-4 width__content d-flex align-items-center button__edit`}
            onClick={handleClick}
        >
            {text || "Edit"}
            {text?.toLowerCase() !== "save"? <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt={"edit"} style={{ marginLeft: "6px", height: "16px"}} /> : 
            <img src={toAbsoluteUrl("/media/cpg/pen-head-gray.png")} alt={"save"} style={{ marginLeft: "6px", height: "16px"}} />}
        </button>
    )
}