export default function StatsUpload(props: any) {
  // console.log("csdcfwefefw", props);
  return (
    <div className="row gx-2 gy-4 mt-4">
      
      {
        props.files && props.files.map((item: any, index: number) => {
          if (item.url) {
            return (
                <img src={item.url} alt="" key={index} style={{
                    width: '45%',
                    maxWidth: '200px',
                    borderRadius: '12px',
                  }} />
              )
          }
        })
      }
    </div>
  )
}
