import { useState, useEffect } from "react";
import { EditButton } from "../../../../../../../_metronic/layout/components/EditButton";
import { Offcanvas } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import moment from "moment";
import * as functions from "../../../../../../global/functions";
import axios from 'axios';
import { toast } from 'react-toastify'



interface Props {
  handleClose: () => void;
  handleShow: () => void;
  show?:boolean;
  item?:any;
  isExternal?:boolean;
}

const API_URL = process.env.REACT_APP_API_URL;

export const SideDrawer = ({isExternal, show, handleClose, handleShow, item}: Props) => {

  
  const [value, setValue] = useState(item?.description || "double tap to type description");
  const [touched, setTouched] = useState(false)
  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    setTouched(true)
  };

  useEffect(()=>{

    const changeDescription = async() =>{
      try {
        await toast.promise(axios.patch(`${API_URL}/api/new-reports/attachments/edit?campaign_id=${item?.campaign_id||"000000"}`, {
          fileId:item?._id || "" ,
          description: value || ""}),
          {
          pending: 'Updating File description',
          success: `File description updated successfully!`,
          error: `Error updating ${item?.originalName || "Link description"}: try again later`
        });;
        
        
      } catch (error) {
        console.error('Error updating value:', error);
      }
    }
    if(!isEditing && touched){
      changeDescription()
      setTouched(false)
    }
  },[isEditing])


  const attachmentDeleteHandler = async (item:any) =>{
    let DELETE_API = ""
    if(item.url){
      DELETE_API = `${API_URL}/api/file/?file_id=${item?._id||""}`
    }
    else{
      DELETE_API = `${API_URL}/api/new-reports/delete-link?link_id=${item?._id||""}`
    }
    try {
      const response = await toast.promise(axios.delete(`${DELETE_API}`),{
        pending: 'Deleting Attachment',
        success: `File ${item?._id||"000"} deleted successfully!`,
        error: `Error deleting file ${item?._id||'000'}: try again later`
      });
      
      
      if(response){
        handleClose() 
      }
      
      
    } catch (error) {
      console.error(`Error deleting file ${item?._id||""}: ${error}`);
    }
  }

  

    const editHandler = (value:any) => {
        if(value === "edit"){
            return setIsEditing(true)
        }
        else{
            return setIsEditing(false)
        }
    }

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end" style={{width:"30%"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <>
            <div className="p-2 attachnent__border__b">
              <img 
              src={item?.url ? item?.url : 
              item?.link ? item?.link : 
              toAbsoluteUrl("/media/cpg/attachment-image.png")} 

              alt="default drawer" style={{
                width:"100%",
                height:"250px",
                objectFit:"cover"
              }} 
              onError={(e)=> functions.addDefaultSrc(e, "/media/cpg/attachment-image.png")}/>

              <div className="my-8">
                <h3 className="text__black text__20 font__700">{item?.url ? item?.originalName || "" : item?.link}</h3>
                <p className="text__gray text__20 mt-1 mb__0">[{item?.url ? item?.format || "" : "url link"}]</p>
              </div>
            </div>

            <div className="my-8">
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className="text__black text__20 font__700">Description</h3>
                  {!isExternal && (
                    <>
                    {!isEditing ? <EditButton bgWhite handleClick={() => editHandler("edit")} /> :
                    <EditButton bgWhite text="Save" handleClick={() => editHandler("save")} />
                    }
                    </>
                  )}
                </div>
                {
                isEditing ? 
                  (
                  <textarea value={value} 
                  className="text__gray text__18 mt-1" style={{width:"100%"}}
                  onChange={handleInputChange}  />
                    ) : (
                      <p style={{width:"100%"}} className="text__gray text__18 mt-1 ">{value}</p>
                    )}

                <div className="d-flex justify-content-between align-items-center my-6 mb__0">
                  <p className="text__gray text__18">Uploaded</p>
                  <p className="text__black text__20 font__700">{item?.createdAt ? moment.utc(item?.createdAt || "January 12, 2023").format('MMMM DD, YYYY') : "No Date"}</p>
                </div>

                {item?.url && item?.size && (
                  <div className="d-flex justify-content-between align-items-center my-4">
                    <p className="text__gray text__18">Size</p>
                    <p className="text__black text__20 font__700">{item?.url ? functions.formatFileSize(item?.size || "") || "" : ""}</p>
                  </div>
                )}

                <div className="d-flex align-items-center my-6 mt-20 py-6" style={{gap:"20px"}}>
                  {!isExternal && <button onClick={() => attachmentDeleteHandler(item)} className="Button border-none button__delete font__600">Delete</button>}
                  {
                    item?.url ? (
                      <a href={item.url} rel="noreferrer" className="button__download d-flex align-items-center justify-content-center" download target="_blank">
                        <button  className="Button border-none font__600 text__download" >Download File</button>
                      </a>
                    ) : (
                      <>
                      
                      <a href={`https://${item.link}`} target="_blank" rel="noreferrer" className="button__download d-flex align-items-center justify-content-center">
                        
                        <button  className="Button border-none font__600 text__download" >Go to link</button>
                      </a>
                      </>
                    )
                  }
                </div>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

