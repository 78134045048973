import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import BrandPostsNumber from './BrandPostsNumber';
import BrandTopPosts from './BrandTopPosts';
import BrandEngagementTrends from './BrandEngagementTrends';


export default function BrandGlanceWrap (props:any) {
    return (
        <div className="brand_glance each_section_wrap">
            <div className="section_title">Brand at a Glance</div>
            <div className="summary_head">
                <div className="each_summary_head">
                    <div className="summary_title">Number of Posts</div>
                    <div className="summary_value">119
                        {/* <span className=""></span> */}
                    </div>
                </div>
                <div className="each_summary_head">
                    <div className="summary_title">Video Views</div>
                    <div className="summary_value">60,245
                        {/* <span className=""></span> */}
                    </div>
                </div>
                <div className="each_summary_head">
                    <div className="summary_title">Total Engagement</div>
                    <div className="summary_value">12,542
                        {/* <span className=""></span> */}
                    </div>
                </div>
                <div className="each_summary_head">
                    <div className="summary_title">Avg. Engagement Rate</div>
                    <div className="summary_value">1.8
                        {/* <span className=""></span> */}
                    </div>
                </div>
                <div className="each_summary_head">
                    <div className="summary_title">Impressions</div>
                    <div className="summary_value">50,635
                        {/* <span className=""></span> */}
                    </div>
                </div>
            </div>

            <div className="engagement_summary">
                <BrandPostsNumber />
                <BrandTopPosts />
                <BrandEngagementTrends />
            </div>
        </div>
    )
}