import Modal from 'react-modal';
import { toAbsoluteUrl } from '../../helpers';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    borderRadius: '24px',
    padding: '24px',
    maxWidth: '450px',
    border: '1px solid #F1F1F1',
    filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.08))',
  },
};


interface ModaliteProps {
  modalIsOpen: boolean;
  toggleModal: (e: boolean) => void;
  children: any;
  reset: () => void;
  submit: () => void;
  title: string;
}


export default function ModalLite(props: ModaliteProps) {
  const { modalIsOpen,reset, toggleModal, children, submit, title } = props;
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => toggleModal(false)}
      style={customStyles}
      contentLabel="Edit Campign"
    >
      <div className="bg-contain bg-left-bottom bg-no-repeat">
        <h2 className="text-black fs-1 fw-bolder my-2 mb-6">{title}</h2>
        {children}
        <div className="d-flex align-items-center justify-content-end p-4">
          <button
            className='cancel-btn'
            type='button'
            onClick={() => toggleModal(false)}
          >
            Cancel
          </button>
          <button
            className='cancel-btn'
            type='button'
            onClick={() => reset()}
          >
            Reset
          </button>
          <button
            className='btn btn-primary rounded'
            type='button'
            onClick={() => submit()}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  )
}
