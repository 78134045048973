/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { classnames } from '../../../../helper'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from "moment";
import { clicksChartArray } from '../../../../app/pages/dashboard/functions/clicksChartArray';

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title?: string;
  spaced?: boolean;
}

const MixedWidgetClicks: React.FC<Props> = ({ title, className, chartColor, chartHeight, spaced }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [datas, set_datas] = useState([]);

  const { click_analytics, clicks, campaignStats, campaignDetails } = useSelector(
    (state: any) => ({
      click_analytics: state.campaign.click_analytics,
      campaignDetails: state.dashboard.campaignDetails,
      campaignStats: state.dashboard.campaignStats,
      clicks: state.campaign.clicks
    })
  );

  useEffect(() => {


  }, [click_analytics])


  function getL(date: any, mode: any) {
    if (mode === 'month') {
      return moment(date).format('MMM');
    } else if (mode === 'hour') {
      return moment(date).fromNow();
    }

    return moment(date).fromNow();
  }

  useEffect(() => {
    // [{clicks, date}, {clicks,date}]
    if (!campaignDetails || !chartRef.current || (!click_analytics && !campaignStats?.target_data?.target_clicks)) {
      return
    }


    const cumulativeSum = (sum => (value: number) => sum += value)(0);

    var start = campaignDetails.StartDate;

    //das variable holds the value integer coming from the clicks_analytics state
    var das: any = [];

    //labs variable holds the start dates of the campaign coming from the clicks_analytics api
    var labs: any = [];

    // pushes the dates and the cummulative sum(progression of data for each day)
    var new_data: any = [];

    // das.push(0);
    // labs.push(getL(start, 'ddd'));
    // new_data.push([start, cumulativeSum(0)])



    // console.log([0, 5, 10, 3, 2, 0].map(cumulativeSum));

    // if (click_analytics) {
    //   click_analytics.forEach((ee: any,i: number) => {
    //     das.push(ee.clicks)
    //     labs.push(getL(ee.date, ee.mode))
    //     new_data.push([ee.date, cumulativeSum(ee.clicks)])
    //     // console.log(new_data);


    //   })


    // }

    // console.log("here ",click_analytics, campaignStats?.target_data?.campaign_actual)

    //checking if there is a value set by the admn
    var cl_act = campaignStats?.target_data?.campaign_actual?.clicks;

    var clicksChartArrayValues = clicksChartArray(clicks?.links)

    for(const key in clicksChartArrayValues){
      labs.push(getL(key,''))
      das.push(clicksChartArrayValues[key])
      new_data.push([key, cumulativeSum(clicksChartArrayValues[key])])
    }
    
    // console.log(new_data)

    // //click_analytics gives analysis on the data
    // if (clicksChartArrayValues && !cl_act) {
    //   // console.log("here 1")
    //   clicksChartArrayValues.forEach((ee: any, i: number) => {
    //     das.push(ee.clicks)
    //     labs.push(getL(ee.date, ee.mode))
    //     new_data.push([ee.date, cumulativeSum(ee.clicks)])
    //   })
    // } else if (click_analytics && cl_act) {
    //   // console.log("here 2")
    //   // creating a progression and prevent the clicks data from going down if the value for the next day goes down
    //   click_analytics.forEach((ee: any, i: number) => {
    //     das.push(ee.clicks)
    //     labs.push(getL(ee.date, ee.mode))
    //     new_data.push([ee.date, cumulativeSum(ee.clicks)])
    //   })
    //   das.push(cl_act);
    //   labs.push(getL(new Date(), 'ddd'));
    //   new_data.push([new Date(), cumulativeSum(cl_act)])

    // } else if (!click_analytics && cl_act) {
    //   // if there is no click analytics data coming and there's an actual data set only the actual value 
    //   das.push(cl_act);
    //   labs.push(getL(campaignStats?.target_data?.updatedAt, 'ddd'));
    //   new_data.push([campaignStats?.target_data?.updatedAt, cumulativeSum(cl_act)])
    // }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, das, labs, new_data, Number(campaignStats?.target_data?.target_clicks || 0)))
    // var chart = new ApexCharts(chartRef.current, {
    //   chart: {
    //    id: 'mychart',
    //    type: 'area'
    //   },
    //   series: [{
    //     data: das 
    //   }]
    // });

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, click_analytics, campaignDetails, campaignStats])

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className={classnames(`flex-grow-1 pb-0`, spaced && "card-p p-4'")}>
          <div className='d-flex flex-stack flex-wrap'>
            <div className=''>
              <span className='text-black text-hover-primary fw-bolder fs-6'>{title}</span>
            </div>
          </div>
        </div>
        <div ref={chartRef}
          // style = {{
          //   height: 200
          // }}
          className='mixed-widget-7-chart card-rounded-bottom'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any, lebs: any, new_data: any, target: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)


  // console.log(new_data.slice(-1)[0][1]);
  return {
    series: [{
      data: new_data
    }],
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 197.5,
      zoom: {
        autoScaleYaxis: true
      }
    },
    yaxis: {
      min: undefined,
      // max: new_data.length > 0 ? new_data.slice(-1)[0][1] >= target ? undefined : target + 100 : undefined,
      max: undefined,
      tickAmount: 4,
    },
    annotations: {
      yaxis: [{
        y: target,
        borderColor: '#8C8C8C',
        label: {
          // show: true,
          text: 'TARGET',
          style: {
            color: "#8C8C8C"
          }
        }
      }],
      // xaxis: [{
      //   x: new Date('01 Dec 2022').getTime(),
      //   borderColor: '#999',
      //   // yAxisIndex: 0,
      //   label: {
      //     // show: true,
      //     text: 'Rally',
      //     style: {
      //       color: "#fff",
      //       background: '#775DD0'
      //     }
      //   }
      // }]
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      // style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      // min: new Date('01 Dec 2022').getTime(),
      min: undefined,
      // max: new_data.length > 0 ? new Date(new_data.slice(-1)[0][0]).getTime() + (2 * 86400000) : undefined,
      max:undefined,
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };
}

export { MixedWidgetClicks }
