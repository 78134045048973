import React from "react";
import ReactDOM from "react-dom";
import Iframe from 'react-iframe'

// function Iframe(props) {
//     return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
// }


const Insights = () => {
  return (
    <div 
    // className="flex flex-wrap justify-content-start"
    style={{
        // backgroundColor: 'red',
        // flex: 1,
        height:800, width: '100%'
    }}>
        <Iframe 
            url="https://app.powerbi.com/reportEmbed?reportId=9b3e2a27-ad3b-4390-aeab-c864c60cd45a&autoAuth=true&ctid=3e4ada7e-cde8-4286-b6a5-e10e3f4f1621"
            width="100%"
            height="100%"
        />

        {/* <Iframe iframe={"https://app.powerbi.com/reportEmbed?reportId=9b3e2a27-ad3b-4390-aeab-c864c60cd45a&autoAuth=true&ctid=3e4ada7e-cde8-4286-b6a5-e10e3f4f1621"} /> */}
    </div>
  )
}

export { Insights }
