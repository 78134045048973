import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import * as functions from "../../../global/functions";

function getIm(n: string) {
  if (n === 'facebook') {
    return 'facebook.png';
  } else if (n === 'tiktok') {
    return 'tiktok.png';
  } else if (n === 'youtube') {
    return 'youtube.png';
  } else if (n === 'twitter') {
    return 'twitter.png';
  } else if (n === 'instagram') {
    return 'insta.png';
  }
  return 'facebook.png';
}

function getBg(n: string) {
  if (n === 'facebook') {
    return 'rgba(0, 98, 224, 0.1)';
  } else if (n === 'tiktok') {
    return '#F4F4F4';
  } else if (n === 'youtube') {
    return 'rgba(255, 0, 0, 0.1)';
  } else if (n === 'instagram') {
    return 'rgba(200, 55, 171, 0.1)';
  }
  return 'rgba(0, 98, 224, 0.1)';
}

export default function StatsSocial(socialsd: any) {
  const [soso, set_s] = useState<any>([]);
  const [facebookUrl, setFacebookUrl] = useState('https://facebook.com')
  const [instaUrl, setInstagramUrl] = useState('https://instagram.com')
  const [youtubeUrl, setYoutubeUrl] = useState('https://youtube.com')
  const [tiktokUrl, setTiktokUrl] = useState('https://tiktok.com')

  useEffect(() => {
    if (socialsd) {
      var all_ds: any = [];
      var datas: any = [];
      socialsd?.socials?.SocialAccounts?.forEach((element: any) => {
        datas = [
          { label: 'Likes', data: functions.abvNum(element.TotalLikes) },
          { label: 'Followers', data: functions.abvNum(element?.Followers) },
          { label: 'Type', data: element?.Type || 'N/A' },
          { label: 'Username', data: `${element.NetworkUser}` },
          { label: 'Comments', data: element.TotalComments ? functions.abvNum(element?.TotalComments) : 'N/A' },
          { label: 'Posts', data: functions.abvNum(element?.NumberOfPosts) || 'N/A' },
          { label: 'Avg. Comments Per Post', data: Number(element.AvgCommentsPerPost).toFixed(2) },
          { label: 'Created By', data: element.CreatedBy },
          { label: 'Monthly Unique Visits', data: Number(element.MonthlyUniqueVisitors).toFixed(2) },
          { label: 'Total Views', data: element?.TotalViews || 'N/A' },
          { label: 'Avg. Views on post', data: Number(element.AvgLikesPerPost).toFixed() },
          { label: 'Engagement Percentage', data: element?.EngagementPercentage || 'N/A' },
        ]
        var socia: any = {};
        socia['label'] = element?.Network.toUpperCase() || '';
        socia['icon'] = getIm(element.Network);
        socia['bgcolor'] = getBg(element.Network);
        socia['data'] = datas;
        all_ds.push(socia);
        if (element.Network === 'instagram') {
          setInstagramUrl(`https://instagram.com/${element?.FullName}`)
        }
        if (element.Network === 'facebook') {
          setFacebookUrl(`https://facebook.com/${element?.FullName}`)
        }
        if (element.Network === 'youtube') {
          setYoutubeUrl(`https://youtube.com/${element?.NetworkUser}`)
        }
        if (element.Network === 'tiktok') {
          setTiktokUrl(`https://tiktok.com/@${element?.NetworkUser}`)
        }
      });

      set_s(all_ds);
    }
  }, [socialsd])

  const returnSocialUrl = (label: string) => {
    console.log('LAENEBELE')
    // if (label == 'instagram') return `https://instagram.com/${element?.FullName}`
    // if (label == 'youtube') return `https://youtube.com/@${element?.NetworkUser}`
    // if (label == 'facebook') return `https://facebook.com/${element?.FullName}`
    // if (label == 'tiktok') return `https://tiktok.com/${element?.NetworkUser}`
  }
  return (
    <div className="row g-5 mt-4">
      {soso && soso.map((social: any, index: number) => (
        <div className="col-xl-6" key={index}>
          <div className="col-xl-12" key={index} style={{ padding: '16px', backgroundColor: `${social.bgcolor}`, borderRadius: '16px' }}>
            <a href={social.label.toLowerCase() == 'youtube'
              ? youtubeUrl
              : social.label.toLowerCase() == 'instagram'
                ? instaUrl
                : social.label.toLowerCase() == 'facebook'
                  ? facebookUrl
                  : tiktokUrl
            } target="_blank" className="d-flex align-items-center">
              <img src={toAbsoluteUrl(`/media/cpg/${social.icon}`)} style={{ width: '20px', objectFit: 'cover' }} />
              <span style={{ color: '#000000', display: 'block', marginLeft: '8px' }}>{social.label}</span>
            </a>
            <div className="row flex-wrap my-8 align-items-center justify-content-between">
              {social.data.map((item: any, index: number) => (
                <div key={index} className="col-xl-3 mb-6">
                  <span className="d-block mb-1 fw-bolder text-black">{item.label}</span>
                  <span className="d-block fs-6">{item.data}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
