import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { getSummary, getKeyInsightsRes, updateRange } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { SummaryData } from "../../../../../../setup/types/report";
import { SummaryChart } from "./SummaryChart";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";
import { CommentBox } from "../../../../../../_metronic/layout/components/CommentBox";
import { CommentListPopUp } from "../components/CommentBox";
import {OptionsDropdown} from "../components/OptionsDropdown"
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../_redux/campaign/Actions";



type CampaignSummaryKeys = Omit<SummaryData, "topChannels" | "keyInsights" | "likes">;

interface CampaignSummary {
    impressions: CampaignSummaryKeys['impressions'];
    clicks: CampaignSummaryKeys['clicks'];
    engangementRate: CampaignSummaryKeys['engangementRate'];
    cpe: CampaignSummaryKeys['engangementRate'];
    budget: CampaignSummaryKeys['budget'];
}

type SummaryWidgetProps = { 
    campaignId: any, 
    isExternal?:boolean, 
    commentsData?:any, 
    commentsFilter?:string ,
    setCommentSectionToDisplay:any,
    commentSectionToDisplay:any,
    isNotAmyris?:boolean;
    isPdfPage?: boolean
}

export const SummaryWidget = ({ campaignId, isNotAmyris, isExternal, commentsData,commentsFilter,commentSectionToDisplay, setCommentSectionToDisplay, isPdfPage }:SummaryWidgetProps ) => {
    const dispatch = useAppDispatch();
    const dispatches = useDispatch<any>();
    const { data: campaignDetail } = useAppSelector((state) => state.report.campaignDetail);
    const { loading, error, data } = useAppSelector((state) => state.report.summary);
    const { loading: detailLoading, error: detailError, data: detailData } = useAppSelector((state) => state.report.campaignDetail);

    const { campaignStats, campaignActivity, campaignDetails } = useSelector(
        (state: any) => ({
            campaignStats: state.campaign.campaignStats,
            campaignDetails: state.campaign.campaignDetails,
            campaignActivity: state.campaign.activity
        })
    );



    const [date, setDate] = useState<string>('7');

    const [commentCount, setCommentCount] = useState<number>(3);

    useEffect(() => {
        if (campaignId) {
            dispatch(getSummary({
                campaign_id: campaignId,
                date_range: date.toString(),
                is_external: isExternal
            }));

            dispatches(actions.fetchCampaignStats(campaignId))
            dispatch(actions.fetchCampaignDetails(campaignId));

            dispatch(getKeyInsightsRes({
                campaign_id: campaignId,
                is_external: isExternal,
                date_range: date.toString()
            }))
        }
    }, [date, campaignId]);
    

    const campaignDates: { startDate: string, endDate: string } = {
        startDate: detailData?.startDate || "",
        endDate: detailData?.endDate || ""
    }

    let weeksArray: number[] = []

    function getWeeksArray(startDate: Date, endDate: Date): number[] {
        const weeksArray = [];
        const daysDiff = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        for (let i = 7; i <= daysDiff; i += 7) {
            weeksArray.push(i);
        }
        return weeksArray;
    }

    if (campaignDates) {
        // Example usage
        const startDate = new Date(campaignDates.startDate);
        const endDate = new Date(campaignDates.endDate);
        weeksArray = getWeeksArray(startDate, endDate); // Output: [7, 14, 21]
    }

    let weeksOptions: { label: string, value: string }[] = []

    weeksArray.forEach((number) => {
        weeksOptions.push({
            label: "week " + (number / 7),
            value: number.toString()
        })
    });

    const zeroData = { currentValue: 0, lastValue: 0, target: 0 }

    let campaignSummary: CampaignSummary = {
        impressions: {
            currentValue: campaignActivity?.data?.new_impressions || parseFloat(campaignStats?.target_data?.campaign_actual?.impressions) || 0,
            lastValue: 0,
            target: parseFloat(campaignStats?.target_data?.target_impression) || 0
        },
        clicks: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.clicks) || 0,
            lastValue: 0,
            target: parseFloat(campaignStats?.target_data?.target_clicks) || 0
        },
        engangementRate: {
            currentValue: campaignActivity?.data?.new_er || parseFloat(campaignStats?.target_data?.campaign_actual?.er) || 0,
            lastValue: 0,
            target: parseFloat(campaignStats?.target_data?.target_er) || 0
        },
        cpe: {
            currentValue: parseFloat(campaignStats?.target_data?.campaign_actual?.cpe) || 0,
            lastValue: 0,
            target: parseFloat(campaignStats?.target_data?.target_cpe) || 0
        },
        budget: {
            totalSpend: parseFloat(campaignStats?.target_data?.campaign_summary?.paid) || 0,
            totalBudget: (parseFloat(campaignStats?.target_data?.campaign_summary?.TotalBudget) ? 
            parseFloat(campaignStats?.target_data?.campaign_summary?.TotalBudget) : 
            parseFloat(campaignDetails?.TotalBudget))  || 0
        }
    }

    // clicks: campaignStats ? campaignStats.target_data.campaign_actual.clicks : zeroData,
    //         engangementRate: campaignStats ? campaignStats.target_data.campaign_actual.engangementRate : zeroData,
    //         cpe: campaignStats ? campaignStats.target_data.campaign_actual.cpe : zeroData,
    //         budget: campaignStats ? campaignStats.target_data.campaign_actual.budget : { totalBudget: 0, totalSpend: 0 },},
    // console.log("commentsData", commentsData)

    if (campaignActivity && campaignStats) {
        var est = campaignActivity?.data?.new_er || parseFloat(campaignStats?.target_data?.campaign_actual?.er)
        var es100s = (est * 100) >= 100 ? est : est * 100;
        let campaignSummaryVar = {
            ...campaignSummary,
            engangementRate: {
                currentValue: es100s || 0,
                lastValue: 0,
                target: parseFloat(campaignStats?.target_data?.target_er) || 0
            }
        }

        campaignSummary = campaignSummaryVar
    }

    return (
        <div id="performance_sum_wrap" className={`${isExternal ? "" : "container"} ${isPdfPage && "pt-0"} performance_sum_wrap`} style={{ backgroundColor: "#fff", borderRadius: "24px", padding: "40px 40px 0px 40px"}}>

            <div className="d-flex justify-content-between align-items-center section_wrap_content">
                {/* <p style={{ fontSize: "20px", fontWeight: 600, margin: 0, color: "#000", letterSpacing: "1", lineHeight: "24px" }}>
                     {campaignDetail?.brandName}
                </p> */}
                
                {!isNotAmyris && !isPdfPage && <OptionsDropdown isNotAmyris={isNotAmyris} />}
                
                <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                    <p className="NeueMontreal-bold"
                        style={{ fontSize: "30px", fontWeight: 700, margin: 0, color: "#000", letterSpacing: "0em" }}>
                        Campaign Performance Summary
                    </p>
                </div>
                {!isPdfPage &&
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: "24px" }} >
                        <CustomSelect
                            options={weeksOptions}
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value)
                                dispatch(updateRange(e.target.value))
                            }}
                            containerStyle={{ gap: 0, padding: "12px", borderRadius: "16px" }}
                            textStyle={{ width: "90px !important" }} />




                        <CommentBox commentSectionToDisplay={commentsFilter} setCommentSectionToDisplay={setCommentSectionToDisplay} comments={[...commentsData]} />
                        {(commentSectionToDisplay === commentsFilter) && <CommentListPopUp isExternal={isExternal} campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={commentsData} />}
                    </div>
                }
            </div>


            <div style={{ padding: "40px 0px" }}>
                {loading && (
                    <div className="d-flex flex-row flex__wrap justify-content-between align-items-stretch">
                        {new Array(5).fill(0).map((_, index) => (
                            <Skeleton key={index} height={200} width={200}
                                style={{
                                    border: "20px solid transparent",
                                    borderRadius: "50%",
                                    background: "none",
                                    borderBottomColor: "transparent",
                                    borderLeftColor: "transparent",
                                    transform: "rotate(-45deg)",
                                }} />
                        ))}
                    </div>
                )}

                {!loading && campaignSummary && campaignStats && (
                    <div className="d-flex flex-row justify-content-between flex__wrap">
                        {Object.keys(campaignSummary).map((dataPoint, index) => {
                            let key = dataPoint as keyof CampaignSummaryKeys;

                            return (
                                <div key={index}
                                    style={{
                                        display: "flex",
                                        marginTop: "10px",
                                        maxHeight: "220px"
                                    }}>
                                    <SummaryChart
                                        dataPoint={key}
                                        values={campaignSummary[key]!}
                                    />

                                    {/* {index < Object.keys(campaignSummary).length - 1 && 
                                            <hr 
                                                style={{ 
                                                    backgroundColor: "#F4F4FA",
                                                    width: "2px",
                                                    height: "100px"
                                                }}
                                            />
                                        } */}
                                </div>

                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}