export function clicksSummation(clickLinks:object[]) {
    let totalNumberOfClicks = 0
    clickLinks && clickLinks.map((option: any, index: number) => {
        
        let clickValues = Object.values(option.clicks ? option.clicks : {})

        let sum = clickValues.reduce((accumulator, currentValue) => {
            if (typeof currentValue === 'number' && typeof accumulator === 'number') {
                return accumulator + currentValue;
              } else {
                return accumulator;
              }
        }, 0);

        if(typeof sum === 'number'){
            return totalNumberOfClicks += sum
        }

        return 1
    })

    return totalNumberOfClicks
}

