import { toAbsoluteUrl } from "../../../helpers";

interface TileProps {
  logo: any;
}

function Brand({ logo }: TileProps) {
  console.log('logo', logo);
  return (
    <div className="my-4 mx-2" style={{ width: '45%', maxWidth: '160px', height: '120px' }}>
      <img src={logo.ThumbLogoUrl ? logo.ThumbLogoUrl : toAbsoluteUrl('/media/cpg/na.png')}
        className="rounded rounded-2" style={{ width: '95%', height: 'auto' }}
      />
    </div>
  )
}

export { Brand }
