import React from 'react'
import { useParams} from 'react-router-dom';
import {SocialIntelligence} from '../social-intelligence';



const BrandAtGlanceExternalPdf: React.FC = () => {
    const params = useParams<any>();
    const {id}= params;
    
    return (
        <>
            <SocialIntelligence isPdf={true} landscapeId={id} isExternal={true}/>

        </>
    )
}



export { BrandAtGlanceExternalPdf }
