import { CSSProperties, ReactNode } from "react";


interface ModalHeaderProps {
    heading: string;
    headingPostion: "center" | "left" | "right";
    headingStyle?: CSSProperties;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
}

export default function ModalHeader({
    heading,
    headingPostion="center",
    headingStyle,
    leftIcon,
    rightIcon,
}: ModalHeaderProps) {

    return (
        <div 
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1
            }}
        >
            {leftIcon && leftIcon}

            <h2 
                className="NeueMontreal-bold text__36 text-dark"
                style={{ 
                    flexGrow: 1,
                    textAlign: headingPostion,
                    ...headingStyle
                }}    
            >
                {heading}
            </h2>

            {rightIcon && rightIcon}
        </div>
    )
}