import { StatsFormField } from "../../types";


export const fbIgPaid: StatsFormField[] = [
    {
        required: false,
        type: 'text',
        key: 'weeklyControl',
        label: 'Weekly Control',
        value: ''
      },
      {
        required: false,
        key: 'monthlyControl',
        type: 'text',
        label: 'Monthly Control',
        value: ''
      },
      {
        required: false,
        type: 'select',
        key: 'platform',
        label: 'Platform',
        options: ['Select type', 'Meta' ],
        value: ''
      },
      {
        required: false,
        type: 'text',
        key: 'brand',
        label: 'Brand',
        value: ''
      },
      {
        required: false,
        type: 'text',
        key: 'objective',
        label: 'Objective',
        value: ''
      },
      {
        required: false,
        type: 'text',
        key: 'campaign',
        label: 'Campaign',
        value: ''
      },
      {
        required: false,
        type: 'select',
        key: 'market',
        label: 'Market',
        options: ['Select type', 'Market' ],
        value: ''
      },
      {
        required: true,
        type: 'select',
        key: 'month',
        label: 'Month',
        options: ["Select Month", "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"],
        value: ''
      },
      {
        required: true,
        type: 'number',
        key: 'week',
        label: 'Week',
        value: 1
      },
      {
        required: true,
        type: 'date',
        key: 'date',
        label: 'Date',
        value: ''
      },
      {
        required: false,
        key: 'campaignName',
        type: 'text',
        label: 'Campaign name',
        value: ''
      },
      {
        required: false,
        key: 'adSetName',
        type: 'text',
        label: 'AdSet name',
        value: ''
      },
      {
        required: true,
        key: 'adName',
        type: 'text',
        label: 'Ad name',
        value: ''
      },
      {
        required: true,
        type: 'number',
        key: 'totalCost',
        label: 'Total Cost',
        addon: '$',
        addons:['$','€','£'],
        value: 0
      },
      {
        required: true,
        type: 'number',
        key: 'impressions',
        label: 'Impressions',
        value: 0
      },
      {
        required: true,
        type: 'number',
        key: 'postEngagements',
        label: 'Post engagements',
        value: 0
      },
      {
        required: true,
        type: 'number',
        key: 'linkClicks',
        label: 'Link clicks',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'landingPageViews',
        label: 'Landing page views',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'websiteConversions',
        label: 'Website conversions',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'websiteConversionsValue',
        label: 'Website conversions value',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'postComments',
        label: 'Post comments',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'postReactions',
        label: 'Post reactions',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'postSaves',
        label: 'Post saves',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'postShares',
        label: 'Post shares',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'videoViews',
        label: 'Three-second video views',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'videoWatches',
        label: 'Video watches at 100%',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'cpm',
        label: 'CPM',
        calculations:"(Total Cost/Impressions)*1000",
        addon:'$',
        addons:['$','€','£'],
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'ctr',
        label: 'CTR',
        calculations: "Link_clicks/Impressions",
        addon:'%',
        // disable:true,
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'cpc',
        label: 'CPC',
        calculations:"TotalCost/LinkClicks",
        addon:'$',
        addons:['$','€','£'],
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'er',
        label: 'Engagement Rate',
        calculations: "Post Engagements/Impressions",
        addon:'%',
        // disable:true,
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'cpe',
        label: 'CPE',
        calculations:"Total Cost/Post Engagements",
        addon:'%',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'attributedPurchases',
        label: 'Attributed Purchases',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'attributedCPA',
        label: 'Attributed CPA',
        calculations:"Total Cost/Attributed Purchases",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'attributedPurchaseConversion',
        label: 'Attirbuted Purchase Conversion Value',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'attributedROAS',
        label: 'Attributed ROAS',
        calculations:"Attirbuted Purchase Conversion Value/Total Cost",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'attributedCVR',
        label: 'Attributed CVR',
        calculations:"Attirbuted Purchase Conversion Value/Link Clicks",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'linkClickPurchases',
        label: 'Link Click Purchases',
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'linkClickCPA',
        label: 'Link Click CPA',
        calculations:"CPC/Link Clicks Purchases",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'linkClickPCV',
        label: 'Link Click Purchase Conversion Value',
        calculations:"Pulled from API Data",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'linkClickROAS',
        label: 'Link Click ROAS',
        calculations:"Link Click Purchase Conversion Value/CPC",
        value: 0
      },
      {
        required: false,
        type: 'number',
        key: 'linkClickCVR',
        label: 'Link Click CVR',
        calculations:"Link Click Purchase/CPE",
        value: 0
      }

]