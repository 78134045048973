import { useRef, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { metricValueFormatter } from "../../../../../../helper";
import { getEarnedMediaValueRes } from "../../../../../../setup/features/report.slice";
import { CustomProgressBar } from "../components/default/CustomProgressBar";
import { motion } from "framer-motion";

import { Formik, Form, Field, FormikProps } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify'

import Skeleton from "react-loading-skeleton";

import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";

const containerVariants = {
    hidden: { 
      opacity: 0, 
      x:-100
    },
    visible: { 
      opacity: 1, 
      x:0,
      transition: { ease: 'easeInOut' }
    },
    exit: {
      transition: { ease: 'easeInOut' }
    }
  };

export const EarnedMediaValue = ({campaignId, isExternal}: { campaignId: string, isExternal?:boolean }) => {

    const dispatch = useAppDispatch();

    const { loading, error, data } = useAppSelector((state) => state.report.EarnedMediaValueRes);
    const range = useAppSelector((state) => state.report.range).toString();

    const [edit, setEdit] = useState<boolean>(true)

    const formikRef = useRef<FormikProps<any>>(null);

    const initialValues:any = {
        "earnedPosts": data?.earnedPosts,
        "Instagramvalue": data?.channels[0]?.earnedMedia || 0,
        "Tiktokvalue": data?.channels[1]?.earnedMedia || 0,
        "Facebookvalue": data?.channels[2]?.earnedMedia || 0,
        "Youtubevalue":data?.channels[3]?.earnedMedia || 0,
        
    }

    useEffect(() => {
        dispatch(getEarnedMediaValueRes({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range: range.toString()
        }));
        
    }, [campaignId, dispatch, range])

    const percentage = (a:number|undefined,b:number|undefined) => {
        if(typeof(a) === "undefined" || typeof(b) === "undefined"){
            return "0"
        }
        let result:number  = (a/b)*100
        return result.toFixed(0)
    }
    
    const handleSocial = (name:string)=>{
        let social = {iconPath:"",color:""}
        
        if (name?.toLowerCase() === "instagram") {social.iconPath = "/media/cpg/insta.png"; social.color = "#C837AB"};
        if (name?.toLowerCase() === "tiktok") { social.iconPath = "/media/cpg/tiktok.png"; social.color = "#000000"};
        if (name?.toLowerCase() === "youtube") {social.iconPath = "/media/cpg/youtube.png"; social.color = "red"};
        if (name?.toLowerCase() === "facebook") {social.iconPath = "/media/cpg/facebook.png"; social.color = "blue"};
        if (name === "") {social.iconPath = "/media/cpg/na.png"; social.color = "green"};
        return social
    }

    const API_URL = process.env.REACT_APP_API_URL;
    

    const handleSubmit = async(values:any) => {
        // handle form submission

        let valuesdata :any = {
            "earnedPosts": values?.earnedPosts,
            "channels": [

            ]
        }

        data?.channels?.map((item:any) =>{
            if(item.name === "Instagram"){
                return valuesdata?.channels?.push({
                    "name": "Instagram",
                    "earnedMedia": values.Instagramvalue
                })
            }
            if(item.name === "Tiktok"){
                return valuesdata?.channels?.push({
                    "name": "Tiktok",
                    "earnedMedia": values.Tiktokvalue
                })
            }
            if(item.name === "Facebook"){
                return valuesdata?.channels?.push({
                    "name": "Facebook",
                    "earnedMedia": values.Facebookvalue
                })
            }
            if(item.name === "Youtube"){
                return valuesdata?.channels?.push({
                    "name": "Youtube",
                    "earnedMedia": values.Youtubevalue
                })
            }

            return true
        })
        
        if(values !== initialValues){
            try {
                const response = await toast.promise(
                    axios.post(`${API_URL}api/new-reports/edit-earned-media-value?tab_name=earned_media_value&campaign_id=${campaignId||"000000"}&range=${range}`,
                     valuesdata),{
                  pending: 'Updating earned media value',
                  success: ` updated earned media value successfully!`,
                  error: `Error updating: try again later`
                });

                
                if(response.status===200){
                    dispatch(getEarnedMediaValueRes({
                        campaign_id: campaignId, 
                        is_external:isExternal,
                        date_range: range.toString()
                    }));
                }
            } 
            catch (error) {
                console.error('Error updating earned media value:', error);
            }
        }
    }



    return (
        <div className="mt-4 p-8 bg-white rounded">
            <div className="d-flex justify-content-between align-items-center">
            <h3 className="fs-3 text-dark NeueMontreal-bold">Earned Media Value (EMV)</h3>

            {!isExternal &&(
                    <>
                        {
                            edit ? <EditButton handleClick={() => setEdit(false)}/> :
                            (
                                <div className="d-flex align-items-center justify-content-around">
                                    <button className="button__delete__default Button border-none rounded p-3 mr__2" onClick={() => {  
                                        setEdit(true)
                                    }}>
                                        Cancel
                                    </button>
                                    <EditButton text="Save" handleClick={() => {
                                        formikRef?.current?.submitForm();
                                        setEdit(true)
                                    }}/>
                                </div>
                            )
                        }
                    </>
                ) }

            </div>
            
            {loading  ? <Skeleton height={90} count={4}/> : 
            (error || data === null || Object.keys(data).length === 0) ? (
                
                <ErrorBoundary error={error}/>
            ) : (
                <>
                <Formik 
                innerRef={formikRef} // Set the ref to the Formik instance
                initialValues={initialValues}
                onSubmit={handleSubmit} 
               >
                
                {(values) => (
                    <Form>
                        <div 
                        className="d-flex align-items-start mt-6" style={{ gap: 20 }}>
                        <div>
                            <p className="m-0 text__black fs-5 fw-light Helvetica-Neue-light">Earned Media Value</p>
                            {loading ? 
                            <Skeleton height={20} count={1} width={60}/> : 
                            (<p 
                                className="Helvetica-Neue-bold"
                                style={{ color: "#3771C8", fontWeight: 600, fontSize: 28, margin: 0 }}>
                                    ${metricValueFormatter(data?.total) || '0'}
                            </p>)}
                        </div>

                        <div>
                            <p className="m-0 text__black fs-5 fw-light Helvetica-Neue-light">Earned Posts</p>
                            {loading?
                            <Skeleton height={20} count={1} width={60}/> :
                            edit ? (<p 
                                className="Helvetica-Neue-bold"
                                style={{ color: "#000", fontWeight: 600, fontSize: 28, margin: 0}}>
                                    {data?.earnedPosts || '0'}
                                </p>) : 
                            <motion.div
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className="text-center d-flex flex__col align-items-center">
                                <Field type="number" name="earnedPosts" className="report__input text-26"/>
                                {/* <label htmlFor="earnedPosts" className="report__label mt-2">Earned Posts</label> */}
                            </motion.div>
                            }
                            
                        </div>
                        
                    </div>

                    {
                        data && data?.channels?.map((item:any, index:number) =>{
                            return(
                                <div 
                                
                                key={index} className="d-flex flex-column mt-4" style={{ gap: 10 }}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <img 
                                            
                                            src={toAbsoluteUrl(handleSocial(item?.name || "").iconPath)}
                                            alt="social media"
                                            style={{
                                                width: "30px",
                                                height: "30px"
                                            }}
                                        />

                                        {loading?
                                        <Skeleton height={20} count={1} width={100}/> :
                                        edit ? <p className="fs-5 Helvetica-Neue-bold fw-bold text-dark m-0">
                                        {percentage(item?.earnedMedia, data?.total) || '0'}% 
                                        (${metricValueFormatter(item?.earnedMedia?.toFixed(2)) || '0'}/${metricValueFormatter(data?.total) || '0'})
                                        </p> :
                                            <motion.div 
                                            variants={containerVariants}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                            className="text-center d-flex flex__col align-items-center">
                                                <Field type="number" name={item?.name+"value"} className="report__input Helvetica-Neue-Medium text-16"/>
                                                <label htmlFor={item?.name+"value"}  className="report__label mt-2">{item?.name+" value"}</label>
                                            </motion.div>
                                        }
                                    </div>

                                    {/* <CustomProgressBar now={parseInt(percentage(data?.channels[0]?.earnedMedia, data?.total)) || 0} progressColor="#C837AB" /> */}
                                    <CustomProgressBar fillColor={handleSocial(item?.name || "").color} fillWidth={`${parseInt(percentage(item?.earnedMedia, data?.total)) || 0}%`} bgColor="#F4F4FA" /> 
                                </div>
                            )
                        })
                    }
                    </Form>
                )}

                </Formik>

                </>
            )}

            
        </div>
    )
}