import React from 'react'

import {SocialIntelligence} from './social-intelligence';



const BrandAtGlance: React.FC = () => {
   

    return (
        <>
            <SocialIntelligence/>

        </>
    )
}

export { BrandAtGlance }
