import { CommentBox } from "../../../../../../_metronic/layout/components/CommentBox";
import { DataPointValue } from "../../../../../../_metronic/layout/components/DataPointValue";
import { EditButton } from "../../../../../../_metronic/layout/components/EditButton";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { getCampaignEfficiency } from "../../../../../../setup/features/efficiency.slice";
import { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FormikProps } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify'


import { ErrorBoundary } from "../../../../../sharedComponents/ErrorBoundary"
import { CommentListPopUp } from "../components/CommentBox";
import Skeleton from "react-loading-skeleton";


type CpDataPoints = [
    { name: "Influencers", key: string, value: string | number, unit: "no-unit" },
    { name: "Total Spend", key: string, value: string | number, unit: "currency" },
    { name: "Cost per Post", key: string, value: string | number, unit: "currency" },
    { name: "CPE", key: string, value: string | number, unit: "currency" },
    { name: "CPV", key: string, value: string | number, unit: "currency" },
    { name: "CPC", key: string, value: string | number, unit: "currency" },
    { name: "CPM", key: string, value: string | number, unit: "currency" },
    { name: "ROAS", key: string, value: string | number, unit: "currency" }
]

interface FormValues {
    influencers: string | number;
    totalSpend: string | number;
    cpp: string | number;
    cpe: string | number;
    cpv: string | number;
    cpc: string | number;
    cpm: string | number;
    roas?: string | number;
}

const dataPoints: CpDataPoints = [
    { name: "Influencers", key: "influencers", value: "5", unit: "no-unit" },
    { name: "Total Spend", key: "totalSpend", value: "25000", unit: "currency" },
    { name: "Cost per Post", key: "cpp", value: "5000", unit: "currency" },
    { name: "CPE", key: "cpe", value: "0.63", unit: "currency" },
    { name: "CPV", key: "cpv", value: "1.393229", unit: "currency" },
    { name: "CPC", key: "cpc", value: "5.032402", unit: "currency" },
    { name: "CPM", key: "cpm", value: "41", unit: "currency" },
    { name: "ROAS", key: "roas", value: "", unit: "currency" }
]

interface campaignEfficiencyData {
    cpc: number,
    cpe: number,
    cpm: number,
    cpp: number,
    cpv: number,
    influencers: number,
    totalSpend: number,
    [key: string]: number
}

interface CpBodyProps {
    loading: boolean | null;
    error: string | null;
    data: campaignEfficiencyData | null;
    edit: boolean;
}




const CpHeader = ({ edit, editClick, cancelClick, saveClick, isExternal, commentsData,commentsFilter,commentSectionToDisplay, setCommentSectionToDisplay, campaignId }: { edit: boolean, cancelClick:any,editClick: any, saveClick: any, isExternal?:boolean, commentsData?:any, commentsFilter?:string ,setCommentSectionToDisplay:any,commentSectionToDisplay:any, campaignId:any }) => {

    // const handleClick = () => {};
    
    return (
        <div className="d-flex section_wrap_content justify-content-between align-items-center">
            <div className="flex-grow-1 text-align-center">
                <h1 className=" font__700 text__28 NeueMontreal-bold text-center m-0">
                    Campaign Efficiency To Date
                </h1>
            </div>

            <div className="d-flex  justify-content-between align-items-center" style={{ gap: 20 }}>
                {!isExternal && (
                    <>
                        {
                            edit ? <EditButton handleClick={editClick} /> :
                                (
                                    <div className="d-flex align-items-center justify-content-around">
                                        <button className="button__delete__default Button border-none rounded p-3 mr__2" onClick={() => {  
                                            cancelClick()
                                        }}>
                                            Cancel
                                        </button>
                                        <EditButton text="Save" handleClick={saveClick} />
                                    </div>
                                    
                                )
                        }
                    </>
                )}
               <CommentBox commentSectionToDisplay={commentsFilter} setCommentSectionToDisplay={setCommentSectionToDisplay} comments={[...commentsData]} />
                {(commentSectionToDisplay===commentsFilter)  &&  <CommentListPopUp isExternal={isExternal} campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} commentFilter={commentsFilter} allComments={commentsData} />}
            </div>
        </div>
    )
}

const CpContent: React.FC<CpBodyProps> = ({ data, loading, error, edit }) => {

    return (
        <>

            {loading ? <Skeleton height={70} count={1} /> : (
                <div className="d-flex flex-row align-items-center Helvetica-Neue-light  justify-content-between mt-8">
                    {dataPoints.map((dp, index) => {
                        // let dataValues:campaignEfficiencyData = {...data}

                        for (const key in data) {

                            if (key.toLowerCase() === dp.key.toLowerCase()) {
                                if(dp.key === "influencers"){
                                    return (
                                        <DataPointValue
                                        key={index}
                                        value={data[key]}
                                        unit={dp.unit}
                                        dataPoint={dp.name}
                                        integerValue={true}
                                        dataPointStyle={{fontFamily:"Helvetica-Neue-light"}}
                                    />
                                    )
                                }
                                return (
                                    <DataPointValue
                                        key={index}
                                        value={data[key]}
                                        unit={dp.unit}
                                        dataPoint={dp.name}
                                        dataPointStyle={{fontFamily:"Helvetica-Neue-light"}}
                                    />
                                )
                            }
                        }

                    })}
                </div>
            )}
        </>
    )
}

const API_URL = process.env.REACT_APP_API_URL;

export const CampaignEfficiency = ({ campaignId, isExternal, allCommentsData, commentsFilter, commentSectionToDisplay, setCommentSectionToDisplay }: { campaignId: string, isExternal?: boolean, allCommentsData?: any, commentsFilter: string, setCommentSectionToDisplay: any, commentSectionToDisplay: any }) => {

    const dispatch = useAppDispatch();
    const { loading, error, data } = useAppSelector((state) => state.campaignEfficiency);

    const formikRef = useRef<FormikProps<FormValues>>(null);

    const [edit, setEdit] = useState<boolean>(true)

    const initialValues: FormValues = {
        influencers: data?.influencers || 0,
        totalSpend: data?.totalSpend || 0,
        cpe: data?.cpe || 0,
        cpc: data?.cpc || 0,
        cpm: data?.cpm || 0,
        cpv: data?.cpv || 0,
        cpp: data?.cpp || 0,
        roas: data?.roas || 0,
    };

    const range = useAppSelector((state) => state.report.range).toString();


    useEffect(() => {
        dispatch(getCampaignEfficiency({
            campaign_id: campaignId, 
            is_external:isExternal,
            date_range: range.toString()
        }));

    }, [campaignId, range]);

    const handleSubmit = async(values: FormValues) => {
        // handle form submission

        if(values !== initialValues){
            try {
                const response = await toast.promise(axios.post(`${API_URL}api/new-reports/edit-campaign?tab_name=campaign_efficiency&campaign_id=${campaignId||"000000"}&range=${range||"7"}`, values),{
                  pending: 'Updating campaign efficiency',
                  success: ` updated campaign successfully!`,
                  error: `Error updating: try again later`
                });

                
                
                if(response.status===200){
                    dispatch(getCampaignEfficiency({
                        campaign_id: campaignId, 
                        is_external:isExternal,
                        date_range: range.toString()
                    }));
                }
            } 
            catch (error) {
                console.error('Error updating value:', error);
            }
        }
        
    };
    

    
    let { commentsLoading, commentsData } = allCommentsData;
    let filteredComments = !commentsLoading && commentsData ? commentsData.filter((eachComment: any) => eachComment?.tab_name === commentsFilter) : []

    return (
        <div className="p-8 bg-white rounded" id="efficiency_wrap">
            {/* data visualizations */}

            <CpHeader campaignId={campaignId} setCommentSectionToDisplay={setCommentSectionToDisplay} 
            commentSectionToDisplay={commentSectionToDisplay} commentsData={filteredComments} 
            commentsFilter={commentsFilter} isExternal={isExternal} edit={edit} 
            editClick={() => setEdit(false)} cancelClick={() => setEdit(true)} saveClick={
                () => {
                    formikRef?.current?.submitForm();
                    setEdit(true)
                } 
            }/>
            {loading && <Skeleton height={70} count={2} />}
            {!loading &&
                (error || data === null || data?.length === 0) ? (
                <ErrorBoundary error={error} />
            ) : (
                <>
                    {edit ? <CpContent data={data || null} loading={loading} error={error} edit={edit}/> : (
                        <Formik 
                         innerRef={formikRef} // Set the ref to the Formik instance
                         initialValues={initialValues}
                         onSubmit={handleSubmit} 
                        >
                            {({ errors, touched }) => (
                                <Form className="d-flex flex-row align-items-center  justify-content-between mt-8">
                                    {
                                        dataPoints.map((item, index) => {
                                            return (
                                                <div key={index} className="text-center d-flex flex__col align-items-center">
                                                    <Field type="number" name={item.key} className="report__input Helvetica-Neue-light text-26" />
                                                    <label htmlFor={item.key} className="report__label mt-2">{item.name}</label>
                                                </div>
                                            )
                                        })
                                    }

                                </Form>
                            )}
                        </Formik>
                    )}
                </>
            )
            }
        </div>
    )
}
