import React, { useRef, useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'


import ApexCharts from 'apexcharts'




export default function BrandPostsNumber (props:any) {
    const chartRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {


        let options = {
            series: [70, 25, 13, 33, 22],
            chart: {
                width: 250,
                type: 'pie',
                zoom: {
                    autoScaleYaxis: true
                },
                // toolbar:{
                //     show:true
                // }
            },
            fill: {
                colors: ['#304B7E', '#597D9F', '#72DBEE', '#77BFEE', '#E63123']
            },
            dataLabels: {
                enabled: false,

            },
            grid: {
                padding: {
                    top: -5,
                    bottom: -15,
                    left: -30
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                offsetX: -50,
                markers: {
                    width: 8,
                    height: 8,
                },
                labels: {
                    colors: ["#666666"]
                }
            },
            labels: ['TikTok', 'Instagram', 'Youtube', 'Twitter', 'Facebook'],

        };

        let numbersChart = new ApexCharts(chartRef.current, options);

        if (numbersChart) {
            numbersChart.render()
        }
    }, [chartRef])

    return (
        <div className="post_number_report">
            <div className="section_head">Number of Posts</div>

            <div className="section_content">
                <div className="numberchart_wrap" ref={chartRef} >

                </div>
                <div className="chart_numbers">
                    <div className="each_chart_number">
                        <div className="number_label">Social Audience</div>
                        <div className="number_value">660K + 0.732%</div>
                    </div>
                    <div className="each_chart_number">
                        <div className="number_label">Most Engaging Channel</div>
                        <div className="number_value">660K + 0.732%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}