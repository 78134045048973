import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'
import ReactApexChart from "react-apexcharts";


import OtherTrends from './OtherTrends';
import TrendingWords from './TrendingWords';


export default function  TopTrendsPanel (pros:any) {
    const options = {
        xaxis: {
            categories: ["10 Dec", "20 Dec", "23 Jan", "10 Feb", "15 Feb", " 20 Feb", "25 Feb"]
        },
        dataLabels: {
            enabled: false,

        }
    };
    const series = [
        {
            name: "series-1",
            data: [0, 40, 55, 60, 70, 88, 90, 103]
        },

    ];
    return (
        <div className="top_trends_over">
            <div className="header_txt">
                Top Trends over Time
            </div>
            <div className="each_trend_wrap">
                <div className="each_word_stat">
                    <ReactApexChart width="100%" options={options} series={series} type="area" />
                </div>
                <div className="each_word_stat">
                    <TrendingWords />
                </div>
                <OtherTrends />

            </div>
            <div className="each_trend_wrap">

            </div>
        </div>
    )
}