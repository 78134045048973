import { CSSProperties } from "react";


interface CommentBoxProps {
    comments: string[];
    textStyle?: CSSProperties;
    setCommentSectionToDisplay?:any;
    commentSectionToDisplay?:any
}

export const CommentBox = ({ comments, textStyle, commentSectionToDisplay, setCommentSectionToDisplay }: CommentBoxProps) => {

    return (
        <div 
            className={`d-flex align-items-center p-1 px-2 comment_trigger`} 
            style={{ backgroundColor: "#000", borderRadius: 6}}
            // onClick={comments.length>=1?()=>setCommentSectionToDisplay(commentSectionToDisplay):()=>{}}
            onClick={()=>setCommentSectionToDisplay(commentSectionToDisplay)}
            > 
            <button
                className="bi bi-chat-fill"
                style={{ 
                    color: "#fff", 
                    fontSize: "1rem", 
                    border: "none",
                    background: "none",
                    ...textStyle
                }}
                >
            </button>

            <p style={{ color: "#FFDD55", fontSize: 18, fontWeight: 500, margin: 0}}>
                {comments.length}
            </p>
        </div>
    )
}