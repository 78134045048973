/* eslint-disable jsx-a11y/anchor-is-valid */
// loading, ghost, routing(id)...
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as actions from "../_redux/campaign/Actions";
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import CreatorNew from './Creator_New';
import * as functions from "../../../global/functions";
import { CampaignStats } from './CampaignStats';
import moment from "moment";
import { KTSVG } from "../../../../_metronic/helpers"
import { CampaignReport } from './report/CampaignReport';
import TruncatedParagraph from './stats/TruncatedParagraph';

const CampaignSummary = (props: any) => {
  let { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const { campaignDetails, links, campaignStats, analytics, activity, corrections, clicks, campaignPosts, campaignInflencers } = useSelector(
    (state: any) => ({
      campaignDetails: state.campaign.campaignDetails,
      campaignStats: state.campaign.campaignStats,
      links: state.campaign.links,
      campaignPosts: state.campaign.campaignPosts,
      analytics: state.campaign.analytics,
      campaignScore: state.campaign.campaignScore,
      clicks: state.campaign.clicks,
      corrections: state.campaign.corrections,
      campaignInflencers: state.campaign.campaignInflencers,
      campaignEngagementRates: state.campaign.campaignEngagementRates,
      activity: state.campaign.activity,
      campaignEngagementRatesError: state.campaign.campaignEngagementRatesError,
    })
  );

  useEffect(() => {
    if (corrections && corrections.length > 0) {
      setCorrections(corrections)
    }
  }, [corrections]);


  const campaignSummaryError = false;
  const campaignSummary = {
    id: 3212,
    desc: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump',
    brand: 'mattersport.png',
    status: 'Active',
    creatorVisibility: true,
    bonusContent: false,
    budget: '$18,000',
    currentSpend: '$16,000',
    type: 'Paid',
    hashtag: '#Matterport',
    phase: '1',
    goal: 'Awareness',
  }
  const campaignId = '809327';

  const [targetImp, setTargetImp] = useState<number>(campaignStats?.target_data?.target_impression || 0);
  const [targetClicks, setTargetClicks] = useState<number>(campaignStats?.target_data?.target_clicks || 0);
  const [targetER, setTargetER] = useState<number>(campaignStats?.target_data?.target_er || 0);
  const [targetCPE, setTargetCPE] = useState<number>(campaignStats?.target_data?.target_cpe || 0);
  const [tab, setTab] = useState<number>(0);
  const [new_link, set_link] = useState<string>('');
  const [paid, set_paid] = useState<number>(0);
  const [socials, set_socials] = useState<any>([]);
  const [campaignsTargets, set_target] = useState<any>([]);
  const [contentView, setContentView] = useState<number>(0);
  const [summaryEdit, setSummaryEdit] = useState<boolean>(false);
  const [targetsEdit, setTargetsEdit] = useState<boolean>(false);
  const [campaignDescription, setCampaignDescription] = useState<string>('')
  const [campaignBudget, setBudget] = useState<number>(0);
  const [campaignHashtag, setHashtag] = useState("");
  const [type, setType] = useState("");
  const [phase, setPhase] = useState("");
  const [goal, setGoal] = useState("");
  const [startDateEdit, setStartDateEdit] = useState(campaignDetails?.StartDate);
  const [endDateEdit, setEndDateEdit] = useState(campaignDetails?.EndDate);
  const [linkEdit, setLinkEdit] = useState<boolean>(false);
  const [correction_data, setCorrections] = useState<any>([]);
  const [data_to_view, set_dat_open] = useState<any>(null);






  const targetEstimated: any = {};
  const targetActual: any = {};

  // const updateTargetEstimated = (label: string, value: any) => targetEstimated[label] = value;
  // const updateTargetActual = (label: string, value: any) => targetActual[label] = value;

  function set_cur(value: string, key: string, type: string) {
    // var corss: any = correction_data;
    var corss = JSON.parse(JSON.stringify(correction_data));
    var index = corss.findIndex((x: any) => x.type === (type === 'engagement' ? 'engagements' : type));

    if (index === -1) {
      //add
      var d: any = {};
      d['campaign_id'] = id;
      d['type'] = type === 'engagement' ? 'engagements' : type;
      if (key === 'amount') {
        d['amount'] = value;
      } else if (key === 'method') {
        d['method'] = value;
      } else if (key === 'date') {
        d['effect_date'] = value;
      }
      d['updated'] = true;

      corss.push(d);

    } else {
      var d = corss[index];
      d['campaign_id'] = id;
      d['type'] = type === 'engagement' ? 'engagements' : type;
      if (key === 'amount') {
        d['amount'] = value;
      } else if (key === 'method') {
        d['method'] = value;
      } else if (key === 'date') {
        d['effect_date'] = value;
      }
      d['updated'] = true;
    }

    // console.log(corss)

    setCorrections(corss);
  }

  function getVal(type: string, key: string) {
    var corss: any = correction_data;
    var index = corss.findIndex((x: any) => x.type === (type === 'engagement' ? 'engagements' : type));

    if (index === -1) {
      return '';
    } else {
      var d = corss[index];
      if (key === 'amount') {
        return d.amount;
      } else if (key === 'method') {
        return d.method;
      } else if (key === 'date') {
        return moment(d?.effect_date).format('YYYY-MM-DD');
      }
    }


    setCorrections(corss);
  }

  function updateTargetEstimated(label: string, value: any) {
    const newState = campaignsTargets.map((obj: any) => {
      if (obj.title === label) {
        return { ...obj, estimated: value };
      }

      return obj;
    });
    // console.log(newState)
    set_target(newState);
  }

  function updateTargetActual(label: string, value: any) {
    const newState = campaignsTargets.map((obj: any) => {
      if (obj.title === label) {
        return { ...obj, actual: value };
      }

      return obj;
    });
    set_target(newState);
  }

  useEffect(() => {
    dispatch(actions.reset_campaign_info());
    dispatch(actions.fetchCampaignDetails(id));
    dispatch(actions.fetchCampaignStats(id));
    dispatch(actions.fetchCampaignPosts(id));
    dispatch(actions.fetchCampaignInfluencers(id));
    dispatch(actions.fetchStuff('links', '?campaign_id=' + id));
    dispatch(actions.fetchStuff('click_analytics', `?mode=day&campaign_id=${id}`));
    dispatch(actions.fetchStuff('clicks', id));
    // dispatch(actions.fetchStuff('analytics', `?campaign_id=${id}`));
    dispatch(actions.fetchStuff('activity', `?campaign_id=${id}`));

    dispatch(actions.fetchStuff('corrections', `?campaign_id=${id}`));


  }, []);

  const send_campaign = () => {
    let sum: any = {};
    sum['Description'] = campaignDescription;
    sum['TotalBudget'] = campaignBudget;
    sum['HashTag'] = campaignHashtag;
    sum['phase'] = phase;
    sum['goal'] = goal;
    sum['paid'] = paid;
    sum['Type'] = type;

    let body: any = {};
    body['campaign_summary'] = sum;

    dispatch(actions.editDetails(body, id)).then((res: any) => {
      setSummaryEdit(!summaryEdit)
      toast.success('Successfully editted campaign info');
      dispatch(actions.fetchCampaignStats(id));
    }).catch(() => {
      setSummaryEdit(!summaryEdit)
      toast.error('Error occured while updating campaign info');
    });
  }

  const send_target = () => {


    let sum: any = {};
    sum['impressions'] = campaignsTargets.find((x: any) => x.title === 'Impressions').estimated.replace(/\,/g, '');
    sum['engagements'] = campaignsTargets.find((x: any) => x.title === 'Engagement').estimated.replace(/\,/g, '');
    sum['clicks'] = campaignsTargets.find((x: any) => x.title === 'Clicks').estimated.replace(/\,/g, '');
    sum['cpe'] = campaignsTargets.find((x: any) => x.title === 'CPE').estimated;
    sum['er'] = campaignsTargets.find((x: any) => x.title === 'ER').estimated;



    let body: any = {};
    body['target_impression'] = campaignsTargets.find((x: any) => x.title === 'Impressions').actual.replace(/\,/g, '');
    body['target_engagement'] = campaignsTargets.find((x: any) => x.title === 'Engagement').actual.replace(/\,/g, '');
    body['target_clicks'] = campaignsTargets.find((x: any) => x.title === 'Clicks').actual.replace(/\,/g, '');
    body['target_er'] = campaignsTargets.find((x: any) => x.title === 'ER').actual;
    body['target_cpe'] = campaignsTargets.find((x: any) => x.title === 'CPE').actual;

    body['campaign_actual'] = sum;

    // console.log(body); return;

    dispatch(actions.editDetails(body, id)).then((res: any) => {
      setTargetsEdit(!targetsEdit)
      toast.success('Successfully editted campaign targets');
      dispatch(actions.fetchCampaignStats(id));
    }).catch(() => {
      setTargetsEdit(!targetsEdit)
      toast.error('Error occured while updating campaign info');
    });
  }

  function send_cors() {


    correction_data.forEach((d: any, i: number) => {
      if (d?.updated) {
        dispatch(actions.editDetails(d, id, 'corrections')).then((res: any) => {
          toast.success(`Successfully corrected ${d.type.toUpperCase()}`);
          dispatch(actions.fetchStuff('corrections', `?campaign_id=${id}`));
          dispatch(actions.fetchStuff('activity', `?campaign_id=${id}`));
          set_dat_open(null);
        }).catch(() => {
          // setTargetsEdit(!targetsEdit)
          set_dat_open(null);
          toast.error('Error occured while updating campaign info');
        });
      } else if (i === (correction_data.length - 1)) {
        set_dat_open(null);
        dispatch(actions.fetchStuff('corrections', `?campaign_id=${id}`));
        dispatch(actions.fetchStuff('activity', `?campaign_id=${id}`));
      }

      if (i === (correction_data.length - 1)) {
        // window.location.reload();
      }

    })


  }

  const send_link = () => {

    let body: any = {};
    body['link'] = new_link;
    body['campaign_id'] = id;
    // console.log(body); return;

    dispatch(actions.editDetails(body, id, 'links')).then((res: any) => {
      setLinkEdit(!linkEdit)
      toast.success('Successfully added link');
      dispatch(actions.fetchStuff('links', '?campaign_id=' + id));
    }).catch(() => {
      setLinkEdit(!linkEdit)
      toast.error('Error occured while updating campaign info');
    });
  }

  const dele = (ele: any) => {

    dispatch(actions.deleteData(`?link_id=${ele}`)).then((res: any) => {
      // setLinkEdit(!linkEdit)
      console.log('RESSSSSSSSSSS', res)
      if (res.error) {
        toast.error('Please try again.');
      } else {
        toast.success('Successfully deleted campaign info');
      }
      dispatch(actions.fetchStuff('links', '?campaign_id=' + id));
    }).catch(() => {
      // setLinkEdit(!linkEdit)
      toast.error('Error occured while updating campaign info');
    })
  }

  useEffect(() => {
    if (campaignStats && campaignDetails) {
      setTargetImp(campaignStats?.target_data?.target_impression || 0);
      setTargetClicks(campaignStats?.target_data?.target_clicks || 0);
      setTargetER(campaignStats?.target_data?.target_er || 0);
      setTargetCPE(campaignStats?.target_data?.target_cpe || 0);
      setCampaignDescription(campaignStats?.target_data?.campaign_summary?.Description || campaignDetails?.Description || '');
      // if (campaignStats?.target_data?.campaign_summary) {
        
        setBudget(campaignStats?.target_data?.campaign_summary?.TotalBudget || campaignDetails?.TotalBudget || '0');
        setPhase(campaignStats?.target_data?.campaign_summary?.phase || campaignDetails?.phase || '');
        setGoal(campaignStats?.target_data?.campaign_summary?.goal || campaignDetails?.goal || '');
        setHashtag(campaignStats?.target_data?.campaign_summary?.HashTag || campaignDetails?.HashTag || ',');
        setType(campaignStats?.target_data?.campaign_summary?.Type || campaignDetails?.Paid ? 'Paid' : 'Unpaid');
      // }
      let paid = 0;
      if (campaignStats?.target_data?.campaign_summary?.paid) {
        set_paid(campaignStats?.target_data.campaign_summary.paid);
        return;
      }
      if (campaignDetails.Payout) {
        campaignDetails.Payout.forEach((e: any, i: number) => {
          if (e.Paid) {
            paid += e.FixedFee;
          }
        });
        set_paid(paid);
      }


    }
  }, [campaignStats, campaignDetails])

  

  useEffect(() => {
    if (campaignDetails) {

      var platforms = JSON.parse(campaignDetails?.PlatformsJSON || "{}");
      var eeeee = Object.keys(platforms).filter((e: any) => (platforms[e] === 1));
      set_socials(eeeee)

    }
  }, [campaignDetails]);

  useEffect(() => {

    var datas: any = [];

    if (campaignStats && campaignDetails) { // && paid
      // console.log('campaignStats?.target_data?.target_cpe: ', campaignStats?.target_data?.target_cpe);

      var imp_anal = activity?.data?.new_impressions;
      var eng_anal = activity?.data?.new_engagements;
      var er_anal = activity?.data?.new_er;


      var eng_real_no = eng_anal || campaignStats?.target_data?.campaign_actual?.engagements || campaignStats.current_data.Engagement;

      var im: any = {};
      im['title'] = 'Impressions';
      im['estimated'] = functions.number_format(imp_anal || campaignStats?.target_data?.campaign_actual?.impressions || campaignStats.current_data.Impressions);
      im['actual'] = functions.number_format(campaignStats?.target_data?.target_impression || 0);


      var eg: any = {};
      eg['title'] = 'Engagement';
      eg['estimated'] = functions.number_format(eng_real_no);
      eg['actual'] = functions.number_format(campaignStats?.target_data?.target_engagement || 0);

      var cl: any = {};
      cl['title'] = 'Clicks';
      cl['estimated'] = functions.number_format(campaignStats?.target_data?.campaign_actual?.clicks || clicks);
      cl['actual'] = functions.number_format(campaignStats?.target_data?.target_clicks || 0);

      var cp: any = {};
      cp['title'] = 'CPE';
      cp['estimated'] = `${campaignStats?.target_data?.campaign_actual?.cpe || Number((paid ? paid : 0) / eng_real_no).toFixed(2)}`;
      cp['actual'] = `${campaignStats?.target_data?.target_cpe || 0}`;

      var er: any = {};
      er['title'] = 'ER';
      var est = er_anal || campaignStats?.target_data?.campaign_actual?.er || campaignStats.current_data.EngagementRate;
      var es100s = (est * 100) >= 100 ? est : est * 100;
      er['estimated'] = `${Number(es100s).toFixed(2)}`;
      // er['estimated'] = `${campaignStats?.target_data?.campaign_actual?.er * 100 || campaignStats.current_data.EngagementRate * 100}`;
      er['actual'] = `${campaignStats?.target_data?.target_er || 0}`;



      datas.push(im);
      datas.push(cl);
      datas.push(eg);
      datas.push(cp);
      datas.push(er);
    }

    // console.log("wefdefwefefwef",datas)
    set_target(datas);

  }, [campaignStats, paid, activity, campaignDetails, clicks])

  const set_default = () => {
    let body: any = {};

    dispatch(actions.editDetails(body, id, 'default')).then((res: any) => {
      toast.success('Default campaign set!');
      // dispatch(actions.fetchCampaignStats(id));
    }).catch(() => {
      toast.error('Error occured while setting default campaign');
    });
  }

  function getIm(s: string) {
    if (s === 'TikTok') {
      return `/media/cpg/tiktok.png`;
    } else if (s === 'Twitter') {
      return `/media/cpg/twitter.png`;
    } else if (s === 'YouTube') {
      return `/media/cpg/youtube.png`;
    } else if (s === 'Facebook') {
      return `/media/cpg/facebook.png`;
    }
    return `/media/cpg/insta.png`;
  }

  const classnames = (...classes: any) => classes.filter(Boolean).join(' ');

  function getCorrection(title: string) {
    var ttt = "";
    if (title === 'engagement') {
      ttt = 'engagements';
    } else {
      ttt = title;
    }

    var result = corrections && corrections.find((obj: any) => {
      return obj.type === ttt;
    })


    if (data_to_view) {
      if (data_to_view === ttt) {
        return (
          <div className="d-flex flex-row justify-center" style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
            <div style={{ marginRight: 10 }}>
              <span className="d-block text-gray-600">Value</span>
              <input
                // type = "number"
                // value={getVal(target.title.toLowerCase(), 'amount')} 
                disabled={ttt === 'CPE'}
                defaultValue={getVal(ttt.toLowerCase(), 'amount')}
                style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                onChange={(e: any) => set_cur(e.target.value, 'amount', ttt.toLowerCase())}
              />
            </div>

            <div style={{ marginRight: 10 }}>
              <span className="d-block text-gray-600">Method</span>
              <select
                disabled={ttt === 'CPE'}
                onChange={(e: any) => set_cur(e.target.value, 'method', ttt.toLowerCase())}
                value={getVal(ttt.toLowerCase(), 'method')}
                style={{ width: '140px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}>
                <option value="add">add</option>
                <option value="subtract">subtract</option>
                <option value="divide">divide</option>
                <option value="multiply">multiply</option>
                <option value="update">update</option>
              </select>
            </div>

            <div style={{ marginRight: 10 }}>
              <span className="d-block text-gray-600">Effect Date</span>
              <input type="date"
                disabled={ttt === 'CPE'}
                value={getVal(ttt.toLowerCase(), 'date')}
                // value="2023-01-29"
                style={{ width: '140px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                onChange={(e: any) => set_cur(e.target.value, 'date', ttt.toLowerCase())}
                placeholder="Start Date"
              />
            </div>


            <div>
              <button className="btn btn-success btn-sm rounded rounded-2 px-4 " style={{ marginRight: 10, marginTop: 20 }}
                onClick={() => send_cors()}
              >
                Update
                <img src={toAbsoluteUrl('/media/cpg/floppy-disk.png')} style={{ marginLeft: '6px', height: '14px' }} />
              </button>

              <button className="btn btn-secondary btn-sm rounded rounded-2 px-4" style={{ marginTop: 20 }}
                onClick={() => {
                  set_dat_open(null);
                }}
              >
                Cancel Edit
              </button>


            </div>
          </div>
        )
      }

    }

    if (!result) {
      return (
        <span className="d-block fw-bold text-black text-start">{title === 'cpe' ? 'DATA from Engagement' : "No corrections added"}
          {
            ttt !== 'cpe' &&
            <button className="btn  btn-sm px-4" style={{ marginLeft: -5, marginBottom: 3 }}
              onClick={() => set_dat_open(ttt)}
            >
              {/* Edit btn-secondary */}
              <img src={toAbsoluteUrl('/media/cpg/pen.png')} style={{ height: '14px' }} />
            </button>

          }

        </span>
      )
    }
    return (
      <span className="d-block fw-bold text-black text-start">{`${result.method.toUpperCase()} ${(result.type === 'impressions' || result.type === 'engagements') ? functions.abvNum(result.amount) : result.amount} (${moment(result.effect_date).format('MMM Do')})`}
        <button className="btn  btn-sm px-4" style={{ marginLeft: -5, marginBottom: 3 }}
          onClick={() => set_dat_open(ttt)}
        >
          <img src={toAbsoluteUrl('/media/cpg/pen.png')} style={{ height: '14px' }} />
        </button>
      </span>
    )
  }

  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }

  return (
    <>
      {/* <button className="btn btn-sm rounded rounded-2 px-4 mb-4" style={{ marginRight: 10 }}
        onClick={() => history.goBack()}
      >
        Go Back
      </button> */}
      <div className='d-flex' style={{
        justifyContent: "space-between"
      }}>
        <div className='d-flex align-items-center' style={{ flex: 1 }}>
          <button className={classnames((tab === 0 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 me-4")}
            onClick={() => setTab(0)}
          >
            <img src={toAbsoluteUrl('/media/cpg/summary-icon.png')} style={{ width: '16px', marginRight: '4px' }} />
            Summary
          </button>
          <button className={classnames((tab === 1 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4")}
            onClick={() => setTab(1)}
          >
            # Stats
          </button>
          <button className={classnames((tab === 2 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 mx-4")}
            onClick={() => setTab(2)}
          >
            # Reports
          </button>
        </div>


        {/* <div>Hello worl!</div> */}
        {campaignDetails &&
          <span className="d-block fw-bolder" style={{ fontSize: 18 }}>{campaignDetails?.CampaignName}</span>
        }
      </div>
      {tab === 0
        && <div className='row gy-5 g-xl-8 mt-4 mt-xl-0'>
          <div className='col-xl-3 col-lg-4'>
            <div className="card p-4 pt-0 mb-6">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="p-0 pt-6 fs-3 text-black mb-4">Summary</h4>
                {summaryEdit
                  ? <div>
                    <button className="btn btn-success btn-sm rounded rounded-2 px-4 " style={{ marginRight: 10 }}
                      onClick={() => send_campaign()}
                    >
                      Save
                      <img src={toAbsoluteUrl('/media/cpg/floppy-disk.png')} style={{ marginLeft: '6px', height: '14px' }} />
                    </button>

                    <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                      onClick={() => {
                        setSummaryEdit(!summaryEdit);
                        dispatch(actions.fetchCampaignStats(id));
                      }}
                    >
                      Cancel Edit
                    </button>


                  </div>
                  : <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                    onClick={() => setSummaryEdit(!summaryEdit)}
                  >
                    Edit
                    <img src={toAbsoluteUrl('/media/cpg/pen.png')} style={{ marginLeft: '3px', height: '12px' }} />
                  </button>
                }
              </div>
              {campaignSummaryError
                ?
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                  Nothing to see,
                  <br /><br />
                  Campaign summary is unavailable
                </div>
                :
                <>
                  {!campaignDetails ?
                    <div className="">
                      <Skeleton count={4} />
                      <Skeleton count={6} height={80} />
                    </div>
                    :
                    <>
                      {summaryEdit
                        ? <div className="py-5">
                          <span className="d-block text-gray-600">Campaign Description</span>
                          <textarea
                            value={campaignDescription} style={{ width: '100%', height: '84px', borderRadius: '8px', padding: '8px' }}
                            onChange={(e: any) => setCampaignDescription(e.target.value)} placeholder="Edit campaign description"
                          />
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Brand</span>
                            <span className="d-block">
                              <img src={campaignDetails.ThumbLogoUrl || toAbsoluteUrl(`/media/cpg/na.png`)} alt="" style={{ height: '28px' }} />
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Status</span>
                            <span className="d-block fw-bolder fs-4" style={{ color: '#06D6A0' }}>{campaignDetails.CampaignStatus}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Campaign Visibility</span>
                            <span className="d-block">{campaignDetails.CampaignVisibility ? "ON" : "OFF"}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Bonus Content</span>
                            <span className="d-block text-black">{campaignDetails?.BonusContent ? "ON" : "OFF"}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Budget</span>
                            <input
                              type="number"
                              value={campaignBudget} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => setBudget(e.target.value)} placeholder="Edit Budget"
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Current Spend</span>
                            <input
                              type="number"
                              value={paid} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => set_paid(e.target.value)} placeholder="Edit Current Spend"
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Type</span>
                            <select name="type" style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e) => setType(e.target.value)} value={type}
                            >
                              <option value="Paid">Paid</option>
                              <option value="Unpaid">Unpaid</option>
                              <option value="Both">Both</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Hashtag</span>
                            <input
                              value={campaignHashtag} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => setHashtag(e.target.value)} placeholder="Edit hashtag"
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Phase</span>
                            <input
                              value={phase} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => setPhase(e.target.value)} placeholder="Edit phase"
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Goal</span>
                            <input
                              value={goal} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => setGoal(e.target.value)} placeholder="Edit Goal"
                            />
                          </div>
                        </div>
                        : <div className="py-5">
                          {/* <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Name</span>
                            <span className="d-block fs-5 fw-bold" style={{ color: '#06D6A0', marginLeft: 10, textAlign: 'right' }}>{campaignDetails.CampaignName}</span>
                          </div> */}

                          {/* <p>{campaignDescription || 'Campaign Description n/A'}</p> */}

                          
                          <TruncatedParagraph text={campaignDescription || 'Campaign Description n/A'} maxLines={3} />
                          
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Brand</span>
                            <span className="d-block">
                              <img src={campaignDetails.ThumbLogoUrl || toAbsoluteUrl(`/media/cpg/na.png`)} alt="" style={{ height: '28px' }} />
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Status</span>
                            <span className="d-block fw-bolder fs-4" style={{ color: '#06D6A0' }}>{campaignDetails.CampaignStatus}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Campaign Visibility</span>
                            <span className="d-block">{campaignDetails.CampaignVisibility ? "ON" : "OFF"}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Bonus Content</span>
                            <span className="d-block text-black">{campaignDetails?.BonusContent ? "ON" : "OFF"}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Budget</span>
                            <span className="d-block text-black fw-bolder fs-6">{`$${functions.number_format(campaignBudget || 0)}`}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Current Spend</span>
                            <span className="d-block text-black fw-bolder fs-6">{`$${functions.number_format(paid)}`}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Type</span>
                            <span className="d-block text-black fw-bolder fs-6">{type}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Hashtag</span>
                            <div className="d-flex align-items-end justify-content-start flex-column">
                              {campaignHashtag && campaignHashtag.split(',').map((tag: any, index: any) => (
                                <span className="d-block text-black fw-bolder fs-6 text-right" key={index}>{tag}</span>
                              ))}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Phase</span>
                            <span className="d-block text-black fw-bolder fs-6">{phase || 'Phase n/A'}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-5">
                            <span className="d-block text-gray-600">Goal</span>
                            <span className="d-block text-black fw-bolder fs-6">{goal || 'Goal n/A'}</span>
                          </div>
                        </div>

                      }
                      <div className="d-flex justify-content-between align-items-center">
                        <Link to={`/app/campaign/${id}`} className="btn btn-primary btn-md rounded rounded-2 px-4">
                          View Dashboard
                        </Link>
                        <button onClick={() => set_default()} className="btn btn-primary btn-md rounded rounded-2 px-4">
                          Set as Default View
                        </button>
                      </div>
                    </>
                  }
                </>
              }
            </div>

            <div className=" card p-4 pt-0 mb-6">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="p-0 pt-6 fs-3 text-black mb-4">Platforms</h4>
                <button className="btn btn-secondary btn-sm rounded rounded-2 px-4">
                  Edit
                </button>
              </div>
              {campaignSummaryError
                ?
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                  Nothing to see,
                  <br /><br />
                  Social platforms is unavailable
                </div>
                :
                <>
                  {!campaignDetails ?
                    <div className="">
                      <Skeleton count={4} />
                      <Skeleton count={6} height={80} />
                    </div>
                    :
                    <>
                      <div className="py-5">

                        {
                          socials.map((s: string, i: any) => {
                            return (
                              <div key={i} className="d-flex align-items-center mb-4">
                                <img src={toAbsoluteUrl(getIm(s))} alt="" style={{ height: '28px', marginRight: '8px' }} />
                                <span className="d-block">
                                  <span className="d-block fs-6">{s}</span>
                                </span>
                              </div>
                            )
                          })
                        }

                      </div>
                    </>
                  }
                </>
              }
            </div>

            <div className="card p-4 pt-0 mb-6">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="p-0 pt-6 fs-3 text-black mb-4">Bitly Links</h4>

                {linkEdit
                  ?
                  <div>
                    <button className="btn btn-success btn-sm rounded rounded-2 px-4" style={{ marginRight: 10 }}
                      onClick={() => send_link()}
                    >
                      Save
                      <img src={toAbsoluteUrl('/media/cpg/floppy-disk.png')} style={{ marginLeft: '6px', height: '14px' }} />
                    </button>

                    <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                      onClick={() => {
                        setLinkEdit(!linkEdit);
                        dispatch(actions.fetchStuff('links', '?campaign_id=' + id));
                      }}
                    >
                      Cancel Edit
                    </button>


                  </div>
                  :
                  <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                    onClick={() => setLinkEdit(!linkEdit)}
                  >
                    Edit
                    <img src={toAbsoluteUrl('/media/cpg/pen.png')} style={{ marginLeft: '6px', height: '12px' }} />
                  </button>

                }
              </div>
              {
                <>
                  {!links ?
                    <div className="">
                      <Skeleton count={4} />
                      <Skeleton count={6} height={80} />
                    </div>
                    :
                    <>
                      {
                        linkEdit
                          ?

                          <div className="py-5">
                            <input
                              style={{ width: '100%', height: '40px', borderRadius: '4px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                              onChange={(e: any) => set_link(e.target.value)}
                              value={new_link}
                              placeholder={"Input bitly link"}
                            />


                            {
                              links && links.map((ele: any, key: number) => {
                                return (
                                  <div key={key} className="d-flex justify-content-between align-items-center mt-2 mb-2">
                                    <span className="d-block text-black fs-6" style={{ width: '60%' }}>{ele.link}</span>
                                    <button onClick={() => dele(ele._id)} className="btn btn-link d-block p-2">
                                      <span style={{ color: '#BB2124', fontSize: 13 }}>Delete</span>
                                    </button>
                                  </div>
                                )
                              })
                            }
                          </div>

                          :

                          links && links.length === 0
                            ?
                            <div className="d-flex flex-center text-center text-muted min-h-150px">
                              Nothing to see,
                              <br /><br />
                              Bitly link isn't added
                            </div>
                            :

                            <div className="py-5">

                              {
                                links && links.map((ele: any, key: number) => {
                                  return (
                                    <div key={key} className="d-flex justify-content-between align-items-center mb-4">
                                      <span className="d-block text-black" style={{ width: '60%', fontSize: '11px' }}>{limitStr(ele.link, 32)}</span>
                                      {/* <span className="d-block text-black fs-6" style={{ wordBreak: 'break-all' }}>{ele.link}</span> */}
                                      <button className="btn btn-md btn-outline-danger p-2" onClick={() => dele(ele._id)}>Delete</button>
                                      <button
                                        onClick={() => {
                                          navigator.clipboard.writeText(ele.link)
                                          toast.info('Copied text to clipboard!');
                                        }}
                                        className="btn btn-link d-block p-1">
                                        <img src={toAbsoluteUrl(`/media/cpg/copy.png`)} alt="" style={{ height: '15px', marginRight: '4px' }} />
                                        <span style={{ color: '#3771C8', fontSize: 13 }}>Copy</span>
                                      </button>
                                    </div>
                                  )
                                })
                              }




                            </div>
                      }

                    </>
                  }
                </>
              }
            </div>
          </div>
          <div className='col-xl-9 col-lg-8'>
            <div className=" card p-4 pt-0 mb-6">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="p-0 pt-6 fs-3 text-black mb-4">Targets</h4>
                {targetsEdit
                  ?
                  <div>
                    <button className="btn btn-success btn-sm rounded rounded-2 px-4" style={{ marginRight: 10 }}
                      onClick={() => send_target()}
                    >
                      Save
                      <img src={toAbsoluteUrl('/media/cpg/floppy-disk.png')} style={{ marginLeft: '6px', height: '14px' }} />
                    </button>

                    <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                      onClick={() => {
                        setTargetsEdit(!targetsEdit);
                        dispatch(actions.fetchCampaignStats(id));
                      }}
                    >
                      Cancel Edit
                    </button>


                  </div>
                  :
                  // null
                  <button className="btn btn-secondary btn-sm rounded rounded-2 px-4"
                    onClick={() => setTargetsEdit(!targetsEdit)}
                  >
                    Edit
                    <img src={toAbsoluteUrl('/media/cpg/pen.png')} style={{ marginLeft: '6px', height: '12px' }} />
                  </button>

                }
              </div>
              {campaignSummaryError
                ?
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                  Nothing to see,
                  <br /><br />
                  Social platforms is unavailable
                </div>
                :
                <>
                  {!campaignStats ?
                    <div className="">
                      <Skeleton count={4} />
                      <Skeleton count={6} height={80} />
                    </div>
                    :
                    <>
                      <div className="row d-flex align-items-center justify-content-between bg-secondary bg-gradient py-4 px-2 rounded mx-1">
                        <div className="col-xl-3">
                          <span className="d-block fw-bold">Name</span>
                        </div>
                        <div className="col-xl-9 d-flex justify-content-between align-items-center">
                          {!targetsEdit && <span className="d-block fw-bold">Cross corrections</span>}
                          {!data_to_view &&
                            <>
                              <span className="d-block fw-bold">Actual</span>
                              <span className="d-block fw-bold">Target</span></>}
                        </div>
                      </div>
                      {campaignsTargets.map((target: any, index: number) => (
                        <div className="row d-flex align-items-center justify-content-between py-3 px-2 mx-1" key={index}>
                          <div className="col-xl-3">
                            <span className="d-block text-gray-600">{target.title}</span>
                          </div>
                          {targetsEdit
                            ?


                            <div className="col-xl-9 d-flex justify-content-between align-items-center">

                              <input
                                // type = "number"
                                value={target.estimated.replace(/\,/g, '')} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                                onChange={(e: any) => updateTargetEstimated(target.title, e.target.value)} placeholder={target.title}
                              />
                              <input
                                // type = "number"
                                value={target.actual.replace(/\,/g, '')} style={{ width: '80px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                                onChange={(e: any) => updateTargetActual(target.title, e.target.value)} placeholder={target.title}
                              />
                            </div>
                            : <div className="col-xl-9 d-flex justify-content-between align-items-center">
                              <div>
                                {
                                  !targetsEdit && getCorrection(target.title.toLowerCase())
                                }
                              </div>
                              {
                                !data_to_view &&
                                <div className="col-xl-6 d-flex justify-content-between align-items-center">
                                  <span className="d-block fw-bolder text-black text-end">{target.title === 'CPE' ? `$${target.estimated}` : target.title === 'ER' ? `${target.estimated}%` : target.estimated}</span>
                                  <span className="d-block fw-bolder text-black text-end">{target.title === 'CPE' ? `$${target.actual}` : target.title === 'ER' ? `${target.actual}%` : target.actual}</span>
                                </div>
                              }
                            </div>
                          }

                        </div>
                      ))}
                      {targetsEdit
                        ? <>
                          {/* <div className="d-flex align-items-center justify-content-between mx-1 px-4 mx-1 border-top pt-4 my-2 mb-4">
                          <span className="d-block text-gray-600">Start Date</span>
                          <input type="date"
                            value={startDateEdit} style={{ width: '140px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                            onChange={(e: any) => setStartDateEdit(e.target.value)} placeholder="Start Date"
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between mx-1 px-4 mx-1">
                          <span className="d-block text-gray-600">End Date</span>
                          <input type="date"
                            value={endDateEdit} style={{ width: '140px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                            onChange={(e: any) => setEndDateEdit(e.target.value)} placeholder="End Date"
                          />
                        </div> */}
                        </>
                        : <>
                          <div className="d-flex align-items-center justify-content-between mx-1 px-4 mx-1 border-top pt-4 my-2 mb-4">
                            <span className="d-block text-gray-600">Start Date</span>
                            <span className="d-block fw-bolder text-black text-end">{moment(campaignDetails?.StartDate).format('MMMM Do YYYY') || '...'}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mx-1 px-4 mx-1">
                            <span className="d-block text-gray-600">End Date</span>
                            <span className="d-block fw-bolder text-black text-end">{moment(campaignDetails?.EndDate).format('MMMM Do YYYY') || '...'}</span>
                          </div>
                        </>
                      }
                    </>
                  }
                </>
              }
            </div>
            <div className=" card p-4 pt-0 mb-6">
              {/* <div className='d-flex align-items-center m-2 my-8'> */}
              <div className='d-flex  m-2 my-8' style={{
                justifyContent: "space-between"
              }}>
                <div className='d-flex align-items-center'>
                  <button className={classnames((contentView === 0 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 me-4")}
                    onClick={() => setContentView(0)}
                  >
                    <img src={toAbsoluteUrl('/media/cpg/creator-icon.png')} style={{ width: '16px', marginRight: '4px' }} />
                    Creators
                  </button>
                  <button className={classnames((contentView === 1 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4")}
                    onClick={() => setContentView(1)}
                  >
                    <img src={toAbsoluteUrl('/media/cpg/post-icon.png')} style={{ width: '16px', marginRight: '4px' }} />
                    Posts
                  </button>
                </div>

                {campaignInflencers &&
                  <span className="d-block fw-bold" style={{ fontSize: 16 }}>{`(${campaignInflencers.length}) Total ${campaignInflencers.length > 1 ? 'creators' : 'creator'}`}</span>
                }
              </div>
              {campaignSummaryError
                ?
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                  Nothing to see,
                  <br /><br />
                  Social platforms is unavailable
                </div>
                :
                <>
                  {!campaignSummary || !campaignSummary.id
                    && <div className="">
                      <Skeleton count={4} />
                      <Skeleton count={6} height={80} />
                    </div>
                  }
                  {campaignSummary && campaignSummary.id &&
                    <>
                      {contentView === 0 &&
                        <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">


                          {
                            campaignInflencers && campaignInflencers.map((ele: any, i: number) => {
                              // console.log(ele)
                              if (ele.PublisherName) {
                                return (
                                  <CreatorNew
                                    key={i}
                                    followers='24m'
                                    name={`${ele.RequiredPostsTotal} posts`}
                                    er='0.49%'
                                    impressions='1.8m'
                                    clicks='1.8m'
                                    fee={ele.FixedFee}
                                    Posts={ele.RequiredPostsTotal}
                                    PublisherId={ele.PublisherId}
                                    handle={ele.PublisherName}
                                    PayoutType={ele.PayoutType}
                                    Status={ele.Status}
                                    Paid={ele.Paid}
                                    aqs='Good'
                                    Currency={ele.Currency}
                                    avatar={ele.PublisherLogoURL}
                                    LastPost={ele.DateRequirementsCompleted}
                                  />
                                )
                              }
                            })
                          }

                        </div>
                      }
                      {contentView === 1 &&
                        <div className="d-flex flex-wrap justify-content-between">

                          {
                            campaignPosts && campaignPosts.map((ele: any, i: number) => {
                              return (
                                <a key={i} href={ele.PostLink} target="_blank">
                                  <img src={ele.Thumbnail}
                                    style={{ width: '94%', margin: '0.5rem auto', maxWidth: '240px', objectFit: 'cover' }} />
                                </a>
                              )
                            })
                          }
                        </div>
                      }
                    </>
                  }
                </>
              }
            </div>
          </div>
        </div>
      }

      {tab === 1 && <CampaignStats />}
      {tab === 2 && <CampaignReport campaignId={id} thumbnail={campaignDetails?.ThumbLogoUrl} />}
    </>
  )
}


export { CampaignSummary }
