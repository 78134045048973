import {  useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export default function StatsCampaign(props: any) {
  const limitStr = (str: string, length: number) => {
    const spl = str.split('');
    let newStr = str;
    if (spl.length > length) {
      spl.length = length;
      newStr = spl.join('') + '...'
    }
    return newStr;
  }


  const [data, set_data] = useState<any>([]);

  useEffect(()=> {
    // console.log(props)
    if (props.campaigns && props.campaigns.CampaignCollection.length > 0) {
      var t: any = [];
      props.campaigns.CampaignCollection.forEach((e: any) => {
        var ddd = JSON.parse(e.PublisherInCampaign?.Benchmarks);
        var b: any = {};
        b['id'] = e.PublisherInCampaign.PublisherId;
        b['summary'] = e.PublisherInCampaign.CampaignName;
        b['status'] = e.PublisherInCampaign.CampaignStatus;
        b['impressions'] = ddd?.Impressions || 'N/A';
        b['engagement'] = ddd?.Engagement || 'N/A';
        b['reach'] = ddd?.Reach || 'N/A';
        b['currency'] = e.PublisherInCampaign?.Currency || 'USD';
        b['ER Sum'] = e.TotalBudget;
        b['cost'] = e.PublisherInCampaign?.TotalBudget || 'N/A';
        b['clicks'] = e.PublisherInCampaign?.Clicks || 'N/A';
        b['start'] = e.PublisherInCampaign.StartDate;
        b['end'] = e.PublisherInCampaign.EndDate;
        // console.log(e);
        t.push(b);
        // set_data((prevState: any) => [...prevState, b])
      })
      set_data(t);
      // set_data(() => {
      //   return t;
      // })
      // setState(prevState => [...prevState, obj1])
    }
  }, [props])

  const columns = [
    {
      // cell: (th: any) => returnSummary(th),
      name: 'Name',
      grow: 2,
      selector: (row: any) => row.summary,
    },
    {
      cell: (row: any) => returnStatus(row.status),
      name: 'Status',
      selector: (row: any) => row.status,
    },
    {
      name: 'Impressions',
      selector: (row: any) => row.impressions,
    },
    {
      name: 'Engagement',
      selector: (row: any) => row.engagement,
    },
    {
      name: 'Reach',
      selector: (row: any) => row.reach,
    },
    // {
    //   name: 'ER Sum',
    //   selector: (row: any) => row.erSum,
    // },
    {
      name: 'Clicks',
      selector: (row: any) => row.clicks,
    },
    {
      name: 'Cost',
      selector: (row: any) => row.currency+' '+row.cost,
    },
    // {
    //   name: 'CPE',
    //   selector: (row: any) => row.cpe,
    // },
    {
      name: 'Start',
      selector: (row: any) => row.start,
    },
    {
      name: 'End',
      selector: (row: any) => row.end,
    },
  ];

  // const data = [
  //   {
  //     id: 1,
  //     summary: {
  //       influencer: 'Chapard',
  //       logo: 'na',
  //       socials: ['instagram', 'tiktok', 'youtube']
  //     },
  //     status: 'active',
  //     impressions: '0.32',
  //     engagement: '1.3M',
  //     totalFollowers: '900k',
  //     erSum: '0.9',
  //     clicks: '600k',
  //     cost: '$2,000.00',
  //     cpe: '$0.2',
  //     start: '27/09/2022',
  //     end: '27/09/2022',

  //   },
  //   {
  //     id: 1,
  //     summary: {
  //       influencer: 'Chapard',
  //       logo: 'na',
  //       socials: ['instagram', 'tiktok', 'youtube']
  //     },
  //     status: 'active',
  //     impressions: '0.32',
  //     engagement: '1.3M',
  //     totalFollowers: '900k',
  //     erSum: '0.9',
  //     clicks: '600k',
  //     cost: '$2,000.00',
  //     cpe: '$0.2',
  //     start: '27/09/2022',
  //     end: '27/09/2022',

  //   },
  //   {
  //     id: 1,
  //     summary: {
  //       influencer: 'Chapard',
  //       logo: 'na',
  //       socials: ['instagram', 'tiktok', 'youtube']
  //     },
  //     status: 'active',
  //     impressions: '0.32',
  //     engagement: '1.3M',
  //     totalFollowers: '900k',
  //     erSum: '0.9',
  //     clicks: '600k',
  //     cost: '$2,000.00',
  //     cpe: '$0.2',
  //     start: '27/09/2022',
  //     end: '27/09/2022',

  //   },
  // ]

  const returnSummary = (tdata: any) => {
    return (
      <div className="m-2" style={{ marginLeft: '0', padding: '0 10px 10px 0' }}>
        {/* <div className="d-flex align-items-center mb-2">
          <img src={toAbsoluteUrl(`/media/cpg/${tdata.summary.logo || 'na'}.png`)} style={{ width: '24px', objectFit: 'cover' }} />
          <span style={{ fontSize: '20px', fontWeight: 700, color: 'black', marginLeft: '8px', wordBreak: 'keep-all' }}>
            {limitStr(tdata.summary.influencer, 19)}
          </span>
        </div>
        <div className="d-flex">
          {tdata.summary.socials && tdata.summary.socials.length > 0 &&
            <>
              {tdata.summary.socials.map((social: any, index: number) => (
                <img src={toAbsoluteUrl(`/media/cpg/${social || 'na'}.png`)} key={`statss-${social}`}
                  style={{ width: '24px', objectFit: 'cover', marginRight: '12px' }}  />
              ))}
            </>
          }
        </div> */}
      </div>
    )
  }

  const returnStatus = (tdata: any) => {
    return (
      <div className="d-flex align-items-center">
        <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#06D6A0', marginRight: '8px' }} />
        <span style={{ fontWeight: 700, color: '#06D6A0', display: 'block', fontSize: '16px' }}>{tdata}</span>
      </div>
    )
  }

  const tableCol = ['Summary', 'Status', 'Impressions', 'Engagement', 'Total Followers', 'ER Sum', 'Clicks', 'Cost', 'CPE', 'Start', 'End'];

  return (
    <div className="row g-5 mt-4">
      <DataTable
        columns={columns}
        data={data}
      />
    </div>
  )
}
