import React, { useState, useEffect } from 'react'
import ReactApexChart from "react-apexcharts";
import distinctColors from 'distinct-colors'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { ApexOptions } from 'apexcharts';
import ExpandedChart from '../shared/ExpandChart'
const getMonthText = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]



const FormateDateValue = (dateValue: any) => {
    let formatted: any = ""
    if (dateValue >= 10) {
        formatted = dateValue
    } else {
        formatted = `0${dateValue}`
    }

    return formatted;
}
const generateSeriesData = (brandInfo: any, filterType: any, timeSeries: any) => {

    let brandData = timeSeries.filter((eachData: any) => eachData?.companyId === brandInfo?.id)
    let dataFromFilter: any;


    if (filterType === "Followers") {
        let followerCount = 0
        let data = brandData.map((chosenBrand: any) => followerCount + (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }

    if (filterType === "Engagement") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => countData + (chosenBrand?.twitterTweetEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.instagramPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }

    if (filterType === "EngagementPercent") {
        let countData = 0

        let data = brandData.map((chosenBrand: any) => {
            let totalEngagement = (chosenBrand?.instagramPostsEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0);

            let totalFollowers = (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0);
            let totalPosts =  (chosenBrand?.twitterTweets || 0) + (chosenBrand?.tikTokPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.instagramPosts || 0) + (chosenBrand?.facebookPosts || 0)

            let engRateValue :any= totalFollowers > 0 ? ((totalEngagement || 0) / (totalFollowers/7)) * 100 : 0
            engRateValue =  totalPosts>0 ? engRateValue/totalPosts :0;
            engRateValue = engRateValue>0? Number(engRateValue).toFixed(2):0
            return engRateValue;

        })

        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))

        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }



        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }
    if (filterType === "VideoViews") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => Math.floor(countData + (chosenBrand?.twitterVideoViews || 0) + (chosenBrand?.tikTokViews || 0) + (chosenBrand?.youTubePostViews || 0) + (chosenBrand?.reelViews || 0)))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate;
            })
        };
    }
    if (filterType === "PostsPerday") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => Math.floor((countData + (chosenBrand?.twitterAverageTweetsPerDay || 0) + (chosenBrand?.tikTokAvgPostsPerDay || 0) + (chosenBrand?.youTubeAveragePostsPerDay || 0) + (chosenBrand?.instagramAveragePostsPerDay || 0) + (chosenBrand?.facebookAveragePostsPerDay || 0))/7))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }
    if (filterType === "TotalPost") {
        let countData = 0
        let data = brandData.map((chosenBrand: any) => Math.floor(countData + (chosenBrand?.twitterTweets || 0) + (chosenBrand?.tikTokPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.instagramPosts || 0) + (chosenBrand?.facebookPosts || 0)))
        let otherData = brandData.map((chosenBrand: any) => ({ moreData: chosenBrand?.peakPost }))
        
        dataFromFilter = {
            name: brandInfo?.name,
            data,
            otherData
        }
        return {
            series: dataFromFilter,
            labels: brandData.map((chosenBrand: any) => {
                let dateValue, formatedDate: any, ik,
                    currentYear: any = new Date();
                currentYear = currentYear.getFullYear();
                if (chosenBrand?.dateType !== "weekly") {
                    dateValue = new Date(chosenBrand?.date);
                    formatedDate = `${FormateDateValue(dateValue.getDate())} ${getMonthText[dateValue.getMonth()]}`
                } else {

                    dateValue = new Date(chosenBrand?.startDate);
                    let endDateValue = new Date(chosenBrand?.endDate);
                    formatedDate = `${FormateDateValue(dateValue.getDate())}  ${getMonthText[dateValue.getMonth()]}`

                }
                return formatedDate
            })
        };
    }

    return null
}
const EngagementChart = (props: any) => {

    const [newSeriesData, setSeriesData] = useState<any[]>([]);
    const [newOptionsData, setOptionsData] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);

    let { title, filter, timeSeries, brandsList, setChartType, setChartSeriesData, setChartOptions, id } = props;



    useEffect(() => {
        let seriesData = brandsList.map((eachData: any) => generateSeriesData(eachData, filter, timeSeries?.social_post_series));




        let seriesOnly = seriesData.map((eachData: any) => eachData?.series)
        let labelOnly = seriesData.map((eachData: any) => eachData?.labels)[0]


        const series = [

            ...seriesOnly

        ];

        let palette: any[] = distinctColors({ count: series.length }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)
        const options: ApexOptions = {
            xaxis: {
                categories: [...labelOnly],
                labels: {
                    rotate: -55,
                    style: {
                        fontSize: "10px"
                    }
                },
                axisTicks: {
                    show: false
                },
            },
            yaxis: [{

                labels: {
                    formatter: function (val: any) {
                        let valToUse = filter === "EngagementPercent" ? val.toFixed(3) : val.toFixed(0);
                        if (val >= 1000) {
                            if (filter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            } else {
                                valToUse = `${Number(val / 1000).toFixed(2)}k`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000)}k`;
                            }
                        }
                        if (val >= 1000000) {
                            if (filter === "EngagementPercent") {
                                valToUse = `${Number(val).toFixed(2)}`;

                            } else {
                                valToUse = `${Number(val / 1000000).toFixed(2)}m`;
                                // valToUse = `${Math.round(Number(val.toFixed(0)) / 1000000)}m`;
                            }
                        }

                        if (val < 1000) {
                            if (filter === "EngagementPercent") {

                                valToUse = `${Number(val).toFixed(2)}%`;
                            }
                        }

                        return valToUse
                    }
                }
            }],

            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                    let allDataSets: any[] = w.globals.initialSeries;




                    let dataPointItems: any[] = [];
                    let trendLineVal, trendPercentVal: any = 0;

                    for (let index = 0; index <= allDataSets.length - 1; index++) {
                        let brandDataValue = allDataSets[index].data[dataPointIndex];

                        let firstBrandDataValue = allDataSets[index].data[0];

                        let trendLine, trendPercent;
                        if (dataPointIndex >= 1) {
                            trendPercent = firstBrandDataValue > 0 ? Number(((brandDataValue - firstBrandDataValue) / firstBrandDataValue) * 100).toFixed(2) : 0
                            if (trendPercent !== 0) {
                                trendLine = Number(trendPercent) > 0 ? "rise" : "fall"
                            }
                        }

                        trendLineVal = trendLine
                        trendPercentVal = trendPercent


                        dataPointItems.push({
                            brandName: allDataSets[index].name,
                            brandDataValue,
                            weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            color: chartColors[index],
                            trendLineVal,
                            trendPercentVal,
                            label: w.globals.categoryLabels[dataPointIndex]
                        })
                    }

                    dataPointItems.sort((a: any, b: any) => (b.brandDataValue - a.brandDataValue))

                    //  <span class={eachItem?.trendLineVal === "rise" ? "rise_trend" : "fall_trend"} >
                    return `<div class="chart_tooltip"> 
                        <div class="label_head">${dataPointItems[0]?.label} 
                            <img
                                class="click_ico"
                                alt='trend'
                                src="${ toAbsoluteUrl('/media/cpg/click.gif')}"
                            /> 
                        </div>
                        <div class="other_chart_info">
                                ${dataPointItems.map((eachItem, index) => {
                                    
                                    return `
                                        <div class="eachbranchInfo" key={index}> 
                                            
                                                <span class="chart_brand_color" style="background-color: ${eachItem.color}"> </span>
                                                <span class="chart_brand_name">${eachItem?.brandName} </span>: 
                                                <span class="chart_brand_value">  ${eachItem?.brandDataValue.toLocaleString()} ${filter==="EngagementPercent"?"%":""} </span> 
                                                ${(eachItem?.trendLineVal && eachItem?.trendPercentVal && Number(eachItem?.trendPercentVal) !== 0) ? `
                                                    
                                                    <span class="${eachItem?.trendLineVal === "rise" ? `rise_trend trend_move` : `fall_trend trend_move`}"  >
                                                    <img
                                                        alt='trend'
                                                        src="${eachItem?.trendLineVal === "rise" ? toAbsoluteUrl('/media/icons/socials/rise.svg') : toAbsoluteUrl('/media/icons/socials/fall.svg')}"
                                                    /> 
                                                    ${Math.abs(eachItem?.trendPercentVal)}%
                                                    </span>
                                                `: ""}
                                            
                                        </div>`

                                    })
                                    }
                        </div>
                            
                        </div>`

                }
            },

            chart: {
                toolbar: {
                    show: false,
                },
                events: {
                    
                    markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                        let allDataSets = chartContext?.series?.ctx?.w?.globals?.initialSeries;
                        let allInfo = chartContext?.series?.ctx?.w?.globals;
                        let chartElem = document.getElementById(id) as HTMLElement;
                        
                        if(document.querySelector(".top_post_msg")){
                            document.querySelector(".top_post_msg")?.remove()
                        }

                        let dataPointItems: any[] = [];
                        for (let index = 0; index <= allDataSets.length - 1; index++) {
                            dataPointItems.push({
                                brandName: allDataSets[index].name,
                                date:allInfo?.categoryLabels[dataPointIndex],
                                engagementTotal:allDataSets[index].otherData[dataPointIndex].moreData?.engagementTotal,
                                weekPeekPost: allDataSets[index].otherData[dataPointIndex].moreData?.postLink,
                            })
                        }
                        dataPointItems.sort((a: any, b: any) => (b.engagementTotal - a.engagementTotal))
                        
                        
                        
                        let elem :any= document.createElement('div');
                        // <div class="date_txt">${dataPointItems[0]?.date}</div>
                        elem.innerHTML = `<div class="chart_tooltip top_post_msg"> 
                                            <div class="date_txt">${dataPointItems[0]?.date}</div>
                                        <div class="post_txt">
                                            Most Engaging Post This Week
                                        <div> <a class="linkto" href="${dataPointItems[0]?.weekPeekPost}" target="_blank">Link to Post</a> </div>
                                    </div>
                                    
                                </div>`;
                                
                        chartElem.appendChild(elem)
                        


                    }

                }

            },
            legend: {
                markers: {
                    width: 8,
                    height: 8,
                }
            },
            stroke: {
                width: 2.5
            },
            colors: [...chartColors],
        };
        setSeriesData(series)

        setOptionsData(options)


    }, []);
    const handleShowModal = () => {

        setShowModal((prev) => !prev);
    }
    
    const handleSetModalData = () => {
        handleShowModal()
        // setChartType(title)
        // setChartSeriesData(newSeriesData)
        // setChartOptions(newOptionsData)
    }


    return (
        <div className="each_engagement_section">
            {showModal && <ExpandedChart chartOptions={newOptionsData} chartSeriesData={newSeriesData} chartType={title} showModal={showModal} handleShowModal={handleShowModal} />}
            <div className="chart_filter">
                <div className="bench_mark_title">{title} </div>
                <div className="expand_cta" onClick={() => handleSetModalData()}>
                    <img src={toAbsoluteUrl('/media/cpg/full-screen.png')} alt="" />
                </div>
            </div>
            {newSeriesData.length >= 1 && <ReactApexChart id={id} options={newOptionsData} series={newSeriesData} type="line" />}

        </div>
    )
}



const SummaryBenchmarkLineCharts = (props: any) => {
    let { timeSeries, brandsList } = props;
    return (
        <div className="benchmarks-wrap">
            <div className="each_benchmark_item" id="slide7">
                <EngagementChart id="chart3" brandsList={brandsList} title="Followers" filter="Followers" timeSeries={timeSeries} />
            </div>
            <div className="each_benchmark_item" id="slide8">
                <EngagementChart id="chart4" brandsList={brandsList} title="Engagement" filter="Engagement" timeSeries={timeSeries} />
            </div>
            <div className="each_benchmark_item" id="slide9">
                <EngagementChart id="chart5" brandsList={brandsList} title="Engagement Rate (%)" filter="EngagementPercent" timeSeries={timeSeries} />
            </div>
            <div className="each_benchmark_item" id="slide10">
                <EngagementChart id="chart6" brandsList={brandsList} title="Posts Per Day" filter="PostsPerday" timeSeries={timeSeries} />
            </div>
            <div className="each_benchmark_item" id="slide11">
                <EngagementChart id="chart7" brandsList={brandsList} title="Total Posts" filter="TotalPost" timeSeries={timeSeries} />
            </div>
            <div className="each_benchmark_item" id="slide12">
                <EngagementChart id="chart8" brandsList={brandsList} title="Video Views" filter="VideoViews" timeSeries={timeSeries} />
            </div>
        </div>
    )
}

// const EngagementScatterChart = ({ title }: any) => {
//     let brandsList = [
//         {
//             brandLogo: toAbsoluteUrl('/media/cpg/brand23.png')
//         },
//         {

//             brandLogo: toAbsoluteUrl('/media/cpg/brand24.png')
//         },
//         {

//             brandLogo: toAbsoluteUrl('/media/cpg/brand25.png')
//         },
//         {

//             brandLogo: toAbsoluteUrl('/media/cpg/brand11.png')
//         }
//     ];

//     const series = [
//         {
//             name: "Olapex",
//             data: [7, 65]
//         },
//         {
//             name: "JVN",
//             data: [2, 70]
//         },
//         {
//             name: 'Bumble and Bumble',
//             data: [3, 80]
//         },
//         {
//             name: 'Amika',
//             data: [5, 7]
//         }

//     ];
//     let palette: any[] = distinctColors({ count: series.length }) || [];
//     let chartColors: any[] = palette.map((each: any) => `${each}`)
//     const options = {
//         // xaxis: {
//         //     categories: ["1 Mar", "7 Mar", "14 Mar", "21 Mar", "28 Mar"]
//         // },
//         dataLabels: {
//             enabled: false,
//         },
//         chart: {
//             // height: 350,
//             // type: 'scatter',
//             toolbar: {
//                 show: false,
//             },
//             width: 120
//         },
//         // legend: {
//         //     show: false,
//         // },
//         // stroke: {
//         //     width: 2.5
//         // },
//         // colors: [...chartColors],
//         // fill: {
//         //     colors: [...chartColors]
//         // },
//     };

//     return (
//         <div className="each_engagement_section">
//             <div className="bench_mark_title">{title} </div>
//             <ReactApexChart  options={options} series={series} type="scatter" />
//             {/* <div className="brand_legends">
//                 {
//                     brandsList.map((eachBrand: any, index: any) => {
//                         return (
//                             <div className="each_legend" key={index}>
//                                 <span className="legend_amber" style={{ backgroundColor: `${chartColors[index]}` }} ></span>
//                                 <img src={eachBrand.brandLogo} alt="" />
//                             </div>
//                         )
//                     })
//                 }
//             </div> */}
//         </div>
//     )
// }

// const SummaryBenchmarkScatterCharts = () => {
//     return (
//         <div className="scatter_benchmarks">
//             <div className="each_line_chart">
//                 <EngagementScatterChart />
//             </div>
//             {/* <div className="each_line_chart">
//                 <EngagementScatterChart />
//             </div> */}
//         </div>
//     )
// }


export default function SummaryAllBenchmark(props: any) {
    let { timeSeries, brandsList } = props;
    return (
        <div className="all_benchmark_summary">
            <SummaryBenchmarkLineCharts brandsList={brandsList} timeSeries={timeSeries} />

        </div>
    )
}