import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import { Link } from 'react-router-dom'

import { toAbsoluteUrl } from '../../../../_metronic/helpers'



export default function BrandTopPosts(props:any) {

    return (
        <div className="top_posts_wrap">
            <div className="section_head">Top Social Posts</div>
            <div className="section_content">
                <div className="social_icons_wrap">
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/user.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/pin.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                        />
                    </div>
                </div>
                <div className="all_top_posts">
                    <div className="each_post_wrap">
                        <div className="post_avatar">
                            <img
                                alt='Icon'
                                src={toAbsoluteUrl('/media/avatars/150-3.jpg')}
                            />
                        </div>
                        <div className="post_content">
                            <div className="post_txt">
                                Day curl ✌️refresh, featuring jvn 🥰✨ Leave-In Conditioning Mist refreshes dry hair ✨ Air Dry Cream holds and defines ✨ Nourishing Shine Drops smooths...
                            </div>
                            <div className="post_reactions">
                                <div className="top_section">
                                    <div className="reaction">
                                        <img
                                            alt='Icon'
                                            src={toAbsoluteUrl('/media/icons/duotune/reaction/like.svg')}
                                        />
                                        <span className="reaction_txt">200 Likes</span>
                                    </div>
                                    <div className="reaction">
                                        <img
                                            alt='Icon'
                                            src={toAbsoluteUrl('/media/icons/duotune/reaction/comment.svg')}
                                        />
                                        <span className="reaction_txt">200 Comments</span>
                                    </div>
                                </div>
                                <div className="reaction">
                                    <img
                                        alt='Icon'
                                        src={toAbsoluteUrl('/media/icons/duotune/reaction/fire.svg')}
                                    />
                                    <span className="reaction_txt">200% Engagement Rate</span>
                                </div>

                            </div>
                            <Link className="link_to_post" to="/posts/kjssdsd">
                                Link to Post
                            </Link>
                        </div>
                    </div>
                    <div className="each_post_wrap">
                        <div className="post_avatar">
                            <img
                                alt='Icon'
                                src={toAbsoluteUrl('/media/avatars/150-3.jpg')}
                            />
                        </div>
                        <div className="post_content">
                            <div className="post_txt">
                                Day curl ✌️refresh, featuring jvn 🥰✨ Leave-In Conditioning Mist refreshes dry hair ✨ Air Dry Cream holds and defines ✨ Nourishing Shine Drops smooths...
                            </div>
                            <div className="post_reactions">
                                <div className="top_section">
                                    <div className="reaction">
                                        <img
                                            alt='Icon'
                                            src={toAbsoluteUrl('/media/icons/duotune/reaction/like.svg')}
                                        />
                                        <span className="reaction_txt">200 Likes</span>
                                    </div>
                                    <div className="reaction">
                                        <img
                                            alt='Icon'
                                            src={toAbsoluteUrl('/media/icons/duotune/reaction/comment.svg')}
                                        />
                                        <span className="reaction_txt">200 Comments</span>
                                    </div>
                                </div>
                                <div className="reaction">
                                    <img
                                        alt='Icon'
                                        src={toAbsoluteUrl('/media/icons/duotune/reaction/fire.svg')}
                                    />
                                    <span className="reaction_txt">200% Engagement Rate</span>
                                </div>

                            </div>
                            <Link className="link_to_post" to="/posts/kjssdsd">
                                Link to Post
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}