import React, { useState, useRef, useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'


import ApexCharts from 'apexcharts'
import distinctColors from 'distinct-colors'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export default function CategoryPanelOne  (props:any) {
    const chartRef = useRef<HTMLDivElement | null>(null);
    // const [colorsPalette, setColorsPallete] = useState<any[]>([])
    const [insightsData, setInsightsData] = useState<any[]>([])


    useEffect(() => {
        let insightData = [
            {
                name: "Oribe",
                value: 45,
                brandLogo: toAbsoluteUrl('/media/cpg/brand21.png')
            },
            {
                name: "Ouai",
                value: 75,
                brandLogo: toAbsoluteUrl('/media/cpg/brand22.png')
            },
            {
                name: "Wow",
                value: 30,
                brandLogo: toAbsoluteUrl('/media/cpg/brand20.png')
            },
            {
                name: "Bumble and Bumble",
                value: 55,
                brandLogo: toAbsoluteUrl('/media/cpg/brand11.png')
            }
        ];

        let palette: any[] = distinctColors({ count: insightData.length }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)
        let insightValues = insightData.map(eachData => eachData?.value)
        let insightLabels = insightData.map(eachData => eachData?.name)
        let insightDataWithColor: any[] = [];
        let totalInsightSum = insightData.reduce((accumulator, object) => {
            return accumulator + object.value;
        }, 0);
        chartColors.forEach((eachData, index) => {
            let percentValue = ((totalInsightSum - insightData[index]?.value) / totalInsightSum) * 100
            insightDataWithColor.push({
                ...insightData[index],
                bgColor: eachData,
                percentValue: Number.parseFloat(percentValue.toString()).toFixed(1)
            })
            // insightData[index].bgColor = eachData
        })


        // setColorsPallete(chartColors)
        setInsightsData([...insightDataWithColor])



        let options = {

            series: [...insightValues],
            chart: {
                type: 'donut',
                width: 200
            },
            dataLabels: {
                enabled: false,
            },
            labels: [...insightLabels],
            // hollow: {
            //     margin: 15,
            //     size: '40%'
            // },
            stroke: {
                width: 0
            },
            legend: {
                show: false,
            },
            fill: {
                colors: [...chartColors]
            },

        }

        let numbersChart = new ApexCharts(chartRef.current, options);

        if (numbersChart) {
            numbersChart.render()
        }
    }, [chartRef])


    const InsightsLegend = ({ insightData, dataSize }: any) => {
        // let itemsIterator = new Array(dataSize).fill(0)
        return (
            <div className="insight_legends">
                {insightData.map((each: any, index: any) => {

                    return (
                        <div className="each_legend" key={index}>
                            <div className="label_right">
                                <span className="legend_amber" style={{ backgroundColor: `${each?.bgColor}` }} ></span>
                                <img src={each?.brandLogo} alt="" />
                            </div>
                            <div className="label_value">
                                {each?.value}
                                <span>{each?.percentValue}%</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="insight_wrap">
            <div className="cat_insight" ref={chartRef}>

            </div>
            
            <InsightsLegend insightData={insightsData} dataSize={insightsData.length} />

        </div>
    )
}