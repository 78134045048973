// import React from 'react'
import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'


export function MenuInner() {
  const intl = useIntl()
  var userEmail = useSelector<any>(({ auth }) => auth?.user?.user?.email, shallowEqual);
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/app/dashboard' />
      
      {
        userEmail !== "melo@amyris.com" && (
          <>
            <MenuItem title={'Campaigns'} to='/app/campaigns' />
          </>
        )
      }
      
      <MenuItem title={'Influencers'} to='/app/influencers' />
      <MenuItem title={'Insights'} to='/app/insights' />
      <MenuItem title={'Social Intelligence'} to='/app/social-intelligence' />
      {/* <MenuItem title={'Social Listing'} to='/app/social-listing/brand' />       */}
    </>
  ) 
}
   