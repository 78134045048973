import React, {useState, useCallback, useEffect} from 'react'
import { ActionableKeyInsights } from "./ActionableKeyInsights";
import { EditButton } from '../../../../../../../_metronic/layout/components/EditButton';
import { SaveButton } from '../../../../../../../_metronic/layout/components/SaveButton';
import { defaultActionableData } from './actionableDefault';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from "../../../../../../../setup/redux/hooks";
import { getActionableInsightsRes } from "../../../../../../../setup/features/report.slice";

interface Props {
    handleChanges?: (type:any, data:any, index:any) => any;
    bgWhite?:boolean;
    editState?:boolean|null;
    type?:string;
    data?:any;
    index?:number;
    cancelState?: boolean|null;
    error?:string|null;
    resetForm?:boolean;
    isExternal?:boolean;
    range:string;
    campaignId:string;
}

const API_URL = process.env.REACT_APP_API_URL;

function KeyInsightComp({error, data, type,index, bgWhite,isExternal, range, campaignId}: Props) {
    const [editState, seteditState] = useState<boolean|null>(null)
    const [cancelState, setcancelState] = useState<boolean|null>(false)
    const [resetForm, setResetForm] = useState<boolean>(false)

    
    const [dataResponse, setDataResponse] = useState(() =>{
        let dataRes = error ? {...defaultActionableData, campaign_id:campaignId, range:range.toString()} : 
    data?.data || data !== null ? {...data, range:range.toString()} : {...defaultActionableData, campaign_id:campaignId, range:range.toString()}
        
        return dataRes
    })

    const dispatch = useAppDispatch()

    let numberOfInsights = 4
    let countOfInsights = 0

    let dataRes = error ? {...defaultActionableData, campaign_id:campaignId, range:range.toString()} : 
    data?.data || data !== null ? {...data, range:range.toString()} : {...defaultActionableData, campaign_id:campaignId, range:range.toString()}
    
    useEffect(()=>{

        setDataResponse(() => {
            let dataRes = error ? {...defaultActionableData, campaign_id:campaignId, range:range.toString()} : 
            data?.data || data !== null ? {...data, range:range.toString()} : {...defaultActionableData, campaign_id:campaignId, range:range.toString()}
        
            return dataRes
        })

    },[data,editState])

    const editHandler = (value:any) => {
        if(value === "edit"){
            setcancelState(false)
            setResetForm(false)
            return seteditState(true)
        }
        else{
            setResetForm(false)
            return seteditState(false)
        }
    }

    const handleChanges = useCallback(async(type:any, dataValue:any, index:any) => {
        
        if(dataRes){
        let dataResp;
        if(type === "KeyInsights"){
            console.log("got into KeyInsights conditional")
            
            const newDetails = [...dataRes?.data[0]?.details];
            newDetails[index] = { ...newDetails[index], 
                metric: dataValue?.metric,
                value: dataValue?.value,
                rate: dataValue?.rate,
                date: dataValue?.date,
                arrow:dataValue?.arrow
             };
            
            let newData = data?[...data?.data]:[...dataRes.data];
            
            // const newData = [...data?.data];
            newData[0] = { ...newData[0], details: newDetails };
            
            dataResp = {...dataRes, data:newData}
            setDataResponse(dataResp)
            dataRes = dataResp

        }

        
        countOfInsights += 1
        

        if(countOfInsights%numberOfInsights === 0 ){
            
            try {
                
                
                const dataSubmit = {...dataResp, range:range.toString()}
                
                const response = await toast.promise(axios.post(`${API_URL}api/new-reports/save-actionable-insights`,dataSubmit),{
                  pending: 'Updating Insights',
                  success: `Insights updated successfully!`,
                  error: `Error updating Insights: try again later`
                })
                
                if(response){
                
                    const dataGet = dispatch(getActionableInsightsRes({
                        campaign_id: campaignId, 
                        is_external:isExternal,
                        date_range:range.toString()
                    }));

                    dataGet.then((res:any) => setResetForm(true))
                }
                
              } catch (error) {
                console.error('Error updating value:', error);
              }
        }

    }

    },[data,range])
    
    return (
        <div className="grid_display bg_white_fade actionable__grid m-2 border__round__16 " style={{
            padding:"2rem",
            paddingTop:"1.5rem"
        }}>
            <div className="actionable__grid__heading">
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                <p 
                className="text__20 p-2 px-3 m-0  border__round_16 Helvetica-Neue-normal bg_white_fade text_gray width__content">
                    Key Insight
                </p>
                
                {!isExternal &&
                        <>
                            {!editState ? <EditButton handleClick={() => editHandler("edit")} /> :
                                <div className="d-flex align-items-center justify-content-around">
                                <button className="button__delete__default Button border-none rounded p-3 mr__2" onClick={() => { 
                                    
                                    setcancelState(true)
                                    seteditState(false)
                                    
                                    // return dispatch(getActionableInsightsRes({
                                    //     campaign_id: campaignId, 
                                    //     is_external:isExternal,
                                    //     date_range:range
                                    // }));
                                }}>
                                    Cancel
                                </button>
                                    <SaveButton bgWhite text="Save" handleClick={() => editHandler("save")} />
                                </div>
                            }
                        </>
                    }
                    </div>
                    
                </div>
                
                
                {
                    error ? new Array(4).fill(0).map((_,index) =>(
                        <div key={index}>
                            <ActionableKeyInsights resetForm={resetForm} editState={editState} 
                        cancelState={cancelState} type="KeyInsights" data={ null} index={index} 
                        handleChanges={handleChanges}/>
                        </div>
                    )) 
                    :
                    data && data?.data[0]?.details?.map((item:any,index:number) =>(
                        <div key={index}>
                            <ActionableKeyInsights resetForm={resetForm} editState={editState} 
                        cancelState={cancelState} type="KeyInsights" data={item || null} 
                        index={index} handleChanges={handleChanges} />
                        </div>
                    ))
                }
        </div>
  )
}

export default KeyInsightComp