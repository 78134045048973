import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  influencerEngagementRates: null,
  influencerEngagementRatesError: false,
  influencerDetails: null,
  campaigns: null,
  socials: null,
  summary: null,
  demographics: null,
  accounts_summary: null,
  contact: null,
  files: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const InfluencerSlice = createSlice({
  name: "influencer",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      // state.error = null;
      state = initialdatasState;
  
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    influencerDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.influencerDetails = action.payload;
    },
    influencerCampaigns: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.campaigns = action.payload;
    },
    influencerSummary: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.summary = action.payload;
    },
    influencerSocials: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.socials = action.payload;
    },
    influencerDemo: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.demographics = action.payload;
    },
    influencerAccount: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.accounts_summary = action.payload;
    },
    influencerContact: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.contact = action.payload;
    },
    influencerFile: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.files = action.payload;
    },
  }
});
