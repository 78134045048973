import { Modal } from "react-bootstrap";
import { PropsWithChildren } from "react";

import classes from "./modal.module.css";


interface ModalProps {
    show: boolean;
    handleShowModal: () => void;
}
interface Props extends PropsWithChildren<ModalProps> {}

export default function LargeModal({ children, show, handleShowModal}: Props) {

    return (
        <Modal 
            show={show} 
            onHide={handleShowModal}
            dialogClassName={classes.modalStyle}
        >
            {children}
        </Modal>
    )
}