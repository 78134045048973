/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  status: string
  title: string
  description: string
  sdate: string
  edate: string
  LogoURL?: string
  campaignId: string
}

const limitStr = (str: string, length: number) => {
  const spl = str.split('');
  let newStr = str;
  if (spl.length > length) {
    spl.length = length;
    newStr = spl.join('') + '...'
  }
  return newStr;
}

const CardX: FC<Props> = ({
  title,
  status,
  description,
  sdate,
  edate,
  campaignId,
  LogoURL,
}) => {
  return (
    <Link to={`/app/campaign/summary/${campaignId}`} className='card border border-2 border-gray-200 mb-5' style={{ borderRadius: '16px' }}>
      <div className='card-header p-4 border-b-1 border-gray-200'>
        <div className='card-title m-0'>
          <img src={LogoURL || toAbsoluteUrl('/media/cpg/na.png')} alt="*"
            style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '8px' }} />
          <h3 className="fs-5 fw-bold word-wrap">{limitStr(title, 20)}</h3>
        </div>
        <div className='card-toolbar'>
          <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#06D6A0' }} />
          <span className={`fw-bolder fs-16 me-auto px-2`} style={{ color: '#06D6A0' }}>
            {status}
          </span>
        </div>
      </div>
      <div className='card-body p-4'>
        <p className='text-gray-700 fw-normal fs-14 mt-1 mb-7' style={{ height: '36px' }}>
          {limitStr(description, 110)}
        </p>
        <div className='d-flex flex justify-content-between mb-5'>
          <div className='py-3'>
            <div className='fw-light text-success'>Start </div>
            <div className='fs-6 fw-thin text-gray-700'>{sdate}</div>
          </div>
          <div className='py-3'>
            <div className='fw-bold text-danger'>End</div>
            <div className='fs-6 fw-thin text-gray-700'>{edate}</div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export { CardX }
