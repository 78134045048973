import { useEffect, useMemo, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import SVG from 'react-inlinesvg';
import { useAppDispatch, useAppSelector } from "../../../../../../../setup/redux/hooks";
import {uploadStaticImages} from "../../../../../../../setup/features/report.slice"
import { getTopPerforming } from "../../../../../../../setup/features/topPerforming.slice";

interface Props {
    posts: {
        image: string
    }[],
    data:any,
    campaignId:string;
    isExternal:boolean | undefined;
    
}

function addDefaultSrc(ev: any, image?:string) {
    ev.target.src = toAbsoluteUrl(image || '/media/cpg/reportImg4.png');
}

//function goes through a google drive url to extract the id
const idCollector = (url:any) => {
    const regexMatch = url.match(/\/d\/(.+?)\//);
    if (regexMatch && regexMatch.length > 1) {
        return regexMatch[1];
    } 
    else {
        return null;
    }
}

// function checks if the url contains social media names and if its a google drive folder
const urlChecker = (url:any) => {
    let urlText = url || ""
    if(urlText?.includes("instagram") || urlText?.includes("creatoriq") || urlText?.includes("tiktok") || urlText?.includes("folders")){
        return true
    }
    return false
}

// function checks if the url contains social media names and if its a google drive folder
const uploadImageChecker = (url:any) => {
    let urlText = url || ""
    if(urlText.includes("amazonaws")){
        return true
    }
    return false
}

const socialDefault = (url:any) => {
    
    let urlText = url || ""
    if(urlText.includes("creatoriq")){
        return urlText
    }
    if(urlText.includes("instagram")){
        return "/media/cpg/reportImg4.png"
    }
    if(urlText.includes("tiktok")){
        return "/media/cpg/reportImg3.png"
    }
    if(urlText.includes("folders")){
        return "/media/cpg/reportImg1.png"
    }
    return "/media/cpg/reportImg2.png"
}

const ContentCard = ({ post, campaignId, isExternal}: any 
    & { handleShowModal(): void, handleInfluencerId(id:string): void }) => {

    const dateRange = useAppSelector((state) => state.report.range);

    const [isImage, setIsImage] = useState(true)
    

    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(-1); 
    const containerRef = useRef<HTMLDivElement>(null); 
    const uploadRef = useRef<HTMLDivElement>(null);
     


    const dispatch = useAppDispatch();

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];
        const formData = new FormData();

        formData.append("campaign_id", `${campaignId ? campaignId?.toString() : ""}`);
        formData.append("file", image);
        formData.append("postId", hoveredImageIndex.toString())

        
        
        dispatch(uploadStaticImages(formData));
        dispatch(getTopPerforming({
            campaign_id: campaignId ? campaignId.toString() : "000000", 
            is_external:isExternal,
            date_range: dateRange ? dateRange.toString() : "7" 
        }));

    }
    

    const handleImageHover = (index: number) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        if(hoveredImageIndex){
            setHoveredImageIndex(-1);
        }
    }

    useEffect(() => {
        // Attach a click event listener to the document
        document.addEventListener('click', handleClickOutside);
    
        // Remove the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    function handleClickOutside(event: MouseEvent) {
        // Check if the click target is inside the div container
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && uploadRef.current && !uploadRef.current.contains(event.target as Node)) {
          // Clicked outside the container, update the state value
          handleImageOut()
        }
    }

    let socialOrVideo = useMemo(() => urlChecker(post.SavedPicture || post.Thumbnail || ""), [post.SavedPicture])

    let uploadImage = useMemo(() => uploadImageChecker(post.SavedPicture || post.Thumbnail || ""), [post.SavedPicture])
    
    let statsGooglePicture = useMemo(() => idCollector(post.SavedPicture || post.Thumbnail || ""), [post.SavedPicture])
   
    // let picture = post.SavedPicture ? post.SavedPicture:post.Thumbnail
    
    return (
        <div className="card rounded" ref={containerRef}>
            <div className="card-body p-0">
                <div className="position-relative mb-6" 
                onMouseOver={() => handleImageHover && handleImageHover(post.Id)} 
                >
                        {
                            post.SavedPicture && !socialOrVideo && !uploadImage && !statsGooglePicture ? 
                            (
                                <>
                                {/* {console.log("here is the creator iq image")} */}
                                <a href={post.PostLink}  target="_blank" rel="noreferrer">
                                    <img
                                        onError={(ev) => {
                                            addDefaultSrc(ev)
                                        }}
                                        src={toAbsoluteUrl(post.SavedPicture || post.Thumbnail )}
                                        alt="creator post"
                                        className="image-fluid d-block" 
                                        style={{ 
                                            width: "200px",
                                            height: "200px",
                                            borderRadius: "16px",
                                            maxHeight: 200, 
                                            resize: "both", 
                                            objectFit: "cover",
                                            cursor:"pointer"
                                        }}
                                        
                                    />
                                </a>
                                </>
                            ) :
                            uploadImage?
                            (
                                <>
                                <a href={post.PostLink}  target="_blank" rel="noreferrer">
                                    <img
                                        onError={(ev) => {
                                            setIsImage(true)
                                        }}
                                        src={toAbsoluteUrl(post.SavedPicture || post.Thumbnail)}
                                        alt="creator post"
                                        className="image-fluid d-block" 

                                        style={{ 
                                            width: "200px",
                                            height: "200px",
                                            borderRadius: "16px", 
                                            resize: "both", 
                                            objectFit: "cover",
                                            cursor:"pointer"
                                        }}
                                        
                                    />
                                </a>
                                </>
                            ) :
                            isImage && statsGooglePicture && !socialOrVideo ?
                            (
                                <>
                                    {/* {console.log("here is the stats image")} */}
                                    <a href={post.PostLink}  target="_blank" rel="noreferrer">
                                    <img
                                        onError={(ev) => {
                                            addDefaultSrc(ev)
                                            setIsImage(false)
                                        }}
                                        src={`https://drive.google.com/uc?export=view&id=${statsGooglePicture}`}
                                        alt="creator post"
                                        className="image-fluid d-block" 

                                        style={{ 
                                            width: "200px",
                                            height: "200px",
                                            borderRadius: "16px", 
                                            resize: "both", 
                                            objectFit: "cover",
                                            cursor:"pointer"
                                        }}
                                        
                                    /> 
                                    </a>
                                </>
                            ) : 
                            !statsGooglePicture && socialOrVideo ?(
                                <>
                                    {/* {console.log("here is the social image / folder -> ")}*/}
                                    <a href={post.PostLink}  target="_blank" rel="noreferrer">
                                        <img 
                                            onError={(ev)=> {
                                                console.log("social Image")
                                                addDefaultSrc(ev)
                                                setIsImage(false)
                                                
                                            }}
                                            src={toAbsoluteUrl(socialDefault(post.SavedPicture ?? post.Thumbnail))}
                                            alt="creator post"
                                            className="image-fluid d-block" 

                                            style={{ 
                                                width: "200px",
                                                height: "200px",
                                                borderRadius: "16px",
                                                resize: "both", 
                                                objectFit: "cover",
                                                cursor:"pointer"
                                            }}
                                            
                                        />
                                    </a>
                                </>
                            ): (
                                    <>
                                        {/* {console.log("here is the iframe video image ->", statsGooglePicture)} */}
                                        <iframe src={`https://drive.google.com/file/d/${statsGooglePicture}/preview`} 
                                            allow="autoplay"
                                            style={{
                                                width: "200px",
                                                height: "200px",
                                                borderRadius: "16px",
                                                resize: "both", 
                                                objectFit: "cover",
                                                cursor:"pointer"
                                            }} 
                                            onError={(ev)=> {
                                                console.log("stats video not dispalyed")
                                                // setIsImage(true)
                                                addDefaultSrc(ev)
                                            }} title={post.name} ></iframe>
                                    </>
                            ) 
                            
                        }

                        

                    
                    
                    {hoveredImageIndex === post.Id && 
                        (
                            <div ref={uploadRef}>

                                <label 
                                    htmlFor="modal-post-dynamic"
                                    style={{ 
                                        border: "none", 
                                        background: "#F4F4FA", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        gap: "10px", 
                                        position: "absolute", 
                                        right: "30px",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        // pointerEvents:"none",
                                        top: "20px",
                                        zIndex: 2,
                                        cursor: "pointer"
                                    }}
                                >
                                    <input name="" type="file" id="modal-post-dynamic" 
                                    accept=".png,.jpg,.jpeg" hidden style={{visibility:'hidden', width:"1px", height:"1px"}} 
                                    onChange={handleImageUpload} />
                                    <SVG 
                                        src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')} 
                                        className='svg-icon-2' 
                                        style={{zIndex: 5, cursor: "pointer"}}
                                    />
                                </label>
                            </div>
                        )
                    }
                </div>

                
            </div>
        </div>
    )
}


export default function TopPosts({ posts, data,campaignId, isExternal }: Props) {
    
    let postData;
    if(Array.isArray(data?.posts)){
        postData = data.posts || []
    }else{
        postData = data.post || []
    }
    return (
        <div>
            <h4
                className="text__24 NeueMontreal-bold text-dark text-align-center p-0"
                style={{ marginBottom: "8px"}}
            >Posts</h4>

            <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ gap: "16px", overflowY:"scroll", height:"250px" }}>
                
                {
                    data === null || postData.length === 0 ? (
                        <div style={{textAlign:"center"}}>
                            No Post Content
                        </div>
                    ) : (
                        data?.posts && postData.map((postItem:any, index:number) => (
                            <>
                                <ContentCard campaignId={campaignId} post={postItem} isExternal={isExternal}/>
                            </>
                        ))
                    )
                }
            </div>
        </div>
    )
}

{/* <div>
    <a href={post.PostLink} target="_blank" rel="noreferrer">
        <img 
            onError={(ev)=> { 
                addDefaultSrc(ev, post.LogoURL)
            }}
            src={post.SavedPicture}
            style={{
                width: "200px",
                height: "200px",
                borderRadius: "16px"
            }}
            alt="creator post"
        />
    </a>
</div> */}