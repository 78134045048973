import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
// import { DateRangePicker } from 'react-date-range';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import clsx from 'clsx'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { HeaderUserMenu } from '../../../../../_metronic/partials'
// import Moment from 'react-moment';
// import 'moment-timezone';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

const sorter = (a: any, b: any) => {
    if (typeof a?.name === 'number' && typeof b?.name === 'number') {
        return a - b;
    } else if (typeof a?.name === 'number' && typeof b?.name !== 'number') {
        return -1;
    } else if (typeof a?.name !== 'number' && typeof b?.name === 'number') {
        return 1;
    } else {
        return a?.name > b?.name ? 1 : -1;
    }
}

export default function SocialListingHeader({ setSectionInView, startDate, setStartDate, endDate, setEndDate, chosenBrand, setChosenBrand, brandsList, setBothDateChosen, setFetchingData, seStartDateToCompare, setEndDateToCompare, setDateFilterOption, isExternal, dateFilterOption }: any) {

    let today = new Date();
    const { pathname } = useLocation()
    const [startOpen, setStartOpen] = useState<boolean>(false);

    const [dateWasChosen, setDateWasChosen] = useState<boolean>(false);
    const [isPickerShown, setShowDatePicker] = useState<boolean>(true)
    const [isDateFilterOpen, setIsDateFilterOpen] = useState<boolean>(false)
    const [sortedBrands, setSortedBrands] = useState<any[]>([])
    const [dateFilter, setDateFilter] = useState<any>()
    const dateFilterRef = useRef<any>();

    const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

    useEffect(() => {
        if (!isExternal) {

            let allBrands: any[] = [...brandsList];
            allBrands.sort(sorter)
            setSortedBrands(allBrands)
        }


    }, []);



    const onChange = (dates: any) => {
        const [start, end] = dates;


        setStartDate(start);
        setEndDate(end);
        setDateWasChosen(true)

        if (start && end) {
            setBothDateChosen(true)
        } else {
            setBothDateChosen(false)
        }
    };

    const openMenu = () => {
        dateFilterRef.current.focus()
        setIsDateFilterOpen(true)
    }
    const closeMenu = () => {
        dateFilterRef.current.blur();
        setIsDateFilterOpen(false)
    }


    const onOpenDates = () => {

        // setIsDateFilterOpen(false)

        dateFilterRef.current.blur();

        setShowDatePicker(true)
        setStartOpen(true)

    }

    let dateFilterOptions = [
        {
            label: "Last Month",
            value: 1,
        },
        {
            label: "Last Quarter",
            value: 2,
        },
        {
            label: "Custom range",
            value: 3,
        },
    ]
    const dateFilterOptionsStyle = {
        control: (base: any) => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        }),
        singleValue: (base: any) => ({
            ...base,
            fontSize: "11px",
            color: "#7B7F99"
        }),
        menu: (base: any) => ({
            ...base,
            minWidth: "100px"
        })
    };

    const dateConverter = (dateString: any) => {
        var dateParts = dateString.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(Number(`20${dateParts[2]}`), dateParts[1] - 1, +dateParts[0]);
        return dateObject.toISOString();
    }

    const dateOptionsChange = (option: any) => {
        setBothDateChosen(false)
        closeMenu()
        setDateFilter(option)

        if (option?.value === 1) {
            let lastMonthStart = moment(new Date()).subtract(1, 'months').startOf('month').format('DD/MM/YY');
            let lastMonthEnd = moment(new Date()).subtract(1, 'months').endOf('month').format('DD/MM/YY');

            let previousMonthStartToCompare = moment(new Date()).subtract(2, 'months').startOf('month').format('DD/MM/YY');
            let previousMonthEndToCompare = moment(new Date()).subtract(2, 'months').endOf('month').format('DD/MM/YY');



            setStartDate(moment(dateConverter(lastMonthStart)).toDate());
            setEndDate(moment(dateConverter(lastMonthEnd)).toDate());


            seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
            setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());

            setDateFilterOption("Last Month");



            setDateWasChosen(true)

            setBothDateChosen(true)
            setStartOpen(false)
            setShowDatePicker(true)
        }
        if (option?.value === 3) {
            onOpenDates()

            setDateFilterOption("custom");

        }


        if (option?.value === 2) {
            let lastMonthStart = moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('DD/MM/YY');
            let lastMonthEnd = moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('DD/MM/YY');


            let previousMonthStartToCompare = moment(new Date()).subtract(2, 'quarter').startOf('quarter').format('DD/MM/YY');
            let previousMonthEndToCompare = moment(new Date()).subtract(2, 'quarter').endOf('quarter').format('DD/MM/YY');


            setStartDate(moment(dateConverter(lastMonthStart)).toDate());
            setEndDate(moment(dateConverter(lastMonthEnd)).toDate());

            seStartDateToCompare(moment(dateConverter(previousMonthStartToCompare)).toDate());
            setEndDateToCompare(moment(dateConverter(previousMonthEndToCompare)).toDate());


            setDateFilterOption("Last Quarter");

            setDateWasChosen(true)


            setBothDateChosen(true)
            setStartOpen(false)
            setShowDatePicker(true)
        }
    }



    return (
        <>
            <div className="social_listing_header">
                <Link to="#" className="s_l_logo">
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/social-listing.png')}
                    />
                </Link>
                <div className="s_l_heading">
                    <div className="s_l_headleft">
                        <div className="heading_nav_items">
                            <ScrollLink className="each_head_nav" to="brand-summary" spy={true} smooth={true} duration={500}>
                                Brand Summary
                            </ScrollLink>
                            <ScrollLink className="each_head_nav" to="benchmark-wrap" spy={true} smooth={true} duration={500}>
                                Competitor Benchmark
                            </ScrollLink>
                            <ScrollLink className="each_head_nav" to="content-analysis" spy={true} smooth={true} duration={500}>
                                Content Analysis
                            </ScrollLink>
                            {/* <div className="each_head_nav">Brand Summary</div>
                            <div className="each_head_nav">Competitor Benchmark</div>
                            <div className="each_head_nav">Content Analysis</div> */}
                        </div>
                    </div>
                    <div className={isExternal ? "s_l_headright with_full" : "s_l_headright"}>
                        <div className="brand_n_date">
                            {!isExternal &&
                                <div className="brand_option">
                                    <select name="" id=""
                                        value={chosenBrand?.id}
                                        // defaultValue={chosenBrand}
                                        onChange={(e) => {
                                            let selectedBrand = sortedBrands.filter((eachItem: any) => eachItem.id == e.target.value)[0]
                                            setChosenBrand(selectedBrand)
                                            // setChosenBrand(e.target.value)
                                            // setFetchingData(true)
                                        }}

                                    >
                                        {
                                            sortedBrands.map((eachBrand: any, index: any) => {
                                                return (
                                                    <option value={eachBrand?.id} key={index}>{eachBrand.name}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="JVN">JVN</option>
                                    <option value="La Coste">La Coste</option>
                                    <option value="Luio Vuittton">Luio Vuittton</option> */}
                                    </select>
                                </div>
                            }
                            {isExternal &&
                                <div className="brand_option">
                                    <div className="brand_name_txt">{chosenBrand?.name}</div>
                                </div>
                            }
                            <div className="date_range_wrap">
                                <div className={!isPickerShown ? "date_range_label" : "date_range_label shifted"}>Date Range</div>

                                <div className="date_selected"

                                >
                                    <div
                                        onClick={() => {
                                            if (dateFilterRef.current) {

                                            }
                                        }}
                                    >
                                        <Select
                                            value={dateFilter}
                                            onChange={dateOptionsChange}
                                            options={dateFilterOptions}
                                            defaultValue={dateFilter || { label: dateFilterOption || "Last Quarter", value: 2 }}
                                            placeholder="Choose date option"
                                            className={!isPickerShown ? "select-wrap" : "select-wrap select_custom"}
                                            // className="select-wrap"
                                            classNamePrefix="date_filtering"
                                            styles={dateFilterOptionsStyle}
                                            // openMenuOnFocus={true}
                                            menuIsOpen={isDateFilterOpen}
                                            onFocus={openMenu}
                                            onBlur={closeMenu}
                                            ref={dateFilterRef}
                                        />
                                    </div>

                                    {/* {!isPickerShown && <input placeholder="Select date" type="readonly" onClick={() => onOpenDates()} />} */}
                                    {(isPickerShown) &&
                                        <div className="date_period">
                                            <div className="each_date_wrap start">

                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startOpen={startOpen}
                                                    // disabled={!startOpen}
                                                    placeholderText=" "
                                                    dateFormat="dd/MM/yy"
                                                    maxDate={new Date()}
                                                    monthsShown={2}
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    withPortal
                                                />


                                            </div>


                                        </div>
                                    }
                                </div>
                                {/* {dateFilter?.value === 3 &&
                                    <div className="open_date_options"
                                        onClick={() => {
                                            if (dateFilterRef.current) {
                                                dateFilterRef.current.focus();
                                            }
                                        }}
                                    >More options &gt;&gt;</div>
                                } */}
                                {
                                    ((!startDate || !endDate) && dateWasChosen) &&
                                    <div className="date_error">Select start and end date</div>
                                }
                                {/* {startDate && <div>start date: {startDate.toDateString()}</div>}
                                {endDate && <div>end date: {endDate?.toDateString()}</div>} */}
                            </div>
                        </div>
                        {!isExternal &&
                            <div className={`usr_menu_ico ${clsx('d-flex align-items-center', toolbarButtonMarginClass)}`}
                                id='kt_header_user_menu_toggle'
                            >
                                <div className={`user_ico ${clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}`}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-attach='parent'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='bottom'
                                >

                                    <img
                                        alt='User'
                                        src={toAbsoluteUrl('/media/icons/socials/user_blue.png')}
                                    />
                                </div>
                                <HeaderUserMenu />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}