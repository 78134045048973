


import SocialListingHeader from './benchmark/HeadStats';
import SummaryAllBenchmark from './benchmark/benchmarks';





export default function SocialListingBenchmark(props: any) {
    let { timeSeries, brandsList, brandsSummaryInfo, isPdfPage } = props;

    return (
        <div className={isPdfPage ? "pdf-wrap" : ""}>
            <div className="brand_glance each_section_wrap" id="benchmark-wrap">
                <div className="section_title">Competitor Benchmark</div>

                <SocialListingHeader brandsSummaryInfo={brandsSummaryInfo} />
                <SummaryAllBenchmark timeSeries={timeSeries} brandsList={brandsList} />



            </div>
        </div>
    )
}