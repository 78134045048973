import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'



export default function ValueChange({ value, change, noBg }: any) {
    
    let valueSplit  = value.split("%")[0];


    
    if (valueSplit==0 || valueSplit=="Infinity" || valueSplit=="NaN") {
        return (
            <div className={`value_change neutral ${noBg?"no_bg":""} `}>
                <div className="change_icon">
                    {/* <img
                        alt='Logo'
                        src={change === "rise" ? toAbsoluteUrl('/media/icons/socials/rise.svg') : toAbsoluteUrl('/media/icons/socials/fall.svg')}
                    /> */}
                </div>
                <div className="change_value">0%</div>
            </div>
        )
    }

    if (valueSplit && valueSplit!=="Infinity") {
        // if (valueSplit && valueSplit!=="Infinity" && valueSplit>=1) {
        return (
            <div className={`value_change ${noBg?"no_bg":""} ${change === "rise" ? "rise" : "fall"}`}>
                <div className="change_icon">
                    <img
                        alt='Logo'
                        src={change === "rise" ? toAbsoluteUrl('/media/icons/socials/rise.svg') : toAbsoluteUrl('/media/icons/socials/fall.svg')}
                    />
                </div>
                <div className="change_value">{value}</div>
            </div>
        )
    }

   
    return <></>;
}