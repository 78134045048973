import { AttachmentFile } from "../components/attachmentComp/AttachmentFile"
import {AddAttachment} from "../components/attachmentComp/AddAttachment"
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { getAttachmentGetRes } from "../../../../../../setup/features/report.slice";

import {useEffect} from 'react'

import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"

import Skeleton from "react-loading-skeleton";
import { AttachmentDropdown } from "../components/attachmentComp/AttachmentDropdown";


export const Attachments = ({ campaignId, isExternal}: { campaignId: string, isExternal?:boolean }) =>{
    const dispatch = useAppDispatch();
    const { loading, error, data } = useAppSelector((state) => state.report.AttachmentGetRes);

    useEffect(() => {
        dispatch(getAttachmentGetRes({
            campaign_id: campaignId, 
            is_external:isExternal
        }));
        
    }, [campaignId]);



    return(
        <div className="my-12">
            <h1 className="NeueMontreal-bold text__36">Links & Attachments</h1>
            {!isExternal &&
                <div className="" style={{marginRight:"auto", marginTop:"-10px"}}>
                    <AttachmentDropdown campaignId={campaignId} isExternal={isExternal}/>
                </div>
            }

            {loading && <Skeleton height={90} count={2} />}
            {!loading && 
            (error) ? (
                <ErrorBoundary error={error} />
            ):
                !loading && (
                    <div className="d-flex gap__30 align-items-center flex__wrap">
                    {
                        data && data.length !== 0 ? data.map((item:any, index:number) => {
                            return (
                                <div key={index}>
                                    <AttachmentFile isExternal={isExternal} item={item} campaignId={campaignId}/>
                                </div>
                            )
                        }) : (
                            <div></div>
                        )
                    }

                    {!isExternal && (<AddAttachment isExternal={isExternal} campaignId={campaignId}/>)}
                </div>
                )
            }
        </div>
    )
}