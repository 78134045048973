/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { classnames } from '../../../../helper'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from "moment";

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title?: string;
  spaced?: boolean;
}

const ChartImpressions: React.FC<Props> = ({ title, className, chartColor, chartHeight, spaced }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const [datas, set_datas] = useState([]);
 
  const { analytics, campaignStats, campaignDetails} = useSelector(
    (state: any) => ({
        analytics: state.campaign.analytics,
        campaignStats: state.dashboard.campaignStats,
        campaignDetails: state.dashboard.campaignDetails
      })
  );

  useEffect(() => {


  }, [analytics])


  function getL(date: any) {
    return moment(date).fromNow();
  }
  
  useEffect(() => {
    // if (!chartRef.current || !analytics) {
    //   return
    // }

    if (!campaignDetails || !chartRef.current || (!analytics && !campaignStats?.target_data?.engagements) ) {
      return
    }

    var das:any = [];
    var labs:any = [];
    var new_data:any = [];

    var start = campaignDetails.StartDate;

    das.push(0);
    labs.push(getL(start));
    new_data.push([start, 0])
 
    var el_act = campaignStats?.target_data?.campaign_actual?.engagements;

    if (analytics) {
      analytics.forEach((ee: any,i: number) => {
        das.push(ee.data.new_engagements || ee.data.Engagement)
        labs.push(getL(ee.createdAt))
        new_data.push([ee.createdAt, ee.data.new_engagements || ee.data.Engagement])
      })
      if (el_act) {
        das.push(el_act);
        labs.push(getL(new Date()));
        new_data.push([new Date(), el_act])
      }
      
    } else if (!analytics && el_act) {
      das.push(el_act);
      labs.push(getL(campaignStats?.target_data?.updatedAt));
      new_data.push([campaignStats?.target_data?.updatedAt, el_act])
    } 

    // if (analytics && !campaignStats?.target_data?.target_engagement) {
    //   console.log("here 1")
    //   analytics.forEach((ee: any,i: number) => {
    //     das.push(ee.data.Engagement)
    //     labs.push(getL(ee.createdAt))
    //     new_data.push([ee.createdAt, ee.data.Engagement])
    //   })
    // } else if (analytics && !campaignStats?.target_data?.target_engagement) {
    //   console.log("here 2")
    //   analytics.forEach((ee: any,i: number) => {
    //     das.push(ee.data.Engagement)
    //     labs.push(getL(ee.createdAt))
    //     new_data.push([ee.createdAt, ee.data.Engagement])
    //   })
    // } else if (!analytics && campaignStats?.target_data?.target_engagement) {
    //   console.log("here 3")
    //   analytics.forEach((ee: any,i: number) => {
    //     das.push(ee.data.Engagement)
    //     labs.push(getL(ee.createdAt))
    //     new_data.push([ee.createdAt, ee.data.Engagement])
    //   })
    // }


    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, das, labs, new_data, Number(campaignStats?.target_data?.target_engagement || 0)))
    // var chart = new ApexCharts(chartRef.current, {
    //   chart: { 
    //    id: 'mychart',
    //    type: 'area'
    //   },
    //   series: [{
    //     data: das 
    //   }]
    // });
    
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, analytics, campaignStats])

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column p-0'>
        <div className={classnames(`flex-grow-1 pb-0`, spaced && "card-p p-4'")}>
          <div className='d-flex flex-stack flex-wrap'>
            <div className=''>
              <span className='text-black text-hover-primary fw-bolder fs-6'>{title}</span>
            </div>
          </div>
        </div>
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any, lebs: any, new_data: any, target: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)


  // console.log(target);
  // console.log(new_data);


  return {
    series: [{
        data: new_data
    }],
    chart: {
    id: 'area-datetime',
    type: 'area',
    height: 197.5,
    zoom: {
      autoScaleYaxis: true
    }
  },
  yaxis: {
    min: undefined,
    max: new_data.length > 0 ? new_data.slice(-1)[0][1] >= target ? undefined : target + 100 : undefined,
    tickAmount: 4,
  },
  annotations: {
    yaxis: [{
      y: target,
      borderColor: '#8C8C8C',
      label: {
        // show: true,
        text: 'TARGET',
        style: {
          color: "#8C8C8C"
        }
      }
    }],
    // xaxis: [{
    //   x: new Date('01 Dec 2022').getTime(),
    //   borderColor: '#999',
    //   // yAxisIndex: 0,
    //   label: {
    //     // show: true,
    //     text: 'Rally',
    //     style: {
    //       color: "#fff",
    //       background: '#775DD0'
    //     }
    //   }
    // }]
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    // style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: undefined,
    max: new_data.length > 0 ? new Date(new_data.slice(-1)[0][0]).getTime() + (86400000) : undefined,
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
  };
}

export { ChartImpressions }
