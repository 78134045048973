import { CSSProperties } from "react";

interface CustomSelectProps {
    options: {
        label: string,
        value: string | number
    }[],
    value?: string;
    className?: any;
    onChange?: (event: any) => void;
    containerStyle?: CSSProperties;
    textStyle?: CSSProperties;
    dropdownIconStyle?: CSSProperties;
    id?:string;
}

export const CustomSelect = ({ containerStyle, className, onChange, options, textStyle, value, id }: CustomSelectProps) => {

    return (
        
            <select 
                name="type" 
                style={{ width: '100px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px', ...textStyle }}
                className="custom-select"
                onChange={onChange} 
                value={value}
                id={id}
            >
               {options.map((opts, index) => (
                    <option key={index} value={opts.value}>{opts.label}</option>
               ))} 
            </select>

    )
}


{/* <div 
            className={className}
            style={{
                borderRadius: '8px', 
                // border: '1px solid #D9D9D9', 
                padding: '13px 13px',
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#F4F4FA",
                border: "none",
                gap: "10px",
                ...containerStyle
            }}
        >
            <select 
                name="type" 
                style={{ 
                    appearance: "none", 
                    background: "none", 
                    border: "none", 
                    fontSize: "14px",
                    color: "#8C8C8C",
                    fontWeight: 500,
                    ...textStyle
                }}
                className=""
                onChange={onChange} 
                value={value}
                id={id}
            >
               {options.map((opts, index) => (
                    <option key={index} value={opts.value}>{opts.label}</option>
               ))} 
            </select>


            <label
                htmlFor={id}
                style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.875 2.36975L9.41406 9.51819C9.17969 9.7135 8.94531 9.79163 8.75 9.79163C8.51562 9.79163 8.28125 9.7135 8.08594 9.55725L0.585938 2.36975C0.195312 2.01819 0.195312 1.39319 0.546875 1.04163C0.898438 0.651001 1.52344 0.651001 1.875 1.00256L8.75 7.56506L15.5859 1.00256C15.9375 0.651001 16.5625 0.651001 16.9141 1.04163C17.2656 1.39319 17.2656 2.01819 16.875 2.36975Z' fill='%238C8C8C'/%3E%3C/svg%3E%0A")`,
                    width: "16px",
                    height: "10px",
                    backgroundRepeat: "no-repeat"
                }}
            ></label>
    </div> */}