import { ApexOptions } from "apexcharts";
import { useState } from "react";
import Chart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import ReactSelect from "react-select";
import { metricValueFormatter } from "../../../../../../helper";
import * as functions from "../../../../../global/functions";

import { useAppSelector } from "../../../../../../setup/redux/hooks"
import { SummaryData, TopChannel } from "../../../../../../setup/types/report";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect";

import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"


type Option = keyof Omit<TopChannel, "name" | "increase">;
type Options = Omit<TopChannel, "name">;

const platformColors = {
    instagram: "#C837AB",
    tiktok: "#000000",
    facebook: "#0062E0",
    youtube: "#FF0000"
}

let value = { value: 0, increase: false, rate: 0};
const placeHolder: TopChannel[] = [
    { name: "instagram", impressions: value, engagementRate: value },
    { name: "tiktok", impressions: value, engagementRate: value },
    { name: "facebook", impressions: value, engagementRate: value },
    { name: "youtube", impressions: value, engagementRate: value }
] 


function firstCharToUpper(s: string) {
    return s?.charAt(0)?.replace(s[0], s?.charAt(0)?.toUpperCase()) + s?.slice(1, s?.length);
}

function mergePlaceholder(original: TopChannel[], placeHolder: TopChannel[]) {
    let channelNames = original?.map((ch) => ch?.name?.toLowerCase() || "");
    let filter = placeHolder?.filter((ch) => !channelNames?.includes(ch.name));

    return [...original, ...filter];
}


const ChannelData = ({ channel, option }: { channel: TopChannel, option: Option}) => {
    
    return (
       <div className="col-6">
            <div className="d-flex align-items-center" style={{ gap: "8px"}}>
                <div 
                    style={{ 
                        backgroundColor: platformColors[(channel?.name?.toLowerCase() || "") as keyof typeof platformColors] || "red",
                        width: "24px",
                        height: "24px",
                        borderRadius: "8px"
                    }}
                ></div>
                <span className="Helvetica-Neue-normal" style={{ fontSize: "14px", margin: 0 }}>
                    {firstCharToUpper(channel?.name)}
                </span>
            </div>

            <div  className="d-flex align-items-start">
                <p style={{ fontSize: "26px", fontWeight: 600, color: "#000", letterSpacing: "1px", margin: 0 }}>
                    {`${option === "impressions" ? `${metricValueFormatter(channel[option]?.value, 1) || 0}` : option === "engagementRate" ? `${channel[option].value.toFixed(2)}%` : "0"}`}
                </p>
                <span style={{ 
                    color: channel[option].increase ? "#06D6A0" : "#FF0000",
                    margin: "8px 0px 0px 4px", 
                    fontSize: 14, 
                    fontWeight: 500 
                }}>
                    {channel[option].rate.toFixed(1)}% {" "}
                    <span className="ml-2">
                        <i 
                            style={{ 
                                color: channel[option]?.increase ? "#06D6A0" : "#FF0000", 
                                fontSize: 12,
                                fontWeight: 500 
                            }} 
                            className={channel[option]?.increase ? "bi bi-arrow-up-right" : "bi bi-arrow-down-left"
                         }>    
                        </i>
                    </span>
                </span>
            </div>
       </div>
    )
}


export const TopChannels = () => {
    const { data, error, loading } = useAppSelector((state) => state.report.summary);
    const topChannels = data?.topChannels && data?.topChannels?.length < 4 
        ? mergePlaceholder(data?.topChannels, placeHolder) : data?.topChannels;
    
    const [option, setOption] = useState<Option>("impressions");

    if (loading) {
        return (
            <div className="col-xl-3 col-lg-6 p-4 bg-white rounded">
                <Skeleton height={"60px"} />

                <Skeleton height={"260px"} />

                <Skeleton height={"120px"} />
            </div>
        )
    }

    if (data === null || topChannels === undefined) {
        return (
            <div className="col-xl-3 col-lg-3 bg-white p-4" style={{ borderRadius: 20, position: "relative" }}>
                <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap" style={{ marginBottom: "43px" }}>
                    <h2 style={{ margin: 0, color: "#000", fontSize: "20px", fontWeight: 700, lineHeight: "28px" }}>
                        Top Channels
                    </h2>

                    <CustomSelect 
                        options={[
                            {label: "Impressions", value: "impressions"},
                            {label: "Engagement Rate", value: "engagementRate"}
                        ]}
                        value={option}
                        onChange={(e) => setOption(e.target.value as Option)}
                        containerStyle={{ gap: 0, padding: "12px", borderRadius: "16px" }}
                        textStyle={{ width: "100px"}}
                    />
        
                </div> 

                <p className="Helvetica-Neue-Medium mt-12 text-center text__20">
                    No data available at the moment
                </p>
            </div>
        );
    }

    const noChannelData = topChannels === undefined || topChannels?.length === 0;

    let chartDataSeries = new Array(topChannels?.length).fill(1);

    if (!topChannels.every((channel) => channel[option]?.value === 0)) {
        chartDataSeries = topChannels.map((channel) => channel[option]?.value)
    }

    let chartData = {
        labels: topChannels.map((ch) => ch["name"]),
        series: chartDataSeries
    }
    

    const chartOptions: ApexOptions = {
        chart: {
            type: 'donut',
        },
        labels:chartData.labels,
        colors: chartData.labels.map(
            (label) => platformColors[(label||"")?.toLowerCase() as keyof typeof platformColors]
        ),
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "60px",
                    labels: {
                        
                        total: {
                            show: false
                        },
                        name: {
                            show: false,
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#000",
                        }
                    },
                    
                },
            }
        },
        tooltip:{
            y:{
            formatter: (val: number) => {
                let value = val?.toFixed(2)
                
                return functions.abvNum(value);
                },
            }
        },
        legend: {
          show: false,
          formatter: (val: string) => {
            let value = parseFloat(val)?.toFixed(2)
            // console.log(functions.abvNum(value))
            return functions.abvNum(value);
            },
        },
        stroke: {
            show: true,
            lineCap: 'round',
            width: 10,
            curve: "straight",
        },
    }

    return (
        <div className="col-xl-3 col-lg-3 bg-white p-4" style={{ borderRadius: 20, position: "relative" }}>                    
            <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap" style={{ marginBottom: "43px" }}>
                <h2 style={{ margin: 0, color: "#000", fontSize: "20px", fontWeight: 700, lineHeight: "28px" }}>
                    Top Channels
                </h2>

                <CustomSelect 
                    options={[
                        {label: "Impressions", value: "impressions"},
                        {label: "Engagement Rate", value: "engagementRate"}
                    ]}
                    value={option}
                    onChange={(e) => setOption(e.target.value as Option)}
                    containerStyle={{ gap: 0, padding: "12px", borderRadius: "16px" }}
                    textStyle={{ width: "100px"}}
                />
    
            </div> 

            {(error || data === null) ? (
                <ErrorBoundary error={error}/>
            ) : (
                <>
                    <Chart series={chartData?.series} labels={chartData?.labels} options={chartOptions} type="donut" />

                    <div className="row" style={{ flexGrow: 1, marginTop: "32px", rowGap: "32px", flexWrap:"wrap" }}>
                        {!noChannelData && topChannels.map((channel, index) => (
                            <ChannelData key={index} channel={channel} option={option} />
                        ))}
                    </div>
                </>
            )}

            
        </div>
    )
}
