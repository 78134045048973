import { toAbsoluteUrl } from "../../../helpers";

interface TileProps {
  avi: string;
  username: string;
  govtname: string;
}

function InfluencerTile({ avi, govtname, username }: TileProps) {
  return (
    <div className="d-flex flex-column justify-center mt-4">
      <>
        <div className=""
          style={{ width: 'auto', height: 'auto', maxHeight: '145px', maxWidth: '160px', borderRadius: '16px', objectFit: 'cover' }}>
          <img src={toAbsoluteUrl(`/media/cpg/${avi}.png`)} style={{ width: '90%', height: '90%' }} />
          <div className="mt-2">
            <span className="d-block text-black fw-bold fs-6">{govtname}</span>
            <span className="d-block text-mute">{username}</span>
          </div>
        </div>
      </>
    </div>
  )
}

export { InfluencerTile }
