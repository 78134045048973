import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { CustomSelect } from "../../../../../../_metronic/layout/components/CustomSelect"
import { TopCreatorsType } from "../../../../../../setup/types/report";
import { useAppSelector } from "../../../../../../setup/redux/hooks";
import * as functions from "../../../../../global/functions";

import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import Skeleton from "react-loading-skeleton";


const TableValueText = ({ text, priority }: { text: string, priority?: boolean }) => (
    <p style={{
        fontSize: "14px",
        fontWeight: priority ? 600 : 300,
        color: "#303030",
        textAlign:priority ? "left" : "right",
        margin: 0
    }}>{text}</p>
)

export const TopCreators = () => {
    const tableHeadings = ["Creator", "Earned Content", "EMV"];

    const { loading, error, data } = useAppSelector((state) => state.report.TopCreatorsRes);

    return (
        <div className="p-6 bg-white border__round_24 ">
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="fs-3 text-dark NeueMontreal-bold">EMV by Creator</h3>

                {/* <div className="d-flex justify-content-between align-items-center" style={{ gap: 10 }}>
                    <CustomSelect options={[{label: "All Post", value: "All Post"}]}/>
                    <CustomSelect options={[{label: "Total", value: "Total"}]}/>
                </div> */}
            </div>

            {loading ? <Skeleton height={70} count={5}/> : (error || data === null || data?.length === 0) ? (
                <ErrorBoundary error={error}/>
            ) : (
                <table className="table table-borderless mt-4 topCreator_table">
                <thead style={{ backgroundColor: "#F4F4FA", borderRadius: "6px" }}>
                    <tr>
                        {tableHeadings.map((heading, index) => {
                            if(heading === "Creator"){
                                return(
                                    <th scope="col" key={index} className="text__left">{heading}</th>
                                )
                            }
                            return (
                                <th scope="col" key={index} className="text__right">{heading}</th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>
                    {data && data?.map((item:TopCreatorsType, index:number) => (
                        <tr key={index} className="border__b-table topCreator__tr">
                            <th scope="row" style={{ marginLeft: "0", paddingLeft: "0px !important" }}>
                                <a href={item?.influencerLink  || "#"} target="_blank" 
                                rel="noreferrer" className="d-flex align-items-center">
                                    {/* <p style={{ fontSize: "16px", color: "#8C8C8C", fontWeight: 600, margin: "0 6px 0 0" }}>#{index + 1}</p> */}
                                    <img
                                        onError={(ev) => functions.addDefaultSrc(ev)}
                                        src={item?.LogoURL || toAbsoluteUrl("/media/cpg/na.png")}
                                        alt="hero-card"
                                        className="image-fluid d-block" 
                                        style={{ 
                                            width: '30px', 
                                            maxHeight: '30px', 
                                            resize: "both", 
                                            objectFit: "cover", 
                                            borderRadius: "10px",
                                            margin: "0 8px 0px 0px"
                                        }}
                                    /> 
                                    <TableValueText text={`${item?.name?.replace(/\\u[\dA-Fa-f]{4}/g, "") || ""}`} priority={true} />
                                </a>
                                </th>
                            <td><TableValueText text={`${item?.earnedPosts || "0"}`} /></td>
                            <td><TableValueText text={`$${functions.abvNum(item?.earnedMedia?.toFixed(2)||"0") || "0"}`} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
            
        </div>
    )
}