
import * as requestFromServer from "./Crud";
import { brandAtGlanceSlice, callTypes } from "./Slice";

import { sampleData } from "./sampledata";

const { actions } = brandAtGlanceSlice;


// export const fetchAllData = (type, queryParams) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.list }));
//   return requestFromServer
//     .findDatas(type, queryParams)
//     .then(response => {
//       var res = response.data;
//       if (type === 'brand_at_glance') {
//         dispatch(actions.brandAtGlanceDetails(res.success));
//         return;
//       }
//       return res;
//     })
//     .catch(error => {
//       error.clientMessage = "Can't find Datas";
//       dispatch(actions.catchError({ error, callType: callTypes.list }));
//     });
// };

export const fetchBrandSummary = (landscapeId, queryParams, queryParams2, is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchABrandSummary(landscapeId, queryParams, is_external)
    .then(response => {
      let res = response.data;
      // dispatch(actions.brandSummary(res.success));
      return requestFromServer
        .fetchABrandSummary(landscapeId, queryParams2, is_external)
        .then(response2 => {
          let res2 = response2.data;
          let resData={
            ...res.success,
            compareData:res2.success
          }
          dispatch(actions.brandSummary(resData));
        })
        .catch(error => {
          error.clientMessage = "Unable to fetch summary";
          // dispatch(actions.brandSummary(sampleData?.summaryData.success));
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch summary";
      // dispatch(actions.brandSummary(sampleData?.summaryData.success));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSocialPosts = (landscapeId, queryParams, queryParamsExc, is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchAllSocialPosts(landscapeId, queryParams, is_external)
    .then(response => {
      let res = response.data;
      // dispatch(actions.brandSocialPosts(res.success));
      // dispatch(actions.brandSocialPosts(sampleData?.allSocialPosts.success));

      return requestFromServer.fetchAllSocialPosts(landscapeId, queryParamsExc, is_external)
        .then(response2 => {
          let res2 = response2.data;
          dispatch(actions.brandSocialPosts({ ...res.success, competitorPosts: res2?.success }));
          // dispatch(actions.brandSocialPosts(sampleData?.allSocialPosts.success));
        })
        .catch(error => {
          error.clientMessage = "Unable to fetch posts";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
          // dispatch(actions.brandSocialPosts(sampleData?.allSocialPosts.success));
        });


    })
    .catch(error => {
      error.clientMessage = "Unable to fetch posts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      // dispatch(actions.brandSocialPosts(sampleData?.allSocialPosts.success));
    });
};

export const fetchSocialTimeSeries = (landscapeId, queryParams, is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchAllSocialTimeSeries(landscapeId, queryParams, is_external)
    .then(response => {
      let res = response.data;
      dispatch(actions.socialTimeSeries(res.success));
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch data";

      // dispatch(actions.socialTimeSeries(sampleData?.timeSeriesData.success));
      dispatch(actions.catchError({ error, callType: callTypes.list }));

    });
};

export const fetchCampaignCompanies = (landscapeId, is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchAllCampaignCompanies(landscapeId, is_external)
    .then(response => {
      let res = response.data;
      dispatch(actions.brandsList(res.success));
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch brands";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignOptimization = (commpanyId, is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchOptimizationPlan(commpanyId, is_external)
    .then(response => {
      let res = response.data;
      dispatch(actions.brandsOptimizationData(res.success));
    })
    .catch(error => {
      error.clientMessage = "Unable to fetch brand optimization data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createCampaignOptimization = (commpanyId, data,is_external) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .createOptimizationPlan(commpanyId, data,is_external)
    .then(response => {
      let res = response.data;
      dispatch(actions.updateBrandsOptimizationData(res.success));
    })
    .catch(error => {
      error.clientMessage = "Unable to update brand optimization data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const addCampaignStats = (commpanyId, data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOptimizationPlan(commpanyId, data)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const reset_info = () => dispatch => {
  dispatch(actions.reset());
};