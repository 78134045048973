import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { FallbackView } from '../../_metronic/partials'
import { CampaignDetails } from '../modules/campaigns/components/CampaignDetails'
import { CampaignSummary } from '../modules/campaigns/components/CampaignSummary'
import { InfluencerDetails } from '../modules/influencer/InfluencerDetails'
import { Campaigns } from '../modules/campaigns/components/Campaigns'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { InfluencerStats } from '../modules/influencer/InfluencerStats'
import { Insights } from '../modules/insights';
import { BrandAtGlance } from '../modules/social-listing/brand-at-glance';
import { BrandAtGlanceT } from '../modules/social-listing/brand-at-glance2';
// import { ProfilePage } from '../modules/profile/ProfilePage';
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'

export function PrivateRoutes() {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const CampainPage = lazy(() => import('../modules/campaigns/CampaignPage'));
  const InfluencerPage = lazy(() => import('../modules/influencer/InfluencerPage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  var userEmail = useSelector<any>(({ auth }) => auth?.user?.user?.email, shallowEqual);
  // melo@amyris.com
  // console.log("userEmail => ",userEmail)

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

          <Route path='/app/dashboard' component={DashboardWrapper} />
          {
            userEmail !== "melo@amyris.com" && (
                <Route path='/app/campaign/summary/:id' component={CampaignSummary} />
            )
          }
          {userEmail !== "melo@amyris.com" && (
            <Route path='/app/campaign/:id' component={DashboardWrapper} />
          )}
          {userEmail !== "melo@amyris.com" && (
            <Route path='/app/campaigns' component={Campaigns} />
          )}
          <Route path='/tv' component={DashboardWrapper} />
          
          <Route path='/app/influencers/:id/stats' component={InfluencerStats} />
          <Route path='/app/influencers/:id' component={InfluencerStats} />
          <Route path='/app/influencers' exact component={InfluencerPage} />
          <Route path='/app/insights' exact component={Insights} />
          <Route path='/app/social-listing/brand' exact component={BrandAtGlanceT} />
          <Route path='/app/social-intelligence/' exact component={BrandAtGlance} />
          <Route path='/profile' component={ProfilePage} />
          <Route path='/apps/chat' component={ChatPage} />
          <Route path='/menu-test' component={MenuTestPage} />
          <Redirect from='/auth' to='/app/campaigns' />
          <Redirect from='/dashboard' to='/app/dashboard' />
          {userEmail !== "melo@amyris.com" && (
            <Redirect exact from='/' to='/app/dashboard' />
          )}
          {userEmail === "melo@amyris.com" && (
            <Redirect exact from='/' to='/app/social-intelligence/' />
          )}
          <Redirect to='error/404' />
       
      </Switch>
    </Suspense>
  )
}

// export function SocialListingRoutes() {


//   return (
//     // <Suspense fallback={<FallbackView />}>
//     <Switch>

//       <Route path='/app/social-listing/brand' exact component={BrandAtGlance} />

//     </Switch>
//     // {/* </Suspense> */}
//   )
// }
