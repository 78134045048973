
const DisplayLineChart = (props: any) => {
    let { dataOne, dataTwo } = props;
    let dataOneCent: any = 0, dataTwoCent: any = 0;

    if (dataOne?.divider > 0 && dataOne?.divisor > 0) {
        dataOneCent = ((dataOne?.divider / dataOne?.divisor) * 100).toFixed(2);

        dataOneCent = dataOneCent < 1 ? dataOneCent : Math.round(dataOneCent)
    }

    if (dataTwo?.divider > 0 && dataTwo?.divisor > 0) {
        dataTwoCent = ((dataTwo?.divider / dataTwo?.divisor) * 100).toFixed(2);
        dataTwoCent = dataTwoCent < 1 ? dataTwoCent : Math.round(dataTwoCent)
    }

    if (dataOneCent > 0 && dataTwoCent > 0) {
        
        return (
            <div className="mapping_wrap">
                <div className="mapping_fill dark_1" style={{ width: `${dataOneCent}%` }}>{dataOneCent}%</div>
                <div className="mapping_fill dark_3" style={{ width: `${dataTwoCent}%` }}>{dataTwoCent}%</div>
            </div>
        )
    }

    if (dataOneCent > 0 && dataTwoCent == 0) {
        return (
            <div className="mapping_wrap">
                <div className="mapping_fill dark_1 full" style={{ width: `${dataOneCent}%` }}>{dataOneCent}%</div>
            </div>
        )
    }
    if (dataOneCent == 0 && dataTwoCent > 0) {
        return (
            <div className="mapping_wrap">
                <div className="mapping_fill dark_3 full" style={{ width: `${dataTwoCent}%` }}>{dataTwoCent}%</div>
            </div>
        )
    }
    return <div className="mapping_wrap empty">No ER(%) data</div>
}

const AllEngageMentFormats = (props: any) => {
    let { brandsSummaryInfo, chosenBrand, brandSocialPostsInfo,dateDiff } = props;

    

    let companyReelsPerday: any = 0, companyReelsEr: any = 0,
        companyIGPhotoPerday: any = 0, companyIGPhotoER: any = 0,
        companyIGVideoPerday: any = 0, companyIGVideoER: any = 0,
        companyIGCarouselPerday: any = 0, companyIGCarouselER: any = 0,
        companyYoutubeVideoPerday: any = 0, companyYoutubeVideoER: any = 0,
        companyFBPhotoPerday: any = 0, companyFBPhotoER: any = 0,
        companyFBVideoPerday: any = 0, companyFBVideoER: any = 0,
        companyTiktokVideoPerday: any = 0, companyTiktokVideoER: any = 0;


    let competitorReelsPerday: any = 0, competitorReelsEr: any = 0,
        competitorIGPhotoPerday: any = 0, competitorIGPhotoER: any = 0,
        competitorIGVideoPerday: any = 0, competitorIGVideoER: any = 0,
        competitorIGCarouselPerday: any = 0, competitorIGCarouselER: any = 0,
        competitorYoutubeVideoPerday: any = 0, competitorYoutubeVideoER: any = 0,
        competitorFBPhotoPerday: any = 0, competitorFBPhotoER: any = 0,
        competitorFBVideoPerday: any = 0, competitorFBVideoER: any = 0,
        competitorTiktokVideoPerday: any = 0, competitorTiktokVideoER: any = 0;



    let companyPosts: any[] = brandSocialPostsInfo?.socialPosts;
    let competitorsPosts: any[] = brandSocialPostsInfo?.competitorPosts?.socialPosts;

    for (let index = 0; index <= companyPosts.length; index++) {
        
        let eachItem = companyPosts[index];
        if (eachItem?.channel === "instagram") {
            if (eachItem?.type === "reel") {
                companyReelsPerday += 1;
                companyReelsEr += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "photo") {
                companyIGPhotoPerday += 1;
                companyIGPhotoER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "carousel") {
                companyIGCarouselPerday += 1;
                companyIGCarouselER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "video") {
                companyIGVideoPerday += 1;
                companyIGVideoER += eachItem?.engagementRate || 0;
            }
        }
        if (eachItem?.channel === "facebook") {
            if (eachItem?.type === "photo") {
                companyFBPhotoPerday += 1;
                companyFBPhotoER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "video") {
                companyFBVideoPerday += 1;
                companyFBVideoER += eachItem?.engagementRate || 0;
            }
        }

        if (eachItem?.channel === "youtube") {
            if (eachItem?.type === "video") {
                companyYoutubeVideoPerday += 1;
                companyYoutubeVideoER += eachItem?.engagementRate || 0;
            }
        }
        if (eachItem?.channel === "tiktok") {
            if (eachItem?.type === "video") {
                companyTiktokVideoPerday += 1;
                companyTiktokVideoER += eachItem?.engagementRate || 0;
            }
        }
    }

    
    companyReelsPerday = (companyReelsPerday > 0 && dateDiff>0)? (companyReelsPerday / dateDiff).toFixed(2) : 0;
    companyReelsEr = (companyReelsEr > 0 && companyPosts.length>0) ? ((companyReelsEr / companyPosts.length) * 100).toFixed(2) : 0;
    companyIGPhotoPerday = (companyIGPhotoPerday > 0 && dateDiff>0) ? (companyIGPhotoPerday / dateDiff).toFixed(2) : 0;
    companyIGPhotoER = (companyIGPhotoER > 0 && companyPosts.length) ? ((companyIGPhotoER / companyPosts.length) * 100).toFixed(2) : 0;
    companyIGCarouselPerday = (companyIGCarouselPerday > 0 && dateDiff>0) ? (companyIGCarouselPerday / dateDiff).toFixed(2) : 0;
    companyIGVideoPerday = (companyIGVideoPerday > 0 && dateDiff > 0) ? ((companyIGVideoPerday / dateDiff) * 100).toFixed(2) : 0;
    companyIGVideoER = (companyIGVideoER > 0 && companyPosts.length>0) ? ((companyIGVideoER / companyPosts.length) * 100).toFixed(2) : 0;
    companyIGCarouselER = (companyIGCarouselER > 0 && companyPosts.length>0) ? ((companyIGCarouselER / companyPosts.length) * 100).toFixed(2) : 0;
    companyYoutubeVideoPerday = (companyYoutubeVideoPerday > 0 && dateDiff>0) ? (companyYoutubeVideoPerday / dateDiff).toFixed(2) : 0;
    companyYoutubeVideoER = (companyYoutubeVideoER > 0 && companyPosts.length>0) ? ((companyYoutubeVideoER / companyPosts.length) * 100).toFixed(2) : 0;
    companyTiktokVideoPerday = (companyTiktokVideoPerday > 0 && dateDiff>0) ? (companyTiktokVideoPerday / dateDiff).toFixed(2) : 0;
    companyTiktokVideoER = (companyTiktokVideoER > 0 && companyPosts.length>0) ? ((companyTiktokVideoER / companyPosts.length) * 100).toFixed(2) : 0;
    
    companyFBPhotoPerday = (companyFBPhotoPerday > 0 && dateDiff>0) ? (companyFBPhotoPerday / dateDiff).toFixed(2) : 0;
    companyFBPhotoER = (companyFBPhotoER > 0 && companyPosts.length) ? ((companyFBPhotoER / companyPosts.length) * 100).toFixed(2) : 0;
    companyFBVideoPerday = (companyFBVideoPerday > 0 && dateDiff>0) ? (companyFBVideoPerday / dateDiff).toFixed(2) : 0;
    companyFBVideoER = (companyFBVideoER > 0 && companyPosts.length>0) ? ((companyFBVideoER / companyPosts.length) * 100).toFixed(2) : 0;


    for (let index = 0; index <= competitorsPosts.length; index++) {
        
        let eachItem = competitorsPosts[index];
        if (eachItem?.channel === "instagram") {
            if (eachItem?.type === "reel") {
                competitorReelsPerday += 1;
                competitorReelsEr += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "photo") {
                competitorIGPhotoPerday += 1;
                competitorIGPhotoER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "carousel") {
                competitorIGCarouselPerday += 1;
                competitorIGCarouselER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "video") {
                competitorIGVideoPerday += 1;
                competitorIGVideoER += eachItem?.engagementRate || 0;
            }
        }
        if (eachItem?.channel === "youtube") {
            if (eachItem?.type === "video") {
                competitorYoutubeVideoPerday += 1;
                competitorYoutubeVideoER += eachItem?.engagementRate || 0;
            }
        }
        if (eachItem?.channel === "tiktok") {
            if (eachItem?.type === "video") {
                competitorTiktokVideoPerday += 1;
                competitorTiktokVideoER += eachItem?.engagementRate || 0;
            }
        }

        if (eachItem?.channel === "facebook") {
            if (eachItem?.type === "photo") {
                competitorFBPhotoPerday += 1;
                competitorFBPhotoER += eachItem?.engagementRate || 0;
            }
            if (eachItem?.type === "video") {
                competitorFBVideoPerday += 1;
                competitorFBVideoER += eachItem?.engagementRate || 0;
            }
        }
    }

    

    competitorReelsPerday = (competitorReelsPerday>0 && dateDiff>0)? (competitorReelsPerday / dateDiff).toFixed(2) : 0;
    competitorReelsEr = (competitorReelsEr>0 && competitorsPosts.length>0)?  ((competitorReelsEr / competitorsPosts.length) * 100).toFixed(2) : 0;
    competitorIGPhotoPerday = (competitorIGPhotoPerday>0 && dateDiff>0)?  (competitorIGPhotoPerday / dateDiff).toFixed(2): 0;
    competitorIGPhotoER = (competitorIGPhotoER> 0 && competitorsPosts.length>0)?  ((competitorIGPhotoER / competitorsPosts.length) * 100).toFixed(2) : 0;
    competitorIGCarouselPerday = (competitorIGCarouselPerday>0 && dateDiff>0)?  (competitorIGCarouselPerday / dateDiff).toFixed(2) : 0;
    competitorIGVideoPerday = (competitorIGVideoPerday>0 && dateDiff>0)?  (competitorIGVideoPerday / dateDiff * 100).toFixed(2) : 0;
    competitorIGVideoER = (competitorIGVideoER>0 &&  competitorsPosts.length>0)?  ((competitorIGVideoER / competitorsPosts.length) * 100).toFixed(2) : 0;
    competitorIGCarouselER = (competitorIGCarouselER>0 && competitorsPosts.length>0)?  ((competitorIGCarouselER / competitorsPosts.length) * 100).toFixed(2): 0;
    // competitorTiktokVideoPerday = (competitorIGVideoPerday>0 && dateDiff>0)?  (competitorTiktokVideoPerday / competitorsPosts.length).toFixed(2): 0;
    competitorYoutubeVideoPerday = (competitorYoutubeVideoPerday>0 && dateDiff>0)?  ((competitorYoutubeVideoPerday / dateDiff) * 100).toFixed(2): 0;
    competitorYoutubeVideoER = (competitorYoutubeVideoER>0 && competitorsPosts.length>0)?  ((competitorYoutubeVideoER / competitorsPosts.length) * 100).toFixed(2): 0;
    competitorTiktokVideoPerday =  (competitorTiktokVideoPerday>0 && dateDiff>0)?  (competitorTiktokVideoPerday / dateDiff).toFixed(2): 0;
    competitorTiktokVideoER = (competitorTiktokVideoER>0 && competitorsPosts.length>0)?  ((competitorTiktokVideoER / competitorsPosts.length) * 100).toFixed(2): 0;

    competitorFBPhotoPerday = (competitorFBPhotoPerday>0 && dateDiff>0)?  (competitorFBPhotoPerday / dateDiff).toFixed(2): 0;
    competitorFBPhotoER = (competitorFBPhotoER> 0 && competitorsPosts.length>0)?  ((competitorFBPhotoER / competitorsPosts.length) * 100).toFixed(2) : 0;
    competitorFBVideoPerday =  (competitorFBVideoPerday>0 && dateDiff>0)?  (competitorFBVideoPerday / dateDiff).toFixed(2): 0;
    competitorFBVideoER = (competitorFBVideoER>0 && competitorsPosts.length>0)?  ((competitorFBVideoER / competitorsPosts.length) * 100).toFixed(2): 0;

    let totalIGReelsErPercent = Number(companyReelsEr) + Number(competitorReelsEr),
        totalIGPhotoErPercent = Number(companyIGPhotoER) + Number(competitorIGPhotoER),
        totalIGCarouselErPercent = Number(companyIGCarouselER) + Number(competitorIGCarouselER),
        totalIGVideoErPercent = Number(companyIGVideoER) + Number(competitorIGVideoER),
        totalYTVideoErPercent = Number(companyYoutubeVideoER) + Number(competitorYoutubeVideoER),
        totalTTVideoErPercent = Number(companyTiktokVideoER) + Number(competitorTiktokVideoER),

        totalFBPhotoErPercent = Number(companyFBPhotoER) + Number(competitorFBPhotoER),
        totalFBVideoErPercent = Number(companyFBVideoER) + Number(competitorFBVideoER);

    


    return (
        <div className="engagement_format_data" id="slide14">

            <table>
                <thead>
                    <tr>
                        <th className="format_data_heading"> <div className="head_txt">Post Type</div></th>
                        <th className="format_data_heading"> <div className="head_txt">{chosenBrand?.companyName} - Post/Day</div> </th>
                        <th className="format_data_heading"> <div className="head_txt">{chosenBrand?.companyName} - ER (%)</div></th>
                        <th className="format_data_heading"> <div className="head_txt"> Competitors - Post/Day</div></th>
                        <th className="format_data_heading">  <div className="head_txt">Competitors - ER (%)</div></th>
                        <th className="format_data_heading">
                            {/* <div className="head_txt">
                                {chosenBrand?.companyName} - ER (%)
                            </div> */}
                            <div className="line_legends">
                                <div className="each_one legend_1">
                                    {chosenBrand?.companyName} - ER (%)
                                    <span></span>
                                </div>
                                <div className="each_one legend_2">
                                    Competitors - ER (%)
                                    <span></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="each_format_data">
                        <td className="format_data_type">IG Reel</td>
                        <td className="format_data">{companyReelsPerday}/day </td>
                        <td className="format_data">{companyReelsEr}% </td>
                        <td className="format_data">{competitorReelsPerday}/day</td>
                        <td className="format_data">{competitorReelsEr}/%</td>
                        <td className="data_mapping">
                            {/* <div className="mapping_wrap">
                                <div className="mapping_fill dark_1" style={{ width: `${(companyReelsEr / totalIGReelsErPercent) * 100}%` }}>{(companyReelsEr / totalIGReelsErPercent) * 100}%</div>
                                <div className="mapping_fill dark_3" style={{ width: `${(competitorReelsEr / totalIGReelsErPercent) * 100}%` }}>{(competitorReelsEr / totalIGReelsErPercent) * 100}%</div>
                            </div> */}
                            <DisplayLineChart dataOne={{ divider: companyReelsEr, divisor: totalIGReelsErPercent }} dataTwo={{ divider: competitorReelsEr, divisor: totalIGReelsErPercent }} />
                        </td>

                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">IG Photo</td>
                        <td className="format_data">{companyIGPhotoPerday}/day </td>
                        <td className="format_data">{companyIGPhotoER}% </td>
                        <td className="format_data">{competitorIGPhotoPerday}/day</td>
                        <td className="format_data">{competitorIGPhotoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyIGPhotoER, divisor: totalIGPhotoErPercent }} dataTwo={{ divider: competitorIGPhotoER, divisor: totalIGPhotoErPercent }} />
                            {/* <div className="mapping_wrap">
                                <div className="mapping_fill dark_1" style={{ width: `${(companyIGPhotoER / totalIGPhotoErPercent) * 100}%` }}>{(companyIGPhotoER / totalIGPhotoErPercent) * 100}%</div>
                                <div className="mapping_fill dark_3" style={{ width: `${(competitorIGPhotoER / totalIGPhotoErPercent) * 100}%` }}>{(competitorIGPhotoER / totalIGPhotoErPercent) * 100}%</div>
                            </div> */}
                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">IG Carousel</td>
                        <td className="format_data">{companyIGCarouselPerday}/day </td>
                        <td className="format_data">{companyIGCarouselER}% </td>
                        <td className="format_data">{competitorIGCarouselPerday}/day</td>
                        <td className="format_data">{competitorIGCarouselER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyIGCarouselER, divisor: totalIGCarouselErPercent }} dataTwo={{ divider: competitorIGCarouselER, divisor: totalIGCarouselErPercent }} />
                            {/* <div className="mapping_wrap">
                                <div className="mapping_fill dark_1" style={{ width: `${(companyIGCarouselER / totalIGCarouselErPercent) * 100}%` }}>{(companyIGCarouselER / totalIGCarouselErPercent) * 100}%</div>
                                <div className="mapping_fill dark_3" style={{ width: `${(competitorIGCarouselER / totalIGCarouselErPercent) * 100}%` }}>{(competitorIGCarouselER / totalIGCarouselErPercent) * 100}%</div>
                            </div> */}
                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">IG Video</td>
                        <td className="format_data">{companyIGVideoPerday}/day </td>
                        <td className="format_data">{companyIGVideoER}%</td>
                        <td className="format_data">{competitorIGVideoPerday}/day</td>
                        <td className="format_data">{competitorIGVideoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyIGVideoER, divisor: totalIGVideoErPercent }} dataTwo={{ divider: competitorIGVideoER, divisor: totalIGVideoErPercent }} />

                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">YT Video</td>
                        <td className="format_data">{companyYoutubeVideoPerday}/day </td>
                        <td className="format_data">{companyYoutubeVideoER}%</td>
                        <td className="format_data">{competitorYoutubeVideoPerday}/day</td>
                        <td className="format_data">{competitorYoutubeVideoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyYoutubeVideoER, divisor: totalYTVideoErPercent }} dataTwo={{ divider: competitorYoutubeVideoER, divisor: totalYTVideoErPercent }} />
                            {/* <div className="mapping_wrap">
                                <div className="mapping_fill dark_1" style={{ width: `${(companyYoutubeVideoER / totalYTVideoErPercent) * 100}%` }}>{(companyYoutubeVideoER / totalYTVideoErPercent) * 100}%</div>
                                <div className="mapping_fill dark_3" style={{ width: `${(competitorYoutubeVideoER / totalYTVideoErPercent) * 100}%` }}>{(competitorYoutubeVideoER / totalYTVideoErPercent) * 100}%</div>
                            </div> */}
                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">TT Video</td>
                        <td className="format_data">{companyTiktokVideoPerday}/day </td>
                        <td className="format_data">{companyTiktokVideoER}%</td>
                        <td className="format_data">{competitorTiktokVideoPerday}/day</td>
                        <td className="format_data">{competitorTiktokVideoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyTiktokVideoER, divisor: totalTTVideoErPercent }} dataTwo={{ divider: competitorTiktokVideoER, divisor: totalTTVideoErPercent }} />
                            {/* <div className="mapping_wrap">
                                <div className="mapping_fill dark_1" style={{ width: `${(companyTiktokVideoER / totalTTVideoErPercent) * 100}%` }}>{(companyTiktokVideoER / totalTTVideoErPercent) * 100}%</div>
                                <div className="mapping_fill dark_3" style={{ width: `${(competitorTiktokVideoER / totalTTVideoErPercent) * 100}%` }}>{(competitorTiktokVideoER / totalTTVideoErPercent) * 100}%</div>
                            </div> */}
                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">FB Video</td>
                        <td className="format_data">{companyFBVideoPerday}/day </td>
                        <td className="format_data">{companyFBVideoER}%</td>
                        <td className="format_data">{competitorFBVideoPerday}/day</td>
                        <td className="format_data">{competitorFBVideoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyFBVideoER, divisor: totalFBVideoErPercent }} dataTwo={{ divider: competitorFBVideoER, divisor: totalFBVideoErPercent }} />
                            
                        </td>
                    </tr>
                    <tr className="each_format_data">
                        <td className="format_data_type">FB Photo</td>
                        <td className="format_data">{companyFBPhotoPerday}/day </td>
                        <td className="format_data">{companyFBPhotoER}%</td>
                        <td className="format_data">{competitorFBPhotoPerday}/day</td>
                        <td className="format_data">{competitorFBPhotoER}/%</td>
                        <td className="data_mapping">
                            <DisplayLineChart dataOne={{ divider: companyFBPhotoER, divisor: totalFBPhotoErPercent }} dataTwo={{ divider: competitorFBPhotoER, divisor: totalFBPhotoErPercent }} />
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}




export default function FormatEngagement(props: any) {
    let { brandsSummaryInfo, dateDiff, chosenBrand, brandSocialPostsInfo } = props;


    return (
        <div className="engagement_formats_wrap">
            <div className="engage_section_header">
                Format Engagement

            </div>
            {chosenBrand && <AllEngageMentFormats dateDiff={dateDiff} brandSocialPostsInfo={brandSocialPostsInfo} chosenBrand={chosenBrand} brandsSummaryInfo={brandsSummaryInfo} />}
        </div>
    )


}