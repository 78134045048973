import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../setup/redux/hooks";
import { getAttachmentPostRes, getAttachmentGetRes } from "../../../../../../../setup/features/report.slice";

import { toast } from 'react-toastify'
import {BsPlusCircle} from "react-icons/bs"


interface Props {
    handleClick?: () => void;
    bgWhite?:boolean;
    editState?:boolean;
    campaignId:string;
    isExternal?:boolean
}


export const AddAttachment = ({campaignId, isExternal}: Props) => {


    const [imageFile, setImageFile] = useState<File | null>(null);

    const { loading, error, data } = useAppSelector((state) => state.report.AttachmentPostRes);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (imageFile) {

            const formData = new FormData();
            formData.append('campaign_id', campaignId);
            formData.append('file', imageFile);
            formData.append('description' , "");
            formData.append('type', "attachment")
            

            const uploadImage = dispatch(getAttachmentPostRes({
                campaign_id: campaignId,
                file: formData
            }));

            toast.promise(
                uploadImage,
                {
                  pending: 'Uploading Attachment',
                  success: 'Attachment Uploaded',
                  error: 'Network error or file size is too large'
                }
            )

            uploadImage.then(res => dispatch(getAttachmentGetRes({
                campaign_id: campaignId, 
                is_external:isExternal
            })))
            setImageFile(null)

        }
    }, [imageFile])
    
    // useEffect(() => {
    //     if(!imageFile){
    //         dispatch(getAttachmentGetRes(campaignId));
    //     }

    // }, [imageFile])

    
    
    // dispatch(getAttachmentPostRes({
    //     campaign_id: campaignId,
        
    // }));
    const handleFile = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const selectedFile = e.target.files?.[0];
        setImageFile(selectedFile ? selectedFile : null);
    }
    return(
        <>
            <div  className="my-12" style={{
                display:"flex",
                flexDirection:"column",
                maxWidth:"200px",
                padding:"30px 0px 0 0px",
                height:"100%",  
            }}>
                <label htmlFor="upload-attachment">
                    <BsPlusCircle className="" style={{
                        width:"60px",
                        height:"60px",
                        cursor:"pointer"
                    }}/>
                </label>
                <input type="file" name="upload-attachment" 
                id="upload-attachment" className="" onChange={handleFile}  style={{
                    width:"1px",
                    height:"1px",
                    visibility:"hidden",
                }}/>
            </div>
            
        </>
    )
}