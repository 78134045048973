/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useEffect, useState, useCallback } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { BrandAtGlanceExternal } from '../modules/social-listing/external/brand-at-glance'
import { BrandAtGlanceExternalPdf } from '../modules/social-listing/external/brand-at-glance-pdf'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { CampaignSummary } from '../modules/campaigns/components/external-report/CampaignSummary'
import { CampaignSummaryPdf } from '../modules/campaigns/components/external-report/download/pdf/CampaignSummary'
import { RootState } from '../../setup'
import { useLocation } from 'react-router-dom'
import { login } from '../../app/modules/auth/redux/AuthCRUD'
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import config from '../global/config';

const Routes: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  var isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual);
  const [tv, set_tv] = useState<boolean>(false);

  function getData() {
    return login({ email: config.EMAIL, password: config.PASSWORD, type: 'admin' });
  }

  const fetchData = useCallback(async () => {
    const data = await getData();
    if (data.data) {
      dispatch(auth.actions.login(data.data.user_token));
      isAuthorized = true;
    }
  }, [])

  useEffect(() => {
    if (location.pathname === '/tv' && !isAuthorized) {
      set_tv(true);
      fetchData()
    }
  }, [fetchData]);



  return (
    <Switch>
      <Route exact path='/campaign-report/:id' component={CampaignSummary} />
      <Route exact path='/campaign-report/download/pdf/:id' component={CampaignSummaryPdf} />
      <Route path='/campaign-report-mgp-external/:id' component={CampaignSummary} />
      <Route exact path='/reports/social-intelligence/:id' component={BrandAtGlanceExternal} />
      <Route exact path='/reports/social-intelligence/download/pdf/:id' component={BrandAtGlanceExternalPdf} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/

        tv ?
          <Redirect from='/auth' to='/tv' />
          :
          <Redirect from='/auth' to='/' />

      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      
      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
      )}


     
    </Switch>
  )
}

export { Routes }
