import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { useState } from "react";
import moment from "moment";
import * as functions from "../../../../../../global/functions";
import { useAppDispatch} from "../../../../../../../setup/redux/hooks";
import { getAttachmentGetRes } from "../../../../../../../setup/features/report.slice";

import {SideDrawer} from "./SideDrawer"


interface Props {
    handleClick?: () => void;
    bgWhite?:boolean;
    editState?:boolean;
    item:any;
    isExternal?:boolean;
    campaignId:string;
}

const fileIcons = {
    pdfIcon: "/media/cpg/pdf-icon-copy.png",
    pngIcon: "/media/cpg/jpg-Icon.png",
    docIcon: "/media/cpg/doc-Icon.png",
    svgIcon: "/media/cpg/svg-Icon.png",
    aviIcon: "/media/cpg/avi-Icon.png",
    aiIcon: "/media/cpg/ai-Icon.png",
    gifIcon: "/media/cpg/gif-Icon.png",
    zipIcon: "/media/cpg/zip-Icon.png",
    xlsIcon: "/media/cpg/xls-Icon.png",
    mp3Icon: "/media/cpg/mp3-Icon.png",
    mkvIcon: "/media/cpg/mkv-Icon.png",
    jpgIcon: "/media/cpg/jpg-Icon.png",
    txtIcon: "/media/cpg/txt-Icon.png",
    psdIcon: "/media/cpg/psd-Icon.png",
    pptIcon: "/media/cpg/pdf-Icon.png",
    linkIcon: "/media/cpg/attachment-link-icon.svg",

}

const fileIconHandler = (ext:string|null) => {
    if(ext === "pdf") return fileIcons.pdfIcon;
    if(ext === "png") return fileIcons.pngIcon;
    if(ext === "doc" || ext === "docx") return fileIcons.docIcon;
    if(ext === "svg") return fileIcons.svgIcon;
    if(ext === "gif") return fileIcons.gifIcon;
    if(ext === "mkv") return fileIcons.mkvIcon;
    if(ext === "xls") return fileIcons.xlsIcon;
    if(ext === "ai") return fileIcons.aiIcon;
    if(ext === "avi") return fileIcons.aviIcon;
    if(ext === "zip") return fileIcons.zipIcon;
    if(ext === "jpg" ||  ext === "jpeg") return fileIcons.jpgIcon;
    if(ext === "txt") return fileIcons.txtIcon;
    if(ext === "psd") return fileIcons.psdIcon;
    if(ext === "ppt") return fileIcons.pptIcon;
    
    return fileIcons.linkIcon
}

export const AttachmentFile = ({handleClick, bgWhite, editState, item, campaignId, isExternal}: Props) => {

    const [show, setShow] = useState(false);

    const [showImage, setShowimage] = useState(true)

    const dispatch = useAppDispatch()

    const handleClose = () => {
        setShow(false)
        dispatch(getAttachmentGetRes({
            campaign_id: campaignId, 
            is_external:isExternal
        }))
    };
    const handleShow = () => setShow(true);

    let imageLink = item.url ? item.url : item.link

    return(
        <>
            <div onClick={handleShow} className="my-12" style={{
                display:"flex",
                flexDirection:"column",
                borderRadius:"30px",
                backgroundColor:"white",
                minWidth:"200px",
                maxWidth:"250px",
                padding:"0px 0px 0 0px",
                cursor:"pointer"
                
            }}>
                {
                    showImage ? (
                        <img 
                        onError={(ev) => {
                            setShowimage(false)
                        }}
                        src={toAbsoluteUrl(imageLink)} 
                        alt="file" 
                        className="image-container"
                        style={{width:"100%", objectFit: 'cover', height:"150px", 
                        marginBottom:"20px",padding:"0px 0px 0 0px", borderRadius:"30px 30px 0 0"}}/>
                    ) : (
                        <img 
                        onError={(ev) => {
                            functions.addDefaultSrc(ev, toAbsoluteUrl(fileIconHandler(item?.format)) )
                        }}
                        src={toAbsoluteUrl(fileIconHandler(item?.format))} 
                        alt="file" 
                        style={{width:"100px", height:"60px",marginBottom:"70px",marginTop:"40px",
                        padding:"0px 20px 0 25px", borderRadius:"30px 30px 0 0"}}/>
                    )
                }

                <div style={{
                    marginBottom:"0.4rem",
                    padding:"0px 20px 0 25px"
                }}>
                    <h3 className="NeueMontreal-bold" 
                    style={{width:"100%", textOverflow:"ellipsis",overflow: "hidden", 
                    maxWidth: "200px", whiteSpace: "nowrap" }}>{item?.originalName || "Link attached"}</h3>
                    <p className="text__16 text_gray font__600">{item?.createdAt ? moment(item?.createdAt || "Today, 11:00PM").format("ddd, h:mmA") : "No date"}</p>
                </div>

                <div className="attachment__p" style={{
                    padding:"0.5rem",
                    backgroundColor:"#E8E8FF",
                    
                }}>
                    <p 
                    className="text__black text__20 font__600 pl-2" 
                    style={{paddingLeft:"20px", overflow: "hidden", textOverflow:"ellipsis", 
                    whiteSpace: "nowrap", maxWidth: "200px"}}>
                        {item?.size ? functions.formatFileSize(item?.size || 0) : item?.link ? item?.link : ""}
                    </p>
                </div>
            </div>
            
            <SideDrawer show={show} isExternal={isExternal} handleClose={handleClose} handleShow={handleShow} item={item} />
        </>
    )
}