import { motion } from 'framer-motion';

type ProgressBarProps = {
    bgColor: "#F4F4FA",
    fillColor: string;
    containerWidth?: string;
    fillWidth: string;
}

const progressVariants = {

    visible: { 
      transition: { delay: 0.5, duration: 0.5 }
    },
    exit: {
      transition: { ease: 'easeInOut' }
    }
  };

export const CustomProgressBar = (
    {bgColor, containerWidth, fillColor, fillWidth,}: ProgressBarProps) => {

    return (
        <motion.div
            style={{
                backgroundColor: bgColor,
                width: containerWidth || "100%",
                height: 10,
                borderRadius: 16
            }}
        >
            <motion.div 
                variants={progressVariants}
                animate="visible"
                style={{
                    width: fillWidth || "0",
                    background: fillColor || "#888686",
                    height: "inherit",
                    borderRadius: "inherit"
                }}
            />
        </motion.div>
    )
}