import React from 'react'

import { toAbsoluteUrl } from '../../../../../_metronic/helpers'


export default function SummaryHeadStats(props: any) {
    let { brandsSummaryInfo } = props;

    let brandMetrics: any[] = brandsSummaryInfo[0]?.metrics
    
    let formattedMetrics = brandMetrics.map((chosenBrand: any) => {
        let totalPosts = (chosenBrand?.instagramPosts || 0) + (chosenBrand?.twitterTweets || 0) + (chosenBrand?.facebookPosts || 0) + (chosenBrand?.youTubePosts || 0) + (chosenBrand?.tikTokPosts || 0);
        let totalPostsPerDay = (chosenBrand?.twitterAverageTweetsPerDay || 0) + (chosenBrand?.tikTokAvgPostsPerDay || 0) + (chosenBrand?.youTubeAveragePostsPerDay || 0) + (chosenBrand?.instagramAveragePostsPerDay || 0) + (chosenBrand?.facebookAveragePostsPerDay || 0);
        let totalFollowers = (chosenBrand?.tikTokFollowers || 0) + (chosenBrand?.instagramFollowedBy || 0) + (chosenBrand?.facebookPageLikes || 0) + (chosenBrand?.youTubeSubscribers || 0);
        let totalVideoViews = (chosenBrand?.twitterVideoViews || 0) + (chosenBrand?.reelviews || 0) + (chosenBrand?.youTubePostViews || 0) + (chosenBrand?.tikTokViews || 0);
        let totalEngagement = (chosenBrand?.twitterTweetEngagementTotal || 0) + (chosenBrand?.youTubePostsEngagementTotal || 0) + (chosenBrand?.tikTokPostsEngagementTotal || 0) + (chosenBrand?.facebookPostEngagementTotal || 0) + (chosenBrand?.instagramPostsEngagementTotal || 0);
        let totalEngagementPercent =totalPosts>0? Math.floor((( ((chosenBrand?.youTubePostsEngagementTotal/chosenBrand?.youTubeSubscribers) || 0) + ((chosenBrand?.tikTokPostsEngagementTotal/chosenBrand?.tikTokFollowers) || 0) + ((chosenBrand?.instagramPostsEngagementTotal/chosenBrand?.instagramFollowedBy) || 0))/totalPosts)) : 0;
        // let totalEngagementPercent = Math.floor(((chosenBrand?.twitterAverageEngagementRatePerTweet || 0) + (chosenBrand?.instagramAverageEngagementRatePerPost || 0) + (chosenBrand?.facebookAverageEngagementRatePerPost || 0) + (chosenBrand?.youTubeAverageEngagementRatePerPost || 0)) * 100);

        let newBrandData = {
            ...chosenBrand,
            totalPosts,
            totalFollowers,
            totalVideoViews,
            totalEngagement,
            totalPostsPerDay,
            totalEngagementPercent
        }
        return newBrandData;
    })

  
    
    let highestFollowers = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalFollowers > current.totalFollowers ? prev : current;
    });
    let highestEngagement = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalEngagement > current.totalEngagement ? prev : current;
    });
    let highestEngagementRate = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalEngagementPercent > current.totalEngagementPercent ? prev : current;
    });
    let highestPostsPerDay = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalPostsPerDay > current.totalPostsPerDay ? prev : current;
    });
    let highestPosts = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalPosts > current.totalPosts ? prev : current;
    });
    let highestVideoViews = formattedMetrics.reduce((prev: any, current: any) => {

        return prev.totalVideoViews > current.totalVideoViews ? prev : current;
    });
    
    return (
        <div className="summary_head for_benchmark" id="slide6">
            <div className="each_summary_heading">
                <div className="summary_label">Highest Followers</div>
                <div className="summary_value_txt with_img">
                    {highestFollowers?.companyName}
                    
                </div>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Highest Engagement</div>
                <div className="summary_value_txt with_img">
                    {highestEngagement?.companyName}
                    
                </div>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Highest Engagement Rate (%)</div>
                <div className="summary_value_txt with_img">
                    {highestEngagementRate?.companyName}
                    
                </div>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Highest Posts per Day</div>
                <div className="summary_value_txt with_img">
                    {highestPostsPerDay?.companyName}
                    
                </div>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Highest Total Posts</div>
                <div className="summary_value_txt with_img">
                    {highestPosts?.companyName}
                    
                </div>
            </div>
            <div className="each_summary_heading">
                <div className="summary_label">Highest Video Views</div>
                <div className="summary_value_txt with_img">
                    {highestVideoViews?.companyName}
                    
                </div>
            </div>
        </div>
    )
}