import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  brandAtGlance: null,
  brandsListInfo:null,
  brandsOptimizationInfoData:null,
  updateBrandsOptimization:null,
  brandsSummaryInfo:null,
  socialTimeSeriesInfo:null,
  brandSocialPostsInfo:null,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const brandAtGlanceSlice = createSlice({
  name: "brandAtGlance",
  initialState: initialdatasState,
  reducers:{
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      // state.error = null;
      state = initialdatasState;
  
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    brandAtGlanceDetails: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.brandAtGlance = action.payload;
    },
    brandsList: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.brandsListInfo = action.payload;
    },
    brandsOptimizationData: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.brandsOptimizationInfoData = action.payload;
    },
    updateBrandsOptimizationData: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.updateBrandsOptimization = action.payload;
    },
    brandSummary: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.brandsSummaryInfo = action.payload;
    },
    socialTimeSeries: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.socialTimeSeriesInfo = action.payload;
    },
    brandSocialPosts: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.brandSocialPostsInfo = action.payload;
    },
  }
})