import { ApexOptions } from "apexcharts";
import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppSelector } from "../../../../../../setup/redux/hooks";
// import { getGraphRes } from "../../../../../../setup/features/report.slice";
import Skeleton from "react-loading-skeleton";
import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import * as functions from "../../../../../global/functions";



export const AreaChart = memo(({ campaignId, isExternal, option, tab}: { campaignId: string, isExternal?:boolean, option:string, tab:string }) => {

  const { loading, error, data } = useAppSelector((state) => state.report.GraphRes);

  const options: ApexOptions = {
      chart: {
        toolbar: {
          show: false
        }
      },
     xaxis: { 
      categories: data && data[tab.toLowerCase()] ? data[tab.toLowerCase()].map((d:any, index:number) => (d.week) || index) : [],
      title: {
        text: 'weeks'
      }
    }, 
     dataLabels: { enabled: false, }, 
    //  markers: {
    //   size: 0.1,
    //   colors: ["#fff"],
    //   discrete: [{
    //     seriesIndex: 0,
    //     dataPointIndex: 1,        
    //     fillColor: "#FFF",
    //     strokeColor: "#FEA201",
    //     size: 8
    //   }
    //   ]
    // },
    markers: {
      size: 8,
      offsetY: -5,
      colors: ["#fff", "#008FFB"],
      strokeColors: "#FEA201",
      strokeWidth: 3,
      hover: {
        size: 8,
      }
    },
    colors: ["#FEA201"],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "vertical",
        shadeIntensity: 0.4,
        gradientToColors: undefined,
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [20, 90, 100],
      }
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return functions.abvNum(val);
        }
      },
    },
    grid: {
      // show: false,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
  
  }; 

  let series = [ { 
    name: option.charAt(0).toUpperCase() + option.slice(1), 
    data: data && data[tab.toLowerCase()] ? data[tab.toLowerCase()].map((d:any) => d[option] || 0) : [] 
  }];

  return (
    <div id="area_chart1">

      {loading &&  <Skeleton height={120} count={1}/>}
      {!loading && (error) ? (
            <ErrorBoundary error={error} />
        ): 
        !loading && data && (
          <>
            
            <ReactApexChart options={options} series={series} type="area" />
          </>
        )}
    </div>
  )

})