import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { getCampaignDetails } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";

import { CmpDetailData } from "../../../../../../setup/types/report";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { DynamicBannerContent } from "./DynamicBannerContent";
import { StaticImagesBanner } from "./StaticImagesBanner";




const placeholderData: CmpDetailData = {
    brandName: "Stripes",
    campaignName: "Brazil Launch Q4/2022 W/C 23rd January 2023",
    campaignDescription: "",
    images: [
        {
            "url": toAbsoluteUrl("/media/cpg/reportImg4.png"),
            "socialNetwork": "Tiktok",
            "username": "rosaline gretzberg"
        },
        {
            "url": toAbsoluteUrl("/media/cpg/reportImg4.png"),
            "socialNetwork": "Instagram",
            "username": "rosaline gretzberg"
        },
        {
            "url": toAbsoluteUrl("/media/cpg/reportImg4.png"),
            "socialNetwork": "Tiktok",
            "username": "rosaline gretzberg"
        },
        {
            "url": toAbsoluteUrl("/media/cpg/reportImg4.png"),
            "socialNetwork": "Tiktok",
            "username": "rosaline gretzberg"
        }
    ]
}

interface Params {
    startDate: string | null,
    endDate: string | null
}

export const ReportBannerSection = (
    { campaignId, handleScrollToView, thumbnail, isExternal, isPdfPage }:
        {
            campaignId: string, handleScrollToView: () => void, thumbnail: string, isExternal?: boolean, isPdfPage?: boolean
        }) => {
    const dispatch = useAppDispatch();
    const { loading, error, data } = useAppSelector((state) => state.report.campaignDetail);

    useEffect(() => {

        if (campaignId) {

            // dispatch(getCampaignDetails({campaign_id:campaignId, is_external:isExternal }));
            localStorage.setItem('brandName', data?.brandName || "");
            dispatch(getCampaignDetails({ campaign_id: campaignId, is_external: isExternal, date_range: "7" }));
            localStorage.setItem('brandName', data?.brandName || "")
        }
    }, [campaignId]);


    if (loading) {
        return (
            <div className="container mb-6 mt-10 p-0">
                <Skeleton count={1} height={"100px"} />
                <Skeleton count={1} height={"400px"} />
            </div>
        )
    }

    if (data === null) {
        return (

            <StaticImagesBanner campaignDetail={{
                brandName: "",
                campaignId: campaignId,
                campaignName: "Campaign Not Available",
                thumbnail
            }}
            />

        )
    }

    if (data.images === undefined || data.images?.length === 0) {
        return (
            <div className={isPdfPage ? "pdf-wrap" : ""}>
                <StaticImagesBanner
                    isPdfPage={isPdfPage}
                    campaignDetail={{
                        brandName: data.brandName,
                        campaignId: campaignId,
                        campaignName: data.campaignName,
                        thumbnail
                    }}
                />
            </div>
        )
    }

    return (
        <div className={isPdfPage ? "pdf-wrap" : ""}>
            <DynamicBannerContent
                data={data}
                isPdfPage={isPdfPage}
                campaignId={campaignId}
                handleScrollToView={handleScrollToView}
                thumbnail={thumbnail}
                isExternal={isExternal ? isExternal : true}
            />
        </div>
    )
}