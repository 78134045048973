import { roundToDecimal } from "../../../../../helper";
import { FormKeyValues, StatsFormField } from "../../types";


export function divisionCalc(param1: number, param2: number, multiplier: number = 1): number {
    return param2 ? (roundToDecimal((param1 / param2) * multiplier)) : param1;
}

export function calcTotalEngagement(formKeyValues: FormKeyValues): number {
    const likesValue = +formKeyValues["likes"]?.value ?? 0;
    const commentsValue = +formKeyValues["comments"]?.value ?? 0;
    const savesValue = +formKeyValues["saves"]?.value ?? 0;
    const stickerTapsValue = +formKeyValues["stickerTaps"]?.value ?? 0;
    const sharesValue = +formKeyValues["shares"]?.value ?? 0;

    return likesValue + commentsValue + savesValue + stickerTapsValue + sharesValue;
}

export function calcCTR(formKeyValues: FormKeyValues): number {
    if(formKeyValues["costPerDeliverable"]){
        const linkClicksValue = +formKeyValues["linkClicks"]?.value ?? 0;
        const impressionsValue = +formKeyValues["impressions"]?.value ?? 0;

        return divisionCalc(linkClicksValue, impressionsValue, 100)
    }
    const linkClicksValue = +formKeyValues["linkClicks"]?.value ?? 0;
    const impressionsValue = +formKeyValues["impressions"]?.value ?? 0;

    return divisionCalc(linkClicksValue, impressionsValue)
}

export function calcER(formKeyValues: FormKeyValues): number {
    if(formKeyValues["costPerDeliverable"]){
        const totalEngagementValue = +formKeyValues["totalEngagements"]?.value ?? 0;
        const reachValue = +formKeyValues["reach"]?.value ?? 0;

        return divisionCalc(totalEngagementValue, reachValue, 100);
    }
    
    const postEngagementsValue = +formKeyValues["postEngagements"]?.value ?? 0;
    const impressionsValue = +formKeyValues["impressions"]?.value ?? 0;

    return divisionCalc(postEngagementsValue, impressionsValue, 100);
    
}

export function calcRetentionRate(formKeyValues: FormKeyValues): number {
    return 0;
}

export function calcReachRate(formKeyValues: FormKeyValues): number {
    const reachValue = +formKeyValues["reach"]?.value ?? 0;
    const followersValue = +formKeyValues["followers"]?.value ?? 0;

    return divisionCalc(reachValue, followersValue, 100);
}

export function calcCPM(formKeyValues: FormKeyValues): number {
    if(formKeyValues["costPerDeliverable"]){
        const costPerDeliverableValue = +formKeyValues["costPerDeliverable"]?.value ?? 0;
        const impressionsValue = +formKeyValues["impressions"]?.value ?? 0;

        return divisionCalc(costPerDeliverableValue, impressionsValue, 100);
    }
    const totalCostValue = +formKeyValues["totalCost"]?.value ?? 0;
    const impressionsValue = +formKeyValues["impressions"]?.value ?? 0;

    return divisionCalc(totalCostValue, impressionsValue, 100);
}

export function calcCPC(formKeyValues: FormKeyValues): number {
    if(formKeyValues["costPerDeliverable"]){
        const costPerDeliverableValue = +formKeyValues["costPerDeliverable"]?.value ?? 0;
        const linkClicksValue = +formKeyValues["linkClicks"]?.value ?? 0;

        return divisionCalc(costPerDeliverableValue, linkClicksValue);
    }
    const totalCostValue = +formKeyValues["totalCost"]?.value ?? 0;
    const linkClicksValue = +formKeyValues["linkClicks"]?.value ?? 0;

    return divisionCalc(totalCostValue, linkClicksValue);
}

export function calcCPV(formKeyValues: FormKeyValues): number {
    const costPerDeliverableValue = +formKeyValues["costPerDeliverable"]?.value ?? 0;
    const videoViewsValue = +formKeyValues["views"]?.value ?? 0;

    return divisionCalc(costPerDeliverableValue, videoViewsValue); 
}
 
export function calcCPU(formKeyValues: FormKeyValues): number {
    const costPerDeliverableValue = +formKeyValues["costPerDeliverable"]?.value ?? 0;
    const reachValue = +formKeyValues["reach"]?.value ?? 0;

    return divisionCalc(costPerDeliverableValue, reachValue);
}

export function calcCPE(formKeyValues: FormKeyValues): number {
    if(formKeyValues["costPerDeliverable"]){
        const costPerDeliverableValue = +formKeyValues["costPerDeliverable"]?.value ?? 0;
        const totalEngagementsValue = +formKeyValues["totalEngagements"]?.value ?? 0;

        return divisionCalc(costPerDeliverableValue, totalEngagementsValue);
    }
    
    const totalCostValue = +formKeyValues["totalCost"]?.value ?? 0;
    const postEngagementsValue = +formKeyValues["postEngagements"]?.value ?? 0;

    return divisionCalc(totalCostValue, postEngagementsValue);
}

export function calcAttributedCPA(formKeyValues: FormKeyValues): number {
    const totalCostValue = +formKeyValues["totalCost"]?.value ?? 0;
    const attributedPurchasesValue = +formKeyValues["attributedPurchases"]?.value ?? 0;

    return divisionCalc(totalCostValue, attributedPurchasesValue);
}

export function calcAttributedROAS(formKeyValues: FormKeyValues): number {
    const attributedPurchaseConversionValue = +formKeyValues["attributedPurchaseConversion"]?.value ?? 0;
    const totalCostValue = +formKeyValues["totalCost"]?.value ?? 0;

    return divisionCalc(attributedPurchaseConversionValue, totalCostValue);
}

export function calcAttributedCVR(formKeyValues: FormKeyValues): number {
    const attributedPurchaseConversionValue = +formKeyValues["attributedPurchaseConversion"]?.value ?? 0;
    const linkClicksValue = +formKeyValues["linkClicks"]?.value ?? 0;

    return divisionCalc(attributedPurchaseConversionValue, linkClicksValue);
}

export function linkClickCPA(formKeyValues: FormKeyValues): number {
    const cpcValue = +formKeyValues["cpc"]?.value ?? 0;
    const linkClickPurchasesValue = +formKeyValues["linkClickPurchases"]?.value ?? 0;

    return divisionCalc(cpcValue, linkClickPurchasesValue);
}

export function linkClickROAS(formKeyValues: FormKeyValues): number {
    const cpcValue = +formKeyValues["cpc"]?.value ?? 0;
    const linkClickPCVValue = +formKeyValues["linkClickPCV"]?.value ?? 0;

    return divisionCalc(linkClickPCVValue , cpcValue);
}

export function linkClickCVR(formKeyValues: FormKeyValues): number {

    const linkClickPurchasesValue = +formKeyValues["linkClickPurchases"]?.value ?? 0;
    const cpeValue = +formKeyValues["cpe"]?.value ?? 0;

    return divisionCalc(linkClickPurchasesValue , cpeValue);
}

export function weeklyControlFn(formKeyValues: FormKeyValues): string {

    const weekValue = formKeyValues["week"]?.value ?? 0;
    const brandValue = formKeyValues["brand"]?.value ?? "";
    const objectiveValue = formKeyValues["objective"]?.value ?? "";
    let result = `${weekValue.toString()}${brandValue.toString()}${objectiveValue.toString()}`
    return result
}

export function monthlyControlFn(formKeyValues: FormKeyValues): string {

    const monthValue = formKeyValues["month"]?.value ?? "";
    const brandValue = formKeyValues["brand"]?.value ?? "";
    const campaignValue = formKeyValues["campaign"]?.value ?? "";
    let result = `${monthValue.toString()}${brandValue.toString()}${campaignValue.toString()}`
    return result
}

export function updateField(fieldKey: string, value: number, formikValues: FormKeyValues): StatsFormField {
    return {...formikValues[fieldKey], value };
}

export function updateStringField(fieldKey: string, value: string, formikValues: FormKeyValues): StatsFormField {
    return {...formikValues[fieldKey], value };
}