import { campaignEfficiencyDataState } from './../types/cmpgnEfficiency';

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


import { influencerEfficiencyState } from "../types/cmpgnEfficiency";

const API_URL = process.env.REACT_APP_API_URL;

const campaignState = { data: null, loading: null, error: null } as campaignEfficiencyDataState;

const influencerState = { data: null, loading: null, error: null } as influencerEfficiencyState;


type idParams = {
    campaign_id: string;
    is_external?: boolean
    date_range: string
}


export const getCampaignEfficiency = createAsyncThunk('report/campaign-efficiency',
    async ({ campaign_id, is_external, date_range }: idParams) => {
        const response = await axios.get(`${API_URL}api/new-reports/stats/campaign-efficiency${is_external ? '/client' : ''}?campaign_id=${campaign_id}&range=${date_range}`)
        return response.data['data']
    })

export const getInfluencerEfficiency = createAsyncThunk('report/influencer-efficiency',
    async ({ campaign_id, is_external, date_range }: idParams) => {
        let result;
        if (is_external) {
            result = await axios.get(`${API_URL}api/new-reports/stats/influencer-efficiency/client?campaign_id=${campaign_id}&range=${date_range}`);
        } else {
            result = await axios.get(`${API_URL}api/new-reports/stats/influencer-efficiency?campaign_id=${campaign_id}&range=${date_range}`);
        }
        return result.data['data'];
    })


export const campaignEfficiencySlice = createSlice({
    name: 'campaignEfficiency',
    initialState: campaignState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getCampaignEfficiency.pending, (state) => {
            state.loading = true;
            state.error = null;
        })

        builder.addCase(getCampaignEfficiency.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;

        })

        builder.addCase(getCampaignEfficiency.rejected, (state, action) => {
            state.data = null;
            state.error = action.error.message || "Error retrieving efficiency data";
            state.loading = false;
        })

    }
})

export const influencerEfficiencySlice = createSlice({
    name: 'influencerEfficiency',
    initialState: influencerState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getInfluencerEfficiency.pending, (state) => {
            state.loading = true;
        })

        builder.addCase(getInfluencerEfficiency.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        })

        builder.addCase(getInfluencerEfficiency.rejected, (state) => {
            state.error = "Error retrieving efficiency data";
            state.loading = false;
        })
    }
})
