import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Dropdown } from 'react-bootstrap';
import { useAppDispatch} from "../../../../../setup/redux/hooks";

import axios from 'axios';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as actions from "../../_redux/campaign/Actions";

import { useState, useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import { ImageCard } from "./ImageCard";

interface MyDropdownProps {
  handleClick?: () => void;
  campaignId: string;
  dataType:string;
  type:string;
}

const API_URL = process.env.REACT_APP_API_URL;



const organicOptions = [
    // { value: 'estimatedDeliveryOrganic', label: 'Estimated Delivery', img: 'overall' },
    { value: 'fbIgOrganic', label: 'Facebook | Instagram', img: 'facebook' },
    { value: 'tiktokOrganic', label: 'TikTok', img: 'sm-tiktok' },
    { value: 'youtubeOrganic', label: 'Youtube', img: 'sm-yt' },
    // { value: 'actualDeliveryOrganic', label: 'Actual Delivery', img: 'overall' },
  ]
  
const paidOptions = [
    // { value: 'estimatedDeliveryPaid', label: 'Estimated Delivery', img: 'overall' },
    { value: 'fbIgPaid', label: 'Facebook | Instagram', img: 'facebook' },
    { value: 'tiktokPaid', label: 'TikTok', img: 'sm-tiktok' },
    { value: 'youtubePaid', label: 'Youtube', img: 'sm-yt' },
    // { value: 'actualDeliveryPaid', label: 'Actual Delivery', img: 'overall' },
  ]

  const viewOptions = [
    { value: 'organic', label: 'Organic' },
    { value: 'paid', label: 'Paid'},
  ]

  interface MyFormValues {
    dataTypeOption: string;
    typeOption: string;
    attachmentOption: string;
    importOption: string;
    csv: File[];
    excelUrl: string;
    persist:boolean;
    
  }
  
  const initialValues: MyFormValues = {
    dataTypeOption: '',
    typeOption:'',
    attachmentOption: '',
    importOption: '',
    csv:[],
    excelUrl:'',
    persist:false,
  };

  const validationSchema = Yup.object({
    dataTypeOption: Yup.string().required('Please select an option'),
    typeOption: Yup.string().required('Please select an option'),
    // csv: Yup.array().min(1, 'Please drop a CSV file').required('Please drop a CSV file'),
  });

export const UploadData: React.FC<MyDropdownProps> = ({campaignId }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [optionList, setOptionList] = useState(organicOptions)
    // const [inputType, setInputType] = useState("file");



    const dispatch = useAppDispatch()


    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen)
      };

    // useEffect(() =>{
    //     if(dropdownOpen === false){
    //         formik.resetForm()
    //     }
    // },[dropdownOpen])
    
    const handleSubmit = async(values:any) => {
        
        const formData = new FormData()

        formData.append("campaign_id", campaignId)
        formData.append("files", values.csv[0])
        formData.append("type", values.typeOption)
        formData.append("data_type", values.dataTypeOption)
        formData.append("url", values.excelUrl)
        formData.append("importStyle", values.importOption)
        formData.append("persist", values.persist)


        if(values.attachmentOption === "file"){
            return await toast.promise(axios.post(`${API_URL}api/new-reports/csv?`,formData),{
                pending: 'Sending file',
                success: `file sent successfully!`,
                error: `Error sending file: try again later`
            }).then(res => {
                dispatch(actions.fetchCampaignStats(campaignId));
                dispatch(actions.fetchCampaignStatsAll(campaignId, values.typeOption, values.dataTypeOption));
                formik.resetForm()
                handleDropdownToggle()
                
            })
        }
        
        
        if(values.attachmentOption === "link"){
            return await toast.promise(axios.post(`${API_URL}api/new-reports/excel?`,formData),{
                pending: 'Sending link',
                success: `link sent successfully!`,
                error: `Error sending link: try again later`
            }).then(res => {
                dispatch(actions.fetchCampaignStats(campaignId));
                dispatch(actions.fetchCampaignStatsAll(campaignId, values.typeOption, values.dataTypeOption));
                formik.resetForm()
                handleDropdownToggle()
            })
        }

        
    }

    const onDrop = (acceptedFiles: File[]) => {
        formik.setFieldValue('csv', acceptedFiles);
    };
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1,
        multiple: false, accept: {
            'text/csv': ['.csv'],
            // 'application/vnd.ms-excel': ['.xls'],
            // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          } });

    const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    });

    useEffect(() => {

    if(formik.values.dataTypeOption === "organic"){
        
        formik.setFieldValue("typeOption", "")
        formik.setFieldValue("persist", false)
        setOptionList(organicOptions)
    } else{
        
        formik.setFieldValue("typeOption", "")
        setOptionList(paidOptions) 
    }
        
    }, [formik.values.dataTypeOption])

    const deleteUploadHandler = () =>{
        formik.setFieldValue("csv", [])
    }
    
  return (
    <>
    {dropdownOpen && <div className="comments_underlay" style={{width:"100%"}}></div>}
                
    <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
        <Dropdown.Toggle  id="dropdown-attachments" className="upload-attachments-options" >
            <img src={toAbsoluteUrl("/media/cpg/upload-attachment.png")} 
            alt="edit sections" style={{marginRight:"8px"}}/>
            Import
        </Dropdown.Toggle>

        { dropdownOpen &&
             (
                <Dropdown.Menu className="dropdownMenu-attachment">
                    <div className="attachmentsMenu-upload">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0 NeueMontreal-bold text__20 text__black">Import CSV</p>
                        <img onClick={handleDropdownToggle} src={toAbsoluteUrl("/media/cpg/circle-xmark-attachments.png")} alt="link" 
                            style={{marginRight:"4px", width:"15px", height:"15px"}}/>
                    </div>
                    
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mt-4 d-flex w-full flex__col justify-content-between align-items-start">
                                <label className=" text_gray text__12" >Traffic type</label>
                                <select 
                                id="dataTypeOption" 
                                name="dataTypeOption" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="w-full mt-1 text_gray bg_white_fade border-none " 
                                style={{padding:"0.5rem"}}>
                                    <option value="">Select Traffic type</option>
                                    {viewOptions.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>

                                {formik.touched.dataTypeOption && formik.errors.dataTypeOption && (
                                    <div className="text_gray text__12">{formik.errors.dataTypeOption}</div>
                                )}
                            </div>

                            <div className="mt-4 d-flex w-full flex__col justify-content-between align-items-start">
                                <label className=" text_gray text__12" >Social Media Target</label>
                                <select 
                                id="typeOption" 
                                name="typeOption" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.typeOption}
                                className="w-full mt-1 text_gray bg_white_fade border-none " 
                                style={{padding:"0.5rem"}}>
                                    <option value="">Select Social Media Target</option>
                                    {optionList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                                {formik.touched.typeOption && formik.errors.typeOption && (
                                    <div className="text_gray text__12">{formik.errors.typeOption}</div>
                                )}
                            </div>

                            <div className="mt-4 d-flex w-full flex__col justify-content-between align-items-start">
                                <label className=" text_gray text__12" >Data Import Type</label>
                                <select 
                                id="importOption" 
                                name="importOption" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.importOption}
                                className="w-full mt-1 text_gray bg_white_fade border-none " 
                                style={{padding:"0.5rem"}}>
                                    <option value="">Select Data Import Type</option>
                                    <option value="append" title="add to existing database">Append</option>
                                    <option value="override" title="override all data in existing database">Override</option>
                                </select>
                                {formik.touched.importOption && formik.errors.importOption && (
                                    <div className="text_gray text__12">{formik.errors.importOption}</div>
                                )}
                            </div>
                                    
                            <div className="mt-4 d-flex w-full flex__col justify-content-between align-items-start">
                                <label className=" text_gray text__12" >Attachment format</label>
                                <select 
                                id="attachmentOption" 
                                name="attachmentOption" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.attachmentOption}
                                className="w-full mt-1 text_gray bg_white_fade border-none " 
                                style={{padding:"0.5rem"}}>
                                    <option value="">Select attachment type</option>
                                    <option value="file">File</option>
                                    <option value="link">Link</option>
                                    
                                </select>
                                {formik.touched.attachmentOption && formik.errors.attachmentOption && (
                                    <div className="text_gray text__12">{formik.errors.attachmentOption}</div>
                                )}
                            </div>
                            
                            {/* Drop Zone */}
                            { formik.values.attachmentOption === "file" &&
                            <>
                                {
                                    (formik.values.csv.length === 0) ? (
                                        <>
                                        <div
                                            className="d-flex text__center flex__col align-items-center justify-center"
                                            {...getRootProps()}
                                            style={{
                                            border: '2px dashed #ddd',
                                            borderRadius: '4px',
                                            padding: '1rem',
                                            marginTop:"1rem",
                                            backgroundColor: isDragActive ? '#f5f5f5' : '#fff',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <img src={toAbsoluteUrl('/media/cpg/file-csv.png')} alt="csv drop" style={{
                                                width:"20px",
                                            }}/>
                                            <p className="mt-1 text_gray text__12">
                                                {isDragActive ? 'Drop the CSV file here...' :
                                                'Click to browse or drag and drop your CSV file'}
                                            </p>
                                        </div>
                                        {formik.touched.csv && formik.errors.csv && (
                                            <div className="text_gray text__12">Drop a CSV file here</div>
                                        )}
                                        </>
                                    ):
                                    (
                                        
                                        <ImageCard 
                                        onDelete={deleteUploadHandler} 
                                        file={formik.values.csv[0]} 
                                        textStyle={{
                                            marginTop:"1rem",
                                            width:"100%",
                                            border:"2px dashed #E2E6EA",
                                            borderRadius:"18px"
                                        }}/>
                                    )
                                }
                            </>
                                
                            }

                            { formik.values.attachmentOption === "link" &&  (

                                <>
                                <div className=" my-2" 
                                style={{padding:"5px"}}>
                                <label className="d-flex align-items-center">
                                    <input
                                    type="checkbox"
                                    id="persist"
                                    name="persist"
                                    checked={formik.values.persist}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="custom-options-checkbox" 
                                    style={{backgroundColor:"teal", color:"white", width:"20px", height:"20px", 
                                    borderRadius:"16px",marginRight:"4px", cursor:"pointer"}}
                                    />
                                    <span className="text__14 text_gray">Persist Data</span>
                                </label>
                                
                                </div>

                                <div className="mt-4 d-flex w-full flex__col justify-content-between align-items-start">
                                    <label className="text_gray text__12" htmlFor="excelUrl">Excel Spreadsheet Url:</label>
                                    <input
                                        id="excelUrl"
                                        name="excelUrl"
                                        type="url"
                                        className="w-full mt-1 text_gray bg_white_fade border-none" 
                                        style={{padding:"0.5rem"}}
                                        value={formik.values.excelUrl}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.excelUrl && formik.errors.excelUrl && (
                                        <div>{formik.errors.excelUrl}</div>
                                    )}
                                </div>
                                </>
                            ) }

                        {/* Submit button */}
                        <div className="mt-6">
                            <button type="submit" 
                            className="Button border-none font__600 text__download upload__attachment_button" >
                                Import
                            </button>
                        </div>

                        </form>
                   
                    </div>
                </Dropdown.Menu>
            )
        }

        
    </Dropdown>
    </>
  );
};

