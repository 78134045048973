import { EarnedMedia } from "./EarnedMedia";
import { EarnedMediaValue } from "./EarnedMediaValue";
import { TopCreators } from "./TopCreators";



export const EarnedMediaOverview = ({campaignId, isExternal}: { campaignId: any, isExternal?:boolean}) => {
    
    return (
        <div className="mt-12">

            <div className="row p-0 " id="env_breakdown">
                <div className="col m-4 bg-white border__round_16">
                   <TopCreators />                             
                </div>  

                <div className="col m-4 bg-white border__round_16">
                    <EarnedMedia campaignId={campaignId} isExternal={isExternal}/>
                </div>
            </div>

            <div className=" mt-4">
                <EarnedMediaValue campaignId={campaignId} isExternal={isExternal}/>
            </div>
        </div>
    )
}