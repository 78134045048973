import React from 'react'
import { useParams} from 'react-router-dom';
import {SocialIntelligence} from '../social-intelligence';



const BrandAtGlanceExternal: React.FC = () => {
    const params = useParams<any>();
    const {id}= params;
    
    return (
        <>
            <SocialIntelligence landscapeId={id} isExternal={true}/>

        </>
    )
}



export { BrandAtGlanceExternal }
