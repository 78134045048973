import { StatsFormField } from "../../types";

export const tiktokOrganic2: StatsFormField[] = [
    {
        required: true,
        type: 'select',
        key: 'phase',
        label: 'Phase',
        options: ['Select type', 'Phase 1', 'Phase 2', 'Phase 3', 'Phase 4', 'Phase 5'],
        value: ""
    },
    {
        required: false,
        key: 'influencerHandle',
        type: 'select',
        label: 'Influencer Handle',
        options: ['Select influencer handle'],
        value: ''
    },
    {
        required: false,
        key: 'influencerName',
        type: 'text',
        label: 'Influencer Name',
        options: ['Select influencer Name'],
        value: ''
    },
    {
        required: true,
        type: 'number',
        key: 'followers',
        label: 'Followers',
        value: 0
    },
    {
        required: true,
        type: 'select',
        key: 'tier',
        label: 'Tier',
        options: ['Select type', 'Nano', 'Micro', 'Mid Tier', 'Macro', 'Mega' ],
        value: ""
    },
    {
        required: true,
        type: 'select',
        key: 'verticals',
        label: 'Verticals',
        options: ['Select type', 'Automotive', 'Beauty','Business & Finance','Creative artists','Culture','Educational', 'Entertainment', 'Family', 'Fashion', 'Food & Beverage','Gaming', 'Health & Wellness',
        'Home & Garden', 'Lifestyle', 'Music', 'News & Politics', 'Non-profit', 'Pets', 'Sports & Fitness', 'Skincare', 'Sustainability', 'Tech', 'Travel'],
        value: ""
    },
    {
        required: true,
        key: 'platform',
        type: 'select',
        label: 'Platform',
        options: ['Select Platform', 'Tiktok'],
        value: ""
    },
    {
        required: true,
        type: 'select',
        key: 'deliverable',
        label: 'Deliverable',
        options: ['TikTok Video', 'TikTok Photo Swipe'],
        value: ""
    },
    {
        required: true,
        key: 'contractOrEarned',
        type: 'select',
        label: 'Contracted / Earned',
        options: ['Select type', 'Contracted', 'Earned'],
        value: ""
    },
    {
        required: false,
        type: 'number',
        key: 'emv',
        label: 'EMV',
        addon: '$',
        addons:['$','€','£'],
        value: 0
      },
    {
        required: false,
        type: 'number',
        key: 'costPerDeliverable',
        label: 'Cost per Deliverable',
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: true,
        type: 'select',
        key: 'contentNarrative',
        label: 'Content Narrative',
        options: ['Select type', 'Tutorial',
        'Routine', 'Day In The life', 'GRWM', 'Sustainability', 'Travel', 'Moments' , 
        'Holiday', 'Gifting', 'Discount lead', 'Introduction', 'Emotion lead', 'Product Only', 
        'Countdown', 'Launch', 'Comedy', 'Teaser', 'Event / IRL / In-store', 'Change makers', 'Ask away', 
        'Holiday Sets', '3 Steps Routine', 'Squalene',
        'Spa To Go Kit', 'Event', 'Lunch', 'Unboxing','Solar Collection', 'Product showcase', 'Add Yours Sticker', 'None of the above'],
        value: ""
    },
    {
        required: false,
        type: 'select',
        key: 'contentStyle',
        label: 'Content Style',
        options: ['Select type', 'Talking to camera', 'VO', 
            'Text Overlay', 'Content reshare', 'Get Ready With Me', 'Story Time', 
            'Routine' , 'Skin and Product shots', 'Gifting', 'Reel Repost', 'None of the above'
        ],
        value: ""
    },
    {
        required: false,
        type: 'url',
        key: 'linkToContent',
        label: 'Link to content',
        value: ""
    },
    {
        required: true,
        type: 'date',
        key: 'datePublished',
        label: 'Date Published',
        value: ''
    },
    {
        required: true,
        type: 'select',
        key: 'month',
        label: 'Month',
        options: ["Select Month", "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"],
        value: ""
    },
    {
        required: true,
        type: 'text',
        key: 'week',
        label: 'Week',
        value: ""
    },
    {
        required: false,
        type: 'number',
        key: 'estimatedUniqueViewers',
        label: 'Estimated Unique Viewers',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'estimatedViews',
        label: 'Estimated Views',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'uniqueViewers',
        label: 'Unique Viewers',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'views',
        label: 'Views',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'averageWatchTime',
        label: 'Average Watch Time',
        addon: 'sec',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'retentionRate',
        label: 'Retention Rate %',
        addon: '%',
        value: 0
    },
    {
      required: false,
      type: 'number',
      key: 'totalPlayTime',
      label: 'Total Play Time',
      addon: 'sec',
      addons: ['sec','hrs','days'],
      value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'likes',
        label: 'Likes',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'comments',
        label: 'Comments',
        value: 0
    },
    {
        required: false,
        type: 'text',
        key: 'saves',
        label: 'Saves',
        value: ""
    },
    {
        required: false,
        type: 'text',
        key: 'shares',
        label: 'Shares',
        value: ""
    },
    {
        required: false,
        type: 'number',
        key: 'totalEngagements',
        label: 'Total Engagements',
        calculations: 'likes+Comments+Saves+Shares',
        disable:true,
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'er',
        label: 'ER%',
        calculations: "Total_engagement/Reach *100",
        disable:true,
        addon: '%',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'linkClicks',
        label: 'Clicks',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'ctr',
        label: 'CTR',
        calculations: "Link_clicks/Impressions *100",
        disable:true,
        addon: '%',
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'cpc',
        label: 'CPC_Internal  (Cost per Click)',
        calculations:"Cost per Deliverable /Link Clicks",
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'cpe',
        label: 'CPE_Internal (Cost per Engagement)',
        calculations:"Cost per deliverable / Total Engagements",
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'cpm',
        label: 'CPM_Internal (Cost per Impression)',
        calculations:"cost per deliverable/Impressions*1000",
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'cpu',
        label: 'Cost per Unique viewer_Internal',
        calculations:"Cost per Deliverable / Reach",
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: false,
        type: 'number',
        key: 'cpv',
        label: 'CPV (Cost per View)_Internal',
        calculations:"Cost per Deliverable (internal)/Views",
        addon: '$',
        addons:['$','€','£'],
        value: 0
    },
    {
        required: false,
        type: 'select',
        key: 'niche',
        label: 'Niche',
        options: ['Select type', 'Adventure', 'Animators', 'Anime', 'Astronomy', 'Atheletes', 'Audio and ASMR artists', 'Backpacking', 'Beat life',
          'Business advisor', 'Business owner', 'Cars', 'Challenge and pranks', 'Cleaning', 'Comedy', 'Cros fit', 'Cycling', 'Crypto', 'football', 'Music',
          'Pets', 'Productivity', 'Professionals', 'Programming', 'Renovation', 'Restaurant', 'STEM', 'Student', 'Sustainability', 'Tech reviewer',
          'Teenage parents', 'LGBTQ+', 'Language',  'Kpop', 'Menopause', 'Multicultural', 'Movies & Series', 
          'Motorcycle (Racing)', 'Motorcycling', 'lUXURYMoney Making', 'Makeup artist','luxury', 
          'Literature& Book enthusiasts', 'Language learning', 'Interior & Design', 'illustrators', 
          'Horse Riding', 'History', 'Hiking', 'High Street', 'Hardware & Software', 'Hair ethusiasts', 'Grooming',
           'Graphic designers', 'Geograophy', 'Gardening', 'Game reviewer', 'Gameplay'
        ],
        value: ""
    },
    {
        required: false,
        type: 'text',
        key: 'coreAudience',    
        label: 'Core Audience',
        value: ""
    },  
    {
        required: false,
        type: 'text',
        key: 'numberOfPosts',
        label: 'Number of posts(Qty)',
        value: ""
    },
    {
        required: false,
        type: 'url',
        key: 'bitlyLink',
        label: 'Bitly link',
        value: ""
    },
    {
        required: false,
        type: 'number',
        key: 'reach',
        label: 'Reach',
        value: 0
    },
    {
        required: true,
        type: 'number',
        key: 'impressions',
        label: 'Impressions',
        value: 0
    },
  ]