import React, { useState,useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux';

import distinctColors from 'distinct-colors'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


import * as actions from "./_redux/Actions";

import BrandGlanceWrap from './components/BrandGlanceWrap';
import CategoryPanelOne from './components/CategoryPanelOne';
import TopTrendsPanel from './components/TopTrendsPanel';




const CategoryInsight = () => {

    return (
        <div className="category_insight each_section_wrap">
            <div className="section_title">Category Insights</div>
            <div className="engagement_summary">
                <CategoryPanelOne />
                <TopTrendsPanel />
            </div>
        </div>
    )
}

const BenchmarkInsight = () => {
    let palette: any[] = distinctColors({ count: 4 }) || [];
    let chartColors: any[] = palette.map((each: any) => `${each}`)
    return (
        <div className="category_insight each_section_wrap">
            <div className="section_title">Social Benchmarks</div>
            <div className="section_content">
                <div className="social_icons_wrap">
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/user.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/pin.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                        />
                    </div>
                </div>
                <div className="summaries_wrap">
                    <div className="each_summary_info">
                        <div className="summary_heading">Social Audience</div>
                        {/* <div className="summary_labels">
                            <div className="summary_label_title">Change</div>
                        </div> */}
                        <div className="all_summary_breakdown with_few_heading">
                            <div className="break_down_headings">
                                <div className="all_headings">
                                    <div className="head-filler"></div>
                                    <div className="head_title">Change</div>
                                </div>
                            </div>
                            <div className="each_breakdown">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 1</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[0]}`,
                                            width: "70%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">5M</div>
                                    <div className="stats_count_change">
                                        2.34% <span className="change_direction up"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 2</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[1]}`,
                                            width: "10%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">105M</div>
                                    <div className="stats_count_change">
                                        3.24% <span className="change_direction up"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 3</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[2]}`,
                                            width: "40%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">3.2M</div>
                                    <div className="stats_count_change">
                                        5.14% <span className="change_direction up"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 4</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[3]}`,
                                            width: "50%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">899k</div>
                                    <div className="stats_count_change">
                                        5.67% <span className="change_direction up"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="each_summary_info">
                        <div className="summary_heading">Engagements</div>
                        {/* <div className="summary_labels width_headings">
                            <div className="summary_labels_headings">
                            <div className="summary_label_title">Change</div>
                            </div>
                            
                        </div> */}
                        <div className="all_summary_breakdown">
                            <div className="break_down_headings ">
                                <div className="all_headings">
                                    <div className="head-filler"></div>
                                    <div className="head_title">ER%</div>
                                    <div className="head_title">Avg. Post Per Week</div>
                                </div>
                            </div>
                            <div className="each_breakdown with_moreinfo">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 1</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[0]}`,
                                            width: "70%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">5M</div>
                                    <div className="stats_count_change">
                                        2.34% <span className="change_direction up"></span>
                                    </div>
                                    <div className="stats_count_change">
                                        4.5
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown with_moreinfo">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 2</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[1]}`,
                                            width: "10%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">105M</div>
                                    <div className="stats_count_change">
                                        3.24% <span className="change_direction up"></span>
                                    </div>
                                    <div className="stats_count_change">
                                        15
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown with_moreinfo">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 3</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[2]}`,
                                            width: "40%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">3.2M</div>
                                    <div className="stats_count_change">
                                        5.14% <span className="change_direction up"></span>
                                    </div>
                                    <div className="stats_count_change">
                                        5
                                    </div>
                                </div>
                            </div>
                            <div className="each_breakdown with_moreinfo">
                                <div className="brand_bar">
                                    <div className="brand_name">Brand 4</div>
                                    <div className="brand_progress">
                                        <div className="brand_reach" style={{
                                            backgroundColor: `${chartColors[3]}`,
                                            width: "50%"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                                <div className="brand_stats">
                                    <div className="stats_count">899k</div>
                                    <div className="stats_count_change">
                                        5.67% <span className="change_direction up"></span>
                                    </div>
                                    <div className="stats_count_change">
                                        3
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="boxes_summary">
                    <div className="each_box">
                        <div className="logo_in_box">
                            <img src={toAbsoluteUrl('/media/cpg/brand11.png')} alt="" />
                        </div>
                        <div className="text_in_box">Invite only</div>
                    </div>
                    <div className="each_box">
                        <div className="logo_in_box">
                            <img src={toAbsoluteUrl('/media/cpg/brand20.png')} alt="" />
                        </div>
                        <div className="text_in_box">Discovery through geolocation</div>
                    </div>
                    <div className="each_box">
                        <div className="logo_in_box">
                            <img src={toAbsoluteUrl('/media/cpg/brand21.png')} alt="" />
                        </div>
                        <div className="text_in_box">Discovery through social graph data </div>
                    </div>
                    <div className="each_box">
                        <div className="logo_in_box">
                            <img src={toAbsoluteUrl('/media/cpg/brand22.png')} alt="" />
                        </div>
                        <div className="text_in_box">Mass appeal</div>
                    </div>
                </div>
                <div className="actionables">
                    <div className="actionables_head">Actionable Insights</div>
                    <div className="all_actions">
                        <ul>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ContentPerformance = () => {

    return (
        <div className="category_insight each_section_wrap content_perfomance">
            <div className="section_title">Content Performance</div>
            <div className="section_content">
                <div className="performance_metrics">
                    <div className="each_metric_wrap">
                        <div className="metric_title">Your Posts Per Day</div>
                        <div className="metric_value">
                            <span className="count_value">1</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Posts in  day"
                                ></i>
                            </span>
                        </div>
                        <div className="other_metric_info">Landscape Avg.: 1.23</div>
                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Your Engagement Rate</div>
                        <div className="metric_value">
                            <span className="count_value">0.23%</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements"
                                ></i>
                            </span>
                        </div>
                        <div className="other_metric_info">Post Avg. 0.48%</div>
                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Your Top Hour of Day</div>
                        <div className="metric_value">
                            <span className="count_value">3PM GMT</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements"
                                ></i>
                            </span>
                        </div>
                        <div className="other_metric_info">Landsape Top: 2pm GMT</div>
                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Your Most Engaging Channel</div>
                        <div className="metric_value">
                            <img
                                className="icon_perform"
                                alt='Logo'
                                src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                            />
                            <span className="count_value">Instagram</span>
                        </div>

                    </div>
                </div>
                <div className="by_channel">
                    <div className="heading_txt">Landscape Activity &amp; Engagement by Channel</div>
                    <div className="each_segment">
                        <div className="segment_headings">
                            <div className="aech_heading">Post Channel</div>
                            <div className="aech_heading">Posts per Day per Company</div>
                            <div className="aech_heading">Engagement Rate by Follower</div>
                        </div>
                        <div className="each_engagement">
                            <div className="for_channel">Instagram</div>
                            <div className="for_posts">1.93/day</div>
                            <div className="for_followers">
                                <div className="rate_bar">
                                    <div className="rate_level" style={{
                                        backgroundColor: `#587E9F`,
                                        width: "80%"
                                    }}></div>
                                </div>
                                <div className="rate_pecent">0.88%</div>
                            </div>
                        </div>
                        <div className="each_engagement">
                            <div className="for_channel">YouTube</div>
                            <div className="for_posts">0.53/day</div>
                            <div className="for_followers">
                                <div className="rate_bar">
                                    <div className="rate_level" style={{
                                        backgroundColor: `#E83222`,
                                        width: "40%"
                                    }}></div>
                                </div>
                                <div className="rate_pecent">0.28%</div>
                            </div>
                        </div>
                        <div className="each_engagement">
                            <div className="for_channel">Twitter</div>
                            <div className="for_posts">0.30/day</div>
                            <div className="for_followers">
                                <div className="rate_bar">
                                    <div className="rate_level" style={{
                                        backgroundColor: `#77C2EF`,
                                        width: "35%"
                                    }}></div>
                                </div>
                                <div className="rate_pecent">0.27%</div>
                            </div>
                        </div>
                        <div className="each_engagement">
                            <div className="for_channel">Facebook</div>
                            <div className="for_posts">1.77/day</div>
                            <div className="for_followers">
                                <div className="rate_bar">
                                    <div className="rate_level" style={{
                                        backgroundColor: `#2E4C7E`,
                                        width: "25%"
                                    }}></div>
                                </div>
                                <div className="rate_pecent">0.16%</div>
                            </div>
                        </div>
                        <div className="each_engagement">
                            <div className="for_channel">TikTok</div>
                            <div className="for_posts">2.43/day</div>
                            <div className="for_followers">
                                <div className="rate_bar">
                                    <div className="rate_level" style={{
                                        backgroundColor: `#73DCED`,
                                        width: "10%"
                                    }}></div>
                                </div>
                                <div className="rate_pecent">0.049%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social_icons_wrap">
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/user.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/pin.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                        />
                    </div>
                </div>
                <div className="performance_metrics by_six">
                    <div className="each_metric_wrap">
                        <div className="metric_title">Number of posts</div>
                        <div className="metric_value">
                            <span className="count_value">119</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Number of posts"
                                ></i>
                            </span>
                        </div>

                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Video Views</div>
                        <div className="metric_value">
                            <span className="count_value">60,245</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Video Views"
                                ></i>
                            </span>
                        </div>

                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Total Engagements</div>
                        <div className="metric_value">
                            <span className="count_value">12,542</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements count"
                                ></i>
                            </span>
                        </div>
                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Avg. Engagement Rate</div>
                        <div className="metric_value">
                            <span className="count_value">1.8%</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements count"
                                ></i>
                            </span>
                        </div>

                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Impressions</div>
                        <div className="metric_value">
                            <span className="count_value">50,635</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements count"
                                ></i>
                            </span>
                        </div>

                    </div>
                    <div className="each_metric_wrap">
                        <div className="metric_title">Your Top Hour of Day</div>
                        <div className="metric_value">
                            <span className="count_value">3PM GMT</span>
                            <span className="tip">
                                <i
                                    className='fas fa-exclamation-circle  fs-7'
                                    data-bs-toggle='tooltip'
                                    title="Total Engagements count"
                                ></i>
                            </span>
                        </div>
                        <div className="other_metric_info">Landsape Top: 2pm GMT</div>
                    </div>
                </div>
                <div className="activity_engagement">
                    <div className="by_channel">
                        <div className="both_heads">
                            <div className="heading_txt">Landscape Activity &amp; Engagement by Post Type</div>
                            <div className="heading_txt">Content Tags</div>
                        </div>

                        <div className="both_segments">
                            <div className="each_segment">
                                <div className="segment_headings">
                                    <div className="aech_heading">Post Type</div>
                                    <div className="aech_heading">Posts per Day per Company</div>
                                    <div className="aech_heading">Engagement Rate by Follower</div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_channel">Ree</div>
                                    <div className="for_posts">1.13/day</div>
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_level" style={{
                                                backgroundColor: `#8C6BB9`,
                                                width: "80%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">0.88%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_channel">Photo</div>
                                    <div className="for_posts">1.67/day</div>
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_level" style={{
                                                backgroundColor: `#E8AB56`,
                                                width: "70%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">0.28%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_channel">Carousel</div>
                                    <div className="for_posts">0.30/day</div>
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_level" style={{
                                                backgroundColor: `#B9B94C`,
                                                width: "40%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">0.27%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_channel">Video</div>
                                    <div className="for_posts">3.87/day</div>
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_level" style={{
                                                backgroundColor: `#F5DE88`,
                                                width: "25%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">0.16%</div>
                                    </div>
                                </div>

                            </div>
                            <div className="each_segment">
                                <div className="segment_headings">
                                    {/* <div className="aech_heading">Post Channel</div>
                                    <div className="aech_heading">Posts per Day per Company</div> */}

                                    <div className="aech_heading"></div>
                                    <div className="aech_heading">ER%</div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_label">
                                                <span>UGC</span>
                                                <span>42%</span>
                                            </div>
                                            <div className="rate_level" style={{
                                                backgroundColor: `#4089CB`,
                                                width: "70%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">xx%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_label">
                                                <span>Product</span>
                                                <span>21%</span>
                                            </div>
                                            <div className="rate_level" style={{
                                                backgroundColor: `#4EABE9`,
                                                width: "30%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">xx%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_label">
                                                <span>Lifestyle</span>
                                                <span>63%</span>
                                            </div>
                                            <div className="rate_level" style={{
                                                backgroundColor: `#3F85C3`,
                                                width: "5%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">xx%</div>
                                    </div>
                                </div>
                                <div className="each_engagement">
                                    <div className="for_followers">
                                        <div className="rate_bar">
                                            <div className="rate_label">
                                                <span>Competition</span>
                                                <span>38%</span>
                                            </div>
                                            <div className="rate_level" style={{
                                                backgroundColor: `#2A5C9A`,
                                                width: "40%"
                                            }}></div>
                                        </div>
                                        <div className="rate_pecent">xx%</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="actionables">
                    <div className="actionables_head">Actionable Insights</div>
                    <div className="all_actions">
                        <ul>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                        </ul>
                    </div>
                </div>
                <div className="posts_slide">
                    <div className="each_post_item"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/stock/600x600/img-24.jpeg')})`
                        }}
                    >
                        <div className="post_overlay">
                            <div className="post_meta">
                                <div className="lock_cta">
                                    <span>+</span>
                                    <img src={toAbsoluteUrl('/media/cpg/add_ico.svg')} alt="" />
                                </div>
                                <div className="post_info">
                                    <div className="profile_pic"></div>
                                    <div className="other_meta">
                                        <div className="profile_name_txt">JVN Hair</div>
                                        <div className="profile_summary">16.9% Eng. Rate by Follower</div>
                                        {/* <div className="link_out">
                                            <img src={toAbsoluteUrl('/media/cpg/share_ico.svg')} alt="" />
                                            <span>View on Instagram</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="reel_tag">
                                    <div className="tag_txt">Reel</div>
                                    <div className="link_out">
                                        <img src={toAbsoluteUrl('/media/cpg/share_ico.svg')} alt="" />
                                        <span>View on Instagram</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="each_post_item"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/stock/600x600/img-24.jpeg')})`
                        }}
                    >
                        <div className="post_overlay">
                            <div className="post_meta">
                                <div className="lock_cta">
                                    <span>+</span>
                                    <img src={toAbsoluteUrl('/media/cpg/add_ico.svg')} alt="" />
                                </div>
                                <div className="post_info">
                                    <div className="profile_pic"></div>
                                    <div className="other_meta">
                                        <div className="profile_name_txt">JVN Hair</div>
                                        <div className="profile_summary">7.31% Eng. Rate by Follower</div>

                                    </div>
                                </div>
                                <div className="reel_tag">
                                    <div className="tag_txt">Reel</div>
                                    <div className="link_out">
                                        <img src={toAbsoluteUrl('/media/cpg/share_ico.svg')} alt="" />
                                        <span>View on Instagram</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="each_post_item"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/stock/600x600/img-24.jpeg')})`
                        }}
                    >
                        <div className="post_overlay">
                            <div className="post_meta">
                                <div className="lock_cta">
                                    <span>+</span>
                                    <img src={toAbsoluteUrl('/media/cpg/add_ico.svg')} alt="" />
                                </div>
                                <div className="post_info">
                                    <div className="profile_pic"></div>
                                    <div className="other_meta">
                                        <div className="profile_name_txt">JVN Hair</div>
                                        <div className="profile_summary">6.35% Eng. Rate by Follower</div>

                                    </div>
                                </div>
                                <div className="reel_tag">
                                    <div className="tag_txt">Reel</div>
                                    <div className="link_out">
                                        <img src={toAbsoluteUrl('/media/cpg/share_ico.svg')} alt="" />
                                        <span>View on Instagram</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="each_post_item"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl('/media/stock/600x600/img-24.jpeg')})`
                        }}
                    >
                        <div className="post_overlay">
                            <div className="post_meta">
                                <div className="lock_cta">
                                    <span>+</span>
                                    <img src={toAbsoluteUrl('/media/cpg/add_ico.svg')} alt="" />
                                </div>
                                <div className="post_info">
                                    <div className="profile_pic"></div>
                                    <div className="other_meta">
                                        <div className="profile_name_txt">JVN Hair</div>
                                        <div className="profile_summary">2.76% Eng. Rate by Follower</div>

                                    </div>
                                </div>
                                <div className="reel_tag">
                                    <div className="tag_txt">Reel</div>
                                    <div className="link_out">
                                        <img src={toAbsoluteUrl('/media/cpg/share_ico.svg')} alt="" />
                                        <span>View on Instagram</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="actionables">
                    <div className="actionables_head">Actionable Insights</div>
                    <div className="all_actions">
                        <ul>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                            <li>Really smart insight about the data, Really smart insight about the data, Really smart insight about the data.</li>
                        </ul>
                    </div>
                </div>
                <div className="top_posts_summary">
                    <div className="summary_headings">
                        <div className="each_summary_head_txt">Published At</div>
                        <div className="each_summary_head_txt">Eng. Total</div>
                        <div className="each_summary_head_txt">Conversation</div>
                        <div className="each_summary_head_txt">Amplification</div>
                        <div className="each_summary_head_txt">Eng. Rate by Follower</div>
                    </div>
                    <div className="summaries_body">
                        <div className="each_summarybody">
                            <div className="each_body_item">
                                <div className="date_items">
                                    <div className="main_date">Jan 23, 2023</div>
                                    <div className="main_time">7:05 PM GMT</div>
                                </div>
                                <div className="post_items">
                                    <div className="profile_img"></div>
                                    <div className="social_ico">
                                        <img src={toAbsoluteUrl('/media/icons/socials/ig_main.svg')} alt="" />
                                    </div>
                                </div>
                                <div className="main_post">
                                    <div className="post_img">
                                        <img src={toAbsoluteUrl('/media/icons/socials/other.jpeg')} alt="" />
                                    </div>
                                    <div className="post_txt">Day curl ✌️refresh, featuring jvn 🥰✨ Leave-In Conditioning Mist refreshes dry hair ✨ Air Dry Cream holds and defines ✨ Nourishing Shine Drops smooths...</div>
                                </div>
                            </div>
                            <div className="each_body_item">31.1k</div>
                            <div className="each_body_item">489</div>
                            <div className="each_body_item">0</div>
                            <div className="each_body_item">16.9%</div>
                        </div>
                        <div className="each_summarybody">
                            <div className="each_body_item">
                                <div className="date_items">
                                    <div className="main_date">Jan 23, 2023</div>
                                    <div className="main_time">7:05 PM GMT</div>
                                </div>
                                <div className="post_items">
                                    <div className="profile_img"></div>
                                    <div className="social_ico">
                                        <img src={toAbsoluteUrl('/media/icons/socials/ig_main.svg')} alt="" />
                                    </div>
                                </div>
                                <div className="main_post">
                                    <div className="post_img">
                                        <img src={toAbsoluteUrl('/media/icons/socials/other.jpeg')} alt="" />
                                    </div>
                                    <div className="post_txt">Day curl ✌️refresh, featuring jvn 🥰✨ Leave-In Conditioning Mist refreshes dry hair ✨ Air Dry Cream holds and defines ✨ Nourishing Shine Drops smooths...</div>
                                </div>
                            </div>
                            <div className="each_body_item">31.1k</div>
                            <div className="each_body_item">489</div>
                            <div className="each_body_item">0</div>
                            <div className="each_body_item">16.9%</div>
                        </div>
                        <div className="each_summarybody">
                            <div className="each_body_item">
                                <div className="date_items">
                                    <div className="main_date">Jan 23, 2023</div>
                                    <div className="main_time">7:05 PM GMT</div>
                                </div>
                                <div className="post_items">
                                    <div className="profile_img"></div>
                                    <div className="social_ico">
                                        <img src={toAbsoluteUrl('/media/icons/socials/ig_main.svg')} alt="" />
                                    </div>
                                </div>
                                <div className="main_post">
                                    <div className="post_img">
                                        <img src={toAbsoluteUrl('/media/icons/socials/other.jpeg')} alt="" />
                                    </div>
                                    <div className="post_txt">Day curl ✌️refresh, featuring jvn 🥰✨ Leave-In Conditioning Mist refreshes dry hair ✨ Air Dry Cream holds and defines ✨ Nourishing Shine Drops smooths...</div>
                                </div>
                            </div>
                            <div className="each_body_item">31.1k</div>
                            <div className="each_body_item">489</div>
                            <div className="each_body_item">0</div>
                            <div className="each_body_item">16.9%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const BrandAtGlanceT: React.FC = () => {
    const [activeNav, setActiveNav] = useState("brand_glance");
    const dispatch = useDispatch<any>();
    return (
        <>
            <div className="brand_page_wrap">
                
                <div className="all_sections">
                    <div className="top_heading">
                        <div className="brand_filter each_filter">
                            <div className="filer-title">Brand</div>
                            <select name="" id="">
                                <option value="JVN Hair">JVN Hair</option>
                                <option value="LAC Cosmetics">LAC Cosmetics</option>

                            </select>
                        </div>
                        <div className="all_tabs">
                            <div className={`each_tab_item ${activeNav === "brand_glance"?"active_item":""}`} onClick={() => setActiveNav("brand_glance")}>Brand at a Glance</div>
                            <div className={`each_tab_item ${activeNav === "cat_insight"?"active_item":""}`} onClick={() => setActiveNav("cat_insight")}>Category Insights</div>
                            <div className={`each_tab_item ${activeNav === "bench_mark"?"active_item":""}`} onClick={() => setActiveNav("bench_mark")}>Social Benchmarks</div>
                            <div className={`each_tab_item ${activeNav === "performance"?"active_item":""}`} onClick={() => setActiveNav("performance")}>Content Performance</div>
                        </div>
                        <div className="date_filter each_filter">
                            <div className="filer-title">Date Range</div>
                            <select name="" id="">
                                <option value="Last 30 days">Last 30 days</option>
                                <option value="Last 7 days">Last 7 days</option>

                            </select>
                        </div>
                    </div>
                    {activeNav === "brand_glance" &&
                        <BrandGlanceWrap />
                    }
                    {activeNav === "cat_insight" &&
                        <CategoryInsight />
                    }

                    {activeNav === "bench_mark" &&
                        <BenchmarkInsight />
                    }
                    {activeNav === "performance" &&
                        <ContentPerformance />
                    }

                    {/* <CategoryInsight />
                    <BenchmarkInsight />
                    <ContentPerformance /> */}
                </div>
            </div>

        </>
    )
}

export { BrandAtGlanceT }
