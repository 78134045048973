import { useState, useRef, useEffect } from "react";
import SVG from 'react-inlinesvg'
import {motion} from 'framer-motion'

import { CmpDetailData } from "../../../../../../setup/types/report";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { BannerImage } from "./BannerImage";
import { getCampaignDetails, uploadStaticImages } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { toast } from "react-toastify";
import axios from "axios";


interface Props {
    campaignId: string;
    data: CmpDetailData;
    handleScrollToView:() => void;
    thumbnail: string;
    isExternal: boolean;
    isPdfPage?:boolean
}

const springVariants = {
    animation: {
      y: [0, 80],
      x: 0,
      transition: {
        y: {
          yoyo: Infinity,
          duration: 1,
          ease: 'easeOut'
        }
      }
    }
  };

  const API_URL = process.env.REACT_APP_API_URL;

export const DynamicBannerContent = ({ data, campaignId, handleScrollToView, thumbnail, isExternal, isPdfPage }: Props) => {
    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(-1);

    const containerRef = useRef<HTMLDivElement>(null);
    const uploadRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const range  = useAppSelector((state) => state.report.range).toString() ;

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];
        const formData = new FormData();

        formData.append("campaign_id", campaignId);
        formData.append("file", image);
        formData.append("postId", hoveredImageIndex.toString())
        // formData.append("file", image, image.name);
        const response = dispatch(uploadStaticImages(formData));
        response.then(res => {
            dispatch(getCampaignDetails({campaign_id:campaignId, is_external:false, date_range:range }));
        })
    }

    const handleReportRefresh = async() => {
            await toast.promise(axios.get(`${API_URL}api/new-reports/refersh-realtime?campaign_id=${campaignId}`),{
              pending: 'Updating reports',
              success: `reports updated successfully!`,
              error: `Error updating reports: try again later`
            }).then(res => {
                dispatch(getCampaignDetails(
                    {campaign_id:campaignId, is_external:false, date_range:range }
                ));
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleImageHover = (index: any) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        setHoveredImageIndex(-1);
    }

    useEffect(() => {
        // Attach a click event listener to the document
        document.addEventListener('click', handleClickOutside);
    
        // Remove the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    function handleClickOutside(event: MouseEvent) {
        // Check if the click target is inside the div container
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && uploadRef.current && !uploadRef.current.contains(event.target as Node)) {
          // Clicked outside the container, update the state value
          handleImageOut()
        }
      }

      const [dataArr, setDataArr] = useState<any>([])

      useEffect(()=>{
          let dataArray: any = [];
          let j = 0
  
          
          if (data !== null) {
              dataArray = [...data?.images];
          }

      
          if (dataArray.length < 4) {
              const remainingElements = 4 - dataArray?.length;
            
              for (let i = 0; i < remainingElements; i++) {
                if(data !== null && data?.images[i] !== undefined){
                  dataArray.push(data?.images[j]);
                //   console.log("Undefined: dataArray length -> ",dataArray.length,", data => ",data?.images[j])
                }
                else{
                  if(data !== null){
                      j = 0
                      
                      dataArray.push(data?.images[j])
                    //   console.log("Reset: dataArray lenght -> ",dataArray.length,", data?.images => ",data?.images[j])
                  }
                }
                j++
              }
  
              setDataArr(dataArray)
            }

        if (dataArray.length >= 4){
            setDataArr(dataArray)
        }
      },[data])
      

    return (
        <div style={{ minHeight: "700px", marginTop: "24px" }}>

            {/* Brand Name div */}
            {!isPdfPage &&
            <div className="container"  style={{ textAlign: "center", position: "relative"}}> 
                <h1 style={{ fontSize: "60px", fontWeight: 600, letterSpacing: "6px", textTransform: "uppercase", color: "#010202", margin: 0 }}>
                    {data.brandName}
                </h1>
                <p onClick={handleReportRefresh} className="report-refresh-button">Refresh</p>
                {/* <img src={thumbnail || toAbsoluteUrl(`/media/cpg/na.png`)} alt="" 
                style={{ height: '80px', width: "150px", objectFit: "cover" }} /> */}
            </div>
            }
            
            {/* Brand Banner images */}
            <div  
                ref={containerRef}  
                className="container-fluid row m-0 p-0 mt-8 d-flex justify-content-center"
                style={{ position: "relative", marginLeft:"auto", marginRight:"auto" }}
            >   
                {dataArr?.slice(0,4)?.map((item:any, index:any) => (
                    <div key={index} className="col-3">
                        <BannerImage  
                            key={index} 
                            id={item?.Id ? item.Id : index}
                            src={item.url} 
                            social={item.socialNetwork}
                            username={item.username}
                            index={index}
                            uploadRef={uploadRef}
                            hoveredImageIndex={hoveredImageIndex}
                            handleImageHover={handleImageHover}
                            handleImageUpload={handleImageUpload}
                            handleImageOut={handleImageOut}
                        />
                    </div>
                ))}

                <div 
                    className="d-flex flex-column align-items-center justify-content-center blur-bg"
                    style={{
                        backgroundColor: "#fff",
                        boxShadow: "20px",
                        borderRadius: 40,
                        position: "absolute",
                        alignSelf: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        width: "600px",
                        padding: "30px 70px",
                        textAlign: "center"
                    }}
                >
                    <h1 style={{ fontSize: "32px", fontWeight: 700, lineHeight: "48px"}}>{data.campaignName}</h1>
                </div>
            </div>
            
            {!isPdfPage &&
            <div 
                className="container d-flex justify-content-center down_scroll"
                style={{
                    height:"150px",
                    marginTop: "40px",
                    marginBottom: "40px"
                }}
                onClick={handleScrollToView}
            >
                <motion.div 
                variants={springVariants}
                animate="animation">
                    <SVG 
                        src={toAbsoluteUrl("/media/icons/duotune/chevron/chevron-down.svg")} 
                    />
                </motion.div>
            </div>
            }

        </div>
    )
}
