import React, {  useRef, useEffect } from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'
import ReactApexChart from "react-apexcharts";

import ApexCharts from 'apexcharts'
import distinctColors from 'distinct-colors'


export default function OtherTrends (props:any){
    const chartRef = useRef<HTMLDivElement | null>(null);
    const options = {
        xaxis: {
            categories: ["10 Dec", "20 Dec", "23 Jan", "10 Feb", "15 Feb", " 20 Feb", "25 Feb"]
        },
        dataLabels: {
            enabled: false,

        }
    };
    const series = [
        {
            name: "series-1",
            data: [0, 40, 55, 60, 70, 88, 90, 103]
        },

    ];
    useEffect(() => {
        let palette: any[] = distinctColors({ count: 6 }) || [];
        let chartColors: any[] = palette.map((each: any) => `${each}`)
        let options = {

            series: [20, 40, 70],
            chart: {
                type: 'donut',
                width: 300
            },
            dataLabels: {
                enabled: false,
            },
            labels: ["Negative", "Neutral", "Positive"],
            // hollow: {
            //     margin: 15,
            //     size: '40%'
            // },
            stroke: {
                width: 0
            },
            legend: {
                show: false,
                // position: 'bottom',
                // markers: {
                //     width: 8,
                //     height: 8,
                // },
                // labels: {
                //     colors: ["#666666"]
                // }
            },
            fill: {
                colors: [...chartColors]
            },

        }
        let numbersChart = new ApexCharts(chartRef.current, options);

        if (numbersChart) {
            numbersChart.render()
        }
    }, [chartRef])
    return (
        <div className="each_word_stat other_trend">
            <div className="word_trend_chart" ref={chartRef}></div>
            <ReactApexChart width="100%" options={options} series={series} type="area" />
        </div>
    )
}