import { CSSProperties, useEffect, useState } from "react";
import { useFormik } from "formik";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import axios from "axios";

import LargeModal from "../../../../../_metronic/layout/components/modal/LargeModal";
import { FormKeyValues, InfluencerData, StatsFormField } from "../../types";
import { 
    calcCPC, 
    calcCPE, 
    calcCPM, 
    calcCPU, 
    calcCPV, 
    calcCTR, 
    calcER, 
    calcReachRate, 
    calcTotalEngagement,
    calcAttributedCPA,
    calcAttributedROAS,
    calcAttributedCVR,
    linkClickCPA,
    linkClickROAS,
    linkClickCVR, 
    weeklyControlFn,
    monthlyControlFn,
    updateField,
    updateStringField
} from "./helpers";
import { roundToDecimal } from "../../../../../helper";
import { current } from "@reduxjs/toolkit";


interface Props {
    showModal: boolean;
    handleShowModal: () => void;
    title: string;
    addCampaignStats: (data: Record<string, StatsFormField>) => void;
    editCampaignStats: (data: Record<string, StatsFormField>) => void;
    isEdit?: boolean;
    statsFormData: StatsFormField[];
    initialFormData: StatsFormField[];
    influencerHandlerData: InfluencerData;
    influencerNameData:InfluencerData;
    campaignInflencers:any
}

const urlRegexPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const API_URL = process.env.REACT_APP_API_URL;

const selectElStyle: CSSProperties = {
    borderRadius: '3px', 
    border:'none', 
    cursor:'pointer', 
    height: '36px', width: '100%',
    appearance: 'none', 
    textAlign: 'left', 
    textOverflow: '', 
    padding: "0px 10px",
}

export default function AddCampaignStats({
    showModal, 
    handleShowModal, 
    title, 
    addCampaignStats, 
    campaignInflencers,
    editCampaignStats,
    isEdit=false,
    statsFormData,
    initialFormData,
    influencerHandlerData,
    influencerNameData
}: Props) {
    //this the table form gotten from the parent component
    const tableFormStats: StatsFormField[] = !isEdit ? initialFormData : statsFormData;

    // console.log(tableFormStats)
    //this initializes the formit initial values
    const formikInitialValues: FormKeyValues = tableFormStats.reduce((o, key) => ({ ...o, [key.key]: key}), {});

    //this is a state variable that is used to populate the form with various inputs
    //it is made a state so that it is easily mutuable from any part of the code
    const [tableDataUpdate, setTableDataUpdate] = useState(tableFormStats)

    

    const validate = (values: Record<string, StatsFormField>) => {

        const errors = {} as Record<string, string>;
        
        Object.keys(values).forEach((fieldKey) => {
            const field = values[fieldKey];

            if (field.type === "url" && field.required) {
                if (typeof field.value === "string" && field.value.length < 1) {
                    errors[fieldKey] = "field is required";
                }
                //  else if (typeof field.value === "string" 
                // // && !field.value.match(urlRegexPattern)
                // ) {
                //     errors[fieldKey] = "must match a URL";
                // }
            }

            if (field.type === "text" && field.required) {
                if (typeof field.value === "string" && field.value.length < 1) {
                    errors[fieldKey] = "field is required";
                }
            }

            if (field.type === "select" && field.required) {
                if (field.value === "") {
                    errors[fieldKey] = "no option selected";
                }
            }

            if (field.type === "date" && field.required) {
                if (field.value === "") {
                    errors[fieldKey] = "no date selected";
                }
            }
        })

        return errors;
    };


    const formik = useFormik({
        initialValues: {
            ...formikInitialValues
        },
        onSubmit: (values) => {
            console.log(isEdit)
            if (!isEdit) {
                addCampaignStats(values);
            } else {
                editCampaignStats(values);
            }

            handleShowModal();
            formik.resetForm();
        },
        validate,
        validateOnMount: false,
        enableReinitialize: true
    });
    
    const autoPopulateInfluencerDetails = async(influencerName: string) =>{
            let data:any;
            campaignInflencers.map((item:any) =>{
                if(item.PublisherName === influencerName){
                    data = item
                }
            })

            // console.log(statsFormData)
            
            await axios
            .get(`${API_URL}/api/new-reports/stats/modal-data?campaign_id=${data?.CampaignId||"000000"}&publisher_id=${data?.PublisherId||"000000"}`)
            .then(response=>{
            let fields = ["tier","followers", "verticals", "platform", "costPerDeliverable"]
            let tableData = [...tableDataUpdate]
            fields.map((item) => {
                let itemField  = {...formik.values[item]}

                let itemValue = item === "verticals" ? 'vertical' : item
                
                if(itemField.type === "select"){
                    // Find the index of the item to update
                    const itemIndex = tableDataUpdate.findIndex(itemKey => itemKey.key === item);

                    const newOption = response.data?.data[itemValue];
                    const options = tableData[itemIndex]?.options ?? [];

                    if(newOption === ""){
                        tableData[itemIndex] = { ...tableFormStats[itemIndex] };

                        itemField.value = response.data?.data[item] || ""
                        formik.setFieldValue(item, itemField, false);
                        formik.setFieldTouched(item, true, false);

                        return
                    }

                    if (newOption && options.includes(newOption)) {
                        
                        // Remove the existing option from the array
                        const newOptions = options.reduce((acc: string[], option:string) => {
                            if (option !== newOption) {
                              acc.push(option);
                            }
                            return acc;
                          }, [])

                        let listOptions = [newOption, ...newOptions]
                        tableData[itemIndex] = { ...tableData[itemIndex], options: [] };
                        
                        // console.log(tableData[itemIndex])
                        setTableDataUpdate(tableData)
                        // Add the existing option to the front of the array
                        tableData[itemIndex] = { ...tableData[itemIndex], options: [...listOptions], value: newOption };
                        
                    } 
                    else {
                        // Add the new option to the front of the array
                        // console.log(item)
                        tableData[itemIndex] = { ...tableData[itemIndex], options: [newOption, ...options], value: newOption };
                    }
                    
                    // tableData[itemIndex] = { ...tableData[itemIndex], options:[ newOption, ...options], value: newOption };
                    

                    itemField.value = response.data?.data[item] || ""
                    formik.setFieldValue(item, itemField, false);
                    formik.setFieldTouched(item, true, false);
                }
                else{
                    itemField.value = response.data?.data[item] || "0"
                    formik.setFieldValue(item, itemField, false);
                    formik.setFieldTouched(item, true, false);
                }
                // console.log(itemField)
                
            })
            setTableDataUpdate(tableData)
    
            })
            .catch(error => {
            console.log('Error updating value:', error);
            })
    }  

    useEffect(() => {
        const fieldKey = "totalEngagements";
        const formikValues = formik.values
        const calculatedValue = calcTotalEngagement(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);

    }, [
        formik.values["likes"]?.value,
        formik.values["comments"]?.value,
        formik.values["saves"]?.value,
        formik.values["stickerTaps"]?.value,
        formik.values["shares"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "ctr";
        const formikValues = formik.values
        const calculatedValue = calcCTR(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["linkClicks"]?.value,
        formik.values["impressions"]?.value
    ])

    useEffect(() => {
        const fieldKey = "er";
        const formikValues = formik.values
        const calculatedValue = calcER(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["totalEngagements"]?.value,
        formik.values["reach"]?.value,
        formik.values["postEngagements"]?.value,
        formik.values["impressions"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "reachRate";
        const formikValues = formik.values
        const calculatedValue = calcReachRate(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["reach"]?.value,
        formik.values["followers"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "cpm";
        const formikValues = formik.values
        const calculatedValue = calcCPM(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["costPerDeliverable"]?.value,
        formik.values["totalCost"]?.value,
        formik.values["impressions"]?.value
    ]);
    

    useEffect(() => {
        const fieldKey = "cpc";
        const formikValues = formik.values
        const calculatedValue = calcCPC(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["costPerDeliverable"]?.value,
        formik.values["totalCost"]?.value,
        formik.values["linkClicks"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "cpv";
        const formikValues = formik.values
        const calculatedValue = calcCPV(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["costPerDeliverable"]?.value,
        formik.values["views"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "cpu";
        const formikValues = formik.values
        const calculatedValue = calcCPU(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["costPerDeliverable"]?.value,
        formik.values["reachRate"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "cpe";
        const formikValues = formik.values
        const calculatedValue = calcCPE(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["costPerDeliverable"]?.value,
        formik.values["totalEngagements"]?.value,
        formik.values["totalCost"]?.value,
        formik.values["postEngagements"]?.value,
    ]);

    useEffect(() => {
        const fieldKey = "attributedCPA";
        const formikValues = formik.values
        const calculatedValue = calcAttributedCPA(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["attributedPurchases"]?.value,
        formik.values["totalCost"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "attributedROAS";
        const formikValues = formik.values
        const calculatedValue = calcAttributedROAS(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["attributedPurchaseConversion"]?.value,
        formik.values["totalCost"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "attributedCVR";
        const formikValues = formik.values
        const calculatedValue = calcAttributedCVR(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);
        
        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["attributedPurchaseConversion"]?.value,
        formik.values["linkClicks"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "linkClickCPA";
        const formikValues = formik.values
        const calculatedValue = linkClickCPA(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["cpc"]?.value,
        formik.values["linkClickPurchases"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "linkClickROAS";
        const formikValues = formik.values
        const calculatedValue = linkClickROAS(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["linkClickPCV"]?.value,
        formik.values["cpc"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "linkClickCVR";
        const formikValues = formik.values
        const calculatedValue = linkClickCVR(formikValues);
        const updatedField = updateField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["linkClickPurchases"]?.value,
        formik.values["cpe"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "weeklyControl";
        const formikValues = formik.values
        const calculatedValue = weeklyControlFn(formikValues);
        const updatedField = updateStringField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["brand"]?.value,
        formik.values["week"]?.value,
        formik.values["objective"]?.value
    ]);

    useEffect(() => {
        const fieldKey = "monthlyControl";
        const formikValues = formik.values
        const calculatedValue = monthlyControlFn(formikValues);
        const updatedField = updateStringField(fieldKey, calculatedValue, formikValues);

        formik.setFieldValue(fieldKey, updatedField, false);
    }, [
        formik.values["month"]?.value,
        formik.values["brand"]?.value,
        formik.values["campaign"]?.value
    ]);

    

    function handlePopulateMonth(newValue: any) {
        const dateValue = newValue as string;
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = months[+dateValue.split("-")[1] - 1];

        const fieldKey = "month";
        const monthFieldValue: StatsFormField = formik.values[fieldKey] 
            ? {...formik.values[fieldKey], value: month } : 
            {
                required: true,
                type: 'select',
                key: 'month',
                label: 'Month',
                options: months,
                value: month
            }

        formik.setFieldValue(fieldKey, monthFieldValue, false);
        // formik.setFieldTouched(fieldKey, true, false);
    }

    function handlePopulateInfluencerLinkField(influencerName: string, influencerNameData: InfluencerData) {
        const { location: { protocol, host } } = window;
        const link = `${protocol}//${host}/app/influencers/${influencerNameData[influencerName]?.idx}`;
        const fieldKey = "influencerLink";
        let influencerField = {...formik.values[fieldKey]};
        influencerField.value = link;
        
        formik.setFieldValue(fieldKey, influencerField, false);
        formik.setFieldTouched(fieldKey, true, false);
    }

    function handlePopulateInfluencerName(influencerName: string, influencerhandlerNewData: InfluencerData, influencerNameNewData: InfluencerData){
        console.log("hello")
        Object.entries(influencerhandlerNewData).forEach(([key, itemHandler]) => {
            if(influencerName === key ){
                Object.entries(influencerNameNewData).forEach(([key, itemName]) => {
                    console.log("hello ran here")
                    if(itemHandler.idx === itemName.idx){

                        let influencerField = {...formik.values["influencerName"]};
                        influencerField.value = key
                        console.log(influencerField)
                        console.log("hello ran error ")
                        formik.setFieldValue("influencerName", influencerField, false);
                        formik.setFieldTouched("influencerName", true, false);
                    }
                })
            }
        })
    }

    useEffect(() => {
        // console.log(typeof(formik.values["influencerName"]?.value))
        handlePopulateInfluencerLinkField(formik.values["influencerName"]?.value.toString(), influencerNameData);
        autoPopulateInfluencerDetails(formik.values["influencerName"]?.value.toString());
    },[formik.values["influencerHandle"]?.value])

    function handleFieldInputChange(e: React.ChangeEvent<any>, formField: StatsFormField) {
        const formFieldKey = formField.key;

        const newValue = formField.type === "number" ? +e.target.value : e.target.value;
        

        const newFieldValue = { ...formField, value: newValue };

        formik.setFieldValue(formFieldKey, newFieldValue, false);

        if (formField.type === "date") {
            handlePopulateMonth(newValue)
        }

        if (formField.key === 'influencerHandle') {
            handlePopulateInfluencerName(newValue, influencerHandlerData, influencerNameData)
            
        }

        if (formField.key === 'influencerName') {
            console.log("influencerName => ", typeof(newValue))
            handlePopulateInfluencerLinkField(newValue, influencerNameData);
            autoPopulateInfluencerDetails(newValue);
        }

        
        let touchedFields = formik.touched;

        if (!touchedFields[formFieldKey]) {
            formik.setFieldTouched(formFieldKey, true, false);
        }
    }

    function handleOnChangeAddon(event: React.ChangeEvent<any>, formField: StatsFormField) {
        const addonValue = event.target.value;

        const newFieldValue: StatsFormField = formField.addon ? 
            { ...formField, addon: addonValue ?? formField.addon } : {...formField};

        formik.setFieldValue(formField.key, newFieldValue, false);
    }


    return (
        <LargeModal
            show={showModal}
            handleShowModal={handleShowModal}
        >
            <ModalHeader>
                <h1>{title || "Create Stats Data"}</h1>

                <button 
                    onClick={() => {
                        handleShowModal();
                        formik.resetForm();
                        setTableDataUpdate(tableFormStats)
                         
                    }} 
                    style={{ border: "none", background: "none"}
                }>
                    <i 
                        className="bi bi-x-lg" 
                        style={{ fontSize: "26px", color: "#000", opacity: 0.6}}
                    ></i>
                </button>
            </ModalHeader>

            <ModalBody>
                <form 
                    className="row" 
                    onSubmit={formik.handleSubmit}
                    style={{ overflowY: "scroll" }}
                >
                    {/* tableDataUpdate */}
                    {/* {tableFormStats.map((formField, index) => { */}
                    {tableDataUpdate.map((formField, index) => {
                        const isInput = formField.type === "text" || formField.type === "number" 
                            || formField.type === "url";

                        return (
                            <div 
                                key={formField.key + index} 
                                className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6"
                            >
                                {formField.type === "date" && (
                                    <div key={formField.key} className="my-3">
                                        <label className="d-block mb-2">
                                            {formField.label}
                                            <span className="text-danger fs-4">{formField.required && '*'}</span>
                                        </label>

                                        <input 
                                            style={{ 
                                                border: 'none', 
                                                width: '95%', 
                                                background: "#f4f4f4",
                                                padding: "6px 8px" 
                                            }} 
                                            type={formField.type} 
                                            name={formField.key}
                                            placeholder={formField.value.toString()}
                                            onChange={(event) => {
                                                handleFieldInputChange(event, formField);
                                            }}
                                            disabled={formField.disable}
                                            // defaultValue={formField?.value}
                                            // defaultValue={formField?.value}
                                            // value={formik.values[formField.key].value}
                                        />

                                        {formik.errors[formField.key] ? 
                                            <div className="d-block mt-1 mx-2">
                                                <p className="text-danger fw-bold">
                                                    {formik.errors[formField.key]}
                                                </p>
                                            </div> 
                                            : null
                                        }
                                    </div>
                                )}


                                {isInput && (
                                    <div key={formField.key} className="my-3">
                                        <label className="d-block mb-2">
                                            {formField.label}
                                            <span className="text-danger fs-4">{formField.required && '*'}</span>
                                        </label>

                                        <div className="d-flex align-items-stretch">
                                            {formField.addons ? (
                                                <span>
                                                    <select 
                                                        style={{ 
                                                            borderRadius: '3px', 
                                                            border:'none', 
                                                            cursor:'pointer', 
                                                            height: '36px', 
                                                            width: '3rem', 
                                                            appearance: 'none', 
                                                            textAlign: 'center', 
                                                            textOverflow: '', 
                                                            backgroundColor: '#dce2fc',
                                                        }}
                                                        onChange={(e) => handleOnChangeAddon(e, formik.values[formField.key])}
                                                        defaultValue={formik.values[formField.key]?.addon}
                                                    >

                                                    {formField.addons.map((option: any, index: number) => (
                                                        <option key={index} value={option}>{option}</option>
                                                    ))}

                                                    </select>

                                                </span>
                                            ): (
                                                <>
                                                    {formField.addon && (
                                                        <span
                                                            style={{
                                                                width: '3rem',
                                                                backgroundColor: '#dce2fc',
                                                                textOverflow: '', 
                                                                alignItems: "center",
                                                                display: "flex",
                                                                justifyContent: "center"
                                                            }}
                                                        >{formField.addon}</span>
                                                    )}
                                                </>
                                            )}
                                            <input 
                                                style={{ 
                                                    border: 'none', 
                                                    width: '95%', 
                                                    background: "#f4f4f4",
                                                    padding: "6px 8px" ,
                                                }} 
                                                type={formField.type}
                                                disabled={formField.disable}
                                                placeholder={formField?.value?.toString()}
                                                onChange={(event) => {
                                                    handleFieldInputChange(event, formField);
                                                }}
                                                name={formField.key}
                                                value={formik.values[formField.key]?.value}
                                            />
                                        </div>
                                        {formik.errors[formField.key] ? 
                                            <div className="d-block mt-1 mx-2">
                                                <p className="text-danger fw-bold">
                                                    {formik.errors[formField.key]}
                                                </p>
                                            </div> 
                                            : null
                                        }
                                    </div>
                                )}

                                {formField.type === "select" && (
                                    <div key={formField.key} className="my-3">
                                        <label className="d-block mb-2">
                                            {formField.label}
                                            <span className="text-danger fs-4">{formField.required && '*'}</span>
                                        </label>

                                        {formField.key === "month" ? (
                                            <select 
                                                style={{ ...selectElStyle }}
                                                name={formField.key}
                                                onChange={(event) => {
                                                    handleFieldInputChange(event, formField);
                                                    formik.setFieldTouched(formField.key, false, false)
                                                }}
                                                value={formik.values["month"].value}                                            
                                            >
                                                {formField.options?.map((option, index) => (
                                                    <option key={option + index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        ): (
                                            <select 
                                                defaultValue={formField.value}
                                                style={{...selectElStyle}}
                                                name={formField.key}
                                                onChange={(event) => {
                                                    handleFieldInputChange(event, formField);
                                                    formik.setFieldTouched(formField.key, false, false)
                                                }}
                                                
                                            >   
                                                {/* {console.log("formfield value => ",formField.value)} */}
                                                {formField.options?.map((option, index) => {
                                                    if(option === "Select type"){
                                                        return (
                                                            <option key={option + index} value={""}>
                                                                {option}
                                                            </option>
                                                        )
                                                    }
                                                    return (
                                                        <option key={option + index} value={option}>
                                                            {option}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        )}

                                        {formik.errors[formField.key] ? 
                                            <div className="d-block mt-1 mx-2">
                                                <p className="text-danger fw-bold">
                                                    {formik.errors[formField.key]}
                                                </p>
                                            </div> 
                                            : null
                                        }
                                    </div>
                                )}
                            </div>
                        )
                    })}

                    <div className="d-flex align-items-center justify-content-end p-4">
                        <button
                            className='cancel-btn'
                            type='button'
                            onClick={() => {
                                handleShowModal();
                                formik.resetForm();
                            }}
                        >
                            Cancel
                        </button>
                        <button 
                            className="btn btn-lg btn-info" 
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            {formik.isSubmitting ? 
                                (
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Submitting...</span>
                                  </div>  
                                ) :
                                "Submit"
                            }
                        </button>
                    </div>
                </form>
            </ModalBody>
        </LargeModal>
    )
}