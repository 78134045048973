import { toAbsoluteUrl } from "../../helpers";


interface EditButtonProps {
    handleClick?: (value?:any) => void;
    bgWhite?:boolean;
    text?:string
}

export const SaveButton = ({handleClick, bgWhite, text}: EditButtonProps) => {

    return (
        <button 
            className={`btn bg__black text__yellow px-4 width__content d-flex align-items-center button__edit`}
            onClick={handleClick}
        >
            {text || "Save"}
            <img src={toAbsoluteUrl("/media/cpg/save-icon.png")} alt={"save"} style={{ marginLeft: "6px", height: "16px"}} />
        </button>
    )
}