import {ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {toAbsoluteUrl} from "../../../../../../_metronic/helpers";
import * as functions from "../../../../../global/functions";
import {addDefaultSrc} from "../../../../../global/functions"
import { useAppSelector, useAppDispatch } from "../../../../../../setup/redux/hooks";
import { getEarnedMediaRes, uploadStaticImages } from "../../../../../../setup/features/report.slice";
import {EarnedMediaType} from "../../../../../../setup/types/report";
import Skeleton from "react-loading-skeleton";
import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"
import SVG from 'react-inlinesvg';


type ContentCardProps = {
    id: number | string;
    label: string;
    social: {
        platform: string;
        imageSrc: string;
        imageBgSrc: string;
        icon?: ReactNode;
        handle: string;
        link?:string;
    }
}

//function goes through a google drive url to extract the id
const idCollector = (url: any) => {
    const regexMatch = url.match(/\/d\/(.+?)\//);
    if (regexMatch && regexMatch.length > 1) {
        return regexMatch[1];
    }
    else {
        return null;
    }
}

// function checks if the url contains social media names and if its a google drive folder
const urlChecker = (url: any) => {
    if (url.includes("instagram") || url.includes("tiktok") || url.includes("folders")) {
        return true
    }
    return false
}

// function checks if the url is an uploaded image
const uploadImageChecker = (url: any) => {
    if (url.includes("amazonaws")) {
        return true
    }
    return false
}


const ContentCard = (
    {username, postId, socialNetwork, publisherLogo, thumbnail, earnedMedia, postLink, range, campaignId, isExternal} : 
    EarnedMediaType & {range:string, campaignId:string, isExternal?:boolean}) => {

    const dispatch = useAppDispatch();
    
    let iconPath : string = "";
    if (socialNetwork.toLowerCase() === "instagram") 
        iconPath = "/media/cpg/insta.png";
    if (socialNetwork.toLowerCase()=== "tiktok") 
        iconPath = "/media/cpg/tiktok.png";
    if (socialNetwork.toLowerCase() === "youtube") 
        iconPath = "/media/cpg/youtube.png";
    if (socialNetwork.toLowerCase() === "facebook") 
        iconPath = "/media/cpg/facebook.png";

    const [isImage, setIsImage] = useState(true)

    const [hoveredImageIndex, setHoveredImageIndex] = useState<string>("-1");
    const containerRef = useRef<HTMLDivElement>(null);
    const uploadRef = useRef<HTMLDivElement>(null);

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];

        const formData = new FormData();

        formData.append("campaign_id", `${campaignId ? campaignId?.toString() : ""}`);
        formData.append("file", image);
        formData.append("postId", hoveredImageIndex.toString())

        dispatch(uploadStaticImages(formData)).then(res =>{
            dispatch(getEarnedMediaRes({
                campaign_id: campaignId ? campaignId.toString() : "000000",
                is_external: isExternal,
                date_range: range ? range.toString() : "7"
            }));
        });
        
    }

    const handleImageHover = (index: string) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        if (hoveredImageIndex) {
            setHoveredImageIndex("-1");
        }
    }

    useEffect(() => {
        // Attach a click event listener to the document
        document.addEventListener('click', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function handleClickOutside(event: MouseEvent) {
        // Check if the click target is inside the div container
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && uploadRef.current && !uploadRef.current.contains(event.target as Node)) {
            // Clicked outside the container, update the state value
            handleImageOut()
        }
    }

    let socialOrVideo = useMemo(() => urlChecker(thumbnail), [thumbnail])

    let uploadImage = useMemo(() => uploadImageChecker(thumbnail), [thumbnail])

    let statsGooglePicture = useMemo(() => idCollector(thumbnail), [thumbnail])
    
    return (
        <div className="" ref={containerRef}>
            <div className="">
                <div className="position-relative mb-6 " 
                onMouseOver={() => handleImageHover && handleImageHover(postId)}>
                    <a href={postLink} target="_blank" rel="noreferrer">
                    <img 
                        onError={(ev) => addDefaultSrc(ev, "banner")}
                        src={thumbnail ? thumbnail : publisherLogo }
                        alt="hero-card"
                        className="image-fluid d-block"
                        style={{
                        width: '100%',
                        maxHeight: "150px",
                        resize: "both",
                        objectFit: "cover",
                        borderRadius: "10px",
                        
                    }}/>
                    </a>

                    <div
                        style={{
                        backgroundColor: "#fff",
                        padding: 7,
                        borderRadius: 5,
                        fontWeight: 600,
                        position: "absolute",
                        top: 10,
                        left: 10
                    }}>
                        <p className="fs-7 NeueMontreal-bold m-0">
                            ${functions.abvNum(typeof(earnedMedia) === "string"? parseFloat(earnedMedia) : earnedMedia.toFixed(2))}
                        </p>
                    </div>

                    {hoveredImageIndex === postId &&
                        (
                            <div ref={uploadRef}>

                                <label
                                    htmlFor="top-performing-dynamic"
                                    style={{
                                        border: "none",
                                        background: "#F4F4FA",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        position: "absolute",
                                        right: "10px",
                                        padding: "7px",
                                        borderRadius: "8px",
                                        // pointerEvents:"none",
                                        top: "10px",
                                        zIndex: 2,
                                        cursor: "pointer"
                                    }}
                                >
                                    <input name="" type="file" id="top-performing-dynamic"
                                        accept=".png,.jpg,.jpeg" hidden style={{ visibility: 'hidden', width: "1px", height: "1px" }}
                                        onChange={handleImageUpload} />
                                    <SVG
                                        src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')}
                                        className='svg-icon-2'
                                        style={{ width:"15px", height:"15px", zIndex: 5, cursor: "pointer" }}
                                    />
                                </label>
                            </div>
                        )
                    }

                </div>

                {publisherLogo && <div 
                className="d-flex flex-row mt-6 align-items-center" >
                    <div className="d-flex flex-row align-items-center" style={{width:"100%"}} >
                        <img
                            onError={(ev) => addDefaultSrc(ev)}
                            src={toAbsoluteUrl(publisherLogo)}
                            alt={`publisherLogo`}
                            style={{
                            height: "22px",
                            marginRight: "4px",
                            borderRadius: 4
                        }}/>

                        <p className="fs-8 long-text font-weight-normal m-0" >@{username || ""}</p>
                    </div>

                    <img
                        src={toAbsoluteUrl(iconPath)}
                        alt={"social platform"}
                        style={{
                        height: "22px",
                        marginLeft: "4px"
                    }}/>
                </div>
                }
            </div>
        </div>
    )
}

export const EarnedMedia = ({campaignId, isExternal}: { campaignId: string, isExternal?:boolean }) => {

    const dispatch = useAppDispatch();

    const { loading, error, data } = useAppSelector((state) => state.report.EarnedMediaDataRes);
    const range = useAppSelector((state) => state.report.range).toString();

    useEffect(() => {
        dispatch(getEarnedMediaRes({
            campaign_id: campaignId,
            is_external:isExternal,
            date_range: range.toString()
        }));
        
    }, [campaignId, dispatch, range])

    return (
        <>
        {loading ? <Skeleton height={300} count={1}/> :
        (
            <div className="px-4 pt-6 bg-white border__round_24 ml-16" style={{height:"100%"}}>
            <h1 className="text__20 px-4 NeueMontreal-bold text-dark">
                Earned Media
            </h1>

            {
                (error || data === null || data?.length === 0) ? (
                    <ErrorBoundary error={error}/>
                ) : (
                    <div
                        className="grid_content grid_content-3 grid_content_gap-50 px-4 element-without-scroll-bar py-6 "
                        style={{
                        maxHeight: "720px",
                        overflow: "scroll"
                    }}>
                        {data ? 
                        data?.map((dataInf:EarnedMediaType, index:number) => (
                            <div
                                key={index}
                                className="col-4"
                                style={{
                                width: "150px"
                            }}>
                                <ContentCard {...dataInf} 
                                campaignId={campaignId} 
                                isExternal={isExternal}
                                range={range.toString()}/>
                            </div>
                        ))
                        : 
                        <h1 style={{textAlign:"center"}}>No earned influencer content available at this time</h1>}
                    </div>
                )
            }
            
        </div>
        )}
        </>
    )
}