import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'
import ReactApexChart from "react-apexcharts";

import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export default function BrandEngagementTrends(props:any){
    // const chartRef = useRef<HTMLDivElement | null>(null)
    const options = {
        xaxis: {
            categories: ["10 Dec", "20 Dec", "23 Jan", "10 Feb", "15 Feb", " 20 Feb", "25 Feb"]
        },
        dataLabels: {
            enabled: false,

        },
    };
    const series = [
        {
            name: "series-1",
            data: [0, 40, 55, 60, 70, 88, 90, 103]
        },

    ];

    return (
        <div className="engagements_wrap">
            <div className="section_head">Engagement Trends</div>
            <div className="section_content">
                <div className="social_icons_wrap">
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/user.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tiktok.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/ig.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/fb.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/pin.svg')}
                        />
                    </div>
                    <div className="each_icon">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/socials/tw.svg')}
                        />
                    </div>
                </div>
                <div className="all_top_posts">
                    <ReactApexChart options={options} series={series} type="area" />
                </div>
                <div className="all_top_posts">
                    <ReactApexChart options={options} series={series} type="area" />
                </div>
            </div>
        </div>
    )
}