import Modal from "react-bootstrap/Modal";
import ModalHeader from "./ModalHeader";
import TPCModalBody from "../topPerforming/TPCModalBody";
import classes from "./style.module.css";


interface AppModalProps {
    show: boolean;
    handleShowModal(): void;
    influencerId?:string;
    topData:any;
    campaignId:string;
    isExternal:boolean | undefined;
}


export default function TPCModal({ 
    show, 
    influencerId,
    handleShowModal, 
    topData,
    campaignId,
    isExternal
}: AppModalProps) {
    
    
    return (
        <Modal
            show={show}
            dialogClassName={classes.myModalStyle}
            size="xl"
        >
            <Modal.Header className="border-0">
                <ModalHeader 
                    heading="Creator Breakdown" 
                    headingPostion="left" 
                    rightIcon={
                        <i className="bi bi-x-circle-fill"  
                            style={{fontSize: "30px"}}
                            onClick={handleShowModal}
                        ></i>
                    }/>
            </Modal.Header>

            <Modal.Body>
                <TPCModalBody 
                influencerId={influencerId ?? ""} 
                topData={topData} campaignId={campaignId} 
                isExternal={isExternal}/>
            </Modal.Body>
        </Modal>
    )
}