import { metricValueFormatter, abvNum } from "../../../helper";


type UnitType = "currency" | "percentage" | "no-unit";

interface DPValueProps {
    value: number;
    unit: UnitType;
    textStyle?: React.CSSProperties;
    dataPoint: string;
    dataPointStyle?: React.CSSProperties;
    integerValue?: boolean;
}


function getUnit(unit: UnitType) {
    switch (unit) {
        case "currency":
            return "$";
        case "percentage":
            return "%";
        default:
            return "";
    }
}

export const DataPointValue = ({
    dataPoint, dataPointStyle, textStyle, unit, value, integerValue
}: DPValueProps) => {
    return (
        <div className="text-center">
            <p
                style={{
                    color: "#000",
                    fontSize: 26,
                    fontWeight: 600,
                    fontFamily: 'NeueMontreal-bold',
                    marginBottom: 2,
                    ...textStyle
                }}
            >
                {unit !== "percentage" && <span>{getUnit(unit)}</span>}
                
                {integerValue ? value : value? abvNum(value.toFixed(2)) : "0"}

                {unit === "percentage" && <span>{getUnit(unit)}</span>}
            </p>

            <p
                
                style={{
                    color: "#000",
                    fontSize: 16,
                    fontWeight: 400,
                    margin: 0,
                    ...dataPointStyle
                }}
            >{dataPoint}</p>
        </div>
    )
}