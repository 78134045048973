

interface Params {
    error: string | null;
}

const ErrorBoundary = ({error}:Params) => {
    
  return (
    <>
        <div className="d-flex flex-center text-center text-muted bg-white text_red font-bolder m-4 min-h-150px">
            Nothing to see,
            <br /><br />
            {error || "Data not available"}

        </div>
    </>
  )
}


export {ErrorBoundary}
