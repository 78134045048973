export function clicksChartArray(clickLinks:object[]) {
    let finalObject : { [key: string]: number } = {}
    clickLinks && clickLinks.map((option: any, index: number) => {
        
        let clickValues = option.clicks ? option.clicks : {}
        for (const key in clickValues) {
            if (finalObject[key]) {
              finalObject[key] += finalObject[key];
            } else {
              finalObject[key] = clickValues[key];
            }
        }
        return 1
    })

    return finalObject
}