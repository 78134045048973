import { ApexOptions } from "apexcharts";
import { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import {ErrorBoundary} from "../../../../../sharedComponents/ErrorBoundary"

import Skeleton from "react-loading-skeleton";
import { getMonthAndDay, metricValueFormatter } from "../../../../../../helper";

import { getCampaignAnalytics } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import * as functions from "../../../../../global/functions";


export const VerticalBar = ({ campaignId, isExternal, option, tab }: { campaignId: string, isExternal?: boolean, option:string, tab:string }) => {
  const { data: analytics, loading, error } = useAppSelector((state) => state.report.campaignAnalytics);
  const [chartHeight, setChartHeight] = useState<number>(0);
  const [chartWidth, setChartWidth] = useState<number>(0);
  const chartRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { loading:graphLoading, error:graphError, data } = useAppSelector((state) => state.report.GraphRes);

  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaignAnalytics({
        campaign_id: campaignId,
        is_external: isExternal
      }));
    }
  }, []);

  useEffect(() => {
    
    const chartContainer = chartRef?.current !== null && chartRef?.current!.parentElement ? chartRef?.current!.parentElement : document.createElement('div');
    // console.log(chartRef?.current!.parentElement)
    if (chartContainer) {
      const { width, height } = chartContainer.getBoundingClientRect();
      setChartHeight(height || 0);
      setChartWidth(width || 0);
    }
  }, [graphLoading]);

  if (loading) {
    return <Skeleton height={40} />
  }

  // const impressionData = analytics.map((analytic) => {
  //   const { createdAt, data: { Impressions } } = analytic;

  //   return { date: getMonthAndDay(createdAt), value: Impressions };
  // });

  // const series = {
  //   name: "Impressions",
  //   data: impressionData.map((data) => data.value).slice(impressionData.length - 7, impressionData.length)
  // }

  const series = {
    name: option.charAt(0).toUpperCase() + option.slice(1),
    data: data && data[tab.toLowerCase()] ? data[tab.toLowerCase()].map((d:any) => d[option] || 0)  : []
  }

  let options: ApexOptions = {
    colors: ["#33F3ED"],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "vertical",
        shadeIntensity: 0.4,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.6,
        stops: [60, 90, 100],
        // colorStops: []
      }
    },
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: false,
        rangeBarOverlap: true,
        columnWidth: '55%',
        borderRadius: 10
      }
    },
    dataLabels: {
      enabled: false,
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val: any): string {
    //       return metricValueFormatter(val, 4)
    //     }
    //   }
    // },
    xaxis: {
      categories: data && data[tab.toLowerCase()] ? 
      data[tab.toLowerCase()].map((d:any, index:number) => (d.week) || index) : [],
      // axisBorder: {
      //   show: false
      // },
      axisTicks: {
        show: false,
      },
      title: {
        text: 'weeks'
      }
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return functions.abvNum(val);
        }
      }
    },
    // markers: {
    //   size: 1,
    //   colors: ["#ff0000"],
    //   discrete: [{
    //     seriesIndex: 0,
    //     // For dataPointIndex: 0 there is no discrete marker at all!
    //     dataPointIndex: 1,

    //     // Also docs says its "fill" and "stroke", but "fillColor" and "strokeColor" are working
    //     fillColor: "#0A0",
    //     strokeColor: "#FFF",
    //     size: 7
    //   }
    //   ]
    // },
    // markers: {
    //   size: 8,
    //   offsetY: -5,
    //   colors: ["#fff", "#008FFB"],
    //   strokeColors: "#FEA201",
    //   strokeWidth: 3,
    //   hover: {
    //     size: 8,
    //   }
    // },
    annotations: {
      points: [
        // {
        //   x: 0,
        //   y: 60,
        //   // seriesIndex:3,
        //   marker: {
        //     size: 10,
        //     shape: 'circle',
        //   },
        // },
        // {
        //   x: 40,
        //   y: 7,
        //   seriesIndex:0,
        //   marker: {
        //     size: 10,
        //     shape: 'circle',
        //   },
        // },
      ]
    }
  }

  return (

    <div id="vertical_chart1">
      {graphLoading && <Skeleton height={120} count={1} />}
      {!graphLoading && !loading && 
        (graphError) ? (

            <ErrorBoundary error={error} />
        ):
          (
            <div className="mt-2 chart-scrollable" ref={chartRef} 
            style={{
              width:"100%", 
              height:"inherit", 
              overflow:"scroll" }}>
              <Chart
                type="bar"
                options={options}
                series={[series]}
                width={"100%"}
              />
            </div>
          )
      }
    </div>
  )
}