import React from 'react'
// import { Redirect, Route, Switch } from 'react-router-dom'

import SummaryHeadStats from './summary/HeadStats';
import SummaryEngagements from './summary/TopEngagements';
import ActionableInsights from './summary/ActionableInsights';



export default function SocialListingSummary(props: any) {
    let { brandsSummaryInfo, chosenBrand, timeSeries, brandsList, brandSocialPosts, brandsOptimizationInfoData, isExternal, isPdfPage, startDateToCompare, endDateToCompare } = props;



    let brandSelected = brandsSummaryInfo[0].metrics.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId)[0]
    let brandDataToCompare = brandsSummaryInfo?.compareData[0]?.metrics.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId)[0]

    let seriesForBrandOnly = timeSeries?.social_post_series.filter((eachItem: any) => eachItem.companyId == chosenBrand?.focusCompanyId);



    return (
        <div className={isPdfPage ? "pdf-wrap" : ""}>
            <div className={"brand_glance each_section_wrap"} id="brand-summary">
                <div id="slide1">
                    <div className="section_title">Brand Summary</div>

                    <SummaryHeadStats chosenBrand={brandSelected} timeSeries={seriesForBrandOnly} dataToCompare={brandDataToCompare} />
                </div>
                <SummaryEngagements brandSocialPosts={brandSocialPosts} brandsList={brandsList} timeSeries={timeSeries} />
                <ActionableInsights startDateToCompare={startDateToCompare} endDateToCompare={endDateToCompare}  isExternal={isExternal} dataToCompare={brandDataToCompare} chosenBrandId={chosenBrand?.focusCompanyId} chosenBrand={brandSelected} brandsOptimizationInfoData={brandsOptimizationInfoData} timeSeries={seriesForBrandOnly} />

            </div>
        </div>
    )
}