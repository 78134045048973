import { toast } from "react-toastify";
import * as requestFromServer from "./Crud";
import { CampaignSlice, callTypes } from "./Slice";

const { actions } = CampaignSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignDetails = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDetails(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignDetails(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

const cumulativeSum = (sum => (value) => sum += value)(0);
        
export const fetchStuff = (type, queryParams, reds = "false") => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStuff(type, queryParams)
    .then(response => {
      let res = response.data;
      if (type === 'links') {
        dispatch(actions.campaignLinks(res.success));
      } 
      // else if (type === 'clicks') {
      //   if (reds === 'true') {
      //     return res.success;
      //   }
      // } 
      else if (type === 'click_analytics') {
        dispatch(actions.campaignAnalytics(res.success));
        // var cl = 0;
        // res.success.forEach((ee,i) => {
        //   cl+=ee.clicks
        //   // new_data.push([ee.date, cumulativeSum(ee.clicks)])
        // })
        
        // dispatch(actions.campaignClicks(cl));
        
      } else if (type === 'analytics') {
        dispatch(actions.campaignRealAnalytics(res.success));
      } else if (type === 'clicks') {
        dispatch(actions.campaignClicks(res.success));
      }else if (type === 'corrections') {
        dispatch(actions.campaignCors(res.success));
      } else if (type === 'activity') {
        dispatch(actions.campaignAct(res.success));
      } 
    })
    .catch(error => {
      error.clientMessage = "Can't fetch details";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const reset_campaign_info = () => dispatch => {
  dispatch(actions.reset());
};

export const fetchCampaignStats = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStats(id)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStats(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignEngagementRates = (id, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchEngagementRates(id, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignEngagementRates(res));
    })
    .catch(error => {
      if (error.response.status === 403) {
        dispatch(actions.campaignEngagementRatesError());
      }
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignScore = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchScore(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignPosts = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchPosts(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignPosts(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDefault = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDafault()
    .then(response => {
      let res = response.data;
      dispatch(actions.default(res.success[0]));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignInfluencers = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchCampaignInfluencers(type, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignInflencers(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = id => dispatch => {
  if (!id) {
    return dispatch(actions.DataFetched({ DataForEdit: undefined }));
  }
};

export const editDetails = (body, queryParams, type = 'details') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .editDetails(body, queryParams, type)
    .then(response => {
      let res = response.data;
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addCampaignStats = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addCampaignStats(data)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteData = (queryParams, type = 'details') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(queryParams, type)
    .then(response => {
      let res = response.data;
      return res;
      // dispatch(actions.campaignScore(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCampaignStatsAll = (id, formType, statType="organic") => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsAll(id, formType, statType)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsAll(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignStatsPaginated = (id, formType, statType="organic", queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsPaginated(id, formType, statType, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsAll(res.success));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCampaignStatsCount = (id, formType, statType="organic", queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchStatsPaginated(id, formType, statType, queryParams)
    .then(response => {
      let res = response.data;
      dispatch(actions.campaignStatsCount(res.count));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteCampaignStat = (body) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .deleteSingleStat(body)
    .then(response => {
      let res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const editCampaignStat = (body) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .editSingleStat(body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      error.clientMessage = "Can't fetch stats";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};