import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { useAppDispatch, useAppSelector } from "../../../../../../../setup/redux/hooks";
import { addReportComment, getCampaignComments, deleteReportComment } from "../../../../../../../setup/features/report.slice";
interface Props {
    commentFilter?: string;
    allComments?: any;
    setCommentSectionToDisplay?: any;
    isExternal?: boolean;
    campaignId?: any
}

const formatDate = (dateValue: any) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let dateformatted: any = new Date(dateValue);
    dateformatted = `${dateformatted.getDate()} ${monthNames[dateformatted.getMonth()]} ${dateformatted.getFullYear()}`;


    return dateformatted;
}

export const CommentListPopUp = ({ commentFilter, allComments, setCommentSectionToDisplay, isExternal, campaignId }: Props) => {
    const [commentText, setCommentText] = useState<string>("");
    const [commentUserName, setCommentUserName] = useState<string>("");
    const [commentToDelete, setCommentToDelete] = useState<string>("");

    const [commentToSubmit, setCommentToSubmit] = useState<string>("");
    const dispatch = useAppDispatch();
    const { loading: addCommentsLoading, error: addCommentsError, data: addCommentsResponse, } = useAppSelector((state) => state.report.addReportCommentRes);
    
    const { loading: deleteCommentsLoading, error: deleteCommentsError, data: deleteCommentsResponse, } = useAppSelector((state) => state.report.deleteReportCommentRes);

    const { loading: commentsLoading, error: commentsError, data: commentsData, } = useAppSelector((state) => state.report.commentsRes);
    useEffect(() => {
        if (commentToSubmit) {
            let payload:any = {
                campaign_id: campaignId,
                comment: commentToSubmit,
                tab_name: commentFilter
            }
            if(isExternal){
                payload.fullname = commentUserName
            }else{
                delete payload.fullname
            }
            // console.log("payload is", payload)
            dispatch(addReportComment({
                data: payload,
                is_external: isExternal
            }));

        }
    }, [commentToSubmit])

    useEffect(() => {
        if (commentToDelete) {
            // console.log("payload is", payload)
            dispatch(deleteReportComment({
                data: commentToDelete
            }));

        }
    }, [commentToDelete])

    useEffect(() => {
        if (addCommentsResponse) {
            setCommentText("")
            // let payload = {
            //     campaign_id: campaignId,
            //     comment: commentToSubmit,
            //     tab_name: commentFilter
            // }
            // console.log("payload is", payload)
            dispatch(getCampaignComments({ campaign_id: campaignId, is_external: isExternal }));

        }
    }, [addCommentsResponse])
    useEffect(() => {
        if ( deleteCommentsLoading && !deleteCommentsError ) {
            setCommentText("")
            // let payload = {
            //     campaign_id: campaignId,
            //     comment: commentToSubmit,
            //     tab_name: commentFilter
            // }
            // console.log("payload is", payload)
            dispatch(getCampaignComments({ campaign_id: campaignId, is_external: isExternal }));

        }
    }, [deleteCommentsLoading])

    // console.log("addCommentsLoading", addCommentsLoading)
    // console.log("addCommentsError", addCommentsError)
    // console.log("addCommentsResponse", addCommentsResponse)
    return (
        <>
            <div className="comments_underlay" onClick={() => setCommentSectionToDisplay()} ></div>
            <div className="comments_wrap">
                <div className="comments_heading">{commentFilter}</div>
                {(allComments.length === 0 && !commentsLoading) && <div className="no_comments">No comments at the moment</div>}
                {(commentsLoading) && <div className="no_comments">Fetching comments</div>}

                <div className="all_comments_list">
                    {
                        allComments.map((eachItem: any, index: any) => {
                            return (
                                <div className="each_comment" key={index}>
                                    <div className="comment_heading">
                                        <div className="comment_user_and_date">
                                            <div className="comment_user_n_delete">
                                                <div className="comment_user">{eachItem?.user}</div>
                                               {!isExternal &&
                                                <div className="delete_ico" onClick={()=>setCommentToDelete(eachItem?._id)}>
                                                    <img src={toAbsoluteUrl("/media/cpg/delete_ico.png")} />
                                                </div>
                                                }
                                            </div>
                                            <div className="comment_date">{eachItem?.createdAt ? formatDate(eachItem?.createdAt) : ""}</div>
                                        </div>
                                    </div>
                                    <div className="comment_txt">{eachItem?.comment}</div>
                                    {(commentToDelete===eachItem?._id && deleteCommentsLoading) && <div className="delete_overlay"> Deleting...</div> }
                                </div>

                            )
                        })
                    }

                </div>
                {!commentsLoading &&
                    <div className="leave_a_comment_wrap">
                        {(isExternal && !addCommentsLoading) && <input value={commentUserName} onChange={(e: any) => setCommentUserName(e.target.value)} type="text" placeholder="Full Name" /> }
                        <div className="leave_a_comment">
                            {addCommentsLoading &&
                                <div className="adding_comments">
                                    Uploading comment...
                                </div>
                            }
                            <textarea value={commentText} onChange={(e: any) => setCommentText(e.target.value)} rows={3} placeholder="Add Comment"></textarea>
                            <button type="button"
                                onClick={() => {
                                    if (commentText.length >= 1) {
                                        setCommentToSubmit(commentText)
                                    }
                                }}
                            >
                                <img src={toAbsoluteUrl("/media/cpg/send.svg")} />
                            </button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}