import { useEffect, useState, useRef } from 'react'
import Chart from "react-apexcharts";

function RadialChart(props: any) {
    // console.log(props)
  const { width = '250px', stroke = 2, value = 'n/A', label, rate, fill = '#06D6A0', oop } = props;
  const chartHeight = 200;
  const labelColor = 'black';
  const chart_ref = useRef<any>();
  const baseColor = 'yellow';
  const [option, set_options] = useState<any>({
    series: [stroke],
    opt: {
      chart: {
        fontFamily: 'inherit',
        height: chartHeight,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -120,
          endAngle: 120,
          track: {
            backgroundColor: '#AAAAAA',
            strokeWidth: 66,
          },
          hollow: {
            margin: 0,
            size: '52%',
          },
          dataLabels: {
            name: {
              show: false,
              fontWeight: '600',
            },
            value: {
              color: labelColor,
              fontSize: '16px',
              fontWeight: '500',
              offsetY: 1,
              show: true,
              formatter: function (val: any) {
                // return val + '';
                return props.meta;
              },
            },
          },
        },
      },
      colors: [baseColor],
      stroke: {
        lineCap: 'round',
      },
      labels: [props.meta],
      fill: {
        colors: [fill],
      },
      ...oop,
    },
  });

  // console.log(option)

  useEffect(() => {
    if (props) {
      // set_options((prevState: any)=> ({
      //     ...prevState,
      //     series: [props.stroke],
      // }))
      
      var option_da = {...option}
      option_da.opt.labels = ['dcdfd'];
   
      const newOptions = {
        ...option,
        series: [props.stroke],
        opt: {
          plotOptions: {
            radialBar: {
              startAngle: -120,
              endAngle: 120,
              track: {
                backgroundColor: '#AAAAAA',
                strokeWidth: 66,
              },
              hollow: {
                margin: 0,
                size: '52%',
              },
              dataLabels: {
                name: {
                  show: false,
                  fontWeight: '600',
                },
                value: {
                  color: labelColor,
                  fontSize: '18px',
                  fontWeight: '800',
                  offsetY: 1,
                  show: true,
                  formatter: function (val: any) {
                    return props.meta
                  },
                },
              },
            },
          },
        }
      }


      set_options(newOptions)

      

    }


  }, [props]);

  return (
    <div className="radial-container d-flex flex-column align-items-center" style={{ width: width }}>
      <Chart 
      ref = {chart_ref}
      options={option.opt} series={option.series} type="radialBar" />
      <div className="position-relative" style={{ bottom: '10px' }}>
        <span className="text-center d-block mb-1 text-gray-400" style={{ fontSize: '12px' }}>{rate || 0}</span>
        <span className="fs-6 text-center d-block fw-bold">{label}</span>
      </div>
    </div>
  )
}


export { RadialChart }