import { FC, useState } from 'react'
import clsx from 'clsx'
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { classnames } from '../../../../helper';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px'
  },
};
const platforms = ['TikTok', 'Facebook', 'WeChat', 'Instagram', 'Youtube', 'Snapchat', 'LinkedIn', 'Podcasts', 'Pinterest', 'Twitter', 'Weibo', 'Twitch'];
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const inputClassName = "appearance-none w-100 d-block px-3 h-48px border bg-gray-200 rounded placeholder-gray-500 fs-5";
const styles = {
  height: '54px',
  marginBottom: '16px',
  backgroundColor: '#F4F4FA',
}
const Topbar: FC = (props) => {
  let subtitle: any;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [campaignName, setCampaignName] = useState<string>('');
  const [budget, setBudget] = useState<any>('');
  const [client, setClient] = useState<string>('');
  const [fromDate, setFromDate] = useState<any>('');
  const [toDate, setToDate] = useState<any>('');

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        {/* <button
          className='btn btn-primary border border-dark rounded rounded-3'
          type='button'
          onClick={() => setIsOpen(true)}
          style={{ backgroundColor: '#3771C8' }}
        >
          <img
            className='me-2'
            style={{ width: '18px', height: '18px' }}
            src={toAbsoluteUrl('/media/cpg/rocket-takeoff.png')}
            alt='l'
          />
          Launch Campaign
        </button> */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-48px w-48px rounded'
              src={toAbsoluteUrl('/media/cpg/avatar.png')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  )
}

export { Topbar }
