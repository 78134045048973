import { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

function RadialChart(props: any) {
  const { width = '200px', stroke = 2, value = 'n/A', label, rate, fill = '#06D6A0', oop } = props;
  const chartHeight = 200;
  const labelColor = 'black';
  const baseColor = 'yellow';
  const [option, set_options] = useState<any>({
    series: [stroke],
    opt: {
      chart: {
        fontFamily: 'inherit',
        height: chartHeight,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -110,
          endAngle: 110,
          track: {
            backgroundColor: '#AAAAAA',
            strokeWidth: 66,
          },
          hollow: {
            margin: 0,
            size: '62%',
          },
          dataLabels: {
            name: {
              show: false,
              fontWeight: '600',
            },
            value: {
              color: labelColor,
              fontSize: '20px',
              fontWeight: '800',
              offsetY: 1,
              show: true,
              formatter: function (val: any) {
                return val + '%'
              },
            },
          },
        },
      },
      colors: [baseColor],
      stroke: {
        lineCap: 'round',
      },
      labels: ['Progress'],
      fill: {
        colors: [fill],
      },
      ...oop,
    },
  });

  useEffect(() => {
    if (props) {
      set_options((prevState: any)=> ({
          ...prevState,
          series: [props.stroke]
      }))
    }
  }, [props]);

  return (
    <div className="radial-container d-flex flex-column align-items-center" style={{ width: width }}>
      <Chart options={option.opt} series={option.series} type="radialBar" />
      <div className="position-relative" style={{ bottom: '10px' }}>
        <span className="text-center d-block mb-1 text-gray-400" style={{ fontSize: '12px' }}>{rate || 0}</span>
        <span className="fs-6 text-center d-block fw-bold">{label}</span>
      </div> 
    </div>   
  )
}


export { RadialChart }