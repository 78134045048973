/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DataTable, { TableProps } from 'react-data-table-component';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as actions from "../_redux/campaign/Actions";
// import { tableForms } from './data'

import {tableForms, paidTableForms} from '../util/campaignFields'
import { roundToDecimal } from '../../../../helper';
import AddCampaignStats from './stats/AddCampaignStats';
import { InfluencerData, StatsFormField } from '../types';
import { UploadData } from './stats/UploadData';



const classnames = (...classes: any) => classes.filter(Boolean).join(' ');

const organicOptions = [
  // { value: 'estimatedDeliveryOrganic', label: 'Estimated Delivery', img: 'overall' },
  { value: 'fbIgOrganic', label: 'Facebook | Instagram', img: 'facebook' },
  { value: 'tiktokOrganic', label: 'TikTok', img: 'sm-tiktok' },
  { value: 'youtubeOrganic', label: 'Youtube', img: 'sm-yt' },
  // { value: 'actualDeliveryOrganic', label: 'Actual Delivery', img: 'overall' },
]

const paidOptions = [
  // { value: 'estimatedDeliveryPaid', label: 'Estimated Delivery', img: 'overall' },
  { value: 'fbIgPaid', label: 'Facebook | Instagram', img: 'facebook' },
  { value: 'tiktokPaid', label: 'TikTok', img: 'sm-tiktok' },
  { value: 'youtubePaid', label: 'Youtube', img: 'sm-yt' },
  // { value: 'actualDeliveryPaid', label: 'Actual Delivery', img: 'overall' },
]

// data_type: "organic" || "paid" 

const CampaignStats = (props: any) => {

  let { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const [view, setView] = useState<string>('organic');
  const [editing, setEditing] = useState<boolean>(false);
  const [formFields, updatefields] = useState<any>({});
  const [tableColumns, updateTableColumns] = useState<any>([]);
  const [tableData, updateTableData] = useState<any>([]);
  const [espana, setEspana] = useState<any>([]);
  const [currentFormName, setCurrentFormName] = useState('fbIgOrganic');
  const [defaultformSelect] = useState({ value: 'fbIgOrganic', label: 'Facebook | Instagram', img: 'facebook' });
  const [currentForm, setCurrentForm] = useState<StatsFormField[]>(tableForms[currentFormName]);

  const [skip, setSkip] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [actionRow, setActionRow] = useState<any>({});
  const [inflHandlerIdx, setInflHandlerIdx] = useState<InfluencerData>({});
  const [inflNameIdx, setInflNameIdx] = useState<InfluencerData>({});
  const [pumpfake, setPumpfake] = useState(1);
  const [ddOptions, setDdOptions] = useState<any>(organicOptions)
  const [ showModal, setShowModal ] = useState<boolean>(false);
  const [ isEdit, setIsEdit ] = useState<boolean>(false);


  const { campaignStatsAll, campaignStatsCount, campaignDetails, campaignInflencers } = useSelector(
    (state: any) => ({
      campaignDetails: state.campaign.campaignDetails,
      campaignStatsAll: state.campaign.campaignStatsAll,
      campaignStatsCount: state.campaign.campaignStatsCount,
      campaignInflencers: state.campaign.campaignInflencers,
    })
  );

  

  let roro: any = [];
  let inflIndex: any = {};

  useEffect(() => {
    dispatch(actions.reset_campaign_info());
    dispatch(actions.fetchCampaignDetails(id));
    dispatch(actions.fetchCampaignEngagementRates(id));
    dispatch(actions.fetchCampaignScore(id));
    dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
    dispatch(actions.fetchCampaignStatsCount(id, currentFormName, view, '&count=1'));
    pageChangeManual(10);
    setPumpfake(pumpfake + 1)
    if (view === 'organic') {
      setDdOptions(organicOptions)
    }
    if (view === 'paid') {
      setDdOptions(paidOptions)
    }
  }, []);

  useEffect(() => {
    if (view === 'organic') {
      setDdOptions(organicOptions)
    }
    if (view === 'paid') {
      setDdOptions(paidOptions)
    }
    pageChangeManual(10);
    setPumpfake(pumpfake + 1)
  }, [view])

  useEffect(() => {
    dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
    dispatch(actions.fetchCampaignStatsCount(id, currentFormName, view, '&count=1'));
  }, [currentForm, view]);

  useEffect(() => {
    if (campaignInflencers && campaignInflencers.length > 0) {
      const inflHandlerList: any = ['Select Influencer Handle'];
      const inflNameList: any = ['Select Influencer Name'];
      const inflIdxHandleClone = inflHandlerIdx;
      const inflIdxNameClone = inflNameIdx;

      campaignInflencers.map((infl: any) => {
        inflHandlerList.push(infl?.UserName);
        inflIdxHandleClone[infl?.UserName] = {
          idx: infl?.PublisherId,
          postsNo: infl?.RequiredPostsTotal,
        };
        
        inflNameList.push(infl?.PublisherName);
        inflIdxNameClone[infl?.PublisherName] = {
          idx: infl?.PublisherId,
          postsNo: infl?.RequiredPostsTotal,
        };
      })
      setInflHandlerIdx(inflIdxHandleClone);
      setInflNameIdx(inflIdxNameClone);

      currentForm.map((field, index) => {
        if (field.key === "influencerHandle") {
          field.options = inflHandlerList;
          currentForm[index] = field;
        }
      });

      currentForm.map((field, index) => {
        if (field.key === "influencerName") {
          field.options = inflNameList;
          currentForm[index] = field;
        }
      });
    }
  }, [campaignInflencers, currentForm, view])

  useEffect(() => {
    instTable();
  }, [campaignStatsAll, pageLimit]);

  useEffect(() => {
    if (campaignDetails?.PlatformsJSON) {
      const exe:any = [];
      let platforms = JSON.parse(campaignDetails?.PlatformsJSON || "{}");
      Object.keys(platforms).map(el => {
        if (platforms[el]) {
          exe.push(el);
        }
      })

      currentForm.map((field, index) => {
        if (field.key === "deliverable") {
          // const combinedOptions = new Set(currentForm[index].options?.concat(exe));
          const combinedOptions = new Set(currentForm[index].options)
          currentForm[index].options = Array.from(combinedOptions);
          
        }
      });
    }
  }, [campaignDetails, currentForm])

  const handleShowModal = () => {
    setIsEdit(false)
    setShowModal((prev) => !prev);
  }

  const handleClickAdd = () => setShowModal(true);
  const handleClickEdit = () => {
    setIsEdit(true);
    setShowModal(true);
  }

  const deleteTableRow = (row: any, index: number) => {
    const rowToDelete = campaignStatsAll[index];
    setActionRow(rowToDelete);
    const body: any = {};
    
    body.row_id = rowToDelete._id;
    body.data_type = view
    dispatch(actions.deleteCampaignStat(body)).then((res: any) => {
      toast.success('Successfully deleted campaign stat');
      dispatch(actions.fetchCampaignStats(id));
      dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
    })
  }

  const editStatsForm = (index: number) => {
    setIsEdit(true);
    setActionRow(campaignStatsAll[index]);
    const campaignStatsFields: Record<string, StatsFormField> = campaignStatsAll[index]?.data;
    const allKeys = Object.keys(campaignStatsFields);

    setCurrentForm((prev) => {
      let prevClone = [...prev];

      allKeys.forEach((key, index) => {
        
        for (let index = 0; index < prevClone.length; index++) {
          let field = prevClone[index];

          if (field.key === key) {
            let cmpField = campaignStatsFields[key];
            let cmpFieldOptions = cmpField.options;

            if (field.options && cmpFieldOptions) {
              field.options = [...cmpFieldOptions]
            }
            field = {
              ...campaignStatsFields[key],
            }
            prevClone[index] = field;
            break;
          }
        }

      })

      return prevClone;
    })

    handleClickEdit();
  }

  const instTable = () => {
    const cols: any = [
      {"name": "S/N",
      "selector":(row: any) => row["S/N"],
      "fixed":"left",
      style: {
        position: 'sticky',
        left: 0,
        zIndex:10,
        backgroundColor: '#f8f8f8',
      }}
    ];
    const rows: any = [];
    let row: any = {};
    let addedRows = false;
    if (campaignStatsAll && campaignStatsAll.length > 0) {
      campaignStatsAll.map((stat: any, index: number) => {
        if (stat && stat.data) {
          row = {
            "S/N": index + 1,
          }; // clears row of any data in it
         
          Object.keys(stat.data).map((key: any) => {
            if (!addedRows) {
              cols.push({
                name: stat.data[key].label,
                selector: (row: any) => row[stat.data[key].label],
              });
            }
            const label = stat.data[key].label;
            const value = stat.data[key].addon ? `${stat.data[key].addon} ${stat.data[key].value}` : stat.data[key].value;

            row[label] = value || 'n/A';
          });
          addedRows = true;
          rows.push(row);
        }
      });
    } 
    cols.push({
      cell: (row: any, index: any) => (
        <>
          <div onClick={() => deleteTableRow(row, index)}>
            <img src={toAbsoluteUrl('/media/cpg/delete.png')} alt="" style={{ width: '32px', objectFit: 'cover', margin: '0 4px' }} />
          </div>
          <div onClick={() => {
            // setEditRowData({ isEdit: true, rowIndex: index }); 
            editStatsForm(index);
          }}>
            <img src={toAbsoluteUrl('/media/cpg/edit.png')} alt="" style={{ width: '32px', objectFit: 'cover', margin: '0 4px' }} />
          </div>
        </>
      ) 
    });
    setTimeout(() => {
      updateTableColumns(cols);
      updateTableData(rows);

    }, 200)
  }

  const addCampaignStats = (data: Record<string, StatsFormField>) => {
    const body: any = {};
    body.data = data;
    body.data_type = view;
    body.campaign_id = id;
    body.type = currentFormName;
    

    dispatch(actions.addCampaignStats(body)).then((res: any) => {
      // handleShowModal();
      toast.success('Successfully added campaign info');
      dispatch(actions.fetchCampaignStats(id));
      dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
    }).catch(() => {
      setEditing(!editing)
      toast.error('Error occured while updating campaign info');
    });
  }

  const editCampaignStats = (data: Record<string, StatsFormField>) => {
    const body: any = {};
    body.data = data;
    body.campaign_id = id;
    body.type = currentFormName;
    body.stat_id = actionRow._id;
    body.data_type = view

    
    dispatch(actions.editCampaignStat(body)).then((res: any) => {
      // handleShowModal();
      // setCurrentForm(tableForms[currentFormName])
      

      if (res.success) {
        toast.success('Successfully edited campaign stat');
        dispatch(actions.fetchCampaignStats(id));
        dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
      } else {
        toast.error('Something went wrong');
        dispatch(actions.fetchCampaignStats(id));
        dispatch(actions.fetchCampaignStatsAll(id, currentFormName, view));
      }
    })
  }

  const updateFormTable = (key: string) => {
   
    updatefields({});
    setEditing(false);
    setCurrentFormName(key);
    if(view === "organic"){
      return setCurrentForm(tableForms[key]);
    }
    else{
      return setCurrentForm(paidTableForms[key]);
    }
  }

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div className="px-2 py-4" onClick={() => updateFormTable(data.value)}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <button className="dropdown-item" ref={innerRef} {...innerProps}>
          <img src={toAbsoluteUrl(`/media/cpg/${data.img}.png`)} alt='data' style={{ width: '24px', marginRight: '12px' }} />
          {data.label}
        </button>
      </div>
    )
  };

  function convertArrayOfObjectsToCSV(array: any) {
    let result: any;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(tableData[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        // eslint-disable-next-line no-plusplus
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array: any) {
    //Maps to replace "," with "|"
    const newArray = array.map((item: any, index: any) => {
      const newRows: any = {};
      Object.keys(item).forEach((key: string) => {
        const value = item[key];
        if (typeof value === "string") {
          newRows[key] = value.replace(",", "|");
        } else {
          newRows[key] = value;
        }
      });
      return newRows;
    });
    
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(newArray);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const handlePageChange = async () => {
    const skipper = skip + 1;
    setSkip(skipper);
    await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, view, `&limit=${pageLimit}&skip=${skipper}`));
    instTable();
  }

  const handlePerRowsChange = async (limit = 10) => {
    setPageLimit(limit);
    await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, view, `&limit=${limit}&skip=${skip}`));
    instTable();
  };

  const addNewStatline = () => {
    if (currentForm)
      setEditing(!editing)
  }

  const pageChangeManual = async (lim: any) => {
    // const lim = Number(e.target.value);
    setPageLimit(lim);
    setSkip(0)
    setPageIndex(0);
    await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, view, `&limit=${lim}&skip=${skip}`));
    instTable();
  }

  const paginateManual = async (type: string) => {
    if (type === 'prev' && pageIndex > 0) {
      setSkip(skip - 1);
      await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, view, `&limit=${pageLimit}&skip=${skip - 1}`));
      instTable();
      setPageIndex(pageIndex - 1)
    }

    if ((pageIndex + 1) * pageLimit <= campaignStatsCount) {

    }

    if (type === 'next' && (pageIndex + 1) * pageLimit <= campaignStatsCount) {
      setSkip(skip + 1);
      await dispatch(actions.fetchCampaignStatsPaginated(id, currentFormName, view, `&limit=${pageLimit}&skip=${skip + 1}`));
      instTable();
      setPageIndex(pageIndex + 1)
    }
  }

  const updateView = (view: 'organic' | 'paid') => {
    setEditing(false);
    
    if (view === 'organic') {
      console.log("organic checked")
      setCurrentFormName("fbIgOrganic")
      setCurrentForm(tableForms["fbIgOrganic"])

    }
    if (view === 'paid') {
      console.log("paid checked")
      setCurrentFormName("fbIgPaid")
      setCurrentForm(paidTableForms["fbIgPaid"])

    }
    setView(view);
  }

  const customStyles = {
    head: {
      style: {
        position: 'sticky',
        left: 0,
        backgroundColor: '#f8f8f8',
      },
    },
  };


  return (
    <>
      <div className='row g-4 mt-2'>
        <div className="py-6 px-4 card">
          <h2 className="mt-1 mb-6">{campaignDetails?.CampaignName}</h2>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className='d-flex align-items-center'>
              <button className={classnames((view === 'organic' ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 me-4")}
                onClick={() => updateView('organic')}
              >
                <img src={toAbsoluteUrl('/media/cpg/chart-line-up.png')} style={{ width: '16px', marginRight: '4px' }} />
                Organic
              </button>
              <button className={classnames((view === 'paid' ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4")}
                onClick={() => updateView('paid')}
              >
                <img src={toAbsoluteUrl('/media/cpg/paid.png')} style={{ width: '16px', marginRight: '4px' }} />
                Paid
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div style={{ width: '220px', maxWidth: '220px', marginRight: '20px', zIndex:12 }}>
                <Select
                  components={{ Option: CustomOption }}
                  defaultValue={defaultformSelect}
                  options={ddOptions}
                />
              </div>
              
              <div className='d-flex align-items-center justify-center' style={{ marginRight: '15px'}}>
                <UploadData campaignId={campaignDetails?.CampaignId} dataType={view} type={currentFormName}/>
              </div>
              <button className="btn btn-primary uppercase me-4" onClick={() => downloadCSV(tableData)}>
                <span className='fw-bold fs-7'>Export</span>
              </button>
              <button className="btn btn-sm btn-warning btn-sm" style={{padding:"0.9rem " }} 
              onClick={handleClickAdd}>
                <span className='fw-bold fs-7 text-black'>Add New</span>
                <img src={toAbsoluteUrl('/media/cpg/plus.png')} style={{ width: '10px', marginLeft: '6px' }} />
              </button>
            </div>
          </div>
          <div className="mt-6 ">
            
              <DataTable
                columns={tableColumns}
                data={tableData}
                fixedHeader
                customStyles={customStyles}
              // pagination
              // paginationServer
              // paginationTotalRows={campaignStatsCount}
              // onChangeRowsPerPage={handlePerRowsChange}
              // onChangePage={handlePageChange}
              />

            {tableData && tableData.length > 0 &&
              <div className="pag-con">
                <button className="pag-btn">Page ({pageIndex + 1})</button>
                <button className="pag-btn" onClick={() => paginateManual('prev')}>Prev</button>
                <button className="pag-btn" onClick={() => paginateManual('next')}>Next</button>
                <select onChange={(e) => pageChangeManual(e.target.value)}>
                  <option value="10" selected>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value={campaignStatsCount || "40"}>All</option>
                </select>
              </div>
            }
          </div>
        </div>
      </div>
      
      {showModal && <AddCampaignStats 
        addCampaignStats={addCampaignStats}
        editCampaignStats={editCampaignStats}
        handleShowModal={handleShowModal}
        initialFormData={view === "organic" ? tableForms[currentFormName] : paidTableForms[currentFormName]}
        isEdit={isEdit}
        showModal={showModal} 
        statsFormData={currentForm}
        title={`${isEdit ? "Edit" : "Create"} Entry ${campaignDetails?.CampaignName}`}
        influencerHandlerData={inflHandlerIdx}
        influencerNameData={inflNameIdx}
        campaignInflencers={campaignInflencers}
       />}
    </>
  )
}

export { CampaignStats }
