import { topPerformingDataState } from '../types/topPerformingTypes';

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



const API_URL = process.env.REACT_APP_API_URL;

const topPerformingState = { topData:null, loading:null, error:null } as topPerformingDataState;

type BasicParams = {
    campaign_id: string;
    is_external?:boolean;
    date_range:string
}
// type idParams = {
//     campaign_id: string;
// }

type Params = {
    campaign_id: string;
    date_range:string
}


export const getTopPerforming = createAsyncThunk('report/top-performing-contents',
 async ({campaign_id, date_range, is_external} :BasicParams) => {
    const response = await axios.get(`${API_URL}api/new-reports/stats/top-performing-contents${is_external?'/client':''}?campaign_id=${campaign_id}&range=${date_range}`)
    return response.data['data']
})

export const topPerformingSlice = createSlice({
    name: 'topPerforming',
    initialState: topPerformingState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getTopPerforming.pending, (state) => {
            state.loading = true;
            state.error = null;
        })

        builder.addCase(getTopPerforming.fulfilled, (state, action) => {
            state.topData = action.payload;
            state.loading = false;
        })

        builder.addCase(getTopPerforming.rejected, (state, action) => {
            state.error = action.error.message || "Error retrieving TopPerforming data";
            state.loading = false;
        })

    }
})

