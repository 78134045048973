import * as functions from "../../../../app/global/functions";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Link} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

interface InfProps {
  src: any;
  UserName: string;
  tr: string;
  value: any;
  br: string;
}

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);


function Influencer({ src, UserName, tr, value, br }: InfProps) {
  // console.log('valuddD: ', value);

  function addDefaultSrc(ev: any){
    ev.target.src = toAbsoluteUrl('/media/cpg/na.png');
  }

  return (
    <OverlayTrigger
      placement="right"
      // delay={{ show: 250, hide: 400 }} //PublisherName
      overlay={renderTooltip(value.Influencer.UserName)}
    >
    <a
      href={`/app/influencers/${value.Influencer.NetworkPublisherId}`}
    >
    <div className="w-full d-flex flex-column justify-content-center mb-4 p-2" style = {{
      height: 163.5
    }}>
      <img  onError={(ev) => addDefaultSrc(ev)} src={value.Influencer.LogoURL} className="rounded rounded-3 w-auto" alt="@" style={{ height: '90px', objectFit: 'cover' }} />
      <div className="d-flex" style = {{
        flexDirection: 'row',
        display: 'flex'
      }}>
          <div>
            <div className="d-flex align-items-center mt-2">
              <span className="d-block text-gray-600 fs-7 me-6 fw-light">ER</span>
            </div>
            {value &&
              <div className="flex justify-content-start align-items-center mt-1">
                <span className="text-black fw-boldest me-6">{`${(value?.ER * 100).toFixed(2)}%`}</span>
              </div>
            }
          </div>

          <div>
            <div className="d-flex align-items-center mt-2">
              <span className="d-block text-gray-600 fs-7 me-6 fw-light">{'Impressions'}</span>
            </div>
            {value &&
              <div className="flex justify-content-start align-items-center mt-1">
                <span className="text-black fw-boldest me-6">{`${functions.abvNum(value?.Impressions || 0)}`}</span>
              </div>
            }
          </div>

          
      </div>
    </div>
    </a>
    </OverlayTrigger>
  )
}

export { Influencer }