import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { getCampaignDetails, uploadStaticImages } from "../../../../../../setup/features/report.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { BannerImage } from "./BannerImage";
import { toast } from "react-toastify";
import axios from "axios";


const placeholder = [
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
];

interface StaticImagesBannerProps {
    campaignDetail: {
        brandName?: string;
        campaignId: string;
        campaignName: string;
        thumbnail: string;
    },
    isPdfPage?:boolean
}

const API_URL = process.env.REACT_APP_API_URL;

export const StaticImagesBanner = (
    { campaignDetail: {brandName, campaignId, campaignName, thumbnail}, isPdfPage 
}: StaticImagesBannerProps) => {

    const dispatch = useAppDispatch();
    const range  = useAppSelector((state) => state.report.range).toString();
    const { data, loading, error } = useAppSelector((state) => state.report.staticImages);

    const [staticImages, setStaticImages] = useState<{url: string}[]>(placeholder);
    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(-1);


    useEffect(() => {
        if (data && data.images.length > 0) {
            const uploadedImages = data.images;
            setStaticImages((prev) => {
                let currentImagesClone = [...prev];
                let updatedImage = [...uploadedImages, ...currentImagesClone];

                return updatedImage.slice(0, 4);
            })
        }
    }, [data]);

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];
        const formData = new FormData();

        formData.append("campaign_id", campaignId);
        formData.append("image", image, image.name);
        formData.append("postId", hoveredImageIndex.toString())

        const response = dispatch(uploadStaticImages(formData));
        response.then(res => {
            dispatch(getCampaignDetails({campaign_id:campaignId, is_external:false, date_range:range }));
        })
    }

    const handleImageHover = (index: number) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        setHoveredImageIndex(-1);
    }

    const handleReportRefresh = async() => {
        await toast.promise(axios.get(`${API_URL}api/new-reports/refersh-realtime?campaign_id=${campaignId}`),{
          pending: 'Updating reports',
          success: `reports updated successfully!`,
          error: `Error updating reports: try again later`
        }).then(res => {
            dispatch(getCampaignDetails(
                {campaign_id:campaignId, is_external:false, date_range:range }
            ));
        }).catch((error) => {
            console.log(error)
        })
}

    if (loading) {
        return (
            <div>
                <Skeleton height={50} />
            </div>
        )
    }

    return (
        <div style={{ minHeight: "700px", marginTop: "24px" }}>
            
            {!isPdfPage &&
                <div className="container mb-6" style={{ textAlign: "center", position: "relative"}}> 
                <h1 style={{ fontSize: "50px", fontWeight: 600, letterSpacing: "4px", textTransform: "uppercase", color: "#010202", margin: 0 }}>
                    {brandName}
                </h1>
                <p onClick={handleReportRefresh} className="report-refresh-button">Refresh</p>
                {/* <img src={thumbnail || toAbsoluteUrl(`/media/cpg/na.png`)} alt="" style={{ height: '80px', width: "150px", objectFit: "cover" }} /> */}
            </div>
            }

            <div  
                className="container-fluid row m-0 p-0"
                style={{ position: "relative" }}
            >   
                {staticImages.map((item, index) => (
                    <div key={index} className="col-3">
                        <BannerImage  
                            key={index} 
                            id={index}
                            src={item.url} 
                            index={index}
                            hoveredImageIndex={hoveredImageIndex}
                            handleImageHover={handleImageHover}
                            handleImageUpload={handleImageUpload}
                            handleImageOut={handleImageOut}
                        />
                    </div>
                ))}

                <div 
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                        backgroundColor: "#fff",
                        boxShadow: "20px",
                        borderRadius: 20,
                        position: "absolute",
                        alignSelf: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        width: "600px",
                        padding: "30px 70px",
                        textAlign: "center"
                    }}
                >
                    <h1 style={{ fontSize: "32px", fontWeight: 700, lineHeight: "48px"}}>{campaignName}</h1>
                    
                </div>
            </div>
            
            
            <div className="container d-flex justify-content-center">
                <button className="bi bi-chevron-down" 
                    style={{ 
                        color: "#000", 
                        fontSize: "32px", 
                        border: "none",
                        background: "none",
                        marginTop: "40px"
                    }}>    
                </button>
            </div>

        </div>
    )
}