import React, { useEffect, useState } from 'react';

interface TruncatedParagraphProps {
  text: string;
  maxLines: number;
}

const TruncatedParagraph: React.FC<TruncatedParagraphProps> = ({ text, maxLines }) => {
  const [truncated, setTruncated] = useState(true);
  const [truncateText, setTruncateText] = useState("")

  const toggleTruncate = () => {
    setTruncated(!truncated);
  };

  const getTruncatedText = () => {

    const textData = text
    // Split the text into lines
    const lines = textData.split('\n');

    // Only keep the first `maxLines` lines
    const truncatedLines = lines.slice(0);
    

    // Join the truncated lines back into a string
    setTruncateText(truncatedLines.join('\n'))
    return truncatedLines.join('\n');
  };

  useEffect(()=>{
    getTruncatedText()
  },[text])

  const renderToggleButton = () => {
    if(maxLines > 3 ){
        if (truncated) {
            return (
              <button className='read-more-button Helvetica-Neue-light' onClick={toggleTruncate}>Read More</button>
            );
          } else {
            return (
              <button className='read-more-button Helvetica-Neue-light' onClick={toggleTruncate}>Read Less</button>
            );
          }
    }
  };

  return (  
    <div>
      <p style={{ display: truncated ? '-webkit-box' : 'block', 
      WebkitLineClamp: maxLines, WebkitBoxOrient: 'vertical', 
      overflow: 'hidden', marginBottom:"5px" }}>
        {truncateText}
        {/* {text} */}
      </p>
      {/* {renderToggleButton()} */}
      {text !== 'Campaign Description n/A' && text !== '' &&
        <button onClick={toggleTruncate} 
        className="read-more-button text__12 mb__2 Helvetica-Neue-light">
            {truncated ? "Read More":"Read Less"}
        </button>
    }
    </div>
  );
};

export default TruncatedParagraph;
