/* eslint-disable jsx-a11y/anchor-is-valid */
// loading, ghost, routing(id)...
import { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import * as actions from "./_redux/Actions";
import * as campaign_actions from "../../../app/modules/campaigns/_redux/campaign/Actions";
import {
  MixedWidgetClicks, ImageTile, Influencer, ChartImpressions, StatisticsWidgetX, RadialChart, ActionableUpdates
}
  from '../../../_metronic/partials/widgets'
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import * as functions from "../../global/functions"; 
import Fade from 'react-bootstrap/Fade';
import { Transition } from 'react-transition-group';
import { motion, AnimatePresence } from "framer-motion"
import { useAnimatePresence } from "use-animate-presence";
import config from '../../global/config';

//Functions
import { clicksSummation } from './functions/clicksSummation';

const variants = {
  x: { from: -800, to: 0 },
  // x: { from: 0, to: 100 },
  // opacity: {from: 0.5, to: 1}  
};

const scoregrad = {
  type: "gradient",
  gradient: {
    shade: "dark",
    type: "horizontal",
    gradientToColors: ["#FFDD55", "#eaff00"],
    stops: [0, 50, 100]
  },
  colors: ["#ed0000"],
};

var indsss = 0;

const DashboardWrapper = (props: any) => {
  let { id } = useParams<any>();
  // console.log(id)
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const location = useLocation();
  const nodeRef = useRef(null);

  const animatedDiv = useAnimatePresence({
    variants,
    initial: "visible",
    animateFirstRender: true,
    // options: {
    //   stiffness: 500,
    //   mass: 1,
    //   damping: 10,
    // },
  });

 
  const { campaignDetails, campaignStats, clicks, analytics, activity, defaultCampaign, campaignScore, campaignEngagementRates, campaignEngagementRatesError } = useSelector(
    (state: any) => ({
      campaignDetails: state.dashboard.campaignDetails,
      clicks: state.campaign.clicks,
      analytics: state.campaign.analytics,
      activity: state.campaign.activity,
      defaultCampaign: state.dashboard.defaultCampaign,
      campaignStats: state.dashboard.campaignStats,
      campaignScore: state.dashboard.campaignScore,
      campaignEngagementRates: state.dashboard.campaignEngagementRates,
      campaignEngagementRatesError: state.dashboard.campaignEngagementRatesError,
    })
  );

  console.log(clicks)
  

  const [score, set_score] = useState<number>(0);
  const [tv, set_tv] = useState<boolean>(false);
  const [datasss, set_dat] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [payed, setPayed] = useState<number>(0);
  const [imp_data, set_imp] = useState<any>(null);
  
  useEffect(() => {
    dispatch(actions.fetchDefault());
    dispatch(actions.reset_campaign_info());
    dispatch(campaign_actions.reset_campaign_info());
  }, []);


  useEffect(() => {
    if (activity) {
      var imp_anal = activity.data?.new_engagements;
      set_imp(imp_anal) 
    }
  }, [activity])

  useEffect(() => {
    if (defaultCampaign) {
        reqss(id || defaultCampaign.campaign_id);
    }
  }, [defaultCampaign]);


  useEffect(() => {
    if (location.pathname === '/tv') {
      set_tv(true);
    }


  //   setOpen(false);
  //   setTimeout(
  //     () => animatedDiv.togglePresence(), 
  //     2000
  // )}

    
  }, [location])


  useEffect(() => {
    if (tv) {
      dispatch(campaign_actions.fetchAllData('campaigns', `?all=true`)).then((data: any) => {
        if (data && data.success.length > 0) {
          // console.log(data.success);
          var dd: any = [];
          data.success.forEach((ele: any, i: number) => {
            if (ele.data.CampaignStatus === 'Active') {
              dd.push(ele);
            }
          })
          // console.log("TV!!!!", dd.length);
          set_dat(dd)
        } else {
          toast.error('No campaigns found!');
        }
      }).catch((error: any) => {
        toast.error('Error occured while fetching campaigns');
      })
    }
 
  }, [tv])

 
  useEffect(() => {
    if (tv && datasss.length > 0) {
      // setOpen(false);
      // animatedDiv.togglePresence()
     const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
        // console.log('calling!!!');
        // const randomElement = datasss[Math.floor(Math.random() * datasss.length)];
        // reqss(randomElement.data.CampaignId);
        // var ind = index;
        // console.log("INdesxxx", indsss, datasss.length)
        // const randomElement = datasss[ind];
        // reqss(randomElement.data.CampaignId);
        // setIndex((indsss + 1) )
       
        const randomElement = datasss[indsss];
        reqss(randomElement.data.CampaignId);
       
        if (indsss + 1 >= datasss.length) {
          indsss = 0;
        } else {
          indsss += 1;
        }
        
        
        // animatedDiv.togglePresence()
        // setTimeout(
        //   () => animatedDiv.togglePresence(), 
        //   1500
        // );
        setOpen(false)
        setTimeout(
          () => setOpen(true),
          500
        )
        
 
      }, config.TIME)
    
      return () => clearInterval(intervalId); //This is important
    }
  }, [datasss, tv])


  function reqss(idd: any) {
    dispatch(actions.reset_campaign_info());
    dispatch(campaign_actions.reset_campaign_info());
 
    dispatch(actions.fetchCampaignDetails('d', idd));
    dispatch(actions.fetchCampaignStats('d', idd));
    dispatch(actions.fetchCampaignEngagementRates(idd));
    dispatch(campaign_actions.fetchStuff('clicks', idd));
    dispatch(campaign_actions.fetchStuff('click_analytics', `?mode=day&campaign_id=${idd}`));
    dispatch(campaign_actions.fetchStuff('analytics', `?campaign_id=${idd}`));
    dispatch(campaign_actions.fetchStuff('activity', `?campaign_id=${idd}`));
    setOpen(true);
    
   
  }


  useEffect(() => {
    var new_arr: any = [];
    setPayed(0);
    if (campaignStats && campaignDetails) {
      if (!campaignStats.target_data) {
        return;
      }
      let paid = 0;
      if (campaignDetails.Payout) {
        campaignDetails.Payout.forEach((e: any, i: number) => {
          if (e.Paid) {
            paid += e.FixedFee;
          }
        });
        setPayed(paid);
      }
   
    } 

    // console.log(Impressions_ref.current);
  }, [campaignStats, campaignDetails]);



  useEffect(() => {
    
    var imp_score = 0;
    var cl_score = 0;
    var er_score = 0;
    var cpe_score = 0;
    

    if (campaignStats) {
      if (campaignStats.current_data && campaignStats.target_data) {
        const { Impressions, EngagementRate } = campaignStats.current_data;
        const { target_impression, target_clicks, target_er, target_cpe} = campaignStats.target_data;


        var imp_anal = activity?.data?.new_impressions;
        // var eng_anal = activity?.data?.new_engagements;
        var er_anal = activity?.data?.new_er;
        
        // var est = EngagementRate/target_er
        var el_act = er_anal || campaignStats?.target_data?.campaign_actual?.er || EngagementRate;
        var cl_act = campaignStats?.target_data?.campaign_actual?.clicks;
        var cpe_act = campaignStats?.target_data?.campaign_actual?.cpe;
        var imp_act = imp_anal || campaignStats?.target_data?.campaign_actual?.impressions;
        var eff = EngagementRate * 100 >= 100 ? EngagementRate : EngagementRate * 100

        imp_score = ((imp_act || Impressions)/target_impression) * 100
        // er_score = ((EngagementRate * 100)/target_er) * 100;
        er_score = ((el_act * 100 >= 100 ? el_act : el_act * 100)/target_er) * 100;
        cpe_score = ((cpe_act ||  Number((payed?payed : 0) / eff))/target_cpe) * 100; //payed
        cl_score = ((cl_act || clicks)/target_clicks) * 100;


        var score = ((imp_score + cl_score + er_score + cpe_score) / 4);

        // console.log("Score!!!", score)
        set_score(score > 100 ? 100 : Math.round(score));

         
      }
     

 
    }
     
    
  
  }, [campaignStats, activity, clicks])

  return (
    <AnimatePresence>
      {open && (
        <motion.div 
            initial={{ opacity: .5, x: -400 }}
            animate={{ opacity: 1, x: .5 }}
            exit={{ opacity: .5, x: -700 }}
            transition={{ ease: "easeOut", duration: 1 }}
          >
            {/* <button 
            onClick={() => {
              setOpen(false)
                setTimeout(
                  () => setOpen(true),
                  500
              )}
            }
            // onClick={() => animatedDiv.togglePresence()}
            >Toggle</button> */}
          {/* {animatedDiv.isRendered &&  */}
            {/* <div ref={animatedDiv.ref} > */}
                {
                id &&
                <button className="btn btn-primary btn-sm rounded rounded-2 px-4 mb-10 " style={{ marginRight: 10 }}
                    onClick={() => history.goBack()}
                  >
                  Go Back
                </button> 
                }
                <div className='row g-1 g-xl-1'>
                  <div className='col-xl-12'>
                    <StatisticsWidgetX index = {indsss + 1} activity = {activity} dataL = {datasss.length} campaignData={campaignDetails} clicks = {clicks} campaignStats={campaignStats} />
                  </div>
                </div>

                <div className='row mt-2 g-xl-4 mt-xl-0'>
                  <div className='col-xl-3 col-lg-6'>
                    <div className="card p-2 pt-0 mb-2">
                      <h5 className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
                      Best Content
                        <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
                      </h5>
                      {campaignEngagementRatesError
                        ?
                        <div className="d-flex flex-center text-center text-muted min-h-150px">
                          Nothing to see,
                          <br /><br />
                          Best Content by ER unavailable
                        </div>
                        :
                        <>
                          {!campaignEngagementRates || !campaignEngagementRates.best_content
                            && <div className=""> 
                              <Skeleton count={2} />
                              <Skeleton count={1} height={80} />
                            </div>
                          }
                          {campaignEngagementRates && campaignEngagementRates.best_content &&
                            <div className="d-flex justify-content-between">
                              {campaignEngagementRates.best_content.map((content: any, index: number) => (
                                <ImageTile rising={true} key={index} {...content} />
                              ))}
                            </div>
                          }
                        </>

                      }
                    </div> 

                    <div className="card p-2 pt-0 mb-2">
                      <span className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
                        Low performing
                        <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
                      </span>

                      {campaignEngagementRatesError
                        ?
                        <div className="d-flex flex-center text-center text-muted min-h-150px">
                          Nothing to see,
                          <br /><br />
                          Low performing by ER unavailable
                        </div>
                        : <>
                          {!campaignEngagementRates || !campaignEngagementRates.low_perfroming
                            && <div className="">
                              <Skeleton count={2} height={80} />
                            </div>
                          }

                          {campaignEngagementRates && campaignEngagementRates.low_perfroming &&
                            <div className="d-flex justify-content-between">
                              {campaignEngagementRates.low_perfroming.map((content: any, index: number) => (
                                <ImageTile key={index} {...content} />
                              ))}
                            </div>
                          }
                        </>
                      }

                    </div>

                    <div className="card p-2 pt-0 mb-2">
                      <span className="p-0 pt-6 fs-5 text-black fw-bolder mb-3">
                        Top Platforms
                        <span className="font-light fs-8 text-sm text-gray-400"> (by ER)</span>
                      </span>
                      {campaignEngagementRatesError
                        ?
                        <div className="d-flex flex-center text-center text-muted min-h-150px">
                          Nothing to see,
                          <br /><br />
                          Top Platforms by ER not is unavailable
                        </div>
                        : <>
                          {!campaignEngagementRates || !campaignEngagementRates.top_platforms
                            && <div className="">
                              <Skeleton count={2} />
                              <Skeleton count={1} height={80} />
                            </div>
                          }
                          {campaignEngagementRates && campaignEngagementRates.top_platforms &&
                            <div className="d-flex justify-content-between justify-content-lg-start">
                              {campaignEngagementRates.top_platforms.map((content: any, index: number) => (
                                <ImageTile key={index} {...content} platform={content} />
                              ))}
                            </div>
                          }
                        </>
                      }
                    </div>
                  </div>
                  <div className='col-xl-4 col-lg-6'>
                    <div className="mt-0">
                      <MixedWidgetClicks
                        className='card-xxl-stretch-50 mb-5 mb-xl-8'
                        chartColor='warning'
                        // title={`Clicks (${functions.abvNum( campaignStats?.target_data?.campaign_actual?.clicks || clicks)})`}
                        title={`Clicks (${functions.abvNum(clicksSummation(clicks?.links))})`}
                        chartHeight='175px'
                        spaced
                      />  
                    </div>

                    <div className="mt-8">
                      <ChartImpressions
                        className='card-xxl-stretch-50 mb-5 mb-xl-8'
                        chartColor='primary'
                        title={`Engagement (${functions.abvNum(imp_data || campaignStats?.target_data?.campaign_actual?.engagements || campaignStats?.current_data?.Engagement)})`}
                        chartHeight='175px'
                        spaced
                      />
                    </div>
                  </div>
                  <div className='col-xl-2 col-lg-6'>
                    <div className="card w-full p-4 pt-6">
                      <h5 className="text-xl text-black fw-medium mb-4">
                        Top Influencers
                        <span className="block font-light fs-8 text-sm text-gray-400"> (by ER)</span>
                      </h5>
                      {campaignEngagementRatesError
                        ? <div className="d-flex flex-center text-center text-muted min-h-150px">
                          Nothing to see,
                          <br /><br />
                          Top Influencers by ER not is unavailable
                        </div>
                        : <>
                          {!campaignEngagementRates || !campaignEngagementRates.top_influencers
                            &&
                            <div className="">
                              <Skeleton count={2} />
                              <Skeleton count={3} height={80} />
                            </div>
                          }
                          {campaignEngagementRates && campaignEngagementRates.top_influencers &&
                            campaignEngagementRates.top_influencers.map((influencer: any, index: number) => (
                              <Influencer key={index} {...influencer} />
                            ))
                          }
                        </>
                      }

                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-6'>
                    <div className="card p-4 pt-6">
                      <h5 className="text-xl text-black fw-medium mb-4">Campaign Score</h5>
                      <RadialChart
                        width="300px"
                        stroke={Math.round(score)}
                        value=' '
                        label=' '
                        rate=' '
                        oop={{ fill: scoregrad }}
                      />
                      <h5 className="text-xl text-black fw-medium mb-1 mt-6" style = {{marginTop: 10}}>Actionable Updates</h5>
                      {/* <span className='text-muted fw-bold fs-7'>coming soon... </span> */}
     
                      <ActionableUpdates />
                    </div>
                  {/* </div> */}
                </div>
            </div>
          
        </motion.div>
      )}
    </AnimatePresence>
   
  )
}


export { DashboardWrapper }
