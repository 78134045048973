import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { metricValueFormatter } from "../../../../../../../helper";
import PerformanceMetrics from "./PerformanceMetrics";
import TPCAudience from "./TPCAudience";
import { TPCProfileHeader } from "./TPCProfileHeader";
import TopComments from "./TopComments";
import TopPosts from "./TopPosts";


const placeholder = [
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"url": toAbsoluteUrl("/media/cpg/reportImg4.png")},
];

const posts = [
    {"image": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"image": toAbsoluteUrl("/media/cpg/reportImg4.png")},
    {"image": toAbsoluteUrl("/media/cpg/reportImg4.png")},
]

interface Props{
    influencerId:string; 
    topData:any, 
    campaignId:string;
    isExternal:boolean | undefined;
}

export default function TPCModalBody({influencerId, topData, campaignId, isExternal}:Props) {
    
    const influencerData = topData?.creators.find((item:any) => {
        return item.publisherId?.toString() === influencerId
    })
    
    const impressionsPerViews = metricValueFormatter(influencerData?.impressions, 2) || "0";
    const averageEngagementRate = `${(influencerData?.engangementRate||0)?.toFixed(2)} %`;

    return (
        <div>
            <div 
                className="d-flex align-items-stretch "
                style={{ gap: "50px", height: "fit-content", marginBottom: "24px"}}
            >
                <div style={{ 
                    display: "flex", 
                    flexDirection: "column",  
                    flex: 1,  
                    gap: "32px"
                }}>
                    <TPCProfileHeader 
                        profileName={influencerData?.name || ""}
                        followers={influencerData?.followers || 0}
                        avatar={influencerData?.logoURL || ""}
                        impressionsPerViews={impressionsPerViews || "0"}
                        averageEngagementRate={averageEngagementRate || "0"}
                        influencerLink={influencerData?.influencerLink || ""}
                    />

                    <TopPosts posts={posts} 
                    data={influencerData} campaignId={campaignId} 
                    isExternal={isExternal}/>
                </div>

                <PerformanceMetrics 
                    data={influencerData}
                />

                <TopComments data={influencerData} campaignId={campaignId} 
                    isExternal={isExternal}/>
            </div>

            <div>
                <TPCAudience data={influencerData}/>
            </div>
        </div>
    )
}