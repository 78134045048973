
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { getTopPerforming } from "../../../../../../setup/features/topPerforming.slice";
import { getTopPerformingNarrativeRes, uploadStaticImages } from "../../../../../../setup/features/report.slice"
import { useAppDispatch, useAppSelector } from "../../../../../../setup/redux/hooks";
import { topCreators } from '../components/topPerforming/topCreators'
import {topCategories} from '../components/topPerforming/topCategories'
import {  ContentCardProps } from '../components/topPerforming/contentCardProps';
import * as functions from '../../../../../global/functions'

import { useEffect, useState, useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { ErrorBoundary } from "../../../../../sharedComponents/ErrorBoundary"
import SVG from 'react-inlinesvg';
import TPCModal from "../components/default/TPCModal";
import { motion, AnimatePresence } from 'framer-motion';





type ProgressBarProps = {
    bgColor: "#F4F4FA",
    fillColor: string;
    containerWidth?: string;
    fillWidth: string;
}


function addDefaultSrc(ev: any) {
    ev.target.src = toAbsoluteUrl('/media/cpg/reportImg4.png');
}

const socialDefault = (url: any) => {
    if (url.includes("instagram")) {
        return "/media/cpg/reportImg4.png"
    }
    if (url.includes("tiktok")) {
        return "/media/cpg/reportImg3.png"
    }
    if (url.includes("folders")) {
        return "/media/cpg/reportImg1.png"
    }
    return "/media/cpg/reportImg2.png"
}





const CustomProgressBar = (
    { bgColor, containerWidth, fillColor, fillWidth, }: ProgressBarProps) => {

    return (
        <div
            style={{
                backgroundColor: bgColor,
                width: containerWidth || "100%",
                height: 10,
                borderRadius: 4
            }}
        >
            <div
                style={{
                    width: fillWidth,
                    background: fillColor === "blue" ? "linear-gradient(270deg, #3771C8 0%, rgba(55, 113, 200, 0.4) 100%)" : "linear-gradient(270deg, #06D6A0 0%, rgba(6, 214, 160, 0.4) 100%)",
                    height: "inherit",
                    borderRadius: "inherit"
                }}
            />
        </div>
    )
}

//function goes through a google drive url to extract the id
const idCollector = (url: any) => {
    const regexMatch = url.match(/\/d\/(.+?)\//);
    if (regexMatch && regexMatch.length > 1) {
        return regexMatch[1];
    }
    else {
        return null;
    }
}

// function checks if the url contains social media names and if its a google drive folder
const urlChecker = (url: any) => {
    if (url.includes("instagram") || url.includes("tiktok") || url.includes("folders")) {
        return true
    }
    return false
}

// function checks if the url is an uploaded image
const uploadImageChecker = (url: any) => {
    if (url.includes("amazonaws")) {
        return true
    }
    return false
}

const ContentCard = ({ id, postId, label, social, data, campaignId, isExternal, dateRange, handleShowModal, handleInfluencerId, isPdfPage }: ContentCardProps
    & { handleShowModal(): void, handleInfluencerId(id: string): void }) => {

    let iconPath: string = "";
    if (social.platform?.toLowerCase() === "instagram") iconPath = "/media/cpg/insta.png";
    if (social.platform?.toLowerCase() === "tiktok") iconPath = "/media/cpg/tiktok.png";
    if (social.platform?.toLowerCase() === "youtube") iconPath = "/media/cpg/youtube.png";
    if (social.platform?.toLowerCase() === "facebook") iconPath = "/media/cpg/facebook.png";

    const [isImage, setIsImage] = useState(true)

    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(-1);
    const containerRef = useRef<HTMLDivElement>(null);
    const uploadRef = useRef<HTMLDivElement>(null);



    const dispatch = useAppDispatch();

    const handleImageUpload = (event: any) => {
        const image: File = event.target.files[0];
        const formData = new FormData();

        formData.append("campaign_id", `${campaignId ? campaignId?.toString() : ""}`);
        formData.append("file", image);
        formData.append("postId", hoveredImageIndex.toString())

        dispatch(uploadStaticImages(formData));
        dispatch(getTopPerforming({
            campaign_id: campaignId ? campaignId.toString() : "000000",
            is_external: isExternal,
            date_range: dateRange ? dateRange.toString() : "7"
        }));

    }


    const handleImageHover = (index: number) => {
        setHoveredImageIndex(index);
    };

    const handleImageOut = () => {
        if (hoveredImageIndex) {
            setHoveredImageIndex(-1);
        }
    }

    useEffect(() => {
        // Attach a click event listener to the document
        document.addEventListener('click', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function handleClickOutside(event: MouseEvent) {
        // Check if the click target is inside the div container
        if (containerRef.current && !containerRef.current.contains(event.target as Node) && uploadRef.current && !uploadRef.current.contains(event.target as Node)) {
            // Clicked outside the container, update the state value
            handleImageOut()
        }
    }

    let socialOrVideo = useMemo(() => urlChecker(social.imageBgSrc), [social.imageBgSrc])

    let uploadImage = useMemo(() => uploadImageChecker(social.imageBgSrc), [social.imageBgSrc])

    let statsGooglePicture = useMemo(() => idCollector(social.imageBgSrc), [social.imageBgSrc])


    return (
        <div className="card rounded" ref={containerRef}>
            <div className="card-body p-0">
                <div className="position-relative mb-6"
                    onMouseOver={() => handleImageHover && handleImageHover(postId)}
                >   

                    {
                        
                        social.imageBgSrc === "/media/cpg/reportImg4.png" ?
                        (
                            <>
                                <a href={social.link !== "" ? social.link : toAbsoluteUrl(social.imageBgSrc)} target="_blank" rel="noreferrer">
                                    <img
                                        onError={(ev) => {
                                            setIsImage(true)
                                        }}
                                        src={toAbsoluteUrl(social.imageBgSrc)}
                                        alt="hero-card"

                                        className={isPdfPage ? "image-fluid d-block top_content_img" : "image-fluid d-block"}
                                        style={{
                                            width: '100%',
                                            height: 400,
                                            maxHeight: 400,
                                            resize: "both",
                                            objectFit: "cover",
                                            borderRadius: "20px",
                                            cursor: "pointer"
                                        }}

                                    />
                                </a>
                            </>
                        ) :
                        data !== "stats" ?
                            (
                                <>
                                    {/* {console.log("here is the creator iq image")} */}
                                    <a href={social.link} target="_blank" rel="noreferrer">
                                        <img
                                            onError={(ev) => {
                                                addDefaultSrc(ev)
                                            }}
                                            src={toAbsoluteUrl(social.imageBgSrc)}
                                            alt="hero-card"
                                            className={isPdfPage ? "image-fluid d-block top_content_img" : "image-fluid d-block"}
                                            style={{
                                                width: '100%',
                                                height: 400,
                                                maxHeight: 400,
                                                resize: "both",
                                                objectFit: "cover",
                                                borderRadius: "20px",
                                                cursor: "pointer"
                                            }}

                                        />
                                    </a>
                                </>
                            ) :
                            uploadImage ?
                                (
                                    <>
                                        <a href={social.link} target="_blank" rel="noreferrer">
                                            <img
                                                onError={(ev) => {
                                                    setIsImage(true)
                                                }}
                                                src={toAbsoluteUrl(social.imageBgSrc)}
                                                alt="hero-card"

                                                className={isPdfPage ? "image-fluid d-block top_content_img" : "image-fluid d-block"}
                                                style={{
                                                    width: '100%',
                                                    height: 400,
                                                    maxHeight: 400,
                                                    resize: "both",
                                                    objectFit: "cover",
                                                    borderRadius: "20px",
                                                    cursor: "pointer"
                                                }}

                                            />
                                        </a>
                                    </>
                                ) :
                                isImage && statsGooglePicture && !socialOrVideo ?
                                    (
                                        <>
                                            {/* {console.log("here is the stats image")} */}
                                            <a href={social.link} target="_blank" rel="noreferrer">
                                                <img
                                                    onError={(ev) => {
                                                        addDefaultSrc(ev)
                                                        setIsImage(false)
                                                    }}
                                                    src={`https://drive.google.com/uc?export=view&id=${statsGooglePicture}`}
                                                    alt="hero-card"

                                                    className={isPdfPage ? "image-fluid d-block top_content_img" : "image-fluid d-block"}
                                                    style={{
                                                        width: '100%',
                                                        height: 400,
                                                        maxHeight: 400,
                                                        resize: "both",
                                                        objectFit: "cover",
                                                        borderRadius: "20px",
                                                        cursor: "pointer"
                                                    }}

                                                />
                                            </a>
                                        </>
                                    ) :
                                    !statsGooglePicture && socialOrVideo ? (
                                        <>
                                            {/* {console.log("here is the social image / folder -> ")}*/}
                                            <a href={social.link} target="_blank" rel="noreferrer">
                                                <img
                                                    onError={(ev) => {
                                                        console.log("social Image")
                                                        addDefaultSrc(ev)
                                                        setIsImage(false)

                                                    }}
                                                    src={toAbsoluteUrl(socialDefault(social.imageBgSrc))}
                                                    alt="hero-card"

                                                    className={isPdfPage ? "image-fluid d-block top_content_img" : "image-fluid d-block"}
                                                    style={{
                                                        width: '100%',
                                                        height: 400,
                                                        maxHeight: 400,
                                                        resize: "both",
                                                        objectFit: "cover",
                                                        borderRadius: "20px",
                                                        cursor: "pointer"
                                                    }}

                                                />
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            {/* {console.log("here is the iframe video image ->", statsGooglePicture)} */}
                                            <iframe src={`https://drive.google.com/file/d/${statsGooglePicture}/preview`}
                                                allow="autoplay"
                                                className={isPdfPage ? "top_content_img" : ""}
                                                style={{
                                                    width: '100%',
                                                    height: 400,
                                                    maxHeight: 400,
                                                    resize: "both",
                                                    objectFit: "cover",
                                                    borderRadius: "20px",
                                                    cursor: "pointer"
                                                }}
                                                onError={(ev) => {
                                                    console.log("stats video not dispalyed")
                                                    // setIsImage(true)
                                                    addDefaultSrc(ev)
                                                }} title={label} ></iframe>
                                        </>
                                    )

                    }



                    <div
                        style={{
                            backgroundColor: "#fff",
                            padding: 12,
                            borderRadius: 14,
                            position: "absolute",
                            top: 16,
                            left: 16,
                            cursor: "pointer"
                        }} onClick={() => {
                            handleInfluencerId(social.publisherId ? social.publisherId : "")
                            handleShowModal()
                        }}>
                        <p className="fs-7 fw-bold m-0">{label}</p>
                    </div>

                    {hoveredImageIndex === postId &&
                        (
                            <div ref={uploadRef}>

                                <label
                                    htmlFor="top-performing-dynamic"
                                    style={{
                                        border: "none",
                                        background: "#F4F4FA",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                        position: "absolute",
                                        right: "30px",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        // pointerEvents:"none",
                                        top: "20px",
                                        zIndex: 2,
                                        cursor: "pointer"
                                    }}
                                >
                                    <input name="" type="file" id="top-performing-dynamic"
                                        accept=".png,.jpg,.jpeg" hidden style={{ visibility: 'hidden', width: "1px", height: "1px" }}
                                        onChange={handleImageUpload} />
                                    <SVG
                                        src={toAbsoluteUrl('/media/icons/duotune/communication/upload.svg')}
                                        className='svg-icon-2'
                                        style={{ zIndex: 5, cursor: "pointer" }}
                                    />
                                </label>
                            </div>
                        )
                    }
                </div>

                <p className="fs-4 fw-bold">#{id}</p>

                {social &&
                    <div className="d-flex flex-row mt-6 align-items-center justify-content-between" >
                        <a href={social?.link} target="_blank" rel="noreferrer">
                            <div className="d-flex flex-row align-items-center flex-grow-1">

                                <img
                                    onError={(ev) => addDefaultSrc(ev)}
                                    src={toAbsoluteUrl(social?.imageSrc)}
                                    alt={social?.platform || ""}
                                    style={{ height: "22px", marginRight: "4px", borderRadius: 4 }}
                                />


                                <p className="fs-8 font-weight-normal m-0">@{social?.handle || ""}</p>
                            </div>
                        </a>

                        <img
                            src={toAbsoluteUrl(iconPath)}
                            alt={social.platform}
                            style={{ height: "22px", marginRight: "4px" }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

const VerticalCard = ({ imgSrc, name, value }: { imgSrc: string, name: string, value: string }) => {
    return (
        <div
            className="d-flex flex-row justify-content-between align-items-center mb-2"
            style={{ backgroundColor: "#F4F4FA", borderRadius: 20, padding: 14 }}
        >
            <img
                src={toAbsoluteUrl("/media/cpg/reportImg1.png")}
                alt="vertical card"
                style={{ height: "50px", width: "50px", marginRight: "4px", borderRadius: 10 }}
            />

            <p className="m-0 p-0">{name}</p>

            <p className="fs-4 fw-bold text-dark m-0">
                {value}
            </p>
        </div>
    )
}

export const TopPerformingContent = ({ campaignId, isExternal, isPdfPage }: { campaignId: string, isExternal?: boolean, isPdfPage?: boolean }) => {

    const dispatch = useAppDispatch();

    const { loading, error, topData } = useAppSelector((state) => state.topPerforming);
    const range = useAppSelector((state) => state.report.range);

    const { loading: narrativeLoading, error: narrativeError, data: narrativeData } = useAppSelector((state) => state.report.TopPerformingNarrativeRes);
    const [filterValues, setFilterValues] = useState({
        first: "Reach",
        second: "All"
    })
    useEffect(() => {

        dispatch(getTopPerforming({
            campaign_id: campaignId,
            is_external: isExternal,
            date_range: range.toString()
        }));
        dispatch(getTopPerformingNarrativeRes({
            campaign_id: campaignId,
            is_external: isExternal,
            date_range: range.toString()
        }))

        setFilterValues({
            first: "Reach",
            second: "All"
        })

    }, [campaignId, dispatch, range]);

    const [showTPCModal, setShowTPCModal] = useState<boolean>(false);
    const [influencerId, setInfluencerId] = useState<string>("");
    const [hoveredItemId, setHoveredItemId] = useState<string>("");

    function handleHover(itemId:string){
        setHoveredItemId(itemId)
    };

    function handleShowModal() {
        setShowTPCModal((prev) => !prev);
    }

    function handleInfluencerId(id: string) {
        setInfluencerId(id);
    }


    let creatorsContent: ContentCardProps[] = topCreators(topData, filterValues)
    let narrativeCategories:any = topCategories(topData, filterValues)

    useEffect(() => {
        creatorsContent = topCreators(topData, filterValues)
        narrativeCategories = topCategories(topData, filterValues)
        // console.log("top Categories => ",topCategories(topData, filterValues))
    }, [topData, filterValues])

    const socialObject = {
        instagram : ["Reel", "IG Story", "Carousel", "Static"],
        tiktok : ["TikTok Video", "TikTok Photo Swipe"],
        youtube : ["YouTube Long-Form", "YouTube Short"]
    }

    return (
        <>
            <TPCModal
                show={showTPCModal}
                handleShowModal={handleShowModal}
                influencerId={influencerId}
                topData={topData}
                campaignId={campaignId}
                isExternal={isExternal}
            />
            {loading ? (
                <div className="mb-20">
                    <Skeleton height={30} count={1} />
                    <Skeleton height={120} count={1} />
                    <Skeleton height={120} count={1} />
                </div>
            ) :
                (


                    <div className={isPdfPage ? "mb-20" : "mb-20 mt-10"}>
                        <div id="top_this_week" className="p-8" style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h5 className="text__26 NeueMontreal-bold text-dark text-align-center p-0 m-0">
                                    Top Performing Content this week
                                </h5>
                                {!isPdfPage &&
                                    <div className="d-flex justify-content-between align-items-center mb-5">
                                        <select
                                            name="type"
                                            style={{ width: '120px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                                            className="custom-select"
                                            onChange={(e) => setFilterValues({
                                                ...filterValues,
                                                first: e.target.value
                                            })}

                                        >
                                            <option value="Reach">Reach</option>
                                            <option value="Impressions">Impressions</option>
                                            <option value="Likes">Likes</option>
                                            <option value="Engagement">Engagement</option>
                                            <option value="EngangementRate">ER%</option>
                                            <option value="CTR">CTR%</option>
                                            <option value="LinkClicks">Link Clicks</option>
                                            {/* {!(filterValues.second === "Carousel") && <option value="CTR">CTR%</option>} */}
                                            {/* {!(filterValues.second === "Carousel") && <option value="LinkClicks">Link Clicks</option>} */}
                                        </select>

                                        <div style={{ width: 20 }} />

                                        <select
                                            name="type"
                                            className="custom-select"
                                            style={{ width: '120px', height: '36px', borderRadius: '8px', border: '1px solid #D9D9D9', padding: '0 6px' }}
                                            onChange={(e) => setFilterValues({
                                                ...filterValues,
                                                second: e.target.value
                                            })}
                                        // value={""}
                                        // defaultValue="Paid"
                                        // color="#8C8C8C"
                                        >
                                            <option value="All">All</option>
                                            <option value="Reel">Reels</option>
                                            <option value="IG Story">Stories</option>
                                            <option value="Carousel">Carousels</option>
                                            <option value="Static">Static</option>
                                            <option value="TikTok Video">TikTok Video</option>
                                            {/* {!(filterValues.first === "CTR"|| filterValues.first === "LinkClicks") && 
                            <option value="Carousel">Carousels/Statics</option>} */}
                                            <option value="TikTok Photo Swipe">TikTok Photo Swipe</option>
                                            <option value="YouTube Long-Form">YouTube Long-Form</option>
                                            <option value="YouTube Short">YouTube Short</option>
                                        </select>
                                    </div>
                                }
                            </div>

                            <div className="row">
                                {creatorsContent && creatorsContent.slice(0, 4).map((cmpInf, index) => (
                                    <div key={index} className="col-3">
                                        {!loading &&
                                            <>
                                                {
                                                    (error || topData === null || topData.length === 0) ? (
                                                        <ErrorBoundary error={error} />
                                                    ) : (
                                                        <ContentCard isPdfPage={isPdfPage} handleShowModal={handleShowModal} handleInfluencerId={handleInfluencerId} {...cmpInf} campaignId={campaignId} isExternal={isExternal} dateRange={range} />
                                                    )
                                                }

                                            </>
                                        }

                                    </div>
                                ))
                                }

                            </div>
                        </div>

                        {/* Influencer and Content tab */}
                        <div id="top_this_week_sections" className={isPdfPage ? "row" : "row mt-10"}>
                            <div className="col p-8 " style={{ backgroundColor: "#fff", borderRadius: 20, marginRight: 10, overflow:"hidden" }}>
                                <h2 className="fs-3 NeueMontreal-bold text__24 text-dark">Influencer Tiers</h2>
                                {narrativeLoading && <Skeleton height={40} count={4} />}

                                {!narrativeLoading && ( narrativeError ) ? (
                                    <ErrorBoundary error={"No Influencer tier data available"} />
                                ) : (
                                    <>
                                        {narrativeData?.tiers?.map((infKey, index) => {
                                        
                                            // const category = narrativeCategories["InfluencerTier"][infKey];
                                            return (
                                                <div key={index} className="mt-8">
                                                <p className="fs-6 fw-light text-dark mb__2 Helvetica-Neue-light">{infKey.name || ""}</p>

                                                {infKey.values?.map((item, itemIndex) => {
                                                    
                                                    if(filterValues.second !== "All" && 
                                                    !(socialObject as any)[item.socialNetwork.toLowerCase()].
                                                    includes(filterValues.second)){ 
                                                        return
                                                    } 
                                                    
                                                    // const currentItem = category[item];
                                                    let currentItem:string = `${filterValues.first.toLowerCase()}`;
                                                    if (filterValues.first === "Engagement"){
                                                        currentItem = "engagements"
                                                    }
                                                    if (filterValues.first === "EngangementRate"){
                                                        currentItem = "engagementRate"
                                                    }
                                                    if (filterValues.first === "LinkClicks"){
                                                        currentItem = "clicks"
                                                    }
                                                    // const socialGradient = functions.handleSocialGradient(item);
                                                    const socialGradient = functions.handleSocialGradient(item.socialNetwork);
                                                    const index = `${infKey.name}${currentItem}${item.socialNetwork}`

                                                    const currentValue:number = typeof item[currentItem] === 'number' 
                                                    ? item[currentItem] as number : Number(item[currentItem]) || 0;

                                                    return (
                                                    <div key={itemIndex} className="d-flex justify-content-between align-items-center mb__10">
                                                        {socialGradient && socialGradient.iconPath && (
                                                        <img
                                                            src={toAbsoluteUrl(socialGradient.iconPath)}
                                                            alt={item.socialNetwork}
                                                            style={{ height: "22px", marginRight: "4px" }}
                                                        />
                                                        )}

                                                        <div style={{ width: "100%", marginRight: 10, marginLeft: 10, overflow:"hidden" }}>
                                                        <CustomProgressBar
                                                            fillColor={`${socialGradient?.color || "blue"}`}
                                                            fillWidth={`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}
                                                            bgColor="#F4F4FA"
                                                            containerWidth="100%"
                                                        />
                                                        </div>
                                                        

                                                        <motion.div 
                                                        onHoverStart={() => handleHover(index)}
                                                        onHoverEnd={() => handleHover("")}
                                                        
                                                        style={{ width: 250 }}>
                                                        
                                                        {index === hoveredItemId ? (
                                                            <motion.p 
                                                            className="Helvetica-Neue-bold"
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                                <span style={{ fontWeight: "bold" }}>{`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}</span>
                                                            </motion.p>
                                                             
                                                        ):(
                                                            <motion.p 
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                               <span className="Helvetica-Neue-bold" style={{ fontWeight: "bold" }}>
                                                                    {functions.abvNum(currentValue?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate")  && "%"} </span>
                                                                      /  
                                                                    <span className="Helvetica-Neue-normal"> {functions.abvNum(narrativeData?.total[currentItem]?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate") && "%"}</span>
                                                            </motion.p>
                                                        )}
                                                           
                                                        </motion.div>
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            );
                                        })}

                                    </>
                                )}


                            </div>

                            <div className="col p-8" style={{ backgroundColor: "#fff", borderRadius: 20, marginRight: 10, overflow:"hidden" }}>
                                <h2 className="fs-3 NeueMontreal-bold text__24 text-dark p-0 m-0 mb-4">
                                    Influencer Verticals
                                </h2>
                                {narrativeLoading && <Skeleton height={40} count={4} />}

                                {!narrativeLoading && ( narrativeError ) ? (
                                    <ErrorBoundary error={"No Influencer verticals data available"} />
                                ) : (
                                    <>
                                        {narrativeData?.verticals?.map((infKey, index) => {
                                        
                                            // const category = narrativeCategories["InfluencerTier"][infKey];
                                            return (
                                                <div key={index} className="mt-8">
                                                <p className="fs-6 fw-light text-dark mb__2">{infKey.name || ""}</p>

                                                {infKey.values?.map((item, itemIndex) => {
                                                    if(filterValues.second !== "All" && 
                                                    !(socialObject as any)[item.socialNetwork.toLowerCase()].
                                                    includes(filterValues.second)){ 
                                                        return
                                                    } 
                                                    
                                                    // const currentItem = category[item];
                                                    let currentItem:string = `${filterValues.first.toLowerCase()}`;
                                                    if (filterValues.first === "Engagement"){
                                                        currentItem = "engagements"
                                                    }
                                                    if (filterValues.first === "EngangementRate"){
                                                        currentItem = "engagementRate"
                                                    }
                                                    if (filterValues.first === "LinkClicks"){
                                                        currentItem = "clicks"
                                                    }
                                                    // const socialGradient = functions.handleSocialGradient(item);
                                                    const socialGradient = functions.handleSocialGradient(item.socialNetwork);
                                                    const index = `${infKey.name}${currentItem}`

                                                    const currentValue:number = typeof item[currentItem] === 'number' 
                                                    ? item[currentItem] as number : Number(item[currentItem]) || 0;

                                                    return (
                                                    <div key={itemIndex} className="d-flex justify-content-between align-items-center mb__10">
                                                        {socialGradient && socialGradient.iconPath && (
                                                        <img
                                                            src={toAbsoluteUrl(socialGradient.iconPath)}
                                                            alt={item.socialNetwork}
                                                            style={{ height: "22px", marginRight: "4px" }}
                                                        />
                                                        )}

                                                        <div style={{ width: "100%", marginRight: 10, marginLeft: 10, overflow:"hidden" }}>
                                                        <CustomProgressBar
                                                            fillColor={`${socialGradient?.color || "blue"}`}
                                                            fillWidth={`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}
                                                            bgColor="#F4F4FA"
                                                            containerWidth="100%"
                                                        />
                                                        </div>
                                                        

                                                        <motion.div 
                                                        onHoverStart={() => handleHover(index)}
                                                        onHoverEnd={() => handleHover("")}
                                                        
                                                        style={{ width: 250 }}>
                                                        
                                                        {index === hoveredItemId ? (
                                                            <motion.p 
                                                            className="Helvetica-Neue-bold"
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                                <span style={{ fontWeight: "bold" }}>{`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}</span>
                                                            </motion.p>
                                                             
                                                        ):(
                                                            <motion.p 
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                               <span className="Helvetica-Neue-bold" style={{ fontWeight: "bold" }}>
                                                                    {functions.abvNum(currentValue?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate")  && "%"} </span>
                                                                      /  
                                                                    <span className="Helvetica-Neue-normal"> {functions.abvNum(narrativeData?.total[currentItem]?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate") && "%"}</span>
                                                            </motion.p>
                                                        )}
                                                           
                                                        </motion.div>
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            );
                                        })}

                                    </>
                                )}

                            </div>

                            <div className="col p-8" style={{ backgroundColor: "#fff", borderRadius: 20, marginRight: 10, overflow:"hidden" }}>
                                <h2 className="fs-3 NeueMontreal-bold text__24 text-dark p-0 m-0 mb-4">
                                    Content Narrative
                                </h2>
                                {narrativeLoading && <Skeleton height={40} count={4} />}

                                {!narrativeLoading && ( narrativeError ) ? (
                                    <ErrorBoundary error={"No Influencer tier data available"} />
                                ) : (
                                    <>
                                        {narrativeData?.narratives?.map((infKey, index) => {
                                        
                                            // const category = narrativeCategories["InfluencerTier"][infKey];
                                            return (
                                                <div key={index} className="mt-8">
                                                <p className="fs-6 fw-light text-dark mb__2">{infKey.name || ""}</p>

                                                {infKey.values?.map((item, itemIndex) => {
                                                    
                                                    if(filterValues.second !== "All" && 
                                                    !(socialObject as any)[item.socialNetwork.toLowerCase()].
                                                    includes(filterValues.second)){ 
                                                        return
                                                    } 

                                                    // const currentItem = category[item];
                                                    let currentItem:string = `${filterValues.first.toLowerCase()}`;
                                                    if (filterValues.first === "Engagement"){
                                                        currentItem = "engagements"
                                                    }
                                                    if (filterValues.first === "EngangementRate"){
                                                        currentItem = "engagementRate"
                                                    }
                                                    if (filterValues.first === "LinkClicks"){
                                                        currentItem = "clicks"
                                                    }
                                                    // const socialGradient = functions.handleSocialGradient(item);
                                                    const socialGradient = functions.handleSocialGradient(item.socialNetwork);
                                                    const index = `${infKey.name}${currentItem}`

                                                    const currentValue:number = typeof item[currentItem] === 'number' 
                                                    ? item[currentItem] as number : Number(item[currentItem]) || 0;

                                                    return (
                                                    <div key={itemIndex} className="d-flex justify-content-between align-items-center mb__10">
                                                        {socialGradient && socialGradient.iconPath && (
                                                        <img
                                                            src={toAbsoluteUrl(socialGradient.iconPath)}
                                                            alt={item.socialNetwork}
                                                            style={{ height: "22px", marginRight: "4px" }}
                                                        />
                                                        )}

                                                        <div style={{ width: "100%", marginRight: 10, marginLeft: 10, overflow:"hidden" }}>
                                                        <CustomProgressBar
                                                            fillColor={`${socialGradient?.color || "blue"}`}
                                                            fillWidth={`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}
                                                            bgColor="#F4F4FA"
                                                            containerWidth="100%"
                                                        />
                                                        </div>
                                                        

                                                        <motion.div 
                                                        onHoverStart={() => handleHover(index)}
                                                        onHoverEnd={() => handleHover("")}
                                                        
                                                        style={{ width: 250 }}>
                                                        
                                                        {index === hoveredItemId ? (
                                                            <motion.p 
                                                            className="Helvetica-Neue-bold"
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                                <span style={{ fontWeight: "bold" }}>{`${functions.percentage(currentValue, narrativeData?.total[currentItem]) || 0}%`}</span>
                                                            </motion.p>
                                                             
                                                        ):(
                                                            <motion.p 
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            exit={{ opacity: 0, x: 20 }}
                                                            transition={{ duration: 1 }}
                                                            style={{ marginBottom: "0px", textAlign: "center", color: "var(--primary-black)" }}>
                                                               <span className="Helvetica-Neue-bold" style={{ fontWeight: "bold" }}>
                                                                    {functions.abvNum(currentValue?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate")  && "%"} </span>
                                                                      /  
                                                                    <span className="Helvetica-Neue-normal"> {functions.abvNum(narrativeData?.total[currentItem]?.toFixed(2)) || 0}{(filterValues.first === "CTR" || filterValues.first === "EngangementRate") && "%"}</span>
                                                            </motion.p>
                                                        )}
                                                        
                                                        </motion.div>
                                                    </div>
                                                    );
                                                })}
                                                </div>
                                            );
                                        })}

                                    </>
                                )}

                            </div>


                            <div className="col p-8" style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                                <h2 className="fs-3 NeueMontreal-bold text__24 text-dark p-0 m-0">
                                    Top Creators
                                </h2>

                                <div className="mt-4" style={{ display: "flex", flexDirection: "column", height: "95%" }}>
                                    {!loading && (error || topData === null || topData.length === 0) ? (
                                        <ErrorBoundary error={error} />
                                    ) : (
                                        <>
                                            {(topData?.creators ?? []).slice(0, 5).map((item: any, index: any) => (
                                                <a href={item?.influencerLink} target="_blank" rel="noreferrer">
                                                    <div key={index} className="d-flex flex-row justify-content-start align-items-center border__b py-4">


                                                        <img
                                                            onError={(ev) => addDefaultSrc(ev)}
                                                            src={item?.logoURL || toAbsoluteUrl("/media/cpg/reportImg1.png")}
                                                            alt={"creator"}
                                                            style={{ height: "30px", width: "30px", marginRight: "4px", borderRadius: "15px" }}
                                                        />


                                                        <p className=" Helvetica-Neue-Medium text__16 text__black p-0 m-0 mx-2">
                                                            {item?.name}
                                                        </p>

                                                    </div>

                                                </a>
                                            ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                )}

        </>
    )
}