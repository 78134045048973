/* eslint-disable jsx-a11y/anchor-is-valid */
// 618220 - have both current and target
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toAbsoluteUrl } from '../../../helpers'
import { RadialChart } from '../custom/RadialChart'

function abvNum(value: any) {
  if (!value) return 0;
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue: any = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) { break; }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

const limitStr = (str: string, length: number) => {
  const spl = str.split('');
  let newStr = str;
  if (spl.length > length) {
    spl.length = length;
    newStr = spl.join('') + '...'
  }
  return newStr;
}

const chs = [
  {
    stroke: 70,
    label: 'AntDetilery',
    width: 200,
    rate: '123',
  },
  {
    stroke: 70,
    label: 'AntDetilery',
    width: 200,
    rate: '123',
  },
  {
    stroke: 70,
    label: 'AntDetilery',
    width: 200,
    rate: '123',
  }
]

type Props = {
  className?: string
  svgIcon?: string
  iconColor?: string
  title?: string
  description?: string
  influencerDetails: any;
  campaignStats: any
}

const InfluencerTopWidget: React.FC<Props> = ({
  className,
  campaignStats,
  influencerDetails,
}) => {

  const [payed, setPayed] = useState<number>(0);
  const [chartData, setChartData] = useState<any>([
    {
      stroke: 0,
      value: 0,
      rate: 0,
      label: "Impressions",
    },
    {
      stroke: 0,
      value: 0,
      rate: 0,
      label: "Clicks",
    },
    {
      stroke: 0,
      value: 0,
      rate: 0,
      label: "ER",
    },
  ]);

  const calcAvg = (a: any, b: any) => {
    if (a && b) {
      const numb = Number((a / Number(b))) * 100
      return numb.toFixed(2);
    }
    return 0.00;
  }

  const updateChart = () => {
    let clone = chartData;
    if (campaignStats.current_data && campaignStats.target_data) {
      const { Impressions, Clicks, EngagementRate } = campaignStats.current_data;
      const { target_impression, target_clicks, target_er, target_cpe } = campaignStats.target_data;
      clone.map((clone: any) => {
        if (clone?.label === 'Impressions') {
          clone.stroke = calcAvg(Impressions, target_impression);
          clone.value = Impressions.toString();
          clone.rate = `${abvNum(Impressions)} / ${abvNum(target_impression)}`;
        }
        if (clone.label === 'Clicks') {
          clone.stroke = calcAvg(Clicks, target_clicks);
          clone.value = Clicks?.toString();
          clone.rate = `${abvNum(Clicks)} / ${abvNum(target_clicks)}`;
        }
        if (clone.label === 'ER') {
          clone.stroke = calcAvg(EngagementRate, target_er);
          clone.value = EngagementRate?.toString();
          clone.rate = `${abvNum(EngagementRate)} / ${abvNum(target_er)}`;
        }

      });
      clone.done = true;
      setChartData(clone);
    } else {
      clone.done = true;
    }
  }


  // useEffect(() => {
  //   var new_arr: any = [];
  //   if (campaignStats && campaignData) {
  //     if (!campaignStats.target_data) {
  //       return;
  //     }
  //     let paid = payed;
  //     if (campaignData.Payout) {
  //       campaignData.Payout.forEach((e: any, i: number) => {
  //         if (e.Paid) {
  //           paid += e.FixedFee;
  //         }
  //       });
  //       setPayed(paid);
  //     }
  //     if (campaignStats.current_data && campaignStats.target_data) {
  //       updateChart();
  //     }

  //   }
  // }, [campaignStats, campaignData]);

  // const [timeframe, setTimeframe] = useState('');
  // const diffTime = Math.abs(new Date(campaignData?.StartDate).valueOf() - new Date(campaignData?.EndDate).valueOf());
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return (
    <>
      {/* {!campaignData || !campaignData.CampaignId
        && <Skeleton height={70} count={3} />
      } */}
      {/* {campaignData && campaignData.CampaignId && */}
      <div className={`card ${className}`}>
        <div className='card-body row'>
          <div className="col-xl-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-start">
                <img src={influencerDetails?.LogoURL || toAbsoluteUrl('/media/cpg/na.png')} alt="@"
                  style={{ width: '185px', height: '185px', marginRight: '12px', borderRadius: '18px', display: 'block' }} />
                <div className='ml-2 pt-1'>
                  <h5 className="fw-bold mb-0" style={{ fontSize: '20px' }}>{influencerDetails?.PublisherName || ''}</h5>
                  {/* <span className="d-block fs-6 fw-lighter text-muted mb-4">@petitelife_inclors</span> */}
                  <div className="mb-1">
                    <span className="ml-1 fs-6 fw-lighter">AQS</span>
                    <img src={toAbsoluteUrl('/media/cpg/circle-info.png')} style={{ width: '16px', height: '16px', marginLeft: '6px' }} />
                  </div>
                  <span className="d-flex align-items-center mb-4"
                    style={{ backgroundColor: '#F4F4FA', borderRadius: '8px', padding: '6px', width: 'max-content' }}>
                    <div style={{ width: '16px', height: '16px', borderRadius: '50%', backgroundColor: '#06D6A0', marginRight: '8px' }} />
                    <span className="fs-4 fw-bold">Good</span>
                  </span>
                  <span className="d-block fs-6 fw-lighter">Followers</span>
                  <span className="d-block fw-bolder fs-3">1.6m</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="d-flex" style={{
              // background:"red",
              justifyContent: 'space-between'
            }}>
              {chartData &&
                chs.map((chart: any, index: number) => (
                  <RadialChart key={index} {...chart} />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  )
}

export { InfluencerTopWidget }
