/* eslint-disable jsx-a11y/anchor-is-valid */
// loading, ghost, routing(id)...
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import * as actions from "../../../../_redux/campaign/Actions";
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import CreatorNew from './../../../Creator_New';
import * as functions from "../../../../../../global/functions";
import { CampaignStats } from './../../../CampaignStats';
import moment from "moment";
import { KTSVG } from "../../../../../../../_metronic/helpers"
import { CampaignReport } from './../../../report/CampaignReport';

const CampaignSummaryPdf = (props: any) => {
  let { id } = useParams<any>();
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const currentURL = window.location.href;
  let keywordExists = currentURL.includes("campaign-report-mgp-external");


  const { campaignDetails, links, campaignStats, analytics, activity, corrections, clicks, campaignPosts, campaignInflencers } = useSelector(
    (state: any) => ({
      campaignDetails: state.campaign.campaignDetails,
      campaignStats: state.campaign.campaignStats,
      links: state.campaign.links,
      campaignPosts: state.campaign.campaignPosts,
      analytics: state.campaign.analytics,
      campaignScore: state.campaign.campaignScore,
      clicks: state.campaign.clicks,
      corrections: state.campaign.corrections,
      campaignInflencers: state.campaign.campaignInflencers,
      campaignEngagementRates: state.campaign.campaignEngagementRates,
      activity: state.campaign.activity,
      campaignEngagementRatesError: state.campaign.campaignEngagementRatesError,
    })
  );

  console.log("downloaed")

  useEffect(() => {
    if (corrections && corrections.length > 0) {
      setCorrections(corrections)
    }
  }, [corrections]);


  const campaignSummaryError = false;
  const campaignSummary = {
    id: 3212,
    desc: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump',
    brand: 'mattersport.png',
    status: 'Active',
    creatorVisibility: true,
    bonusContent: false,
    budget: '$18,000',
    currentSpend: '$16,000',
    type: 'Paid',
    hashtag: '#Matterport',
    phase: '1',
    goal: 'Awareness',
  }
  const campaignId = '809327';

  const [targetImp, setTargetImp] = useState<number>(campaignStats?.target_data?.target_impression || 0);
  const [targetClicks, setTargetClicks] = useState<number>(campaignStats?.target_data?.target_clicks || 0);
  const [targetER, setTargetER] = useState<number>(campaignStats?.target_data?.target_er || 0);
  const [targetCPE, setTargetCPE] = useState<number>(campaignStats?.target_data?.target_cpe || 0);
  const [tab, setTab] = useState<number>(2);
  const [new_link, set_link] = useState<string>('');
  const [paid, set_paid] = useState<number>(0);
  const [socials, set_socials] = useState<any>([]);
  const [campaignsTargets, set_target] = useState<any>([]);
  const [contentView, setContentView] = useState<number>(0);
  const [summaryEdit, setSummaryEdit] = useState<boolean>(false);
  const [targetsEdit, setTargetsEdit] = useState<boolean>(false);
  const [campaignDescription, setCampaignDescription] = useState<string>('')
  const [campaignBudget, setBudget] = useState<number>(0);
  const [campaignHashtag, setHashtag] = useState("");
  const [type, setType] = useState("");
  const [phase, setPhase] = useState("");
  const [goal, setGoal] = useState("");
  const [startDateEdit, setStartDateEdit] = useState(campaignDetails?.StartDate);
  const [endDateEdit, setEndDateEdit] = useState(campaignDetails?.EndDate);
  const [linkEdit, setLinkEdit] = useState<boolean>(false);
  const [correction_data, setCorrections] = useState<any>([]);
  const [data_to_view, set_dat_open] = useState<any>(null);






  const targetEstimated: any = {};
  const targetActual: any = {};




  useEffect(() => {
    dispatch(actions.reset_campaign_info());
    dispatch(actions.fetchCampaignDetails(id));
    dispatch(actions.fetchStuff('activity', `?campaign_id=${id}`));
    dispatch(actions.fetchCampaignStats(id));
    // dispatch(actions.fetchCampaignPosts(id));
    // dispatch(actions.fetchCampaignInfluencers(id));
    // dispatch(actions.fetchStuff('links', '?campaign_id=' + id));
    // dispatch(actions.fetchStuff('click_analytics', `?mode=day&campaign_id=${id}`));
    // dispatch(actions.fetchStuff('clicks', id));
    // // dispatch(actions.fetchStuff('analytics', `?campaign_id=${id}`));
    

    // dispatch(actions.fetchStuff('corrections', `?campaign_id=${id}`));


  }, []);



 






  useEffect(() => {
    if (campaignStats && campaignDetails) {
      setTargetImp(campaignStats?.target_data?.target_impression || 0);
      setTargetClicks(campaignStats?.target_data?.target_clicks || 0);
      setTargetER(campaignStats?.target_data?.target_er || 0);
      setTargetCPE(campaignStats?.target_data?.target_cpe || 0);
      if (campaignStats?.target_data?.campaign_summary) {
        setCampaignDescription(campaignStats?.target_data?.campaign_summary.Description || campaignDetails?.Description || '');
        setBudget(campaignStats?.target_data?.campaign_summary.TotalBudget || campaignDetails?.TotalBudget || '0');
        setPhase(campaignStats?.target_data?.campaign_summary.phase || campaignDetails?.phase || '');
        setGoal(campaignStats?.target_data?.campaign_summary.goal || campaignDetails?.goal || '');
        setHashtag(campaignStats?.target_data?.campaign_summary.HashTag || campaignDetails?.HashTag || ',');
        setType(campaignStats?.target_data?.campaign_summary.Type || campaignDetails?.Paid ? 'Paid' : 'Unpaid');
      }
      let paid = 0;
      if (campaignStats?.target_data?.campaign_summary?.paid) {
        set_paid(campaignStats?.target_data.campaign_summary.paid);
        return;
      }
      if (campaignDetails.Payout) {
        campaignDetails.Payout.forEach((e: any, i: number) => {
          if (e.Paid) {
            paid += e.FixedFee;
          }
        });
        set_paid(paid);
      }


    }
  }, [campaignStats, campaignDetails])



 





  const classnames = (...classes: any) => classes.filter(Boolean).join(' ');





  return (
    <div className="external_report with_pdf">
      {/* <button className="btn btn-sm rounded rounded-2 px-4 mb-4" style={{ marginRight: 10 }}
        onClick={() => history.goBack()}
      >
        Go Back
      </button> */}
      <div className='d-flex' style={{
        justifyContent: "space-between"
      }}>
        <div className='d-flex align-items-center' style={{ flex: 1 }}>
          {/* <button className={classnames((tab === 0 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 me-4")}
            onClick={() => setTab(0)}
          >
            <img src={toAbsoluteUrl('/media/cpg/summary-icon.png')} style={{ width: '16px', marginRight: '4px' }} />
            Summary
          </button>
          <button className={classnames((tab === 1 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4")}
            onClick={() => setTab(1)}
          >
            # Stats
          </button> */}
          {/* <button className={classnames((tab === 2 ? "btn-primary active text-white" : "text-gray-600 btn-link"), "btn rounded rounded-2 px-4 mx-4")}
            onClick={() => setTab(2)}
          >
            # Reports
          </button> */}
        </div>


        {/* <div>Hello worl!</div> */}
        {/* {campaignDetails &&
          <span className="d-block fw-bolder" style={{ fontSize: 18 }}>{campaignDetails?.CampaignName}</span>
        } */}
      </div>
     
      {(tab === 2  && campaignDetails) && <CampaignReport isExternal={true} isNotAmyris={keywordExists} isPdfPage={true} campaignId={id} thumbnail={campaignDetails?.ThumbLogoUrl} />}
    </div>
  )
}


export { CampaignSummaryPdf }
